import React, { useState } from "react";
import {
  MainWrapper,
  Container,
  Section,
} from "pages/TeamPage/TeamPage.styled";
import TableComponent from "components/core/TableComponent";
import PageHeader from "components/layout/PageHeader";
import FilterBar from "components/core/FilterBar";

type GroupsPageProps = {};

const GroupsPage: React.FC<GroupsPageProps> = () => {
  const [filter, setFilter] = useState();
  return (
    <MainWrapper>
      <Container>
        <PageHeader
          title={"Team Groups"}
          subtitle={"You can manage and audit finstead team groups here."}
        />
      </Container>
      <Section>
        <FilterBar setFilter={setFilter} />
        <TableComponent
          title={"Groups"}
          endpoint="authentication/groups/"
          filter={filter}
          fields={{
            id: { name: "Id" },
            name: { name: "Name" },
            assigned: { name: "Assigned" },
            permissions: { name: "Permissions" },
            status: { name: "Status" },
            created_at: { name: "Created At" },
          }}
        />
      </Section>
    </MainWrapper>
  );
};

export default GroupsPage;
