import React, { useState, useEffect, useCallback } from "react";
import LineChart from "components/metrics/LineChart";
import constants from "styles/constants";
import { MainWrapper } from "pages/DashboardPage/DashboardPage.styled";
import { useParams } from "react-router-dom";
import PageHeader from "components/layout/PageHeader";
import { Container, Section } from "pages/TeamPage/TeamPage.styled";
import { get, post } from "utilities/Network";
import SectionHeader from "components/layout/SectionHeader";
import { BrokerageChartWrapper } from "pages/DashboardPage/DashboardPage.styled";
import DateRangeSelect from "components/inputs/DateRangeSelect";
import { formatUnderCase, timeString, formatMoney } from "utilities/Strings/";
import DropDownField from "components/inputs/DropDownField";
import Button from "components/core/Button";
import { toast } from "react-toastify";

const getChartGranularity = (periodType: string) => {
  if (periodType === "today") {
    return "hour";
  } else {
    return "day";
  }
};

const TeamMemberPage = () => {
  const { teamMemberId } = useParams();
  const [promptModal, setPromptModal] = useState();
  const [member, setMember] = useState<any>({});
  const [periodRangeStart, setPeriodRangeStart] = useState<Date>();
  const [brokerDashboardStats, setBrokerDashboardStats] = useState<any>({});
  const [periodRangeEnd, setPeriodRangeEnd] = useState<Date>();
  const [period, setPeriod] = useState<any>({
    title: "This Month",
    value: "this_month",
  });

  const resetPassword = () => {
    toast.info("Just a moment...", {
      theme: "dark",
    });
    get(`authentication/team/${teamMemberId}/reset-password/`)
      .then((response) => {
        toast.info("Reset Password Email Sent", {
          theme: "dark",
        });
      })
      .catch((response) => {
        toast.error("Failed to send reset password email", {
          theme: "dark",
        });
      });
  };

  useEffect(() => {
    get(`authentication/team/${teamMemberId}/`).then((response) =>
      setMember(response.data)
    );
  }, [teamMemberId]);

  const fetchBrokerMetrics = useCallback(
    (cb: any) => {
      post("reports/broker-metrics/", {
        period: period?.value,
        period_range: {
          start: periodRangeStart,
          end: periodRangeEnd,
        },
        broker: teamMemberId,
      }).then((response: any) => {
        cb(response);
      });
    },
    [period?.value, periodRangeEnd, periodRangeStart, teamMemberId]
  );

  useEffect(() => {
    console.log(period);

    fetchBrokerMetrics((response: any) => {
      setBrokerDashboardStats(response.data);
      if (period?.value !== "custom_range") {
        setPeriodRangeStart(new Date(response.data.period.start));
        setPeriodRangeEnd(new Date(response.data.period.end));
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [period, teamMemberId]);

  return (
    <MainWrapper>
      <Container>
        <PageHeader
          title={`${member?.name || teamMemberId}`}
          subtitle={<>{teamMemberId}</>}
          breadcrumbs={[
            {
              title: "Team",
              link: "/team",
            },
          ]}
        />
      </Container>
      <Section>
        <div className="w-full h-full flex flex-row gap-8 mt-10">
          <div className="w-1/3 rounded-md bg-slate-100 min-h-[400px] p-4">
            <h2 className="text-2xl font-bold">{member?.name}</h2>
            <hr />
            <div className="mt-2 flex w-full h-full flex-col">
              <div className="w-full flex gap-2 flex-row">
                <label className="w-1/2">First Name:</label>
                <label className="w-1/2 font-bold">{member?.first_name}</label>
              </div>
              <div className="w-full flex gap-2 flex-row">
                <label className="w-1/2">Last Name:</label>
                <label className="w-1/2 font-bold">{member?.last_name}</label>
              </div>
              <div className="w-full flex gap-2 flex-row">
                <label className="w-1/2">Email Address:</label>
                <label className="w-1/2 font-bold">
                  {member?.email_address}
                </label>
              </div>
              <div className="w-full flex gap-2 flex-row">
                <label className="w-1/2">Personal Mobile:</label>
                <label className="w-1/2 font-bold">
                  {member?.personal_mobile}
                </label>
              </div>
              <div className="w-full flex gap-2 flex-row">
                <label className="w-1/2">Last Login:</label>
                <label className="w-1/2 font-bold">{member?.last_login}</label>
              </div>
              <div className="w-full flex gap-2 flex-row">
                <label className="w-1/2">Last Active:</label>
                <label className="w-1/2 font-bold">{member?.last_active}</label>
              </div>
              <div className="w-full flex gap-2 flex-row">
                <label className="w-1/2">Last Endpoint:</label>
                <label className="w-1/2 font-bold">
                  {member?.last_endpoint}
                </label>
              </div>
              <div className="w-full flex gap-2 flex-row">
                <label className="w-1/2">Last Password Reset:</label>
                <label className="w-1/2 font-bold">
                  {member?.last_password_reset}
                </label>
              </div>
              <div className="w-full flex gap-2 flex-row">
                <label className="w-1/2">Requires MFA Reset:</label>
                <label className="w-1/2 font-bold">
                  {member?.requires_2fa_reset}
                </label>
              </div>
              <div className="w-full flex gap-2 flex-row">
                <label className="w-1/2">Requires Password Reset:</label>
                <label className="w-1/2 font-bold">
                  {member?.requires_password_reset}
                </label>
              </div>
              <div className="w-full mt-10 flex gap-4 flex-wrap">
                <Button
                  danger
                  onClick={() => {}}
                  text="Deactivate"
                  wide
                ></Button>
                <Button
                  wide
                  warning
                  onClick={resetPassword}
                  text="Reset Password"
                ></Button>
                <Button
                  wide
                  warning
                  onClick={() => {}}
                  text="Reset MFA"
                ></Button>
                <Button
                  wide
                  warning
                  onClick={() => {}}
                  // onClick={() => setPromptModal()}
                  text="Force Logout"
                ></Button>
              </div>
            </div>
          </div>
          <div className="w-2/3 rounded-md bg-slate-100 min-h-[400px] p-4">
            <Section>
              <SectionHeader
                title={"Activity"}
                subtitle={"General Activity"}
                filters={
                  <DropDownField
                    label={"Period"}
                    value={period.value}
                    onChange={(v) =>
                      setPeriod({ value: v, text: formatUnderCase(v) })
                    }
                    options={[
                      {
                        text: "Today",
                        value: "today",
                      },
                      {
                        text: "This Week",
                        value: "this_week",
                      },
                      {
                        text: "This Month",
                        value: "this_month",
                      },
                      {
                        text: "This Quarter",
                        value: "this_quarter",
                      },
                      {
                        text: "This Year",
                        value: "this_year",
                      },
                    ]}
                  />
                }
              />
              <BrokerageChartWrapper>
                <LineChart
                  XAxisDataKey={"label"}
                  lines={[
                    {
                      dataKey: "Auditable Events",
                      colour: constants.colors.success_700,
                    },
                  ]}
                  tooltip={true}
                  configuration={{
                    period: period.value,
                    period_range: {
                      start: periodRangeStart,
                      end: periodRangeEnd,
                    },
                    query_date_field: "created_at",
                    model: "CRMAuditTrailEvent",
                    app: "authentication",
                    granularity: getChartGranularity(period.value),
                    segregate: false,
                    data_key: "Auditable Events",
                    broker: teamMemberId,
                    broker_lookup_field: "user",
                  }}
                />
              </BrokerageChartWrapper>
            </Section>
          </div>
        </div>
      </Section>
    </MainWrapper>
  );
};

export default TeamMemberPage;
