import React, { useState } from "react";
import Button from "components/core/Button";
import * as RSP from "react-sliding-pane";

type SlidingPaneProps = RSP.ReactSlidingPaneProps & {
  cancelPromptTitle: string;
  cancelPromptMessage: string;
  onCancelHideCancelPrompt?: boolean;
};

type ConfirmationPromptProps = {
  title: string;
  message: string;
  onConfirm: () => void;
  onCancel: () => void;
};

const ConfirmationPrompt = ({
  title,
  message,
  onConfirm,
  onCancel,
}: ConfirmationPromptProps) => {
  return (
    <div
      className="w-full h-full bg-black bg-opacity-75 absolute top-0 left-0 flex justify-center items-center"
      style={{ zIndex: 999999 }}
    >
      <div className="bg-white rounded-xl max-w-[50%] max-h-[50%] min-w-[150px] min-h-[150px] p-8 text-center">
        <h2 className="text-2xl font-bold">{title}</h2>
        <p className="mt-2">{message}</p>
        <div className="w-full flex items-center justify-center gap-8 mt-6">
          <Button onClick={onConfirm} text={"Confirm"} warning wide />
          <Button onClick={onCancel} text={"Go Back"} wide />
        </div>
      </div>
    </div>
  );
};

const SlidingPane: React.FC<SlidingPaneProps> = (props) => {
  const [showConfirmationPrompt, setShowConfirmationPrompt] = useState(false);
  return (
    <>
      {showConfirmationPrompt && (
        <ConfirmationPrompt
          title={props.cancelPromptTitle}
          message={props.cancelPromptMessage}
          onConfirm={() => {
            props.onRequestClose();
            setShowConfirmationPrompt(false);
          }}
          onCancel={() => setShowConfirmationPrompt(false)}
        />
      )}
      <RSP.ReactSlidingPane
        {...{
          ...props,
          onRequestClose: () => {
            if (!props.onCancelHideCancelPrompt) {
              setShowConfirmationPrompt(true);
            } else {
              props.onRequestClose();
            }
          },
        }}
      />
    </>
  );
};

export default SlidingPane;
