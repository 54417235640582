import React from "react";
import Badge, { BadgeType } from "components/core/Badge";

type DealStatusBadgeProps = {
  status: string;
};

const DealStatusBadge: React.FC<DealStatusBadgeProps> = ({ status }) => {
  switch (status) {
    case "open":
      return <Badge text={"OPEN"} type={BadgeType.info} />;
    case "lost":
      return <Badge text={"LOST"} type={BadgeType.danger} />;
    case "won":
      return <Badge text={"WON"} type={BadgeType.success} />;
    case "settled":
      return <Badge text={"SETTLED"} type={BadgeType.success} />;
    default:
      return <Badge text={status?.toUpperCase()} type={BadgeType.info} />;
  }
};

export default DealStatusBadge;
