import React, { useEffect } from "react";

declare global {
  interface Window {
    intercomSettings: {
      api_base: string;
      app_id: string;
    };
    Intercom: any;
  }
}

interface IntercomFunction {
  (...args: any[]): void;
  q?: any[];
  c?: (...args: any[]) => void;
}
const Intercom: React.FC = () => {
  useEffect(() => {
    window.intercomSettings = {
      api_base: "https://api-iam.intercom.io",
      app_id: "p1qcm32n",
    };

    const w = window;
    const ic = w.Intercom;

    if (typeof ic === "function") {
      ic("reattach_activator");
      ic("update", w.intercomSettings);
    } else {
      const i: IntercomFunction = function (...args: any[]) {
        i.c!(...args);
      };
      i.q = [];
      i.c = function (...args: any[]) {
        i.q!.push(args);
      };
      w.Intercom = i;
    }

    const s = document.createElement("script");
    s.type = "text/javascript";
    s.async = true;
    s.src = "https://widget.intercom.io/widget/p1qcm32n";
    const x = document.getElementsByTagName("script")[0];
    x.parentNode?.insertBefore(s, x);
  }, []);

  return null; // return null if you don't want to render anything, or return the necessary JSX if you have something to render.
};

export default Intercom;
