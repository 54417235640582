import styled from "styled-components";

import { TextSmMedium, TextMdNormal, TextSmBold } from "styles/mixins";

export const ErrorMessage = styled.p`
  margin-top: 0px;
  padding-top: 0px;
  margin-bottom: 15px;
  font-size: ${TextSmMedium};
  text-align: right;
  color: red;
`;

export const Wrapper = styled.div`
  display: flex;
  align-self: stretch;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
`;
export const InputFieldBase = styled.div`
  display: flex;
  align-self: stretch;
  flex-direction: column;
  align-items: flex-start;
  gap: 6px;
`;
export const InputWithLabel = styled.div`
  display: flex;
  align-self: stretch;
  flex-direction: column;
  align-items: flex-start;
  gap: 6px;
`;
export const Label = styled.span`
  text-align: left;
  color: ${({ theme }) => theme.colors.gray_700};

  ${TextSmBold}
`;
export type InputProps = {
  disabled?: boolean;
  hasError?: boolean;
  loading?: boolean;
};
export const Input = styled("input")<InputProps>`
  display: flex;
  background: {props => props.disabled || props.loading ? props.theme.colors.gray_100 : "#fff"};
  filter: drop-shadow(inset 0px 1px 2px rgba(1, 2, 2, 0.05));
  border: 1px solid
    ${(props) =>
      !!props.hasError ? props.theme.colors.error_500 : "rgb(208, 213, 221)"};
  border-radius: 8px;
  overflow: hidden;
  align-self: stretch;
  align-items: center;
  padding: 10px 14px 10px 14px;
  gap: 8px;
  ${TextMdNormal}
  ::placeholder {
    color: ${(props) => props.theme.colors.gray_500};
  }
`;
