import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import {
  StyledPath,
  Styled1,
  Divider,
  Header,
  Navigation,
  Nav,
  ContentWrapper,
  SidebarNavigationWrapper,
} from "./SideMenu.styled";
import ReactTooltip from "react-tooltip";

import SideMenuFooter from "components/layout/SideMenuFooter";

import NavItem, { NavItemProps } from "components/layout/NavItem/NavItem";
import Logo from "components/core/Logo";
import { setLocalStorageItem } from "utilities/LocalStorage";

export type SideMenuProps = {
  nav_item_props?: Array<NavItemProps>;
  hidden?: boolean;
  collapsed?: boolean;
  setCollapsed?: any;
};

const SideMenu: React.FC<SideMenuProps> = ({
  nav_item_props,
  hidden = false,
  collapsed = false,
  setCollapsed = () => {},
}) => {
  const location = useLocation();

  if (location.pathname === "/login") {
    setLocalStorageItem("auth", null);
    return null;
  }

  if (hidden) {
    return null;
  }

  return (
    <SidebarNavigationWrapper
      style={collapsed ? { width: "55px" } : {}}
      // onMouseEnter={() => setCollapsed(false)}
      // onMouseLeave={() => setCollapsed(true)}
    >
      <ReactTooltip />
      <Divider>
        <Styled1>
          <StyledPath d={`M 1 5000 L 0 5000 L 0 0 L 1 0 L 1 5000 Z`} />
        </Styled1>
      </Divider>
      <ContentWrapper>
        <Nav>
          <Header style={collapsed ? { paddingLeft: "0px" } : {}}>
            <Logo
              width={"200px"}
              style={
                collapsed
                  ? {
                      position: "absolute",
                      left: "9px",
                      top: "35px",
                      width: "145px",
                    }
                  : {}
              }
            />
          </Header>
          {collapsed && <br />}
          <Navigation style={collapsed ? { paddingLeft: "5px" } : {}}>
            {nav_item_props?.map((p, i) => (
              <NavItem {...p} key={i} collapsed={collapsed} />
            ))}
          </Navigation>
        </Nav>
        <SideMenuFooter collapsed={collapsed} />
      </ContentWrapper>
    </SidebarNavigationWrapper>
  );
};

export default SideMenu;
