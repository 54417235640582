import React, { useEffect, useState, useCallback } from "react";

export type ThreeLoadingDotsType = {};

const ThreeLoadingDots: React.FC<ThreeLoadingDotsType> = ({}) => {
  const [content, setContent] = useState(".");

  const updateContent = useCallback(() => {
    if (content == ".") {
      setContent("..");
    } else if (content == "..") {
      setContent("...");
    } else if (content == "...") {
      setContent(".");
    }
  }, [content]);

  useEffect(() => {
    const intervalId = setInterval(updateContent, 500);
    return () => clearInterval(intervalId);
  }, [updateContent]);

  return <>{content}</>;
};

export default ThreeLoadingDots;
