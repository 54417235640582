import React from "react";
import { animated, useSpring } from "react-spring";
import styled from "styled-components";
import InfoBlock from "reports_pages/InfoBlock";
import { DataReport } from "types";
import LogoSourceDark from "assets/brand/logo-dark.svg";

type ReportContainerProps = {
  children: any;
  report: DataReport;
};

const Container = styled.div`
  min-height: 1500px;
  width: 100vw;
`;

const ReportContainer = ({ children, report }: ReportContainerProps) => {
  const fadeInProps = useSpring({
    opacity: 1,
    transform: "translate(0px, 0px)",
    from: {
      opacity: 0,
      transform: "translate(-150px, 0px)",
    },
  });

  //

  return (
    <Container className="flex justify-center bg-white">
      <div className="w-full max-w-[1200px] mt-6 p-4 border-2 border-slate-400 rounded-md bg-white mb-6">
        {/* Header */}
        <div className=" flex flex-row mb-6">
          {/* Logo */}
          <div className="w-1/3 flex items-left flex-col gap-6">
            <img src={LogoSourceDark} className="max-w-[170px]" />
            <h1 className="text-3xl font-medium text-slate-800 mt-5">
              {report.details.title}
            </h1>
          </div>
          <div className="w-full lg:w-1/3 text-center font-bold text-sm">
            PRIVATE AND CONFIDENTIAL
          </div>
          {/* File Details */}
          <div className="w-1/3 flex justify-end text-sm">
            <InfoBlock
              info={{
                "Report ID": report.details.id,
                "Report Period": `${report.details.current_period.from} -> ${report.details.current_period.to}`,
                "Comparison Period": `${report.details.comparison_period.from} -> ${report.details.comparison_period.to}`,
                "Report Frequency": report.details.frequency,
                "Report Generated": report.details.generated_date,
                "Accessing As": report.details.accessor,
                Authorisation: report.details.accessor_title,
              }}
            />
          </div>
        </div>

        {/* Line Break */}
        <hr />

        {/* Report Content */}
        <div>{children}</div>
      </div>
    </Container>
  );
};

export default ReportContainer;
