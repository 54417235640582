import axios from "axios";
import Button from "components/core/Button";
import LoaderLogo from "components/core/LoaderLogo";
import InputField from "components/inputs/InputField";
import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { clearLocalStorage, setLocalStorageItem } from "utilities/LocalStorage";
import { post } from "utilities/Network";

const checkStringForNumbers = (input: string): boolean => {
  return /\d/.test(input);
};

const stringHasUpperCase = (input: string): boolean => {
  return /[A-Z]/.test(input);
};

const stringHasLowerCase = (input: string): boolean => {
  return /[a-z]/.test(input);
};

const renderCheck = (check: boolean, message: string): JSX.Element => {
  const icon = check ? (
    <span>
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M2 14L5.23309 16.4248C5.66178 16.7463 6.26772 16.6728 6.60705 16.2581L15 6"
          stroke="#009900"
          stroke-width="2"
          stroke-linecap="round"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M9.87439 15.7802L11.603 17.163C12.4588 17.8477 13.7062 17.716 14.4003 16.8678L22.7739 6.63327C23.1237 6.20582 23.0607 5.5758 22.6332 5.22607C22.2058 4.87635 21.5758 4.93935 21.226 5.36679L12.8523 15.6013L11.1409 14.2322L9.87439 15.7802Z"
          fill="#009900"
        />
      </svg>
    </span>
  ) : (
    <span>
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M2 14L5.23309 16.4248C5.66178 16.7463 6.26772 16.6728 6.60705 16.2581L15 6"
          stroke="#e6e6e6"
          stroke-width="2"
          stroke-linecap="round"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M9.87439 15.7802L11.603 17.163C12.4588 17.8477 13.7062 17.716 14.4003 16.8678L22.7739 6.63327C23.1237 6.20582 23.0607 5.5758 22.6332 5.22607C22.2058 4.87635 21.5758 4.93935 21.226 5.36679L12.8523 15.6013L11.1409 14.2322L9.87439 15.7802Z"
          fill="#e6e6e6"
        />
      </svg>
    </span>
  );
  return (
    <div className="flex flex-row gap-4">
      {icon} {message}
    </div>
  );
};

const PartnerWelcomePage: React.FC = () => {
  const { token } = useParams();
  const navigate = useNavigate();
  const [primaryPassword, setPrimaryPassword] = useState("");
  const [secondaryPassword, setSecondaryPassword] = useState("");
  const [passwordValid, setPasswordValid] = useState(false);
  const [submitAttempted, setSubmitAttempted] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [loading, setLoading] = useState(true);
  const [tokenValid, setTokenValid] = useState(false);
  const [passwordSet, setPasswordSet] = useState(false);

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [mobileNumber, setMobileNumber] = useState("");
  const [jobTitle, setJobTitle] = useState("");

  const [paymentEntity, setPaymentEntity] = useState("");
  const [paymentBSB, setPaymentBSB] = useState("");
  const [paymentAccountNumber, setPaymentAccountNumber] = useState("");
  const [bankDetailsValid, setBankDetailsValid] = useState(false);
  const [activated, setActivated] = useState(true);

  useEffect(() => {
    const ppasswordValid =
      primaryPassword.length >= 8 &&
      primaryPassword.length <= 99 &&
      stringHasLowerCase(primaryPassword) &&
      stringHasUpperCase(primaryPassword) &&
      checkStringForNumbers(primaryPassword) &&
      primaryPassword === secondaryPassword &&
      primaryPassword.length >= 1;

    setPasswordValid(ppasswordValid);
  }, [primaryPassword, secondaryPassword, submitAttempted]);

  // Validate the BSB using regex
  const validateBSB = (bsb: any) => {
    const regex = /^(?:\s*\d{3}\s*\-?\s*\d{3}\s*)$/;
    return regex.test(bsb);
  };

  // Validate the account number (we'll assume 6 to 10 digits for this example)
  const validateAccountNumber = (accountNumber: any) => {
    const regex = /^\d{6,10}$/;
    return regex.test(accountNumber);
  };

  useEffect(() => {
    const bbankDetailsValid =
      validateBSB(paymentBSB) &&
      validateAccountNumber(paymentAccountNumber) &&
      firstName.length > 3 &&
      lastName.length > 2 &&
      jobTitle.length > 2 &&
      mobileNumber.length > 8 &&
      paymentEntity.length >= 1; // Check this based on your entity validation criteria

    setBankDetailsValid(bbankDetailsValid);
  }, [
    paymentAccountNumber,
    paymentBSB,
    paymentEntity,
    firstName,
    lastName,
    jobTitle,
    mobileNumber,
  ]);

  useEffect(() => {
    // verify the token
    post(
      "partners/validate-activation-token/",
      {
        token,
      },
      {
        headers: {
          Authorization: token,
        },
      }
    )
      .then((response) => {
        if (response.data.status !== "valid") {
          setTokenValid(false);
          setLoading(false);
        } else {
          setTokenValid(true);
          setLoading(false);
        }
      })
      .catch(() => {
        setTokenValid(false);
        setLoading(false);
      });
  }, [token]);

  const handleActivate = () => {
    if (submitting || !bankDetailsValid) return;
    setSubmitting(true);

    post(
      "partners/complete-setup/",
      {
        first_name: firstName,
        last_name: lastName,
        job_title: jobTitle,
        contact_number: mobileNumber,
        bank_account_number: paymentAccountNumber,
        bank_account_name: paymentEntity,
        bank_account_bsb: paymentBSB,
      },
      {
        headers: {
          Authorization: token,
        },
      }
    ).then((response) => {
      setActivated(true);
      const auth = {
        token: response.data.token,
        user: {
          name: {
            first: firstName,
            last: lastName,
          },
        },
      };
      setLocalStorageItem("auth", auth);
      axios.defaults.headers.common.authorization = auth?.token;
      navigate("/");
    });
  };

  const handleSubmit = () => {
    if (submitting || !passwordValid) return;
    setSubmitAttempted(true);
    setSubmitting(true);

    post(
      "partners/reset-password/",
      {
        password: primaryPassword,
      },
      {
        headers: {
          Authorization: token,
        },
      }
    ).then((response) => {
      setSubmitting(false);
      setPasswordSet(true);
    });
  };

  if (!loading && !tokenValid) {
    <div className="w-full h-full flex items-center justify-center bg-white">
      <h2>
        Sorry, That invitation link is invalid or has expired. Please get in
        touch with your contact at Finstead to organise a replacement.
      </h2>
    </div>;
  }

  if (loading) {
    return (
      <div className="w-full h-full flex items-center justify-center bg-white">
        <LoaderLogo withText light />
      </div>
    );
  }

  let content = (
    <>
      <h1 className="text-center text-3xl mb-8">
        Welcome to the Finstead Partner Program
      </h1>
      <h2 className="text-center text-xl mb-8">
        Please set a password for your account.
      </h2>
      <div className="m-auto max-w-[400px] w-full text-center mb-4">
        {renderCheck(
          primaryPassword.length >= 8 && primaryPassword.length <= 99,
          "Must be between 8 and 99 characters long."
        )}
        {renderCheck(
          stringHasLowerCase(primaryPassword),
          "Must contain one or more lower case letters."
        )}
        {renderCheck(
          stringHasUpperCase(primaryPassword),
          "Must contain one or more upper case letters."
        )}
        {renderCheck(
          checkStringForNumbers(primaryPassword),
          "Must contain one or more numbers."
        )}

        {renderCheck(
          primaryPassword === secondaryPassword && primaryPassword.length >= 1,
          "Passwords must match."
        )}
      </div>
      {/* <form className="pb-4" onSubmit={handleSubmit(formSubmit)}> */}
      <div className="pb-4 mt-4">
        <InputField
          type="password"
          placeholder={"Enter a password"}
          value={primaryPassword}
          onChange={setPrimaryPassword}
          disabled={submitting || loading}
        />

        <InputField
          type="password"
          placeholder={"Repeat the password"}
          value={secondaryPassword}
          onChange={setSecondaryPassword}
          disabled={submitting || loading}
        />

        <div className="w-full text-center">
          <p className="mt-4 mb-4">
            By clicking "Continue" you confirm you have read and accept our{" "}
            <a
              href={"https://finstead.com.au/terms/"}
              className="pointer-cursor text-blue-600"
            >
              Terms of Service
            </a>{" "}
            and{" "}
            <a
              href={"https://finstead.com.au/privacy/"}
              className="pointer-cursor text-blue-600"
            >
              Privacy Policy
            </a>
          </p>
          <Button
            onClick={handleSubmit}
            text={submitting ? "Just a moment..." : "Continue"}
            disabled={submitting || !passwordValid}
            type={"submit"}
            primary
            width={"250px"}
          />
        </div>
        {/* </form> */}
      </div>
    </>
  );
  if (passwordSet) {
    content = (
      <>
        <h1 className="text-center text-3xl mb-8">Almost There!</h1>
        <h2 className="text-center text-xl mb-8">
          We just need a little more information about you and bank details for
          commission payouts.
        </h2>
        <div className="pb-4 mt-4">
          <InputField
            type="text"
            placeholder={"First name"}
            value={firstName}
            onChange={setFirstName}
            disabled={submitting || loading}
          />
          <InputField
            type="text"
            placeholder={"Last Name"}
            value={lastName}
            onChange={setLastName}
            disabled={submitting || loading}
          />

          <InputField
            type="text"
            placeholder={"Job Title"}
            value={jobTitle}
            onChange={setJobTitle}
            disabled={submitting || loading}
          />
          <InputField
            type="text"
            placeholder={"Mobile Number"}
            value={mobileNumber}
            onChange={setMobileNumber}
            disabled={submitting || loading}
          />
          <InputField
            type="text"
            placeholder={"Pay To (Entity Name)"}
            value={paymentEntity}
            onChange={setPaymentEntity}
            disabled={submitting || loading}
          />
          <InputField
            type="text"
            placeholder={"BSB"}
            value={paymentBSB}
            onChange={setPaymentBSB}
            disabled={submitting || loading}
          />
          <InputField
            type="text"
            placeholder={"Account Number"}
            value={paymentAccountNumber}
            onChange={setPaymentAccountNumber}
            disabled={submitting || loading}
          />

          <div className="w-full text-center">
            <Button
              onClick={handleActivate}
              text={submitting ? "Loading..." : "Activate"}
              disabled={submitting || !bankDetailsValid}
              type={"submit"}
              primary
              width={"350px"}
            />
          </div>
        </div>
      </>
    );
  }

  return (
    <div className="w-full h-full flex items-center justify-center bg-white">
      <div className="max-w-full max-h-full p-5 w-[650px] h-auto border-2 border-gray-300 bg-gray-100 min-h-[400px] rounded-md">
        {content}
      </div>
    </div>
  );
};

export default PartnerWelcomePage;
