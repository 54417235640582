import React, { useState, useEffect } from "react";
import * as yup from "yup";
import InputField from "components/inputs/InputField";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import TextArea from "components/inputs/TextArea";
import DropDownField from "components/inputs/DropDownField";
import { get, post } from "utilities/Network";
import Button from "components/core/Button";

type FormData = {
  name: string;
  details: string;
  assignedTo: number;
  taskType: string;
};

const FormSchema = yup.object({
  name: yup.string().min(2).max(255).required("You must name the task"),
  details: yup.string().nullable().max(2048),
  assignedTo: yup
    .number()
    .required("You must select a group to perform the task"),
  taskType: yup.string().required("You must select a task type."),
});

const RequiredFieldsMap = {
  name: true,
  assignedTo: true,
  taskType: true,
  details: false,
};

type TaskFormProps = {
  dealId: any;
  onSubmit?: () => void;
};

const TaskForm: React.FC<TaskFormProps> = ({ dealId, onSubmit }) => {
  const [loading, setLoading] = useState(true);
  const [submitting, setSubmitting] = useState(false);
  const [teams, setTeams] = useState([]);

  // fetch team options
  useEffect(() => {
    get("team/task-groups/").then((response) => {
      setLoading(false);
      setTeams(response.data);
    });
  }, []);

  // use Form Hook
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<FormData>({
    resolver: yupResolver(FormSchema),
  });

  const formSubmit = (formData: FormData) => {
    console.log(formData);

    setSubmitting(true);
    post("tasks/create/", {
      ...formData,
      dealId,
    }).then((response) => {
      setSubmitting(false);
      if (!!onSubmit) onSubmit();
    });
  };

  return (
    <form className="pb-4" onSubmit={handleSubmit(formSubmit)}>
      <InputField
        formControlProps={register("name")}
        label={"Name"}
        loading={loading}
        disabled={submitting || loading}
        errorMessage={errors.name?.message}
        required={!!RequiredFieldsMap.name}
      />
      <TextArea
        formControlProps={register("details")}
        label={"Details"}
        loading={loading}
        disabled={submitting || loading}
        errorMessage={errors.details?.message}
        required={!!RequiredFieldsMap.details}
      />
      <DropDownField
        formControlProps={register("assignedTo")}
        label={"Assigned To Team"}
        loading={loading}
        disabled={submitting || loading}
        errorMessage={errors.assignedTo?.message}
        required={!!RequiredFieldsMap.assignedTo}
        placeholder={loading ? "Fetching Teams..." : "Please select a Team."}
        options={teams}
      />
      <DropDownField
        formControlProps={register("taskType")}
        label={"Task Type"}
        loading={loading}
        disabled={submitting || loading}
        errorMessage={errors.taskType?.message}
        required={!!RequiredFieldsMap.taskType}
        placeholder={"Please select a Task Type."}
        options={[
          {
            text: "Settlement",
            value: "Settlement",
          },
          {
            text: "Other",
            value: "Other",
          },
        ]}
      />
      <div className="w-full text-sm text-center mb-2">
        <p>Once created the assigned party will be notified.</p>
      </div>
      <Button
        text={submitting ? "Creating..." : "Create Task"}
        disabled={submitting || loading}
        type={"submit"}
        onClick={() => {}}
        primary
        wide
      />
    </form>
  );
};

export { TaskForm };
