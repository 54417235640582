import React, { useState } from "react";
import NewLenderForm from "./NewLenderForm";
import {
  MainWrapper,
  Container,
  Section,
} from "pages/TeamPage/TeamPage.styled";
import PageHeader from "components/layout/PageHeader";
import TableComponent from "components/core/TableComponent";
import FilterBar from "components/core/FilterBar";
import Button from "components/core/Button";
import SlidingPane from "components/layout/SlidingPane";
import { useNavigate } from "react-router-dom";

export type LenderPageProps = {};

export type Lender = {
  id: string;
  business_name: string;
  entity_name: string;
  abn: string;
  contact_name: string;
  contact_number: string;
  contact_email_address: string;
  product_count: string;
};

const LendersPage: React.FC<LenderPageProps> = ({}) => {
  const navigate = useNavigate();
  const [isPaneOpen, setIsPaneOpen] = useState(false);
  const [filter, setFilter] = useState<string | undefined>();

  const newLender = () => {
    setIsPaneOpen(true);
  };

  return (
    <MainWrapper>
      <SlidingPane
        className="overlay-pane-content"
        overlayClassName="overlay-pane"
        isOpen={isPaneOpen}
        title="New Lender"
        width="800px"
        subtitle="Complete the form to create a new lender."
        onRequestClose={() => {
          // triggered on "<" on left top click or on outside click
          setIsPaneOpen(false);
        }}
        cancelPromptTitle={"Abandon new Lender?"}
        cancelPromptMessage={"Are you sure you'd like to abandon this form?"}
      >
        <NewLenderForm />
      </SlidingPane>
      <Container>
        <PageHeader
          title={"Lenders"}
          subtitle={"Our Lending partners"}
          rightHandSideChildren={
            <Button onClick={newLender} text={"New Lender"} primary />
          }
        />
      </Container>
      <Section>
        <FilterBar setFilter={setFilter} />
        <TableComponent
          title={"Lenders"}
          filter={filter}
          endpoint="lenders/"
          onRowClick={(row: any, newTab = false) => {
            if (newTab) {
              window.open(
                `https://crm.finstead.com.au/lenders/${row.id}`,
                "_blank",
                "noreferrer"
              );
            } else {
              navigate(`/lenders/${row.id}`);
            }
          }}
          fields={{
            id: {
              name: "Id",
            },
            business_name: {
              name: "Business Name",
            },
            entity_name: {
              name: "Entity Name",
            },
            abn: {
              name: "ABN",
            },
            contact_name: {
              name: "Contact Name",
            },
            contact_email_address: {
              name: "Contact Email Address",
            },
            product_count: {
              name: "Products",
            },
            settled_deals: {
              name: "Settled Deals",
            },
          }}
        />
      </Section>
    </MainWrapper>
  );
};

export default LendersPage;
