import { useCallback } from "react";
import { get } from "utilities/Network";

const useFileManager = ({ fileId }: any) => {
  const downloadFile = useCallback(() => {
    // fetch the download link for the file
    get(`deals/files/${fileId}/get-signed-download-link/`).then((response) => {
      console.log(response.data.link);
      const { downloadLink, key, type } = response.data;

      fetch(downloadLink)
        .then((res) => res.blob())
        .then((blob) => {
          const href = window.URL.createObjectURL(blob);
          const a = document.createElement("a");
          a.href = href;
          a.download = `${key}`;
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);
        });
    });
  }, [fileId]);

  const deleteFile = () => {};
  const updateFile = () => {};

  return { downloadFile, deleteFile, updateFile };
};

export default useFileManager;
