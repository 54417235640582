import styled from "styled-components";

import { TextSmMedium, TextMdNormal } from "styles/mixins";

export const InputFieldWrapper = styled.div`
  display: flex;
  align-self: stretch;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 16px;
`;
export const InputFieldBase = styled.div`
  display: flex;
  align-self: stretch;
  flex-direction: column;
  align-items: flex-start;
  gap: 6px;
`;
export const InputWithLabel = styled.div`
  display: flex;
  align-self: stretch;
  flex-direction: column;
  align-items: flex-start;
  gap: 6px;
`;
export const Label = styled.span`
  text-align: left;
  color: ${({ theme }) => theme.colors.gray_700};

  ${TextSmMedium}
`;
export type InputProps = {
  disabled?: boolean;
  hasError?: boolean;
  loading?: boolean;
};
export const TextArea = styled("textarea")<InputProps>`
  display: flex;
  background: {(props) => props.disabled || props.loading ? props.theme.colors.gray_100 : "#fff"};
  filter: drop-shadow(inset 0px 1px 2px rgba(16, 24, 40, 0.05));
  border: 1px solid ${(props) =>
    !!props.hasError ? props.theme.colors.error_500 : "rgb(208, 213, 221)"};
  border-radius: 8px;
  overflow: hidden;
  align-self: stretch;
  align-items: center;
  padding: 10px 14px 10px 14px;
  gap: 8px;
  ${TextMdNormal}
  ::placeholder {
    color: ${(props) => props.theme.colors.gray_500};
  }
  ${(props) =>
    props.loading &&
    "animation-name: background_pulse;animation-duration:1s;animation-iteration-count:infinite;"}
`;
