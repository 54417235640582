import Modal from "components/core/Modal";
import Button from "components/core/Button";
import { useMemo, useState, useRef } from "react";

const useAlert = () => {
  const act = useRef<() => void | null>();
  const [open, setOpen] = useState(false);
  const [statement, setStatement] = useState("Alert");
  const [confirmationText, setConfirmationText] = useState("Yes");

  const alertModal = useMemo(() => {
    return (
      <Modal
        isOpen={open}
        title={"Alert"}
        onRequestClose={() => setOpen(false)}
      >
        <div className="text-center p-3">
          <h2>{statement}</h2>
          <div className="mt-8 flex flex-row gap-6">
            <div className="w-full flex items-center justify-center">
              <Button
                wide
                warning
                text={confirmationText}
                onClick={() => {
                  if (!!act.current) {
                    act.current();
                  }
                  setOpen(false);
                }}
              />
            </div>
          </div>
        </div>
      </Modal>
    );
  }, [confirmationText, open, statement]);

  const alert = (_act: any, _statement: string, _confirmationText = "Yes") => {
    setStatement(_statement);
    setConfirmationText(_confirmationText);
    act.current = _act;
    setOpen(true);
  };

  return { alert, alertModal };
};

export default useAlert;
