import React, { useEffect, useState, useCallback } from "react";
import InputFieldWrapper from "components/inputs/InputFieldWrapper";
import { Input } from "components/inputs/InputField/InputField.styled";
import { get } from "utilities/Network";
import classnames from "classnames";
import LoaderLogo from "components/core/LoaderLogo";
import AsyncSelect from "react-select/async";
import classname from "classnames";
import IconBase from "components/core/IconBase";
import { ReactComponent as sunIcon } from "assets/icons/info.svg";
import ReactTooltip from "react-tooltip";

export type SearchSelectFieldProps = {
  label?: string;
  info?: string;
  valid?: boolean;
  subtitle?: string;
  placeholder?: string;
  type?: string;
  defaultValue?: any;
  ep: string;
  onChange?: (v: any) => void;
  disabled?: boolean;
  required?: boolean;
  errorMessage?: string;
  formControlProps?: any;
  loading?: boolean;
  getOptionLabel: any;
  newOptionLabel?: string;
  slim?: boolean;
  loadOptionsOnFirstLoad?: boolean;
  newOptionCb?: () => void;
};

const SearchSelectField: React.FC<SearchSelectFieldProps> = ({
  label,
  info,
  subtitle,
  placeholder,
  defaultValue,
  type,
  onChange,
  errorMessage,
  ep = "",
  slim = false,
  loading = false,
  disabled = false,
  required = false,
  getOptionLabel,
  formControlProps = {},
  newOptionLabel,
  valid = false,
  loadOptionsOnFirstLoad = false,
  newOptionCb,
}) => {
  const [searchQuery, setSearchQuery] = useState<string>("");
  const [searching, setSearching] = useState(false);
  const [instances, setInstances] = useState([]);
  const [timer, setTimer] = useState<any>();
  const [focused, setFocused] = useState(false);
  const [value, setValue] = useState(defaultValue);

  const loadOptions = useCallback(
    (inputValue: string, cb: (options: Array<any>) => void) => {
      if (!!timer) clearTimeout(timer);
      setTimer(
        setTimeout(() => {
          setSearching(true);
          get(`${ep}?q=${inputValue}`).then((response) => {
            setSearching(false);
            setInstances(response.data);
            cb(response.data);
          });
        }, 350)
      );
    },
    [ep, timer]
  );

  useEffect(() => {
    setValue(defaultValue);
  }, [defaultValue]);

  return (
    <InputFieldWrapper
      label={label}
      required={required}
      errorMessage={errorMessage}
    >
      <>
        {!!info && (
          <div className="absolute z-20" style={{ right: "33px" }}>
            <IconBase data-tip={info} src={sunIcon} />
          </div>
        )}

        {subtitle && <label>{subtitle}</label>}
        <AsyncSelect
          {...{
            ...formControlProps,
            onChange: (v: any) => {
              setValue(v);
              if (!!onChange) {
                onChange(v);
              }
              if (!!formControlProps.onChange) {
                formControlProps.onChange(v);
              }
            },
          }}
          value={value}
          className={`w-full`}
          styles={{
            control: (baseStyles, state) => ({
              ...baseStyles,
              borderWidth: 1,
              borderRadius: "8px",
              borderColor: valid
                ? "green"
                : !!errorMessage
                ? "red"
                : "rgb(208,213,221)",
              minHeight: "46px",
              backgroundColor: disabled ? "#FAFAFA" : "#FFFFFF",
            }),
          }}
          cacheOptions
          // inputValue={value}
          loadOptions={loadOptions}
          onInputChange={setSearchQuery}
          getOptionLabel={getOptionLabel}
          isDisabled={disabled}
          isClearable={true}
          placeholder={placeholder}
          {...(slim
            ? {
                components: {
                  DropdownIndicator: () => null,
                  IndicatorSeparator: () => null,
                },
              }
            : {})}
        />
        {!!newOptionLabel && (
          <div
            className="w-full text-right text-blue-600 text-sm cursor-pointer"
            onClick={newOptionCb}
          >
            {newOptionLabel}
          </div>
        )}

        {/* <Input
          onFocus={() => {
            setFocused(true);
          }}
          onBlur={() => {
            setFocused(false);
          }}
          loading={loading}
          placeholder={loading ? "" : placeholder}
          type={type}
          value={value}
          onChange={updateSearchQuery}
          disabled={disabled}
          hasError={!!errorMessage}
          className="mb-0 pb-0"
        ></Input> */}

        {/* {loading && (
          <span className="relative right-0 top-[-10px]">
            <LoaderLogo height={100} />
          </span>
        )}
        <div
          className={`overflow-hidden pl-4 w-full flex flex-col padding-5 border-slate-100 rounded-sm transition ease-in-out delay-150 ${classnames(
            {
              "h-[0px]": !focused,
            }
          )}`}
        >
          {instances.map((instance) => (
            <div className="flex items-center mb-2 pl-4 h-[30px] hover:bg-blue-200 hover:drop-shadow-lg border border-slate-300 rounded-md cursor-pointer">
              {optionText(instance)}
            </div>
          ))}
        </div> */}
      </>
    </InputFieldWrapper>
  );
};

export default SearchSelectField;
