import React, { useState, useEffect, useCallback } from "react";
import { useNavigate, useParams } from "react-router-dom";
import constants from "styles/constants";
import TableComponent from "components/core/TableComponent";
import Badge, { BadgeType } from "components/core/Badge";
import Tabs from "components/core/Tabs";
import IconBase from "components/core/IconBase";
import LineChart from "components/metrics/LineChart";
import BrokerDropDownField from "components/inputs/BrokerDropDownField";
import InputFieldWrapper from "components/inputs/InputFieldWrapper";
import { ReactComponent as userIcon } from "assets/icons/user.svg";
import { ReactComponent as funnelIcon } from "assets/icons/filter.svg";
import { ReactComponent as companyIcon } from "assets/icons/box.svg";
import { ReactComponent as mailIcon } from "assets/icons/mail.svg";
import { ReactComponent as alertIcon } from "assets/icons/triangle.svg";
import { ReactComponent as checkIcon } from "assets/icons/tick.svg";
import { ReactComponent as accountManagerIcon } from "assets/icons/star.svg";
import PartnerStatusBadge from "./PartnerStatusBadge";
import Box from "components/core/Box";
import { get, post } from "utilities/Network";
import {
  MainWrapper,
  Container,
  Section,
} from "pages/TeamPage/TeamPage.styled";
import { toast } from "react-toastify";
import PageHeader from "components/layout/PageHeader";
import InfoBox from "components/core/InfoBox";
import MultiSelectDropDownField from "components/inputs/MultiSelectDropDownField";
import { Checkbox } from "components/core/TableComponent/components/UserTableCell/UserTableCell.styled";
import Label from "components/core/Label";
import CheckBox from "components/core/CheckBox";
import Button from "components/core/Button";
import BoxMetric from "components/metrics/BoxMetric";

import useEditableText from "hooks/useEditableText";
import { useCopyToClipboard } from "usehooks-ts";
import PartnerDashboardPage from "partner_pages/PartnerDashboardPage";
import PartnerAPIAccessLogsView from "partner_pages/PartnerAPIAccessLogsView";

const PartnerDetailPage = ({}) => {
  const navigate = useNavigate();
  const { partnerId } = useParams();

  const [leadRecipients, setLeadRecipients] = useState([]);
  const [sendingInvitation, setSendingInvitation] = useState(false);
  const [sentInitialInvitation, setSentInitialInvitation] = useState(false);
  const [changesToLeadPool, setChangesToLeadPool] = useState(false);
  const [leadPoolCheckboxToggle, setLeadPoolCheckboxToggle] = useState<any>();
  const [usingGeneralLeadPool, setUsingGeneralLeadPool] = useState();
  const [copyValue, copy] = useCopyToClipboard();

  const [brokers, setBrokers] = useState();
  const [partner, setPartner] = useState<any | undefined>();

  const editablePartnerBusinessName = useEditableText({
    defaultText: partner?.business_name,
    handleSave: (value, cb: any) => {
      post(`partners/${partner?.id}/`, {
        field: "business_name",
        value,
      })
        .then(() => {
          toast.success("Partner business name updated.");
          cb();
        })
        .catch(() => cb(false));
    },
  });
  const partnerBusinessName = editablePartnerBusinessName;

  const editablePartnerPrimaryDomain = useEditableText({
    defaultText: partner?.primary_domain,
    handleSave: (value, cb: any) => {
      post(`partners/${partner?.id}/`, {
        field: "primary_domain",
        value,
      })
        .then(() => {
          toast.success("Partner primary domain name updated.");
          cb();
        })
        .catch(() => cb(false));
    },
  });
  const partnerPrimaryDomain = editablePartnerPrimaryDomain;

  const editablePrimaryContactFirstName = useEditableText({
    defaultText: partner?.contact_first_name,
    handleSave: (value, cb: any) => {
      post(
        `partners/${partner?.id}/account/${partner?.primary_contact_id}/update/`,
        {
          field: "first_name",
          value,
        }
      )
        .then(() => {
          toast.success("Partner Primary contact first name updated.");
          cb();
        })
        .catch(() => cb(false));
    },
  });
  const primaryContactFirstName = editablePrimaryContactFirstName;

  const editablePrimaryContactLastName = useEditableText({
    defaultText: partner?.contact_last_name,
    handleSave: (value, cb: any) => {
      post(
        `partners/${partner?.id}/account/${partner?.primary_contact_id}/update/`,
        {
          field: "last_name",
          value,
        }
      )
        .then(() => {
          toast.success("Partner Primary contact last name updated.");
          cb();
        })
        .catch(() => cb(false));
    },
  });
  const primaryContactLastName = editablePrimaryContactLastName;

  const editablePrimaryContactEmail = useEditableText({
    defaultText: partner?.contact_email,
    handleSave: (value, cb: any) => {
      post(
        `partners/${partner?.id}/account/${partner?.primary_contact_id}/update/`,
        {
          field: "email_address",
          value,
        }
      )
        .then(() => {
          toast.success("Partner Primary contact email updated.");
          cb();
        })
        .catch(() => cb(false));
    },
  });
  const primaryContactEmail = editablePrimaryContactEmail;

  const editablePrimaryContactNumber = useEditableText({
    defaultText: partner?.contact_number,
    handleSave: (value, cb: any) => {
      post(
        `partners/${partner?.id}/account/${partner?.primary_contact_id}/update/`,
        {
          field: "contact_number",
          value,
        }
      )
        .then(() => {
          toast.success("Partner primary contact number updated.");
          cb();
        })
        .catch(() => cb(false));
    },
  });
  const primaryContactNumber = editablePrimaryContactNumber;

  const load = () => {
    get(`team/brokers/`).then((response: any) => setBrokers(response.data));
    get(`partners/${partnerId}/`).then((response: any) => {
      setPartner(response.data);
      setLeadRecipients(response.data.lead_recipients);
      setLeadPoolCheckboxToggle(response.data.use_lead_pool);
      setUsingGeneralLeadPool(response.data.use_lead_pool);
      setSentInitialInvitation(response.data.sent_initial_invitation);
      setChangesToLeadPool(false);
    });
  };

  useEffect(() => {
    setChangesToLeadPool(true);
  }, [leadPoolCheckboxToggle, leadRecipients]);

  // load the partner and brokers
  useEffect(load, [partnerId]);

  const handleInvite = () => {
    setSendingInvitation(true);
    post(`partners/invite-account/`, {
      email_address: partner?.contact_email,
      partner_id: partnerId,
    }).then((response) => {
      setSendingInvitation(false);
      setSentInitialInvitation(true);
    });
  };

  const updateAssignedOwner = (owner: any) => {
    post(`partners/${partnerId}/update-account-owner/`, {
      owner_id: owner,
    }).then((response) => {
      load();
    });
  };

  const handleSave = () => {
    setChangesToLeadPool(false);
    post("partners/update-lead-recipients-config/", {
      partner_id: partnerId,
      use_lead_pool: leadPoolCheckboxToggle,
      lead_recipients: leadRecipients,
    }).then((response) => {
      load();
    });
  };

  return (
    <MainWrapper>
      <Container>
        <PageHeader
          title={`${
            !!partner ? partner.business_name : `Loading Partner ${partnerId}`
          }`}
          subtitle={
            <>
              {partnerId}
              <br />
              <span className="text-sm">
                Created {partner && partner?.created_at}
              </span>
            </>
          }
          breadcrumbs={[
            {
              title: "Partners",
              link: "/partners",
            },
          ]}
          rightHandSideChildren={
            <div className="min-w-[150px]">
              <PartnerStatusBadge partner={partner} />
            </div>
          }
        />
      </Container>

      <Section>
        <div className="mt-4 w-full pt-4 pb-4">
          {/* Tabs Control */}
          <Tabs
            tabs={[
              {
                name: "Details",
                component: (
                  <div className="w-full">
                    <div className="mt-4 flex w-full gap-4">
                      <div className="w-1/2">
                        <Box
                          title={"Onboarding"}
                          icon={
                            <IconBase
                              src={mailIcon}
                              color={constants.colors.primary_600}
                            />
                          }
                          w={"100%"}
                        >
                          {sentInitialInvitation ? (
                            partner?.onboarding_complete ? (
                              <div className="text-center">
                                <br />
                                <div className="flex justify-center w-full">
                                  <IconBase
                                    src={checkIcon}
                                    color={constants.colors.success_500}
                                  />
                                </div>
                                <br />
                                <Label
                                  text={`This partner is fully on-boarded and active.`}
                                />
                              </div>
                            ) : (
                              <div className="text-center items-center">
                                <br />
                                <div className="flex justify-center w-full">
                                  <IconBase
                                    src={alertIcon}
                                    color={constants.colors.warning_400}
                                  />
                                </div>
                                <br />
                                <div className="max-w-full w-full">
                                  {`${partner?.business_name} has been invited but has not yet completed the onboarding process. We have emailed them however you can also share the below link with them.`}
                                </div>
                                <br />
                                <br />
                                <div
                                  className="max-w-full w-full break-words cursor-pointer bg-slate-100 p-5 rounded-md"
                                  data-tip="Click to copy."
                                  onClick={() => {
                                    copy(partner?.onboarding_link);
                                    toast.success("Copied to clipboard");
                                  }}
                                >
                                  <p className="text-medium text-lg mb-2">
                                    {" "}
                                    Click to copy invite link.
                                  </p>
                                  {partner?.onboarding_link}
                                </div>
                              </div>
                            )
                          ) : (
                            <div className="text-center">
                              <Label
                                text={`Invite ${partner?.contact_first_name} to access the Finstead Partner Dashboard`}
                              />
                              <br />
                              <Button
                                onClick={handleInvite}
                                text={
                                  sendingInvitation
                                    ? "Sending..."
                                    : "Send Email Invitation"
                                }
                                className="min-w-[250px] mt-2"
                                disabled={sendingInvitation}
                              />
                            </div>
                          )}
                        </Box>
                        <InfoBox
                          title={"Partner Details"}
                          icon={
                            <IconBase
                              src={companyIcon}
                              color={constants.colors.primary_600}
                            />
                          }
                          info={{
                            business_name: partnerBusinessName,
                            primary_domain: partnerPrimaryDomain,
                            has_dashboard_access:
                              partner?.has_dashboard_access ? (
                                <Badge text={"Yes"} type={BadgeType.success} />
                              ) : (
                                <Badge text={"No"} type={BadgeType.danger} />
                              ),
                            onboarding_complete:
                              partner?.onboarding_complete ? (
                                <Badge text={"Yes"} type={BadgeType.success} />
                              ) : (
                                <Badge text={"No"} type={BadgeType.danger} />
                              ),
                          }}
                          w={"100%"}
                        />

                        <InfoBox
                          title={"Primary Contact"}
                          icon={
                            <IconBase
                              src={userIcon}
                              color={constants.colors.primary_600}
                            />
                          }
                          info={{
                            id: partner?.primary_contact_id,
                            first_name: primaryContactFirstName,
                            last_name: primaryContactLastName,
                            email: primaryContactEmail,
                            number: primaryContactNumber,
                          }}
                          w={"100%"}
                        />
                      </div>
                      <div className="w-1/2">
                        <Box
                          title={"Finstead Account Manager"}
                          icon={
                            <IconBase
                              src={accountManagerIcon}
                              color={constants.colors.primary_600}
                            />
                          }
                        >
                          <img
                            className="rounded-full w-[100px] h-[100px] drop-shadow-md"
                            style={{ margin: "auto auto" }}
                            src={partner?.finstead_owner_image}
                          ></img>
                          <br />
                          <div className="flex">
                            <BrokerDropDownField
                              placeholder={partner?.finstead_owner_name}
                              allowDefault={false}
                              endpoint="team/brokers/"
                              onChange={(e) => {
                                if (!!e) {
                                  updateAssignedOwner(e);
                                }
                              }}
                            />
                          </div>
                        </Box>
                        <Box
                          title={"Lead Pool Configuration"}
                          icon={
                            <IconBase
                              src={funnelIcon}
                              color={constants.colors.primary_600}
                            />
                          }
                          w={"100%"}
                        >
                          <div>
                            <Badge
                              type={
                                usingGeneralLeadPool
                                  ? BadgeType.success
                                  : BadgeType.info
                              }
                              text={
                                usingGeneralLeadPool
                                  ? "Using general lead pool"
                                  : "Using custom lead pool"
                              }
                            />
                          </div>
                          <br />
                          <CheckBox
                            checked={usingGeneralLeadPool}
                            onChange={() =>
                              setLeadPoolCheckboxToggle(!leadPoolCheckboxToggle)
                            }
                            text={
                              usingGeneralLeadPool
                                ? "Uncheck to enable a partner specific lead pool."
                                : "Check to distribute leads to general lead pool."
                            }
                          />

                          <InputFieldWrapper
                            label={
                              "Select one or more team members to receive leads from this partner."
                            }
                          >
                            <MultiSelectDropDownField
                              handleChange={setLeadRecipients}
                              options={(brokers || []).map((broker: any) => {
                                return {
                                  value: broker?.id,
                                  label: broker?.name,
                                };
                              })}
                              selected={
                                leadPoolCheckboxToggle ? [] : leadRecipients
                              }
                              disabled={leadPoolCheckboxToggle}
                            />
                          </InputFieldWrapper>
                          {changesToLeadPool && (
                            <div className="mt-2 w-full text-right">
                              <Button
                                onClick={handleSave}
                                text={"Save"}
                                disabled={
                                  !leadPoolCheckboxToggle &&
                                  leadRecipients.length === 0
                                }
                              />
                            </div>
                          )}
                        </Box>
                      </div>
                    </div>
                  </div>
                ),
              },
              {
                name: "Deals",
                component: (
                  <TableComponent
                    onRowClick={(row: any, newTab = false) => {
                      if (newTab) {
                        window.open(
                          `https://crm.finstead.com.au/deals/${row.id}`,
                          "_blank",
                          "noreferrer"
                        );
                      } else {
                        navigate(`/deals/${row.id}`);
                      }
                    }}
                    endpoint={"deals/"}
                    filter={`pid=${partnerId}`}
                    fields={{
                      id: {
                        name: "Id",
                      },
                      pipedrive_id: {
                        name: "Pipedrive Id",
                      },
                      broker: {
                        name: "Broker",
                        middleware: (row: any) => (
                          <>
                            {!!row.broker_profile_picture_url ? (
                              <img
                                src={row.broker_profile_picture_url}
                                className="w-[25px] h-[25px] object-cover rounded-full inline-block mr-2"
                              />
                            ) : (
                              <span className="inline-block mr-2 border border-slate-100 rounded-full">
                                <IconBase src={userIcon} />
                              </span>
                            )}
                            <span>{row.broker}</span>
                          </>
                        ),
                      },
                      title: {
                        name: "Title",
                      },
                      add_time: {
                        name: "Lead In",
                      },
                      value: {
                        name: "Value",
                      },
                      status: {
                        name: "Status",
                        middleware: (row: any) => {
                          switch (row.status) {
                            case "":
                              return "";
                            case "open":
                              return (
                                <Badge type={BadgeType.info} text="Open" />
                              );
                            case "lost":
                              return (
                                <Badge type={BadgeType.danger} text="Lost" />
                              );
                            case "pending assignment":
                              return (
                                <Badge type={BadgeType.info} text={"Lead In"} />
                              );
                            case "won":
                            case "settled":
                              return (
                                <Badge
                                  type={BadgeType.success}
                                  text={"Settled"}
                                />
                              );
                            default:
                              return (
                                <Badge
                                  type={BadgeType.warning}
                                  text={"Unknown ?"}
                                />
                              );
                          }
                        },
                      },
                    }}
                  />
                ),
              },
              {
                name: "Accounts",
                component: (
                  <>
                    <TableComponent
                      // onRowClick={(row) => navigate(`/deals/${row.id}`)}
                      endpoint={`partners/${partnerId}/accounts/`}
                      fields={{
                        id: {
                          name: "Id",
                        },
                        name: {
                          name: "Full Name",
                        },
                        email_address: {
                          name: "Email Address",
                        },
                        contact_number: {
                          name: "Contact Number",
                        },
                        job_title: {
                          name: "Job Title",
                        },
                        last_login: {
                          name: "Last Login",
                        },
                        has_activated_account: {
                          name: "Account Active",
                          middleware: (row: any) =>
                            row.has_activated_account ? (
                              <Badge text="Yes" type={BadgeType.success} />
                            ) : (
                              <Badge text="No" type={BadgeType.info} />
                            ),
                        },
                      }}
                    />
                  </>
                ),
              },
              // {
              //   name: "Settlements",
              //   component: <></>,
              // },
              {
                name: "API Logs",
                component: (
                  <TableComponent
                    endpoint={`partners/${partnerId}/api/logs/`}
                    onRowClick={(row: any, newTab = true) => {
                      if (newTab && !!row.deal_id) {
                        window.open(
                          `https://crm.finstead.com.au/deals/${row.deal_id}`,
                          "_blank",
                          "noreferrer"
                        );
                      } else {
                      }
                    }}
                    fields={{
                      method: {
                        name: "HTTP Method",
                      },
                      endpoint: {
                        name: "Endpoint Path",
                      },
                      errors: {
                        name: "Errors",
                      },
                      deal: {
                        name: "Deal",
                      },
                      when: {
                        name: "Called",
                      },
                      created_at: {
                        name: "Date Time",
                      },
                      status_code: {
                        name: "HTTP Status Code",
                        middleware: (row: any) =>
                          199 < row?.status_code && row?.status_code <= 299 ? (
                            <td>
                              <Badge
                                type={BadgeType.success}
                                text={row.status_code}
                              />
                            </td>
                          ) : (
                            <td>
                              <Badge
                                type={BadgeType.danger}
                                text={row.status_code}
                              />
                            </td>
                          ),
                      },
                      is_test: {
                        name: "Is Test",
                        middleware: (row: any) =>
                          row?.is_test ? (
                            <td>
                              <Badge type={BadgeType.info} text={"YES"} />
                            </td>
                          ) : (
                            <td />
                          ),
                      },
                    }}
                  />
                ),
              },
              // {
              //   name: "Partner's Dashboard",
              //   component: (
              //     <div className="w-full h-[300px]">
              //       <PartnerDashboardPage />
              //     </div>
              //   ),
              // },
            ]}
          />
        </div>
      </Section>
    </MainWrapper>
  );
};

export default PartnerDetailPage;
