import React, { useState, useCallback, useEffect } from "react";
import {
  Container,
  Content,
  MainWrapper,
  Container1,
  Section,
  BrokerageChartWrapper,
  BoxMetricsContainer,
} from "pages/DashboardPage/DashboardPage.styled";
import constants from "styles/constants";
import LineChart from "components/metrics/LineChart";
import SectionHeader from "components/layout/SectionHeader";
import DropDownField from "components/inputs/DropDownField";
import BoxMetric from "components/metrics/BoxMetric";
import { formatUnderCase, timeString, formatMoney } from "utilities/Strings/";
import PageHeader from "components/layout/PageHeader";
import useUser from "hooks/useUser";
import { post } from "utilities/Network";

const PartnerDashboardPage = () => {
  const [partnerDashboardStats, setPartnerDashboardStats] = useState<any>({});
  const [period, setPeriod] = useState<any>({
    title: "This Year",
    value: "this_year",
  });
  const [periodRangeStart, setPeriodRangeStart] = useState<Date>();
  const [periodRangeEnd, setPeriodRangeEnd] = useState<Date>();
  const { scope, user } = useUser();

  const fetchPartnerMetrics = useCallback(
    (cb: any) => {
      post("reports/partners/metrics/", {
        period: period?.value,
        period_range: {
          start: periodRangeStart,
          end: periodRangeEnd,
        },
        partner: user?.id,
      }).then((response: any) => {
        cb(response.data);
      });
    },
    [period?.value, periodRangeEnd, periodRangeStart, user]
  );

  useEffect(() => {
    if (!!user) {
      fetchPartnerMetrics(setPartnerDashboardStats);
    }
  }, [fetchPartnerMetrics, user]);

  const getChartGranularity = (periodType: string) => {
    if (periodType === "today") {
      return "hour";
    } else {
      return "day";
    }
  };

  return (
    <MainWrapper>
      <Container>
        <PageHeader
          title={"Your Dashboard"}
          subtitle={"Welcome to your Finstead Partner dashboard!"}
        />
      </Container>

      {/* Filters Header */}
      <Section className="pt-3">
        <Container1>
          <div className="flex gap-4 w-full">
            <div className="w-3/4"></div>
            <div className="w-1/4 flex items-end">
              <DropDownField
                label={"Period"}
                value={period.value}
                onChange={(v) =>
                  setPeriod({ value: v, text: formatUnderCase(v) })
                }
                options={[
                  {
                    text: "Today",
                    value: "today",
                  },
                  {
                    text: "This Week",
                    value: "this_week",
                  },
                  {
                    text: "This Month",
                    value: "this_month",
                  },
                  {
                    text: "This Quarter",
                    value: "this_quarter",
                  },
                  {
                    text: "This Year",
                    value: "this_year",
                  },
                ]}
              />
            </div>
          </div>
        </Container1>
      </Section>

      <Section className="pt-3">
        <Container1>
          {/* Metric Blocks */}
          <Content className="w-full">
            <Section className="">
              <SectionHeader
                title={"Metrics"}
                subtitle={"High level metrics describing performance"}
              />
              <BoxMetricsContainer className="flex flex-wrap items-center justify-center w-full gap-4">
                <BoxMetric
                  title={"Referrals"}
                  value={partnerDashboardStats?.leads_in?.value}
                  delta_value={partnerDashboardStats?.leads_in?.delta_value}
                  delta_type={partnerDashboardStats?.leads_in?.delta_type}
                  delta_is_positive={
                    partnerDashboardStats?.leads_in?.delta_is_positive
                  }
                  period_description={
                    partnerDashboardStats?.leads_in?.delta_period_description
                  }
                ></BoxMetric>
                <BoxMetric
                  title={"Open"}
                  value={partnerDashboardStats?.open?.value}
                  delta_value={partnerDashboardStats?.open?.delta_value}
                  delta_type={partnerDashboardStats?.open?.delta_type}
                  delta_is_positive={
                    partnerDashboardStats?.open?.delta_is_positive
                  }
                  period_description={
                    partnerDashboardStats?.open?.delta_period_description
                  }
                ></BoxMetric>
                <BoxMetric
                  title={"Lost"}
                  inverse_delta={true}
                  value={partnerDashboardStats?.lost?.value}
                  delta_value={partnerDashboardStats?.lost?.delta_value}
                  delta_type={partnerDashboardStats?.lost?.delta_type}
                  delta_is_positive={
                    partnerDashboardStats?.lost?.delta_is_positive
                  }
                  period_description={
                    partnerDashboardStats?.lost?.delta_period_description
                  }
                ></BoxMetric>
                <BoxMetric
                  title={"Apps Submitted"}
                  value={partnerDashboardStats?.apps_submitted?.value}
                  delta_value={
                    partnerDashboardStats?.apps_submitted?.delta_value
                  }
                  delta_type={partnerDashboardStats?.apps_submitted?.delta_type}
                  delta_is_positive={
                    partnerDashboardStats?.apps_submitted?.delta_is_positive
                  }
                  period_description={
                    partnerDashboardStats?.apps_submitted
                      ?.delta_period_description
                  }
                ></BoxMetric>
                <BoxMetric
                  title={"Settled"}
                  value={partnerDashboardStats?.settled?.value}
                  delta_value={partnerDashboardStats?.settled?.delta_value}
                  delta_type={partnerDashboardStats?.settled?.delta_type}
                  delta_is_positive={
                    partnerDashboardStats?.settled?.delta_is_positive
                  }
                  period_description={
                    partnerDashboardStats?.settled?.delta_period_description
                  }
                ></BoxMetric>
              </BoxMetricsContainer>

              <div className="flex w-full gap-8">
                <div className="flex-1">
                  <Section>
                    <SectionHeader
                      title={"Referrals"}
                      subtitle={"Deals which you have referred to Finstead."}
                    />
                    <BrokerageChartWrapper>
                      <LineChart
                        isPartner={true}
                        XAxisDataKey={"label"}
                        lines={[
                          {
                            dataKey: "Leads In",
                            colour: constants.colors.success_700,
                          },
                        ]}
                        tooltip={true}
                        loading={!user}
                        configuration={{
                          period: period.value,
                          period_range: {
                            start: periodRangeStart,
                            end: periodRangeEnd,
                          },
                          query_date_field: "created_at",
                          model: "Deal",
                          app: "deals",
                          granularity: getChartGranularity(period.value),
                          segregate: false,
                          data_key: "Leads In",
                          partner: user?.id,
                        }}
                      />
                    </BrokerageChartWrapper>
                  </Section>
                  <Section className="mt-4">
                    <SectionHeader
                      title={"Rolling Apps Submitted"}
                      subtitle={
                        "Deals which have been submitted for application during the performance period."
                      }
                    />
                    <BrokerageChartWrapper>
                      <LineChart
                        isPartner={true}
                        XAxisDataKey={"label"}
                        lines={[
                          {
                            dataKey: "Apps Submitted",
                            colour: constants.colors.success_700,
                          },
                        ]}
                        tooltip={true}
                        loading={!user}
                        configuration={{
                          period: period.value,
                          period_range: {
                            start: periodRangeStart,
                            end: periodRangeEnd,
                          },
                          query_date_field:
                            "application_submitted_to_lender_report_complete_time",
                          model: "Deal",
                          app: "deals",
                          granularity: getChartGranularity(period.value),
                          segregate: false,
                          data_key: "Apps Submitted",
                          partner: user?.id,
                        }}
                      />
                    </BrokerageChartWrapper>
                  </Section>
                  <Section className="mt-4">
                    <SectionHeader
                      title={"Settlements"}
                      subtitle={
                        "Deals which have settled during the performance period."
                      }
                    />
                    <BrokerageChartWrapper>
                      <LineChart
                        isPartner={true}
                        XAxisDataKey={"label"}
                        lines={[
                          {
                            dataKey: "Settlements",
                            colour: constants.colors.success_700,
                          },
                        ]}
                        tooltip={true}
                        loading={!user}
                        configuration={{
                          period: period.value,
                          period_range: {
                            start: periodRangeStart,
                            end: periodRangeEnd,
                          },
                          query_date_field: "settled_report_complete_time",
                          model: "Deal",
                          app: "deals",
                          granularity: getChartGranularity(period.value),
                          segregate: false,
                          data_key: "Settlements",
                          partner: user?.id,
                        }}
                      />
                    </BrokerageChartWrapper>
                  </Section>
                </div>
              </div>
            </Section>
          </Content>
        </Container1>
      </Section>
    </MainWrapper>
  );
};

export default PartnerDashboardPage;
