import React, { useEffect, useState } from "react";
import {
  MainWrapper,
  Container,
  Section,
} from "pages/TeamPage/TeamPage.styled";

import PageHeader from "components/layout/PageHeader";
import TableComponent from "components/core/TableComponent";
import FilterBar from "components/core/FilterBar";
import { useNavigate } from "react-router-dom";
import NewOrganisationForm from "./NewOrganisationForm";
import Button from "components/core/Button";
import SlidingPane from "components/layout/SlidingPane";
import "react-sliding-pane/dist/react-sliding-pane.css";

export type OrganisationPageProps = {};

export type Organisation = {
  id: string;
  title: string;
  value: string;
  status: string;
};

const OrganisationPage: React.FC<OrganisationPageProps> = ({}) => {
  const navigate = useNavigate();
  const [filter, setFilter] = useState<string | undefined>();
  const [isPaneOpen, setPaneOpen] = useState<boolean>(false);

  return (
    <MainWrapper>
      <SlidingPane
        className="overlay-pane-content"
        overlayClassName="overlay-pane"
        isOpen={isPaneOpen}
        title="New Organisation"
        width="800px"
        subtitle="Complete the form to create a new organisation."
        onRequestClose={() => {
          // triggered on "<" on left top click or on outside click
          setPaneOpen(false);
        }}
        cancelPromptTitle={"Abandon new Organisation?"}
        cancelPromptMessage={"Are you sure you'd like to abandon this form?"}
      >
        <NewOrganisationForm />
      </SlidingPane>

      <Container>
        <PageHeader
          title={"Organisations"}
          subtitle={
            "All organisations associated with Finstead deals - if you want to add a new organisation, create a new deal."
          }
        />
      </Container>
      <Section className="min-h-full">
        <div className="flex w-full">
          <FilterBar setFilter={setFilter} />
        </div>

        <TableComponent
          onRowClick={(row: any, newTab = false) => {
            if (newTab) {
              window.open(
                `https://crm.finstead.com.au/organisations/${row.id}`,
                "_blank",
                "noreferrer"
              );
            } else {
              navigate(`/organisations/${row.id}`);
            }
          }}
          title={"Organisations"}
          endpoint={"organisations/"}
          filter={filter}
          fields={{
            id: {
              name: "Id",
            },
            pipedrive_id: {
              name: "Pipedrive Id",
            },
            name: {
              name: "Name",
            },
            abn: {
              name: "ABN",
            },
            state: {
              name: "State",
            },
            post_code: {
              name: "Postcode",
            },
            created_at: {
              name: "Created At",
            },
          }}
        />
      </Section>
    </MainWrapper>
  );
};

export default OrganisationPage;
