import React, { useState, useEffect } from "react";
import { promiseEnums } from "utilities/Enums";
import constants from "styles/constants";
import LoadingBar from "react-top-loading-bar";
import IconBase from "../IconBase";
import { ReactComponent as ClockIcon } from "assets/icons/clock.svg";
import { ReactComponent as CheckIcon } from "assets/icons/check.svg";
import { get, post } from "utilities/Network";
import Board from "@asseinfo/react-kanban";
import Button from "components/core/Button";
import "@asseinfo/react-kanban/dist/styles.css";
import { formatUnderCase } from "utilities/Strings";
import "./styles.css";
import { useNavigate } from "react-router-dom";
import ReactTooltip from "react-tooltip";
import useAreYouSure from "hooks/useAreYouSure";

type KanBanComponentProps = {
  title?: string;
  fields?: any;
  endpoint: string;
  filter?: string;
  loading?: boolean;
  handleDealFocused?: any;
  triggerRefresh?: boolean;
  is_settled_stages?: Array<number>;
  lastLaneAction?: (cb?: () => void) => void;
  isPartnerView?: boolean;
};

export const generateLeftArrow = (header: any) => {
  return (
    <div className="w-100">
      <svg className="inline-block arrow" width="16" height="56">
        <g fill="none" fill-rule="evenodd">
          <path className="arrow__right" fill="white" d="M0 0h16v56H0z"></path>
          <path
            className="arrow__border"
            fill="#E5E5E5"
            d="M1 0l8 28-8 28H0V0z"
          ></path>
          <path className="arrow__left" fill="white" d="M0 1l8 27-8 27z"></path>
        </g>
      </svg>
      <div
        style={{
          display: "inline-block",
          paddingTop: "5px",
        }}
      >
        <strong style={{ position: "relative", top: "7px" }}>
          {formatUnderCase(header.title)}
        </strong>
        <br />
        <div className="font-medium">
          <small>{header.value} - </small>
          <small>
            {header.cards.length >= 30
              ? `${header.cards.length}+`
              : header.cards.length}{" "}
            {header.cards.length == 1 ? "deal" : "deals"}
          </small>
        </div>
      </div>
    </div>
  );
};

export const generateBufferArrow = (header: any) => {
  return (
    <div className="w-100">
      <svg width="17" height="55" className="inline-block">
        <g fill="none" fill-rule="evenodd">
          <path fill="rgba(0,0,0,0)" d="M0 0h16v56H0z"></path>
          <path fill="rgba(0,0,0,0)" d="M1 0l8 28-8 28H0V0z"></path>
          <path fill="rgba(0,0,0,0)" d="M0 1l8 27-8 27z"></path>
        </g>
      </svg>
      <div
        style={{
          display: "inline-block",
          paddingTop: "5px",
        }}
      >
        <strong style={{ position: "relative", top: "7px" }}>
          {formatUnderCase(header.title)}
        </strong>

        <br />
        <div className="font-medium">
          <small>{header.value} - </small>
          <small>
            {header.cards.length >= 30
              ? `${header.cards.length}+`
              : header.cards.length}{" "}
            {header.cards.length == 1 ? "deal" : "deals"}
          </small>
        </div>
      </div>
    </div>
  );
};

const renderCard = (deal: any, navigate: any, setFocused: any) => {
  return (
    <div
      className="bg-white border rounded-md card rounded-0 w-fill border-slate-600 cursor-pointer"
      onClick={() => navigate(`/deals/${deal.id}`)}
      onMouseDown={() => setFocused(deal)}
    >
      <div className="kanban-card-content" style={{ zoom: "0.8" }}>
        <strong className="text-md">{deal.title}</strong>
        <br />
        <small>{deal.organisation}</small>
        <div>
          {deal.broker ? (
            <>
              <strong>{deal.broker}</strong>
            </>
          ) : (
            <>unassigned</>
          )}
          {deal.value ? (
            <>
              <div>{deal.value}</div>
            </>
          ) : null}
          <p>{deal.created_at}</p>
        </div>

        {deal.overdue_activity_count > 0 ? (
          <div className="flex w-full justify-end">
            <IconBase
              src={ClockIcon}
              color={"red"}
              data-tip={deal.overdue_activities[0].subject}
            />
            {/* <span className="bg-red-600 rounded-full w-[16px] h-[16px] relative right-[-1px] top-[-1px] z-10 text-white text-xs text-center">
              {deal.overdue_activity_count}
            </span> */}
          </div>
        ) : null}
        {deal.has_activities_today ? (
          <div className="flex w-full justify-end">
            <IconBase src={ClockIcon} color={"green"} />
          </div>
        ) : null}
        {/* <Button
          text={"open"}
          onClick={() => {
            window.location.pathname = `/deal/${deal.id}`;
          }}
        /> */}
      </div>
    </div>
  );
};

const KanBanComponent: React.FC<KanBanComponentProps> = ({
  title,
  fields,
  endpoint,
  filter,
  loading,
  triggerRefresh,
  handleDealFocused,
  lastLaneAction,
  is_settled_stages,
  isPartnerView = false,
}) => {
  const navigate = useNavigate();
  const { areYouSure, areYouSureModal } = useAreYouSure();
  const [kanbanData, setData] = useState<any>();
  const [firstLoad, setFirstLoad] = useState(true);
  const [kanBanState, setKanBanState] = useState(promiseEnums.pending);
  const [progress, setProgress] = useState(0);

  const refreshKanban = () => {
    console.log("Filter updated, kanban refreshing");
    console.log(filter);
    if (firstLoad) {
      setFirstLoad(false);
      setKanBanState(promiseEnums.pending);
    }
    console.log(`Fetch Kanban: ${JSON.stringify(filter)}`);
    get(endpoint + "?" + (filter || ""))
      .then((response) => {
        setData(response.data);
        setKanBanState(promiseEnums.success);
        setProgress(100);
        setTimeout(() => {
          setProgress(0);
        }, 300);
      })
      .catch(() => {
        setKanBanState(promiseEnums.errored);
      });
    setProgress(75);
  };

  useEffect(refreshKanban, [endpoint, filter, firstLoad, triggerRefresh]);

  const handleCardDragEnd = (deal: any, from: any, to: any) => {
    console.log(`card dragged from ${from.fromColumnId} to ${to.toColumnId}`);
    console.log(from);
    console.log(to);

    // get the indexes of the to and from columns

    if (
      !!lastLaneAction &&
      to.toColumnId === kanbanData?.lanes[kanbanData?.lanes.length - 1].id
    ) {
      lastLaneAction(() => {
        // callback
        console.log("last lane action complete");
        refreshKanban();
      });
    } else {
      console.log("default state change");

      const foo = () => {
        let cA = 0,
          cB = 0;
        const pA = from.fromPosition;
        const pB = to.toPosition;
        for (let i = 0; i < kanbanData?.lanes.length; ++i) {
          if (to.toColumnId === kanbanData?.lanes[i].id) {
            console.log(`to column id ${to.toColumnId} index is ${i}`);
            cB = i;
          }
          if (from.fromColumnId === kanbanData?.lanes[i].id) {
            console.log(`from column id ${to.toColumnId} index is ${i}`);
            cA = i;
          }
        }
        const L = kanbanData?.lanes;
        const C = L[cA].cards[pA];
        const L1 = [
          ...L.slice(0, cA),
          {
            ...L[cA],
            cards: [...L[cA].cards.slice(0, pA), ...L[cA].cards.slice(pA + 1)],
          },
          ...L.slice(cA + 1),
        ];
        const L2 = [
          ...L1.slice(0, cB),
          {
            ...L1[cB],
            cards: [
              ...(pB <= 0 ? [] : L1[cB].cards.slice(0, pB)),
              C,
              ...L1[cB].cards.slice(pB),
            ],
          },
          ...L1.slice(cB + 1),
        ];
        setData({ ...kanbanData, lanes: L2 });

        // lanes_card_removed = [...L.slice(0, )]
        // default state change
        post(`deals/${deal.id}/stage/update/`, {
          stage: to.toColumnId,
        })
          .then((response) => {
            // do nothing
          })
          .catch(() => {
            // refresh if failed to get correct state
            refreshKanban();
          });
      };

      console.log(
        `is_settled_stages.indexOf(from.fromColumnId) !== -1: ${
          is_settled_stages?.indexOf(from.fromColumnId) !== -1
        }`
      );

      if (
        !!is_settled_stages &&
        is_settled_stages.indexOf(from.fromColumnId) !== -1
      ) {
        areYouSure(
          foo,
          "Are you sure you want to move this deal out of settled?",
          "Yes",
          "No"
        );
      } else {
        foo();
      }
    }
  };

  if (kanBanState == promiseEnums.pending) {
    return (
      <LoadingBar
        color={constants.colors.primary_600}
        progress={progress}
        onLoaderFinished={() => setProgress(-1)}
        loaderSpeed={1000}
      />
    );
  }

  return (
    <div
      className="flex-1 w-full mt-2 pb-4 border-rounded"
      // style={{ backgroundColor: "#e2e2e2" }}
    >
      {areYouSureModal}
      <Board
        className="w-full"
        children={{
          columns: kanbanData?.lanes.map((lane: any, index: any) => {
            return {
              ...lane,
              title:
                index === 0
                  ? generateBufferArrow(lane)
                  : generateLeftArrow(lane),
            };
          }),
        }}
        disableColumnDrag={true}
        renderCard={(deal: any) =>
          renderCard(deal, navigate, handleDealFocused)
        }
        onCardDragEnd={handleCardDragEnd}
        disableCardDrag={isPartnerView}
      ></Board>
      <ReactTooltip />
    </div>
  );
};

export default KanBanComponent;
