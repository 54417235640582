import styled from "styled-components";

import { TextMdNormal } from "styles/mixins";

export type DateSelectProps = {
  disabled?: boolean;
  loading?: boolean;
};

export const DateSelect = styled("input")<DateSelectProps>`
    display: block;
    height: 43.5px;
    background: {props => props.disabled || props.loading ? props.theme.colors.gray_100 : "#fff"};
    filter: drop-shadow(inset 0px 1px 2px rgba(1, 2, 2, 0.05));
    border: 1px solid rgb(208, 213, 221);
    border-radius: 8px;
    overflow: hidden;
    align-self: stretch;
    align-items: center;
    padding: 10px 14px 10px 14px;
    gap: 8px;
    ${TextMdNormal}
    ::placeholder {
      color: ${(props) => props.theme.colors.gray_500};
    }
  `;
