import React, { useState, useCallback, useEffect } from "react";
import {
  Container,
  Content,
  MainWrapper,
  Container1,
  Section,
  BrokerageChartWrapper,
  BoxMetricsContainer,
} from "pages/DashboardPage/DashboardPage.styled";
import constants from "styles/constants";
import LineChart from "components/metrics/LineChart";
import SectionHeader from "components/layout/SectionHeader";
import DropDownField from "components/inputs/DropDownField";
import BoxMetric from "components/metrics/BoxMetric";
import { formatUnderCase, timeString, formatMoney } from "utilities/Strings/";
import PageHeader from "components/layout/PageHeader";
import useUser from "hooks/useUser";
import { post } from "utilities/Network";
import Tabs from "components/core/Tabs";
import PartnerAPIKeyManagementView from "./PartnerAPIKeyManagementView";
import PartnerAPIAccessLogsView from "./PartnerAPIAccessLogsView";
import PartnerAPIWebhookManagementView from "./PartnerAPIWebhookManagementView";
import Button from "components/core/Button";
import PartnerNewAPIKeyForm from "./forms/PartnerNewAPIKeyForm";
import PartnerNewWebhookRegistrationForm from "./forms/PartnerNewWebhookRegistrationForm";

const PartnerAppAPIPage = () => {
  const [activeTabIndex, setActiveTabIndex] = useState<number>();
  const [newAPIKeyFormOpen, setNewAPIKeyFormOpen] = useState(false);
  const [newWebhookRegistrationFormOpen, setNewWebhookRegistrationFormOpen] =
    useState(false);
  const [apiKeyRefreshTrigger, setApiKeyRefreshTrigger] = useState(false);

  const handleNewAPIKey = () => {
    setNewAPIKeyFormOpen(true);
  };
  const handleNewWebhookRegistration = () => {
    setNewWebhookRegistrationFormOpen(true);
  };

  return (
    <MainWrapper>
      <Container>
        <PageHeader
          title={"Partner API"}
          rightHandSideChildren={
            <>
              {activeTabIndex === 1 && (
                <Button
                  text={"+ New API Key"}
                  onClick={handleNewAPIKey}
                  primary
                />
              )}
              {activeTabIndex === 2 && (
                <Button
                  className="min-w-[250px]"
                  primary
                  text={"+ New Webhook Registration"}
                  onClick={handleNewWebhookRegistration}
                />
              )}
            </>
          }
        />
      </Container>

      {/* Api Key Form */}
      <PartnerNewAPIKeyForm
        toggle={setNewAPIKeyFormOpen}
        isOpen={newAPIKeyFormOpen}
        cb={() => setApiKeyRefreshTrigger(!apiKeyRefreshTrigger)}
      />

      {/* Webhook Registration Form */}
      <PartnerNewWebhookRegistrationForm
        toggle={setNewWebhookRegistrationFormOpen}
        isOpen={newWebhookRegistrationFormOpen}
      />

      {/* Filters Header */}
      <Container>
        <Section className="pt-3">
          <Tabs
            onChange={setActiveTabIndex}
            tabs={[
              {
                name: "Access Logs",
                component: (
                  <PartnerAPIAccessLogsView
                    refreshTrigger={apiKeyRefreshTrigger}
                  />
                ),
              },
              { name: "API Keys", component: <PartnerAPIKeyManagementView /> },
              // {
              //   name: "Webhooks",
              //   component: <PartnerAPIWebhookManagementView />,
              // },
            ]}
          />
        </Section>
      </Container>
    </MainWrapper>
  );
};

export default PartnerAppAPIPage;
