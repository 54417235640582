import { css } from "styled-components";

export const DisplayMdSemibold = css`
  font-size: 36px;
  letter-spacing: -0.02em;
  font-family: "Inter";
  font-weight: 600;
  line-height: 44px;
`;

export const TextSmMedium = css`
  font-size: 14px;
  letter-spacing: 0em;
  font-family: "Inter";
  font-weight: 500;
  line-height: 20px;
`;

export const TextSmBold = css`
  font-size: 14px;
  letter-spacing: 0em;
  font-family: "Inter";
  font-weight: 700;
  line-height: 20px;
`;

export const TextMdMedium = css`
  font-size: 16px;
  letter-spacing: 0em;
  font-family: "Inter";
  font-weight: 500;
  line-height: 24px;
`;

export const TextSmNormal = css`
  font-size: 14px;
  letter-spacing: 0em;
  font-family: "Inter";
  line-height: 20px;
`;

export const TextMdNormal = css`
  font-size: 16px;
  letter-spacing: 0em;
  font-family: "Inter";
  line-height: 24px;
`;

export const DisplaySmMedium = css`
  font-size: 30px;
  letter-spacing: 0em;
  font-family: "Inter";
  font-weight: 500;
  line-height: 38px;
`;

export const TextLgMedium = css`
  font-size: 18px;
  letter-spacing: 0em;
  font-family: "Inter";
  font-weight: 500;
  line-height: 28px;
`;

export const TextXsMedium = css`
  font-size: 12px;
  letter-spacing: 0em;
  font-family: "Inter";
  font-weight: 500;
  line-height: 18px;
`;

export const TextXsNormal = css`
  font-size: 12px;
  letter-spacing: 0em;
  font-family: "Inter";
  line-height: 18px;
`;

export const DisplaySmSemiBold = css`
  font-size: 30px;
  letter-spacing: 0em;
  font-family: "Inter";
  font-weight: 600;
  line-height: 38px;
`;

export const DisplaySmSemibold = css`
  font-size: 30px;
  letter-spacing: 0em;
  font-family: "Inter";
  font-weight: 600;
  line-height: 38px;
`;

export const TextLgSemibold = css`
  font-size: 18px;
  letter-spacing: 0em;
  font-family: "Inter";
  font-weight: 600;
  line-height: 28px;
`;

export const TextXlNormal = css`
  font-size: 20px;
  letter-spacing: 0em;
  font-family: "Inter";
  line-height: 30px;
`;

export const DisplayXlMedium = css`
  font-size: 60px;
  letter-spacing: -0.02em;
  font-family: "Inter";
  font-weight: 500;
  line-height: 72px;
`;
