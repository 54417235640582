import React, { useEffect, useState } from "react";
import Button from "components/core/Button";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import SearchSelectField from "components/inputs/SelectSearchField";
import { post } from "utilities/Network";
import TextArea from "components/inputs/TextArea";
import useLenders from "hooks/useLenders";
import DropDownField from "components/inputs/DropDownField";
import InputField from "components/inputs/InputField";
import Badge, { BadgeType } from "components/core/Badge";
import usePartners from "hooks/usePartners";
import converter from "number-to-words";
import { formatMoney, getLenderSearchSelectLabel } from "utilities/Strings";
import { get } from "utilities/Network";
import DealTimeline from "components/core/DealTimeline";

import BasicTableCell from "components/core/TableComponent/components/BasicTableCell";
import {
  TH,
  THContentWrapper,
  THText,
  TR,
  THR,
  TBody,
  THead,
  Table,
  TableContainer,
} from "components/core/TableComponent/TableComponent.styled";
import useDealStateManager from "hooks/useDealStateManager";
import { getReferrerSearchSelectLabel } from "utilities/Strings";
import { Lender, Referrer } from "types";

export type FormData = {
  // lender: number;
  brokerage: number;
  loan_term: number;
  interest_rate: number;
  balloon: number;
  deposit: number;
  net_amount_financed: number;
  dof: number;
  referrer_commission: number;
  settled_time: any;
};

export const FormSchema = yup.object({
  brokerage: yup.number().required("You must provide the brokerage.").min(0),
  loan_term: yup
    .number()
    .required("You must provide the term of the loan in months.")
    .min(1)
    .max(1000),
  interest_rate: yup
    .number()
    .required("You must provide the interest rate of the loan.")
    .min(0.001)
    .max(50000),
  balloon: yup
    .number()
    .required("You must provide a balloon amount")
    .default(0)
    .min(0),
  deposit: yup.number().nullable().min(0),
  net_amount_financed: yup
    .number()
    .required("You must provide the NAF amount")
    .default(0)
    .min(0),
  dof: yup
    .number()
    .required("You must provide the DOF amount")
    .default(0)
    .min(0),
  referrer_commission: yup
    .number()
    .required("You must set the referrer commission amount")
    .min(0),
  settled_time: yup
    .date()
    .required(
      "You must set the official settled time for the deal, it's okay to back date it."
    ),
});

export const RequiredFieldsMap = {
  notes: false,
  loan_term: true,
  brokerage: true,
  interest_rate: true,
  balloon: true,
  deposit: false,
  net_amount_financed: true,
  dof: true,
  referrer_commission: true,
  settled_time: true,
};

type ReportProps = {
  deal: any;
  triggerModalClose: any;
};

const SettledReport: React.FC<ReportProps> = ({ deal, triggerModalClose }) => {
  const [submitting, setSubmitting] = useState(false);
  const {
    register,
    handleSubmit,
    watch,
    setValue,
    reset,
    getValues,
    formState: { errors },
  } = useForm<FormData>({
    resolver: yupResolver(FormSchema),
  });

  const [products, setProducts] = useState([]);

  const dof = watch("dof");
  const brokerage = watch("brokerage");
  const naf = watch("net_amount_financed");
  const brokerageRate = (brokerage / naf) * 100;
  const dofRate = (dof / naf) * 100;
  const term = watch("loan_term");
  const balloon = watch("balloon");
  const deposit = watch("deposit");
  const interestRate = watch("interest_rate");

  const limits: { [key: string]: any } = {
    brokerage: {
      low: 0.25,
      high: 5,
    },
    dof: {
      low: 0.25,
      high: 5,
    },
  };

  const renderSuggestion = (title: string, value: number, lookup: string) => {
    if (value < limits[lookup].low) {
      return (
        <Badge
          type={BadgeType.danger}
          text={`${title} is lower than the ${limits[lookup].low}% threshold.`}
        />
      );
    } else if (value > limits[lookup].high) {
      return (
        <Badge
          type={BadgeType.warning}
          text={`${title} is higher than the ${limits[lookup].high}% threshold.`}
        />
      );
    } else {
      return (
        <Badge
          type={BadgeType.info}
          text={`${title} is within the acceptable range (${limits[lookup].low} -> ${limits[lookup].high}).`}
        />
      );
    }
  };

  // useEffect(() => {
  //   if (!!deal?.lender) {
  //     setValue("lender", deal?.lender.id);
  //   }

  //   if (!!deal?.partner) {
  //     setValue("partner", deal?.partner.id);
  //   }

  //   if (!!deal?.product) {
  //     setValue("product", deal?.product.id);
  //   }
  // });

  // useEffect(() => {
  //   if (!!deal?.lender) {
  //     get(`lenders/${deal?.lender.id}/products/list/`).then((response) => {
  //       setProducts(response.data);
  //       // if (!!deal?.product) {
  //       //   setValue("product", deal?.product.id);
  //       // }
  //     });
  //   }
  // }, [deal?.lender, deal?.product, setValue]);

  // const updateLenderProducts = (lender: Lender) => {
  //   if (!lender) {
  //     setProducts([]);
  //     reset({ product: undefined });
  //     return;
  //   }
  //   get(`lenders/${lender.id}/products/list/`).then((response) => {
  //     setProducts(response.data);
  //   });
  // };

  // const { partners } = usePartners();

  const onSubmit = (formData: FormData) => {
    console.log(`onSubmit ${formData}`);
    console.log(formData);
    setSubmitting(false);
    post(`deals/${deal.id}/settled-report/`, formData).then((response) => {
      triggerModalClose(true);
    });
  };

  return (
    <form>
      <div className="flex gap-4">
        <div className="w-1/2">
          <TableContainer>
            <Table>
              <THead>
                <THR>
                  <TH>Property</TH>
                  <TH>Value</TH>
                  <TH></TH>
                  <TH>Insight</TH>
                </THR>
              </THead>
              <TBody>
                <TR>
                  <BasicTableCell text={"Net Amount Financed"} />
                  <BasicTableCell text={formatMoney(naf)} />
                </TR>
                <TR>
                  <BasicTableCell text={"Brokerage"} />
                  <BasicTableCell text={formatMoney(brokerage)} />
                  <BasicTableCell text={`${brokerageRate.toFixed(2)}%`} />

                  <BasicTableCell
                    text={
                      brokerageRate &&
                      renderSuggestion("Brokerage", brokerageRate, "brokerage")
                    }
                  />
                </TR>
                <TR>
                  <BasicTableCell text="DOF" />
                  <BasicTableCell text={formatMoney(dof)} />
                  <BasicTableCell text={`${dofRate.toFixed(2)}%`} />
                  <BasicTableCell
                    text={dofRate && renderSuggestion("DOF", dofRate, "dof")}
                  />
                </TR>
                <TR>
                  <BasicTableCell text="Balloon" />
                  <BasicTableCell text={formatMoney(balloon)} />
                </TR>
                <TR>
                  <BasicTableCell text={"Deposit"} />
                  <BasicTableCell text={formatMoney(deposit)} />
                </TR>
                <TR>
                  <BasicTableCell text="Loan Term" />
                  <BasicTableCell text={`${term} months`} />
                </TR>
                <TR>
                  <BasicTableCell text="InterestRate" />
                  <BasicTableCell text={`${interestRate}%`} />
                </TR>
                {/* <TR>
                  <BasicTableCell text="Total Payment" />
                  <BasicTableCell
                    text={`${naf * Math.pow(1 + interestRate, term / 12)}`}
                  />
                </TR> */}
              </TBody>
            </Table>
          </TableContainer>
          <br />

          {/* Deal Timeline */}
          {/* {timeline.length > 0 && (
            <>
              <hr />
              <br />
              <DealTimeline events={timeline} />
            </>
          )} */}
        </div>
        {/* <div className="w-1/3"> */}
        {/* Referral Partner */}
        {/* <DropDownField
            formControlProps={register("partner")}
            label={"Referrer"}
            errorMessage={errors.partner?.message}
            required={!!RequiredFieldsMap.partner}
            disabled={!partners || submitting}
            placeholder={"Please select deal referral partner"}
            options={
              (!!partners &&
                partners.map((e: any) => {
                  return { text: `${e.business_name} (${e.id})`, value: e.id };
                })) ||
              []
            }
            loading={submitting}
          /> */}

        {/* <SearchSelectField
            formControlProps={register("partner")}
            // valid={true}
            label={"Referrer"}
            errorMessage={errors.partner?.message}
            required={!!RequiredFieldsMap.partner}
            defaultValue={deal?.partner}
            // info={
            //   "You shouldn't typically specify a referral partner if this is a repeat deal and the customer has come back to you directly."
            // }
            placeholder={"Search Partners"}
            ep={`partners/search/`}
            // onChange={(partner: Referrer) => {
            //   // reset({ parter: partner?.id });
            // }}
            onChange={(partner: Referrer) => {
              setValue("partner", partner?.id);
            }}
            getOptionLabel={getReferrerSearchSelectLabel}
            disabled={submitting}
            // newOptionLabel={"New Partner"}
            // newOptionCb={() => {}}
          /> */}

        {/* <SearchSelectField
            formControlProps={register("lender")}
            // valid={true}
            label={"Lender"}
            defaultValue={deal?.lender}
            required={!!RequiredFieldsMap.lender}
            errorMessage={errors.lender?.message}
            placeholder={"Search Lenders"}
            ep={`lenders/search/`}
            getOptionLabel={getLenderSearchSelectLabel}
            disabled={submitting}
            onChange={(lender: Lender) => {
              updateLenderProducts(lender);
              setValue("lender", lender?.id);
            }}
          />

          <DropDownField
            formControlProps={register("product")}
            label={"Product"}
            errorMessage={errors.product?.message}
            required={!!RequiredFieldsMap.product}
            disabled={!products || products.length == 0 || submitting}
            placeholder={"Please select the lender then product"}
            options={
              (!!products &&
                products.map((e: any) => {
                  return { text: `${e.name} (${e.id})`, value: e.id };
                })) ||
              []
            }
            loading={submitting}
          /> */}
        {/* </div> */}

        {/* Lender */}
        {/* <DropDownField
            formControlProps={register("lender")}
            label={"Lender"}
            errorMessage={errors.lender?.message}
            required={!!RequiredFieldsMap.lender}
            placeholder={"Please select the lender"}
            options={
              lenders.map((e: any) => {
                return { text: `${e.business_name} (${e.id})`, value: e.id };
              }) || []
            }
            disabled={submitting}
            loading={submitting}
          /> */}

        {/* </div> */}
        <div className="w-1/2">
          {/* Official Settlement Time */}
          <InputField
            formControlProps={register("settled_time")}
            label={"Official Settled Time"}
            errorMessage={errors.settled_time?.message}
            required={!!RequiredFieldsMap.settled_time}
            disabled={submitting}
            loading={submitting}
            type="date"
          />
          {/* Term */}
          <InputField
            formControlProps={register("loan_term")}
            label={"Loan term in months"}
            errorMessage={errors.loan_term?.message}
            required={!!RequiredFieldsMap.loan_term}
            disabled={submitting}
            loading={submitting}
            type="number"
          />

          {/* Interest Rate */}
          <InputField
            formControlProps={register("interest_rate")}
            label={"Interest Rate %      (i.e. 8.0 = 8.0%, 1.2 = 1.2%)"}
            errorMessage={errors.interest_rate?.message}
            required={!!RequiredFieldsMap.interest_rate}
            disabled={submitting}
            loading={submitting}
            type="number"
          />

          {/* Balloon dollar amount */}
          <InputField
            formControlProps={register("balloon")}
            label={"Balloon Dollar Amount"}
            errorMessage={errors.balloon?.message}
            required={!!RequiredFieldsMap.balloon}
            disabled={submitting}
            loading={submitting}
            type="number"
          />

          {/*  */}
          <InputField
            formControlProps={register("brokerage")}
            label={"Brokerage Amount"}
            errorMessage={errors.brokerage?.message}
            required={!!RequiredFieldsMap.brokerage}
            disabled={submitting}
            loading={submitting}
            type="number"
          />

          {/* Deposit */}
          <InputField
            formControlProps={register("deposit")}
            label={"Deposit Dollar Amount"}
            errorMessage={errors.deposit?.message}
            required={!!RequiredFieldsMap.deposit}
            disabled={submitting}
            loading={submitting}
            type="number"
          />

          {/* Net Amount Financed */}
          <InputField
            formControlProps={register("net_amount_financed")}
            label={"Net Amount Financed ($ AUD) (excluding deposit)"}
            errorMessage={errors.net_amount_financed?.message}
            required={!!RequiredFieldsMap.net_amount_financed}
            disabled={submitting}
            loading={submitting}
            type="number"
          />

          {/* DOF PayAway (Referrer Commission) */}
          <InputField
            formControlProps={register("dof")}
            label={"DOF"}
            errorMessage={errors.dof?.message}
            required={!!RequiredFieldsMap.dof}
            disabled={submitting}
            loading={submitting}
            type="number"
          />

          {/* Referral Commission (dollar or percentage of ) */}
          <InputField
            formControlProps={register("referrer_commission")}
            label={"Referral Partner Commission"}
            errorMessage={errors.referrer_commission?.message}
            required={!!RequiredFieldsMap.referrer_commission}
            disabled={submitting}
            loading={submitting}
            type="number"
          />

          <div className="mb-4 w-100">
            <Badge
              type={BadgeType.secondary}
              text={
                "By clicking 'Confirm deal settled' you confirm that you've checked the details above are accurate and correct."
              }
            />
          </div>
          <Button
            onClick={handleSubmit(onSubmit)}
            text={"Confirm deal settled"}
            primary
            disabled={submitting}
            wide={true}
          />
        </div>
      </div>
    </form>
  );
};

export default SettledReport;
