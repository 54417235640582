import React, { useCallback, useState, useEffect } from "react";
import InputFieldWrapper from "components/inputs/InputFieldWrapper";
import { useDropzone } from "react-dropzone";
import classNames from "classnames";
import { humanFileSize } from "utilities/Strings";
import IconBase from "components/core/IconBase";
import { ReactComponent as TrashIcon } from "assets/icons/trash.svg";

export type FileDropZoneProps = {
  label?: string;
  subtitle?: string;
  placeholder?: string;
  hoverPlaceholder?: string;
  onDrop: (v: any) => void;
  disabled?: boolean;
  required?: boolean;
  errorMessage?: string;
  formControlProps?: any;
  loading?: boolean;
  multiple?: boolean;
};

const FileDropZone: React.FC<FileDropZoneProps> = ({
  label,
  subtitle,
  placeholder = "Drop here...",
  hoverPlaceholder = "Drag and drop the file here, or click to select",
  onDrop,
  errorMessage,
  loading = false,
  disabled = false,
  required = false,
  formControlProps = {},
  multiple = false,
}) => {
  const [files, setFiles] = useState<any>();

  const handleChange = useCallback(
    (acceptedFiles: any) => {
      if (!acceptedFiles) return;
      // Do something with the files
      if (multiple) {
        onDrop(acceptedFiles);
      } else {
        onDrop(acceptedFiles[0]);
      }
    },
    [onDrop, multiple]
  );

  useEffect(() => {
    handleChange(files);
  }, [files, handleChange]);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop: (updatedFiles: any) => setFiles(updatedFiles),
    multiple: multiple,
    disabled: disabled || loading,
    ...formControlProps,
  });

  console.log("files");
  console.log(files);

  return (
    <InputFieldWrapper
      label={label}
      required={required}
      errorMessage={errorMessage?.split("_").join(" ")}
    >
      <>
        {subtitle && <label>{subtitle}</label>}

        {!!files && (
          <div>
            <ul>
              {files!.map((file: any, i: any) => (
                <li>
                  {file.name} {humanFileSize(file.size)}{" "}
                  <TrashIcon
                    display={"inline"}
                    className="inline-block"
                    cursor={disabled ? "" : "pointer"}
                    onClick={() => {
                      if (disabled) return;
                      setFiles(files.filter((x: any, j: any) => i !== j));
                    }}
                  />
                </li>
              ))}
            </ul>
          </div>
        )}
        {((!multiple && (!files || files.length == 0)) || multiple) && (
          <div
            {...getRootProps()}
            className={classNames(
              "w-full min-h-[150px] border border-dashed border-gray-400 flex justify-center items-center rounded-md mb-4",
              {
                "border-4": isDragActive,
                "border-gray-500": isDragActive,
                "border-red-500": !!errorMessage,
              }
            )}
          >
            <input {...getInputProps()} />
            <p
              className={classNames({
                "text-red-500": !!errorMessage,
              })}
            >
              {isDragActive ? placeholder : hoverPlaceholder}
            </p>
          </div>
        )}
      </>
    </InputFieldWrapper>
  );
};

export default FileDropZone;
