import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import InputField from "components/inputs/InputField";
import Button from "components/core/Button";
import { post } from "utilities/Network";
import LoaderLogo from "components/core/LoaderLogo";
import { ReactComponent as checkCircle } from "assets/icons/check-circle.svg";
import IconBase from "components/core/IconBase";
import useFileManager from "hooks/useFileManager";

export type FormData = {
  invoiceDate: string;
  entityName: string;
  clientAddress: string;
  purchasePrice: number;
  deposit: number;
  balancePayable: number;
};

const FormSchema = yup.object().shape({
  invoiceDate: yup.date().required("Invoice Date is required"),
  entityName: yup
    .string()
    .required("Entity Name is required")
    .min(1, "Entity Name must be at least 1 character long")
    .max(255, "Entity Name can be at most 255 characters long"),
  clientAddress: yup
    .string()
    .required("Client Address is required")
    .min(1, "Client Address must be at least 1 character long")
    .max(1024, "Client Address can be at most 1024 characters long"),
  purchasePrice: yup
    .number()
    .transform((value) => (isNaN(value) ? undefined : value))
    .required("Purchase Price $ is required")
    .positive("Purchase Price $ must be a positive number"),
  deposit: yup
    .number()
    .transform((value) => (isNaN(value) ? undefined : value))
    .required("Deposit $ is required")
    .positive("Deposit $ must be a positive number"),
  balancePayable: yup
    .number()
    .transform((value) => (isNaN(value) ? undefined : value))
    .required("Deposit Payable $ is required")
    .positive("Deposit Payable $ must be a positive number"),
});

type Props = {
  dealId: number;
};

const TaxInvoiceForm: React.FC<Props> = ({ dealId }) => {
  const [generating, setGenerating] = useState(false);
  const [generated, setGenerated] = useState(false);
  const [fileId, setFileId] = useState();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormData>({
    resolver: yupResolver(FormSchema),
  });

  const { downloadFile, deleteFile, updateFile } = useFileManager({
    fileId: fileId,
  });

  const onSubmit = (formData: FormData) => {
    setGenerating(true);

    post(`files/deals/${dealId}/tax-invoice-request/generate/`, {
      ...formData,
    }).then((response) => {
      setFileId(response.data.file_id);
      setGenerating(false);
      setGenerated(true);
    });

    // Handle submission here
    console.log(formData);
  };

  if (generating) {
    return (
      <div className="w-full h-full flex justify-center items-center  flex-col">
        <LoaderLogo light />
        <br />
        <p>Generating Invoice - Please wait this will take a few moments....</p>
      </div>
    );
  }

  if (generated) {
    return (
      <div className="w-full h-full flex justify-center items-center flex-col">
        <div className="max-h-[150px]">
          <IconBase src={checkCircle} color="green" />
        </div>
        <h1 className="font-bold text-lg">Success</h1>
        <br />
        <p>
          The tax invoice request has been generated and added as a file to this
          deal.
        </p>
        <Button
          text={"Download Tax Request Invoice"}
          width={"250px"}
          className="mt-4 text-white"
          info
          onClick={() => {
            downloadFile();
          }}
        ></Button>
      </div>
    );
  }

  return (
    <form className="pb-4 max-w-[700px]" onSubmit={handleSubmit(onSubmit)}>
      <InputField
        formControlProps={register("invoiceDate")}
        label={"Invoice Request Date"}
        type={"date"}
        errorMessage={errors.invoiceDate?.message}
      />
      <InputField
        formControlProps={register("entityName")}
        label={"Entity name"}
        errorMessage={errors.entityName?.message}
      />
      <InputField
        formControlProps={register("clientAddress")}
        label={"Client Address"}
        errorMessage={errors.clientAddress?.message}
      />
      <InputField
        formControlProps={register("purchasePrice")}
        label={"Purchase Price (inc GST) $"}
        type={"number"}
        step={"0.01"}
        errorMessage={errors.purchasePrice?.message}
      />

      <InputField
        formControlProps={register("deposit")}
        label={"Deposit $"}
        type={"number"}
        step={"0.01"}
        errorMessage={errors.deposit?.message}
      />

      <InputField
        formControlProps={register("balancePayable")}
        label={"Balance Payable $"}
        type={"number"}
        step={"0.01"}
        errorMessage={errors.balancePayable?.message}
      />
      <div className="w-full text-right">
        <Button
          type={"submit"}
          primary
          text={"Generate Invoice"}
          onClick={() => {}}
          width={"250px"}
        ></Button>
      </div>
    </form>
  );
};

export default TaxInvoiceForm;
