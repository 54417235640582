import styled from "styled-components";

type StyledButtonProps = {
  wide?: boolean;
  disabled?: boolean;
  primary?: boolean;
  right?: boolean;
  warning?: boolean;
  danger?: boolean;
  info?: boolean;
  width?: string;
};

export const StyledButton = styled("button")<StyledButtonProps>`
  width: ${(props: StyledButtonProps) =>
    props.wide ? "100%" : props.width ? props.width : "124px"};
  height: 44px;
  left: 294px;
  top: 720px;
  border-radius: 8px;
  background-color: ${(props) =>
    props.disabled
      ? props.theme.colors.gray_300
      : props.primary
      ? props.theme.colors.primary_600
      : props.warning
      ? props.theme.colors.warning_400
      : props.danger
      ? props.theme.colors.danger_500
      : props.info
      ? props.theme.colors.blue_400
      : "#FFF"};
  color: ${(props) =>
    props.primary || props.danger || props.info ? "#FFF" : "inherit"};
  border: 1px solid #d0d5dd;
  box-sizing: border-box;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  cursor: ${(props) => (props.disabled ? "cursor" : "pointer")};
  transition: box-shadow 0.1s;

  ${(props) => props.right && "float: right;"}

  &:hover {
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.25);
  }
`;
