import React, { useState, useEffect } from "react";
import { LogoWrapper } from "./Logo.styled";
import LogoSource from "assets/brand/logo.svg";
import LogoSourceDark from "assets/brand/logo-dark.svg";
import StackedSource from "assets/brand/logo-stacked.svg";
import StackedSourceDark from "assets/brand/logo-stacked-dark.svg";

export type LogoProps = {
  dark?: boolean;
  stacked?: boolean;
  width?: string;
  style?: any;
};

const Logo: React.FC<LogoProps> = ({
  dark = false,
  stacked = false,
  width = "100%",
  style = {},
}) => {
  const getSource = () => {
    if (stacked) {
      return dark ? StackedSourceDark : StackedSource;
    } else {
      return dark ? LogoSourceDark : LogoSource;
    }
  };

  const [imageSource, setImageSource] = useState(getSource());

  return (
    <>
      <LogoWrapper style={style}>
        <img src={imageSource} style={{ height: "auto", width: width }} />
      </LogoWrapper>
    </>
  );
};

export default Logo;
