import {
  getLocalStorageItem,
  setLocalStorageItem,
} from "utilities/LocalStorage";

export const getSession = () => {
  // dlog("Checking for an existing session... ");
  const session = getLocalStorageItem("auth");
  console.log("session:");
  console.log(session);

  if (session) {
    if (!session.token) {
      return false;
    } else {
      // dlog(`Found Session: ${JSON.stringify(session)}`)
      return session;
    }
  } else {
    // dlog(`No Session.`)
    return false;
  }
};

export const getSessionItem = (item) => {
  let session = getSession();
  if (!session) {
    // no session, return undefined
    return undefined;
  }
  // session, check if onboarded
  if (Object.keys(session).indexOf(item) === -1) {
    return undefined;
  }
  return session[item];
};

export const updateSessionKey = (key, value) => {
  let session = getSession();
  if (!session) {
    session = {};
  }
};

export const clearSession = () => {
  setLocalStorageItem("auth", undefined);
};
