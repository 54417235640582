import React, { useEffect, useState } from "react";
import {
  MainWrapper,
  HeaderSection,
  Container,
  Divider,
  Section,
} from "pages/TeamPage/TeamPage.styled";
import { getSession } from "utilities/Authentication/";
import FilterBar from "components/core/FilterBar";
import Button from "components/core/Button";
import { useNavigate } from "react-router-dom";
import "react-sliding-pane/dist/react-sliding-pane.css";
import IconBase from "components/core/IconBase";
import TableComponent from "components/core/TableComponent";
import Badge, { BadgeType } from "components/core/Badge";
import { ReactComponent as userIcon } from "assets/icons/user.svg";
import { ReactComponent as ListIcon } from "assets/icons/list.svg";
import { ReactComponent as KanbanIcon } from "assets/icons/trello.svg";
import KanBanComponent from "components/core/KanBanComponent";
import SlidingPane from "components/layout/SlidingPane";
import "react-sliding-pane/dist/react-sliding-pane.css";
import PartnerNewDealForm from "partner_pages/forms/PartnerNewDealForm";
import PageHeader from "components/layout/PageHeader";

type PartnerDealsPageProps = {};
const PartnerDealsPage: React.FC<PartnerDealsPageProps> = () => {
  const navigate = useNavigate();
  const [isPaneOpen, setPaneOpen] = useState(false);
  const [kanbanMode, setKanbanMode] = useState(true);
  const [focusedDeal, setFocusedDeal] = useState<any | undefined>();
  const [triggerRefresh, setTriggerRefresh] = useState(false);
  const [filter, setFilter] = useState<string | undefined>();

  return (
    <MainWrapper>
      <Section>
        <PageHeader title={`Deals`} />
      </Section>
      <Section className="min-h-full">
        <div className="flex w-full">
          <div className="pt-5">
            <button
              className="p-2 rounded-md bg-slate-200"
              onClick={() => setKanbanMode(!kanbanMode)}
            >
              {kanbanMode ? <ListIcon /> : <KanbanIcon />}
            </button>
          </div>
          <div className="flex-1">
            <FilterBar
              setFilter={setFilter}
              brokers={false}
              defaultBrokerId={getSession()?.user?.id}
              rightItem={
                <Button
                  onClick={() => {
                    setPaneOpen(true);
                  }}
                  text={"New Deal"}
                  primary
                />
              }
            />
          </div>
        </div>

        <SlidingPane
          className="overlay-pane-content"
          overlayClassName="overlay-pane"
          isOpen={isPaneOpen}
          title="New Deal"
          width="800px"
          subtitle="Complete the form to create a new deal and refer it to Finstead."
          onRequestClose={() => {
            // triggered on "<" on left top click or on outside click
            setPaneOpen(false);
          }}
          cancelPromptTitle={"Abandon new Deal?"}
          cancelPromptMessage={
            "Are you sure you'd like to abandon this new Deal?"
          }
        >
          <PartnerNewDealForm
            onSubmit={(cb: any) => {
              setPaneOpen(false);
              if (!!cb) {
                cb();
              }
            }}
          />
        </SlidingPane>

        {/* Table / Kanban */}
        {kanbanMode ? (
          !!filter && (
            <KanBanComponent
              title={"Deals"}
              endpoint={"deals/partners/kanban/Opportunity%20Workflow/"}
              filter={filter}
              triggerRefresh={triggerRefresh}
              handleDealFocused={setFocusedDeal}
              isPartnerView={true}
            />
          )
        ) : (
          <TableComponent
            onRowClick={(row: any, newTab = false) => {
              if (newTab) {
                window.open(
                  `https://partners.finstead.com.au/deals/${row.id}`,
                  "_blank",
                  "noreferrer"
                );
              } else {
                navigate(`/deals/${row.id}`);
              }
            }}
            title={"Deals"}
            endpoint={"deals/partners/"}
            filter={filter}
            fields={{
              broker: {
                name: "Finstead Broker",
                middleware: (row: any) => (
                  <>
                    {!!row.broker_profile_picture_url ? (
                      <img
                        src={row.broker_profile_picture_url}
                        className="w-[25px] h-[25px] object-cover rounded-full inline-block mr-2 ml-6"
                      />
                    ) : (
                      <span className="inline-block mr-2 border rounded-full border-slate-100">
                        <IconBase src={userIcon} />
                      </span>
                    )}
                    <span>{row.broker}</span>
                  </>
                ),
              },
              title: {
                name: "Title",
              },
              value: {
                name: "Value",
              },
              current_stage: {
                name: "Current Stage",
              },
              status: {
                name: "Status",
                middleware: (row: any) => {
                  switch (row.status) {
                    case "":
                      return "";
                    case "open":
                      return <Badge type={BadgeType.info} text="Open" />;
                    case "lost":
                      return <Badge type={BadgeType.danger} text="Lost" />;
                    case "pending assignment":
                      return <Badge type={BadgeType.info} text={"Lead In"} />;
                    case "won":
                    case "settled":
                      return (
                        <Badge type={BadgeType.success} text={"Settled"} />
                      );
                    default:
                      return (
                        <Badge type={BadgeType.warning} text={"Unknown ?"} />
                      );
                  }
                },
              },
            }}
          />
        )}
      </Section>
    </MainWrapper>
  );
};

export default PartnerDealsPage;
