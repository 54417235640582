import styled from "styled-components";

export type IconWrapperProps = {
  color?: string;
  size?: string;
  display?: string;
};

export const IconWrapper = styled.span<IconWrapperProps>`
  margin: auto auto;
  & svg {
    color: ${(p) => (p.color ? p.color : "inherit")};
    width: ${(p) => (p.size ? p.size : "inherit")};
    height: ${(p) => (p.size ? p.size : "inherit")};
  }
`;
