import React, { useState, useEffect } from "react";
import useDeal from "hooks/useDeal";
import usePhone from "hooks/usePhone";
import useCallerInfo from "hooks/useCallerInfo";
import Button from "components/core/Button";

type ReOpenDealPromptBoxProps = {
  markOpen: any;
};

const ReOpenDealPromptBox: React.FC<ReOpenDealPromptBoxProps> = ({
  markOpen,
}) => {
  const { deal } = useDeal();

  return (
    <div className="w-full mb-5 border border-slate-400 bg-slate-100 rounded-lg min-h-[150px] text-center pt-3 pb-3">
      <h1 className="text-xl">
        This deal was marked lost at {deal.lost_time} due to{" "}
        <span className="font-bold">{deal.lost_reason}</span>.
      </h1>
      <br />
      <Button
        onClick={() => markOpen()}
        className="min-w-[300px]"
        text={"Re-Open Deal"}
        primary
      />
      <br />
      <br />
    </div>
  );
};

export default ReOpenDealPromptBox;
