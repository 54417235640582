import LoaderLogo from "components/core/LoaderLogo";
import TableComponent from "components/core/TableComponent";
import useUser from "hooks/useUser";
import React from "react";

type ComponentProps = {};
const PartnerAPIKeyManagementView: React.FC<ComponentProps> = () => {
  const { user } = useUser();

  return (
    <div className="w-full h-full">
      {!user ? (
        <LoaderLogo />
      ) : (
        <TableComponent
          endpoint={`partners/api/keys/`}
          onRowClick={(row: any, newTab = false) => {}}
          fields={{
            name: {
              name: "Name",
            },
            key_partial: {
              name: "Key",
            },

            created_at: {
              name: "Created At",
            },
          }}
        />
      )}
    </div>
  );
};

export default PartnerAPIKeyManagementView;
