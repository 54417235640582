import React, { useEffect } from "react";

import ReactTooltip from "react-tooltip";
import { ReactComponent as CheckIcon } from "assets/icons/check.svg";
import "./index.css";

type StepperProps = {
  steps: any;
  currentStep: any;
  handleClick: any;
};

const Stepper: React.FC<StepperProps> = ({ steps, handleClick }) => {
  return (
    <>
      <div className="md-stepper-horizontal orange">
        {steps.map((step: any, index: number) => {
          return (
            <div
              key={index}
              className={`md-step cursor-pointer ${step.complete && "active"}`}
              onClick={() => {
                handleClick(step, step.is_settled_stage);
              }}
            >
              <div className="md-step-circle">
                {!step.complete || !step.complete ? (
                  <span>{index + 1}</span>
                ) : (
                  <CheckIcon
                    style={{
                      display: "inline-block",
                      width: "18px",
                      height: "18px",
                    }}
                  />
                )}
              </div>
              <div className="md-step-title">{step.title}</div>
              <div className="md-step-subtitle">{step.subtitle}</div>
              <div className="md-step-bar-left"></div>
              <div className="md-step-bar-right"></div>
            </div>
          );
        })}
      </div>
    </>
  );
};

export default Stepper;
