import React, { useState, useEffect } from "react";
import NavItem, { NavItemProps } from "components/layout/NavItem/NavItem";
import AlexPhoto from "assets/img/profile-casual.jpg";
import { useSelector } from "react-redux";
import { ReadyState } from "react-use-websocket";
import useCallerInfo from "hooks/useCallerInfo";
import { ReactComponent as logOutIcon } from "assets/icons/log-out.svg";
import Avatar from "components/core/Avatar";
import { useNavigate, Link } from "react-router-dom";
import ReactTooltip from "react-tooltip";
import Marquee from "react-fast-marquee";
import { useStocks } from "hooks/useStocks";
import { ReactComponent as checkCircle } from "assets/icons/check-circle.svg";
import { ReactComponent as alertCircle } from "assets/icons/alert-circle.svg";
import { ReactComponent as circle } from "assets/icons/circle.svg";
import {
  Navigation,
  Styled,
  Styled1,
  Divider,
  Text,
  SupportingText,
  TextAndSupportingText,
  AvatarLabelGroup,
  Account,
  FooterWrapper,
  LogoutButton,
} from "./SideMenuFooter.styled";
import IconBase from "components/core/IconBase";
import { clearSession } from "utilities/Authentication";
import useUser from "hooks/useUser";
import RSSFeedComponent from "components/core/RSSFeedComponent";
import LoaderLogo from "components/core/LoaderLogo";

export type SideMenuFooterProps = {
  nav_item_props?: Array<NavItemProps>;
  collapsed?: boolean;
};

const SideMenuFooter: React.FC<SideMenuFooterProps> = ({
  nav_item_props,
  collapsed = false,
}) => {
  const websocketReadyState = useSelector(
    (state: any) => state.websocket.readyState
  );
  const { callInfo } = useCallerInfo();
  const { user } = useUser();
  const [liveStatus, setLiveStatus] = useState<any>(
    <span style={{ display: "inline-block" }}>
      <IconBase src={circle} color="gray" />
    </span>
  );
  // const { stocks } = useStocks();
  const navigate = useNavigate();

  const handleLogout = () => {
    clearSession();
    navigate("/login");
  };

  // useEffect(() => {
  //   switch (websocketReadyState) {
  //     case ReadyState.OPEN:
  //       setLiveStatus(
  //         <span data-tip="Websocket Connected">
  //           <IconBase src={checkCircle} color="green" size="15px" />
  //         </span>
  //       );
  //       break;
  //     case ReadyState.CLOSED:
  //       setLiveStatus(
  //         <span data-tip="Websocket Closed">
  //           <IconBase src={alertCircle} color="red" size="15px" />
  //         </span>
  //       );
  //       break;
  //     case ReadyState.UNINSTANTIATED:
  //       setLiveStatus(
  //         <span>
  //           <IconBase src={alertCircle} color="gray" size="15px" />
  //         </span>
  //       );
  //       break;
  //     case ReadyState.CONNECTING:
  //       setLiveStatus(
  //         <span data-tip="Websocket Connecting">
  //           <IconBase src={circle} color="orange" size="15px" />
  //         </span>
  //       );
  //       break;
  //     default:
  //       setLiveStatus(
  //         <span>
  //           <IconBase src={circle} color="gray" />
  //         </span>
  //       );
  //       break;
  //   }
  // }, [websocketReadyState]);

  if (collapsed) {
    return (
      <div className="relative bottom-3 left-1" data-tip="logout">
        <LogoutButton onClick={handleLogout}>
          <IconBase src={logOutIcon} color={"white"} size={"20px"} />
        </LogoutButton>
      </div>
    );
  }

  return (
    <FooterWrapper>
      {/* <span
        style={{ position: "fixed", top: "10px", left: "15px" }}
        data-tip="Finstead Live Connection Status"
      >
        {liveStatus}
      </span> */}
      <Navigation>
        {nav_item_props?.map((p, i) => (
          <NavItem {...p} key={i} />
        ))}

        {!!callInfo && Object.keys(callInfo).length > 0 && (
          <div className="fixed z-50 min-w-[200px] h-[75px] bg-white rounded-md text-black text-center pl-2 pt-2 bottom-[120px] left-10 drop-shadow-md">
            <h2>{callInfo.status || "Active"} Call</h2>
            <div>contact: {callInfo.contact}</div>
            {!!callInfo.deal && (
              <div>
                deal: <Link to={`/deals/${callInfo.deal.id}`} />
              </div>
            )}
          </div>
        )}
      </Navigation>

      {/* <div>
        <div className="flex flex-row justify-center gap-4 mb-8">
          <img
            width={"60px"}
            height={"auto"}
            style={{ filter: "greyscale(100%)" }}
            className="opacity-30 hover:opacity-100 hover:w-[150px]"
            src={"https://cdn.finstead.com.au/awards/BestBrokerAwards22.png"}
          />
          <img
            width={"60px"}
            height={"auto"}
            style={{ filter: "greyscale(100%)" }}
            className="opacity-30 hover:opacity-100 hover:w-[150px]"
            src={"https://cdn.finstead.com.au/awards/BestBrokerAwards21.png"}
          />
          <img
            width={"60px"}
            height={"auto"}
            style={{ filter: "greyscale(100%)" }}
            className="opacity-30 hover:opacity-100 hover:w-[150px]"
            src={"https://cdn.finstead.com.au/awards/BestBrokerAwardsMVP22.png"}
          />
        </div>

        <Marquee play gradient={false} style={{ width: "250px" }} speed={3}>
          {stocks.map((stock: any) => {
            return (
              <div className="text-white pl-1 pr-1">
                {stock.stock_code} ${stock.price} |
              </div>
            );
          })}
        </Marquee>
      </div> */}
      <Divider>
        <Styled1>
          <Styled d="M 247 1 L 0 1 L 0 0 L 247 0 L 247 1 Z" />
        </Styled1>
      </Divider>
      <Account>
        <AvatarLabelGroup>
          {/* <Avatar src={user?.profile_picture_url} /> */}
          <TextAndSupportingText>
            <div className="text-slate-200 text-xs">Signed In As</div>
            <Text> {user?.name?.first + " " + user?.name?.last}</Text>
            {/* <SupportingText>{user?.email}</SupportingText> */}
          </TextAndSupportingText>
        </AvatarLabelGroup>
        <LogoutButton onClick={handleLogout} data-tip="Sign out">
          <IconBase src={logOutIcon} color={"white"} size={"20px"} />
        </LogoutButton>
      </Account>
    </FooterWrapper>
  );
};

export default SideMenuFooter;
