import { useState } from "react";
import InputField from "components/inputs/InputField";
import Button from "components/core/Button";
import { setLocalStorageItem } from "utilities/LocalStorage";
import { post } from "utilities/Network";
import axios from "axios";
export type LoginPageProps = {};
import { Form, ErrorMessage } from "pages/NoAuthWrapperPage/LoginPage.styled";
import { promiseEnums } from "utilities/Enums";
import { useNavigate } from "react-router-dom";

type LoginProps = {
  isPartnerPortal?: boolean;
};

const Login = ({ isPartnerPortal }: LoginProps) => {
  const navigate = useNavigate();
  const [loadingProgress, setLoadingProgress] = useState(-1);
  const [loginStatus, setLoginStatus] = useState(promiseEnums.ready);
  const [loginFailed, setLoginFailed] = useState(false);
  const [emailAddress, setEmailAddress] = useState("");
  const [password, setPassword] = useState("");
  const [forgot, setForgotPassword] = useState(false);
  const [showPasswordResetConfirmation, setShowPasswordResetConfirmation] =
    useState(false);

  const closePasswordResetConfirmation = () => {
    setForgotPassword(false);
    setShowPasswordResetConfirmation(true);
    setTimeout(() => {
      setShowPasswordResetConfirmation(false);
    }, 3000);
  };

  const requestReset = () => {
    setLoginStatus(promiseEnums.pending);
    setLoginFailed(false);
    setLoadingProgress(75);
    let authUrl = ``;
    if (isPartnerPortal) {
      authUrl = `partners/request-password-reset/`;
    }

    post(authUrl, {
      email: emailAddress,
    })
      .then((response: any) => {
        setLoadingProgress(100);
        setTimeout(() => {
          setLoadingProgress(-1);
        }, 1000);
        setLoginStatus(promiseEnums.ready);
        closePasswordResetConfirmation();
      })
      .catch((error: any) => {
        // handle login error
        setLoginStatus(promiseEnums.ready);
        closePasswordResetConfirmation();
        setLoadingProgress(100);
        setTimeout(() => {
          setLoadingProgress(-1);
        }, 1000);
      });
  };

  const forgotPassword = () => {
    setForgotPassword(true);
  };

  const signin = () => {
    setLoginStatus(promiseEnums.pending);
    setLoginFailed(false);
    setLoadingProgress(75);
    setLoginFailed(false);
    let authUrl = `authentication/login/`;
    if (isPartnerPortal) {
      authUrl = `authentication/partners/login/`;
    }

    // authenticationModule.login("loginPopup", returnedAccountInfo);
    post(authUrl, {
      email: emailAddress,
      password: password,
    })
      .then((response: any) => {
        if (!response.data.user.mfa_setup) {
          navigate(
            `/reset-mfa/?token=${response.data.user.mfa_reset_token}&mfaUri=${response.data.user.mfa_uri}`
          );
        } else {
          // render loading screen and fetch user profile before forwarding to dashboard
          setLoadingProgress(100);
          setLoginStatus(promiseEnums.success);
          navigate(
            `/challenge-mfa/?token=${response.data.user.mfa_challenge_token}`
          );
        }
      })
      .catch((error: any) => {
        // handle login error
        setLoginStatus(promiseEnums.ready);
        setLoginFailed(true);
        setLoadingProgress(-1);
      });
  };

  if (showPasswordResetConfirmation) {
    return (
      <div className="w-full min-h-[200px] flex items-center justify-center text-center text-lg">
        Thanks! If there is an account with that email address, you will receive
        an email with instructions on how to reset your password. <br />
        We will now redirect you back to login....
      </div>
    );
  }

  if (forgot) {
    return (
      <>
        <Form>
          <InputField
            label={"Email"}
            placeholder={"Enter your email"}
            type={"email"}
            value={emailAddress}
            onChange={setEmailAddress}
            disabled={loginStatus == promiseEnums.pending}
          />
        </Form>
        <Button
          primary
          onClick={requestReset}
          text="Request Reset"
          wide
          disabled={loginStatus != promiseEnums.ready}
        ></Button>
      </>
    );
  }

  return (
    <>
      <Form>
        <InputField
          label={"Email"}
          placeholder={"Enter your email"}
          type={"email"}
          value={emailAddress}
          onChange={setEmailAddress}
          disabled={loginStatus == promiseEnums.pending}
        />
        <InputField
          label={"Password"}
          placeholder={"Enter your password"}
          type={"password"}
          value={password}
          onChange={setPassword}
          disabled={loginStatus == promiseEnums.pending}
        />
      </Form>
      {loginFailed && (
        <ErrorMessage>
          {"Something went wrong, please check your details and try again."}
        </ErrorMessage>
      )}
      <Button
        primary
        onClick={signin}
        text="Sign In"
        wide
        disabled={loginStatus != promiseEnums.ready}
      ></Button>
      <div
        className="flex min-h-[40px] justify-center items-end text-sm"
        onClick={forgotPassword}
      >
        <p className="hover:text-blue-500 cursor-pointer">Forgot Password?</p>
      </div>
    </>
  );
};

export default Login;
