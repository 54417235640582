import React, { useEffect, useState, useMemo } from "react";

import { EditorState, convertToRaw } from "draft-js";
import "draft-js/dist/Draft.css";
import "draftail/dist/draftail.css";
import { DraftailEditor, BLOCK_TYPE, INLINE_STYLE } from "draftail";

type Props = {
  initialState?: any;
  onStateChange?: (state: any) => void;
  disabled?: boolean;
};

const useTextEditor = ({ disabled }: Props) => {
  const [editorState, setEditorState] = useState<any>(
    EditorState.createEmpty()
  );
  const rawEditorState = useMemo(
    () => convertToRaw(editorState.getCurrentContent()),
    [editorState]
  );

  const reset = () => setEditorState(EditorState.createEmpty());

  const editor = useMemo(() => {
    return (
      <DraftailEditor
        disabled={disabled}
        rawContentState={null}
        onSave={() => {}}
        spellcheck
        commands
        placeholder="Write notes here"
        // Makes it easier to write automated tests retrieving the content.
        stateSaveInterval={50}
        stripPastedStyles={false}
        maxListNesting={6}
        enableHorizontalRule={{
          description: "Horizontal rule",
        }}
        enableLineBreak={{
          description: "Soft line break",
          // icon: BR_ICON,
        }}
        showUndoControl={{
          description: "Undo last change",
          // icon: UNDO_ICON,
        }}
        showRedoControl={{
          description: "Redo last change",
          // icon: REDO_ICON,
        }}
        blockTypes={[
          //   { type: BLOCK_TYPE.HEADER_ONE },
          //   { type: BLOCK_TYPE.HEADER_TWO },
          //   { type: BLOCK_TYPE.HEADER_THREE },
          //   { type: BLOCK_TYPE.HEADER_FOUR },
          //   { type: BLOCK_TYPE.HEADER_FIVE },
          { type: BLOCK_TYPE.UNORDERED_LIST_ITEM },
          { type: BLOCK_TYPE.ORDERED_LIST_ITEM },
        ]}
        inlineStyles={[
          { type: INLINE_STYLE.BOLD },
          { type: INLINE_STYLE.ITALIC },
        ]}
        editorState={editorState}
        onChange={setEditorState}
      />
    );
  }, [disabled, editorState]);

  return {
    reset,
    editorState,
    rawEditorState,
    editor,
  };
};

export default useTextEditor;
