import React from "react";
import InputFieldWrapper from "components/inputs/InputFieldWrapper";
import CheckBox from "components/core/CheckBox";
import {
  Label,
  InputWithLabel,
} from "components/inputs/InputFieldWrapper/InputFieldWrapper.styled";

type RadioGroupFieldProps = {
  label?: string;
  required?: boolean;
  errorMessage?: string;
  mode?: string;
  options: Array<any>;
  register: any;
  onItemHover: any;
  disabled?: boolean;
};

const RadioGroupField: React.FC<RadioGroupFieldProps> = ({
  label,
  required,
  errorMessage,
  mode = "any",
  options = [],
  register,
  onItemHover,
  disabled,
}) => {
  return (
    <>
      <InputFieldWrapper
        label={label}
        errorMessage={errorMessage}
        required={required}
      >
        <>
          {options.map((option, index) => (
            <InputWithLabel
              key={index}
              onMouseEnter={() => {
                onItemHover(option);
              }}
              onMouseLeave={() => {
                onItemHover(null);
              }}
            >
              {option.label && <Label>{option.label}</Label>}
              <CheckBox
                text={option.description}
                {...register(option.field)}
                disabled={disabled}
              />
            </InputWithLabel>
          ))}
        </>
      </InputFieldWrapper>
    </>
  );
};

export default RadioGroupField;
