import { useState } from "react";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import InputField from "components/inputs/InputField";
import validateABN from "utilities/Forms/validators/abnValidator";
import Button from "components/core/Button";
import { post } from "utilities/Network";
import { useNavigate } from "react-router-dom";

export type FormData = {
  business_name: string;
  entity_name: string;
  abn: string;
  contact_first_name: string;
  contact_last_name: string;
  contact_number: string;
  contact_email_address: string;
};

export const FormSchema = yup.object({
  business_name: yup.string().min(2).max(255).required(),
  entity_name: yup.string().min(2).max(255).required(),
  contact_first_name: yup.string().min(2).max(255).required(),
  contact_last_name: yup.string().min(2).max(255).required(),
  contact_number: yup.string().min(2).max(255).required(),
  contact_email_address: yup.string().email().required(),
  abn: yup
    .string()
    .required()
    .test(
      "validate-australian-business-number",
      "Please provide a valid Australia Business Number",
      (value) => {
        if (!!value) {
          return validateABN(value);
        }
        return false;
      }
    ),
});

export const RequiredFieldsMap = {
  business_name: true,
  entity_name: true,
  contact_first_name: true,
  contact_last_name: true,
  contact_number: true,
  contact_email_address: true,
  abn: true,
};

const NewLenderForm = () => {
  const navigate = useNavigate();
  const [submitting, setSubmitting] = useState(false);
  // use Form Hook
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<FormData>({
    resolver: yupResolver(FormSchema),
  });

  const onSubmit = (formData: FormData) => {
    setSubmitting(true);
    post(`lenders/`, formData).then((response) => {
      setSubmitting(false);
      navigate(`/lenders/${response.data.id}/`);
    });
  };

  return (
    <>
      {/* Form Container */}
      <form onSubmit={handleSubmit(onSubmit)}>
        <InputField
          formControlProps={register("business_name")}
          label={"Business Name"}
          errorMessage={errors.business_name?.message}
          required={!!RequiredFieldsMap.business_name}
          disabled={submitting}
        />

        <InputField
          formControlProps={register("entity_name")}
          label={"Entity Name"}
          errorMessage={errors.entity_name?.message}
          required={!!RequiredFieldsMap.entity_name}
          disabled={submitting}
        />

        <InputField
          formControlProps={register("abn")}
          label={"Entity Australian Business Number"}
          errorMessage={errors.abn?.message}
          required={!!RequiredFieldsMap.abn}
          disabled={submitting}
        />

        <InputField
          formControlProps={register("contact_first_name")}
          label={"Contact First Name"}
          errorMessage={errors.contact_first_name?.message}
          required={!!RequiredFieldsMap.contact_first_name}
          disabled={submitting}
        />

        <InputField
          formControlProps={register("contact_last_name")}
          label={"Contact Last Name"}
          errorMessage={errors.contact_last_name?.message}
          required={!!RequiredFieldsMap.contact_last_name}
          disabled={submitting}
        />

        <InputField
          formControlProps={register("contact_number")}
          label={"Contact Number"}
          errorMessage={errors.contact_number?.message}
          required={!!RequiredFieldsMap.contact_number}
          disabled={submitting}
        />

        <InputField
          formControlProps={register("contact_email_address")}
          label={"Contact Email"}
          errorMessage={errors.contact_email_address?.message}
          required={!!RequiredFieldsMap.contact_email_address}
          disabled={submitting}
        />

        <div className="w-full mb-2 text-sm text-right">
          <p>Once created you'll be redirect to the new lender's page.</p>
          <br />
          <Button
            text={submitting ? "Submitting" : "Create Lender"}
            disabled={submitting}
            type={"submit"}
            onClick={() => {}}
            primary
          />
        </div>
      </form>
    </>
  );
};

export default NewLenderForm;
