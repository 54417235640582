import React, { useState, useEffect } from "react";
import TableComponent from "components/core/TableComponent";
import {
  MainWrapper,
  Container,
  Section,
} from "pages/TeamPage/TeamPage.styled";
import PageHeader from "components/layout/PageHeader";
import { useParams, useNavigate } from "react-router-dom";
import { get } from "utilities/Network";
import InfoBox from "components/core/InfoBox";
import { ReactComponent as infoIcon } from "assets/icons/info.svg";
import { ReactComponent as userIcon } from "assets/icons/user.svg";
import Badge, { BadgeType } from "components/core/Badge";
import IconBase from "components/core/IconBase";
import constants from "styles/constants";
import Tabs from "components/core/Tabs";
import Button from "components/core/Button";
import SlidingPane from "components/layout/SlidingPane";
import NewProductForm from "./NewProductForm";
import useLenderManager from "hooks/useLenderManager";

const LenderDetailPage = ({ showProductsTab = false }: any) => {
  const navigate = useNavigate();
  const { lenderId } = useParams();
  const [newProductSliderOpen, setNewProductSliderOpen] = useState(false);
  const [lender, setLender] = useState<any>();

  const {
    lenderBusinessName,
    lenderEntityName,
    lenderABN,
    lenderContactFirstName,
    lenderContactLastName,
    lenderContactNumber,
    lenderEmailAddress,
  } = useLenderManager({ lender });

  const fetchLender = () => {
    get(`lenders/${lenderId}/`).then((response) => setLender(response.data));
  };
  useEffect(fetchLender, [lenderId]);

  const newProduct = () => {
    setNewProductSliderOpen(true);
  };

  return (
    <MainWrapper>
      <SlidingPane
        className="overlay-pane-content"
        overlayClassName="overlay-pane"
        isOpen={newProductSliderOpen}
        title="New Product"
        width="800px"
        subtitle={`Complete the form to create a new product for ${lender?.business_name}.`}
        onRequestClose={() => {
          // triggered on "<" on left top click or on outside click
          setNewProductSliderOpen(false);
        }}
        cancelPromptTitle={"Abandon new product?"}
        cancelPromptMessage={
          "Are you sure you'd like to abandon this new product form?"
        }
      >
        <NewProductForm />
      </SlidingPane>
      <Container>
        <PageHeader
          title={`Lender ${lender?.business_name}`}
          subtitle={<>{lenderId}</>}
          breadcrumbs={[
            {
              title: "Lenders",
              link: "/lenders",
            },
          ]}
          rightHandSideChildren={
            <Button onClick={newProduct} text={"New Product"} primary />
          }
        />
      </Container>
      <Section>
        <div className="flex flex-wrap-reverse w-full gap-8 pt-4">
          <Tabs
            defaultTab={showProductsTab ? 1 : 0}
            tabs={[
              {
                name: "Details",
                component: (
                  <>
                    <div className="w-full max-w-[700px]">
                      <InfoBox
                        title={"Lender Details"}
                        icon={
                          <IconBase
                            src={infoIcon}
                            color={constants.colors.primary_600}
                          />
                        }
                        info={{
                          ID: lender?.id,
                          "Business Name": lenderBusinessName,
                          "Entity Name": lenderEntityName,
                          ABN: lenderABN,
                          "Primary Contact First Name": lenderContactFirstName,
                          "Primary Contact Last Name": lenderContactLastName,
                          "Primary Contact Number": lenderContactNumber,
                          "Primary Email Address": lenderEmailAddress,
                        }}
                        w={"100%"}
                      />
                    </div>
                  </>
                ),
              },
              {
                name: "Products",
                component: (
                  <>
                    <TableComponent
                      endpoint={`lenders/${lenderId}/products/`}
                      onRowClick={(row: any, newTab = false) => {
                        if (newTab) {
                          window.open(
                            `https://crm.finstead.com.au/lenders/${lenderId}/products/${row.id}`,
                            "_blank",
                            "noreferrer"
                          );
                        } else {
                          navigate(`/lenders/${lenderId}/products/${row.id}`);
                        }
                      }}
                      fields={{
                        id: { name: "Product ID" },
                        name: { name: "Product Name" },
                      }}
                    />
                  </>
                ),
              },
              {
                name: "Deals",
                component: (
                  <>
                    <TableComponent
                      endpoint={`lenders/${lenderId}/deals/`}
                      onRowClick={(row: any, newTab = false) => {
                        if (newTab) {
                          window.open(
                            `https://crm.finstead.com.au/deals/${row.id}`,
                            "_blank",
                            "noreferrer"
                          );
                        } else {
                          navigate(`/deals/${row.id}`);
                        }
                      }}
                      fields={{
                        id: {
                          name: "Id",
                        },
                        pipedrive_id: {
                          name: "Pipedrive Id",
                        },
                        broker: {
                          name: "Broker",
                          middleware: (row: any) => (
                            <>
                              {!!row.broker_profile_picture_url ? (
                                <img
                                  src={row.broker_profile_picture_url}
                                  className="w-[25px] h-[25px] object-cover rounded-full inline-block mr-2"
                                />
                              ) : (
                                <span className="inline-block mr-2 border rounded-full border-slate-100">
                                  <IconBase src={userIcon} />
                                </span>
                              )}
                              <span>{row.broker}</span>
                            </>
                          ),
                        },
                        title: {
                          name: "Title",
                        },
                        add_time: {
                          name: "Lead In",
                        },
                        value: {
                          name: "Value",
                        },
                        status: {
                          name: "Status",
                          middleware: (row: any) => {
                            switch (row.status) {
                              case "":
                                return "";
                              case "open":
                                return (
                                  <Badge type={BadgeType.info} text="Open" />
                                );
                              case "lost":
                                return (
                                  <Badge type={BadgeType.danger} text="Lost" />
                                );
                              case "pending assignment":
                                return (
                                  <Badge
                                    type={BadgeType.info}
                                    text={"Lead In"}
                                  />
                                );
                              case "won":
                              case "settled":
                                return (
                                  <Badge
                                    type={BadgeType.success}
                                    text={"Settled"}
                                  />
                                );
                              default:
                                return (
                                  <Badge
                                    type={BadgeType.warning}
                                    text={"Unknown ?"}
                                  />
                                );
                            }
                          },
                        },
                      }}
                    />
                  </>
                ),
              },
            ]}
          />
        </div>
      </Section>
    </MainWrapper>
  );
};

export default LenderDetailPage;
