import styled from "styled-components";

import {
  TextSmNormal,
  TextMdNormal,
  DisplayMdSemibold,
  TextXlNormal,
} from "styles/mixins";

export const ReadyForSubmissionBanner = styled.div`
  text-align: center;
  width: 100%;
  margin-top: 32px;
  margin-bottom: 32px;
  ${TextMdNormal};
  color: ${({ theme }) => theme.colors.primary_900};
`;

export const SuccessBanner = styled.div`
  text-align: center;
`;

export const HeadingAndSupportingTextWrapper = styled.div`
  display: flex;
  height: 261px;
  align-self: stretch;
  flex-direction: column;
  align-items: center;
  gap: 20px;
`;
export const HeadingAndBadge = styled.div`
  display: flex;
  align-self: stretch;
  flex-direction: column;
  align-items: center;
  gap: 16px;
`;
export const Heading = styled.span`
  text-align: center;
  align-self: stretch;
  color: ${({ theme }) => theme.colors.gray_900};

  ${DisplayMdSemibold}
`;
export const SupportingText = styled.span`
  text-align: center;
  align-self: stretch;
  color: ${({ theme }) => theme.colors.gray_500};

  ${TextXlNormal}
`;
export const InsuranceInfoBox = styled.div`
  width: 100%;
  padding: 20px;
  min-height: 130px;
  border-radius: 8px;
  font-size: ${TextSmNormal};
  border: 1px solid rgb(208, 213, 221);
  margin-bottom: 20px;
  text-align: left;
`;
