import styled from "styled-components";

import { DisplaySmMedium, TextMdNormal } from "styles/mixins";

export const PageHeaderWrapper = styled.div`
  display: flex;
  align-self: stretch;
  flex-direction: column;
  align-items: flex-start;
  gap: 15px;

  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
`;
export const Content = styled.div`
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  gap: 16px;
`;
export const TextAndSupportingText = styled.div`
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
`;

export const RHSChildren = styled.div`
  text-align: right;
  display: flex;
  justify-content: right;

  flex-grow: 1;
`;
export const Text = styled.span`
  text-align: left;
  align-self: stretch;
  color: ${({ theme }) => theme.colors.gray_900};

  ${DisplaySmMedium}
`;
export const SupportingText = styled.span`
  text-align: left;
  align-self: stretch;
  color: ${({ theme }) => theme.colors.gray_500};

  ${TextMdNormal}
`;

export const Divider = styled.div`
  display: flex;
  background: rgba(228, 231, 236, 1);
  width: 100%;
  height: 1px;
`;
