import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { LoadingState } from "api/constants";
import { dealAPI } from "api";

interface DealState {
  deal: any;
  loading: LoadingState;
}

const initialState: DealState = {
  deal: null,
  loading: LoadingState.idle,
};

export const dealSlice = createSlice({
  name: "deal",
  initialState,
  reducers: {
    setDealId: (state, action: PayloadAction<{ id: number }>) => {
      state.deal = { id: action.payload.id };
    },
    setDealLoading: (state, action: PayloadAction<any>) => {
      state.loading = action.payload;
    },
    setDeal: (state, action: PayloadAction<any>) => {
      state.deal = action.payload;
    },
    resetDeal: (state) => {
      state.deal = null;
    },
    setDealContactMade: (state) => {
      state.deal.first_contact_made = true;
    },
  },
});

export const {
  setDealId,
  setDeal,
  setDealLoading,
  resetDeal,
  setDealContactMade,
} = dealSlice.actions;
export default dealSlice.reducer;
