import React, { useState, useEffect, useCallback } from "react";
import { useParams } from "react-router-dom";
import constants from "styles/constants";
import { LoadingState } from "api/constants";
import Modal from "components/core/Modal";
import BrokerNewDealForm, {
  SectionState,
} from "pages/DealPage/BrokerNewDealForm";

import IconBase from "components/core/IconBase";
import Button from "components/core/Button";
import DealStatusBadge from "./DealStatusBadge";
import TableComponent from "components/core/TableComponent";
import SlidingPane from "components/layout/SlidingPane";
import "react-sliding-pane/dist/react-sliding-pane.css";
import DealSettlementSummary from "frames/DealSettlementSummary";
import {
  formatMoney,
  formatUnderCase,
  getLenderSearchSelectLabel,
} from "utilities/Strings";
import {
  MainWrapper,
  Container,
  Section,
} from "pages/TeamPage/TeamPage.styled";
import { toast } from "react-toastify";
import Stepper from "components/core/Stepper";
import PageHeader from "components/layout/PageHeader";
import InfoBox from "components/core/InfoBox";
import Box from "components/core/Box";
import BrokerDropDownField from "components/inputs/BrokerDropDownField";
import { ReactComponent as homeIcon } from "assets/icons/home.svg";
import { ReactComponent as trophyIcon } from "assets/icons/zap.svg";
import { ReactComponent as PlusIcon } from "assets/icons/plus.svg";
import Tabs from "components/core/Tabs";
import { Editor, EditorState } from "draft-js";
import DealTimeline from "components/core/DealTimeline";
import Badge, { BadgeType } from "components/core/Badge";
import useDeal from "hooks/useDeal";
import LoaderLogo from "components/core/LoaderLogo";
// import FirstContactMadePromptBox from "./FirstContactMadePromptBox";
import ReOpenDealPromptBox from "./ReOpenDealPromptBox";
// import DealSettlementForm from "forms/DealSettlementForm";
import { get, post } from "utilities/Network";
import useDealStateManager from "hooks/useDealStateManager";
import useEditableText from "hooks/useEditableText";
import FileUploadForm from "./FileUploadForm";
import FileDetailComponent from "./FileDetailComponent";
import useApplicantManager from "hooks/useApplicantManager";
import useOrganisationManager from "hooks/useOrganisationManager";
import { useNavigate } from "react-router-dom";
import DropDownField from "components/inputs/DropDownField";
import ReactTooltip from "react-tooltip";
import SectionHeader from "components/layout/SectionHeader";
import ActivityForm from "forms/ActivityForm";
import useAreYouSure from "hooks/useAreYouSure";
import useAlert from "hooks/useAlert";
import useTextEditor from "hooks/useTextEditor";
import { useActivities } from "hooks/useActivities";
import SearchSelectField from "components/inputs/SelectSearchField";
import { Lender, Product } from "types";
import BrokerageInvoiceForm from "forms/BrokerageInvoiceForm";
import ReferrerRCTIForm from "forms/ReferrerRCTIForm";
import ApplicantRCTIForm from "forms/ApplicantRCTIForm";
import APICallLogData from "components/core/APICallLogData/APICallLogData";
import DropDownButton from "components/core/DropDownButton";
import TaxInvoiceForm from "forms/TaxInvoiceForm";
import PrivateSaleInvoiceForm from "forms/PrivateSaleInvoiceForm";

const DealDetailPage = ({ dealIdOverride }: any) => {
  // deal state
  const navigate = useNavigate();
  const { dealId = dealIdOverride } = useParams();

  const { activitiesSection } = useActivities({
    hideFilterBar: true,
    dealId: dealId,
  });
  const { alert, alertModal } = useAlert();
  const { areYouSure, areYouSureModal } = useAreYouSure();
  const [showSettlementReview, setShowSettlementReview] = useState(false);
  const [showDuplicationForm, setShowDuplicationForm] = useState(false);
  const [showBrokerInvoiceGeneration, setShowBrokerInvoiceGeneration] =
    useState(false);

  const [showTaxInvoiceGeneration, setShowTaxInvoiceGeneration] =
    useState(false);
  const [showPrivateSaleInvoice, setShowPrivateSaleInvoice] = useState(false);

  const [showReferrerInvoiceGeneration, setShowReferrerInvoiceGeneration] =
    useState(false);
  const [showApplicantInvoiceGeneration, setShowApplicantInvoiceGeneration] =
    useState(false);

  const [mainTextEditorState, setMainTextEditorState] = useState();

  const [timeline, setTimeline] = useState([]);
  const [activities, setActivities] = useState([]);
  const [modalWidth, setModalWidth] = useState("1100px");

  const { deal, loading, fetchDeal, updateDeal } = useDeal();

  const [notes, setNotes] = useState("");
  const [notePosting, setNotePosting] = useState(false);

  // component state
  const [showModal, setShowModal] = useState(false);
  const [modalProps, setModalProps] = useState<any>({});
  const [products, setProducts] = useState<Array<Product>>([]);

  // notes editor state
  const {
    editor: notesEditor,
    reset: resetNotesEditor,
    rawEditorState: notesRawEditorState,
  } = useTextEditor({ disabled: notePosting });

  const [fileFormOpen, setFileFormOpen] = useState(false);
  const [selectedFile, setSelectedFile] = useState<any>();
  const [openFileDetailPane, setOpenFileDetailPane] = useState(false);
  const [triggerFileListReload, setTriggerFileListReload] = useState(false);

  const {
    applicantFirstNameText,
    applicantLastNameText,
    applicantContactNumber,
    applicantEmailAddress,
  } = useApplicantManager({ applicant: deal?.person });

  const {
    organisationNameText,
    organisationStateText,
    organisationPostCodeText,
    organisationStreetAddressText,
    organisationABNText,
  } = useOrganisationManager({ organisation: deal?.organisation });

  // Deal State manager Hook
  const {
    markLost,
    loadDeal,
    updateAssignedBroker,
    headerText,
    openSettledReport,
    NAFText,
    lendIdText,
    lenderApplicationIdText,
  } = useDealStateManager({
    deal,
    dealId,
    setModalProps,
    setShowModal,
    fetchDeal,
    setTimeline,
    updateDeal,
  });

  // load the deal
  useEffect(loadDeal, [dealId, fetchDeal, loadDeal]);

  const fetchActivities = () => {
    get(`deals/${dealId}/activities/`).then((response) => {
      setActivities(response.data.activities);
    });
  };

  // load the deals activities
  useEffect(fetchActivities, [dealId]);

  const newActivity = () => {
    setModalProps({
      title: "New Activity",
      children: (
        <ActivityForm
          dealId={deal.id}
          cb={() => {
            setShowModal(false);
            fetchActivities();
          }}
        ></ActivityForm>
      ),
    });
    setShowModal(true);
  };

  const markOpen = () => {
    get(`deals/${dealId}/re-open/`).then(() => {
      updateDeal({
        ...deal,
        status: "open",
      });
      get(`deals/${dealId}/timeline/`).then((r) =>
        setTimeline(r.data.timeline)
      );
    });
  };

  const openInLend = () => {
    get(`deals/${deal?.id}/lend/link/`).then((response) => {
      const url = response.data.link;
      window.open(url, "_blank", "noopener,noreferrer");
    });
  };

  const postNote = () => {
    setNotePosting(true);
    post(`deals/${dealId}/notes/`, { content: notesRawEditorState }).then(
      (response) => {
        resetNotesEditor();
        get(`deals/${dealId}/timeline/`).then((r) =>
          setTimeline(r.data.timeline)
        );
        setNotePosting(false);
      }
    );
  };

  const [selectedProductId, setSelectedProductId] = useState(deal?.product?.id);

  useEffect(() => {
    setSelectedProductId(deal?.product?.id);
  }, [deal?.product?.id]);

  const fetchProducts = (lender: Lender) => {
    get(`lenders/${lender.id}/products/list/`).then((response) => {
      setProducts(response.data);
    });
  };

  const openInNewTab = (url: any) => {
    window.open(url, "_blank", "noopener,noreferrer");
  };

  const handleFileRowClicked = (row: any) => {
    setOpenFileDetailPane(true);
    setSelectedFile(row);
  };

  const openBrokerInvoiceGeneration = () => {
    setShowBrokerInvoiceGeneration(true);
  };

  const openApplicantInvoiceGeneration = () => {
    setShowApplicantInvoiceGeneration(true);
  };

  const openReferrerInvoiceGeneration = () => {
    setShowReferrerInvoiceGeneration(true);
  };

  const openTaxInvoiceGeneration = () => {
    setShowTaxInvoiceGeneration(true);
  };

  const openPrivateSaleInvoice = () => {
    setShowPrivateSaleInvoice(true);
  };

  const openSettlementReview = () => {
    setShowSettlementReview(true);
  };

  const openDuplicateForm = () => {
    setShowDuplicationForm(true);
  };

  if (deal === null || loading === LoadingState.pending) {
    return (
      <MainWrapper>
        <Container>
          <PageHeader
            title={`Deal ${dealId} ...`}
            subtitle={`Loading deal ${dealId}`}
            breadcrumbs={[
              {
                title: "Deals",
                link: "/deals",
              },
            ]}
          />
        </Container>
        <Section style={{ alignItems: "center" }}>
          <LoaderLogo />
        </Section>
      </MainWrapper>
    );
  }

  return (
    <MainWrapper>
      {alertModal}
      <ReactTooltip />
      <Container>
        <PageHeader
          title={headerText}
          subtitle={
            <>
              {dealId}
              <br />
              <span className="text-sm">
                {deal &&
                  deal?.asset_type &&
                  formatUnderCase(deal?.asset_type) + "/"}{" "}
                {deal?.value} / lead in {deal && deal?.deal_age}.
              </span>
            </>
          }
          breadcrumbs={[
            {
              title: "Deals",
              link: "/deals",
            },
          ]}
          rightHandSideChildren={
            <div className="min-w-[150px]">
              <div className="w-full flex justify-end mb-4">
                <div className="min-w-[200px]">
                  <DealStatusBadge status={deal?.status} />
                </div>
              </div>
              {/* {deal?.status === "settled" && ( */}
              {/* <Button
                onClick={openBrokerInvoiceGeneration}
                text={"Broker Invoice"}
                className="text-sm min-w-[170px] max-h-[30px] ml-2"
              /> */}
              {/* <Button
                onClick={openApplicantInvoiceGeneration}
                text={"Applicant RCTI"}
                className="text-sm min-w-[170px] max-h-[30px] ml-2"
              /> */}
              <div className="flex flex-row gap-2">
                <Button
                  onClick={openSettlementReview}
                  text={"Review Settlement"}
                  className="text-sm min-w-[170px] max-h-[42px] ml-2"
                />
                <Button
                  onClick={openDuplicateForm}
                  text={"Duplicate Deal"}
                  className="text-sm min-w-[170px] max-h-[42px] ml-2"
                />

                <DropDownButton
                  title={"New Document"}
                  options={[
                    {
                      text: "Referrer RCTI",
                      onClick: openReferrerInvoiceGeneration,
                    },
                    {
                      text: "Broker Invoice",
                      onClick: openBrokerInvoiceGeneration,
                    },
                    {
                      text: "Tax Invoice Request",
                      onClick: openTaxInvoiceGeneration,
                    },
                    // {
                    //   text: "Private Sale Invoice",
                    //   onClick: openPrivateSaleInvoice,
                    // },
                  ]}
                />
              </div>
            </div>
          }
        />
      </Container>

      {/* Private Sale Invoice */}
      <SlidingPane
        className="overlay-pane-content"
        overlayClassName="overlay-pane"
        isOpen={showPrivateSaleInvoice}
        title="Private Sale Invoice"
        width="800px"
        subtitle="Complete the form to generate a new private sale invoice pdf."
        onRequestClose={() => {
          // triggered on "<" on left top click or on outside click
          setShowPrivateSaleInvoice(false);
        }}
        cancelPromptTitle={"Abandon new Private Sale Invoice?"}
        cancelPromptMessage={"Are you sure you'd like to abandon this form?"}
        onCancelHideCancelPrompt={true}
      >
        {/* <BrokerageInvoiceForm dealId={dealId} /> */}
        <PrivateSaleInvoiceForm dealId={dealId} />
      </SlidingPane>

      {/* Tax Invoice */}
      <SlidingPane
        className="overlay-pane-content"
        overlayClassName="overlay-pane"
        isOpen={showTaxInvoiceGeneration}
        title="Tax Invoice Request"
        width="800px"
        subtitle="Complete the form to generate a new tax invoice pdf."
        onRequestClose={() => {
          // triggered on "<" on left top click or on outside click
          setShowTaxInvoiceGeneration(false);
        }}
        cancelPromptTitle={"Abandon new Tax Invoice?"}
        cancelPromptMessage={"Are you sure you'd like to abandon this form?"}
        onCancelHideCancelPrompt={true}
      >
        <TaxInvoiceForm dealId={dealId} />
      </SlidingPane>

      {/* Brokerage Generation */}
      <SlidingPane
        className="overlay-pane-content"
        overlayClassName="overlay-pane"
        isOpen={showBrokerInvoiceGeneration}
        title="New Broker Invoice"
        width="800px"
        subtitle="Complete the form to generate a new brokerage invoice pdf."
        onRequestClose={() => {
          // triggered on "<" on left top click or on outside click
          setShowBrokerInvoiceGeneration(false);
        }}
        cancelPromptTitle={"Abandon new Invoice?"}
        cancelPromptMessage={"Are you sure you'd like to abandon this form?"}
        onCancelHideCancelPrompt={true}
      >
        <BrokerageInvoiceForm dealId={dealId} />
      </SlidingPane>

      {/* Referrer RCTI Generation */}
      <SlidingPane
        className="overlay-pane-content"
        overlayClassName="overlay-pane"
        isOpen={showReferrerInvoiceGeneration}
        title="New Referrer RCTI"
        width="800px"
        subtitle="Complete the form to generate a new referrer RCTI pdf."
        onRequestClose={() => {
          // triggered on "<" on left top click or on outside click
          setShowReferrerInvoiceGeneration(false);
        }}
        cancelPromptTitle={"Abandon referrer RCTI?"}
        cancelPromptMessage={"Are you sure you'd like to abandon this form?"}
        onCancelHideCancelPrompt={true}
      >
        <ReferrerRCTIForm dealId={dealId} />
      </SlidingPane>

      {/* Referrer RCTI Generation */}
      <SlidingPane
        className="overlay-pane-content"
        overlayClassName="overlay-pane"
        isOpen={showApplicantInvoiceGeneration}
        title="New Applicant RCTI"
        width="800px"
        subtitle="Complete the form to generate a new applicant RCTI pdf."
        onRequestClose={() => {
          // triggered on "<" on left top click or on outside click
          setShowApplicantInvoiceGeneration(false);
        }}
        cancelPromptTitle={"Abandon applicant RCTI?"}
        cancelPromptMessage={"Are you sure you'd like to abandon this form?"}
        onCancelHideCancelPrompt={true}
      >
        <ApplicantRCTIForm dealId={dealId} />
      </SlidingPane>

      {/* Deal Duplication Form Modal */}
      <SlidingPane
        className="overlay-pane-content"
        overlayClassName="overlay-pane"
        isOpen={showDuplicationForm}
        title="New Deal (via duplication)"
        width="800px"
        subtitle="Complete the form to create a new deal."
        onRequestClose={() => {
          // triggered on "<" on left top click or on outside click
          setShowDuplicationForm(false);
        }}
        cancelPromptTitle={"Abandon new Deal?"}
        cancelPromptMessage={"Are you sure you'd like to abandon this form?"}
      >
        <BrokerNewDealForm
          newOrganisationDefault={true}
          newApplicantDefault={false}
          defaultOrganisation={deal?.organisation}
          defaultApplicant={deal?.person}
          defaultReferrer={deal?.partner}
          duplicate={true}
          defaultReferrerState={SectionState.search}
          defaultOrganisationState={SectionState.existing}
          defaultApplicantState={SectionState.existing}
          originalDeal={deal}
          onSubmit={(cb: any) => {
            setShowDuplicationForm(false);
            if (!!cb) {
              cb();
            }
          }}
        />
      </SlidingPane>

      {/* Settlement Review Modal */}
      <Modal
        title={`Settlement Review - Deal ${dealId} ${deal.title}`}
        isOpen={showSettlementReview}
        congratulate
        onRequestClose={() => {
          setShowSettlementReview(false);
        }}
      >
        <DealSettlementSummary dealId={dealId} />
      </Modal>

      {/* Deal State Stepper */}
      <Stepper
        steps={deal?.stages}
        currentStep={deal?.stage?.id}
        handleClick={(stage: any, is_settled_stage?: boolean) => {
          if (is_settled_stage) {
            // open the settlement form
            if (!deal.product || !deal.lender) {
              alert(() => {}, "Please select a product and lender first", "Ok");
            } else {
              openSettledReport(() => {});
            }
          } else {
            const moveStage = () => {
              console.log(stage);
              // update the stage

              post(`deals/${deal.id}/stage/update/`, {
                stage: stage?.id,
              })
                .then(() => {
                  get(`deals/${deal?.id}/timeline/`).then((r) =>
                    setTimeline(r.data.timeline)
                  );
                  updateDeal({
                    ...deal,
                    stages: deal.stages.map((_stage: any) => {
                      return {
                        ..._stage,
                        complete: _stage.order_nr <= stage.order_nr,
                      };
                    }),
                    stage: stage,
                  });
                })
                .catch(() => {});
            };

            if (deal?.stage?.is_settled_stage) {
              console.log("CURRENT STAGE IS A SETTLEMENT STAGE, CHECKING");
              areYouSure(
                moveStage,
                "Are you sure you want to move this deal out of settled?",
                "Yes",
                "No"
              );
            } else {
              moveStage();
            }
          }
        }}
      />

      {/* State Detail Pane */}
      <SlidingPane
        className="overlay-pane-content max-w-[100%]"
        overlayClassName="overlay-pane"
        title={modalProps.title}
        isOpen={showModal}
        width={modalWidth}
        onRequestClose={() => {
          setShowModal(false);
        }}
        cancelPromptTitle={"Are you sure?"}
        cancelPromptMessage={"Are you sure you'd like to abandon this form?"}
      >
        <>{modalProps.children}</>
      </SlidingPane>

      {/* File Detail Pane */}
      <SlidingPane
        className="overlay-pane-content max-w-[100%]"
        overlayClassName="overlay-pane"
        isOpen={openFileDetailPane}
        title={`${selectedFile?.key}`}
        width="800px"
        subtitle={`File ID: ${selectedFile?.id}`}
        onRequestClose={() => {
          setOpenFileDetailPane(false);
        }}
        cancelPromptTitle={"Are you sure?"}
        cancelPromptMessage={"Are you sure you'd like to abandon this form?"}
      >
        <FileDetailComponent
          endpoint={`deals/${dealId}/files/${selectedFile?.id}/`}
        />
      </SlidingPane>

      <Section>
        <div className="flex flex-wrap-reverse w-full gap-8">
          {/* Info Boxes */}
          <div>
            {/* {deal.first_contact_made && !deal.first_contact_report_complete && (
              <Box
                title={"Todo"}
                icon={
                  <IconBase
                    src={trophyIcon}
                    color={constants.colors.primary_600}
                  />
                }
              >
                <h2 className="mt-2 mb-2 text-lg">
                  Well done making contact, please complete first contact
                  report.
                </h2>
                <Button
                  text="Complete Report"
                  wide
                  onClick={() => openFirstContactReport}
                />
              </Box>
            )} */}

            <div className="w-100 min-h-[100px] text-center border border-slate-200 rounded-md mb-2 p-4">
              <h2 className="mb-4">Assigned Team Member</h2>
              <hr className="mb-3" />
              <br />
              <img
                className="rounded-full w-[100px] h-[100px] drop-shadow-md"
                style={{ margin: "auto auto" }}
                src={deal.assigned_person_image}
              ></img>
              <br />
              <BrokerDropDownField
                placeholder={deal.assigned_person}
                allowDefault={false}
                endpoint="team/brokers/"
                onChange={(e) => {
                  if (!!e) {
                    updateAssignedBroker(e);
                  }
                }}
              />
            </div>

            <InfoBox
              title={"Actions"}
              icon={
                <IconBase
                  src={trophyIcon}
                  color={constants.colors.primary_600}
                />
              }
              info={{
                ...(deal.status !== "lost"
                  ? {
                      "Lost deal report:": (
                        <>
                          <Badge
                            text="Mark as Lost"
                            type={BadgeType.danger}
                            sm
                            onClick={markLost}
                          />
                        </>
                      ),
                    }
                  : {
                      "Deal Lost": (
                        <>
                          <Badge
                            text="Re-open Deal"
                            type={BadgeType.success}
                            sm
                            onClick={markOpen}
                          />
                        </>
                      ),
                    }),
                "Insurance Referral": (
                  <>
                    <Badge
                      text="Refer to FRS"
                      type={BadgeType.success}
                      sm
                      onClick={() => {
                        openInNewTab(deal?.insurance_referral_link);
                      }}
                    />
                  </>
                ),
                Lend: (
                  <>
                    {/* <Badge
                      text={deal?.lendId !== undefined ? "Open in lend" : "-"}
                      type={
                        deal?.lendId !== undefined
                          ? BadgeType.info
                          : BadgeType.secondary
                      }
                      sm
                      onClick={
                        deal?.lendId !== undefined ? openInLend : () => {}
                      }
                    /> */}
                    <Badge
                      text={"Open in lend"}
                      type={BadgeType.info}
                      sm
                      onClick={openInLend}
                    />
                  </>
                ),
              }}
            />
            <InfoBox
              title={"Lender & Product"}
              icon={
                <IconBase src={homeIcon} color={constants.colors.primary_600} />
              }
              info={{
                Lender: (
                  <div className="flex justify-center w-full h-full mb-2">
                    <SearchSelectField
                      slim={true}
                      placeholder={"Search Lenders"}
                      ep={`lenders/search/`}
                      defaultValue={deal?.lender}
                      onChange={(lender: Lender | null) => {
                        updateDeal({
                          ...deal,
                          lender: lender,
                        });
                        let optionals = {};
                        if (lender === null) {
                          optionals = {
                            field2: "product",
                            value2: null,
                          };
                        } else {
                          console.log("LENDER IS SET SEARCHING FOR PRODUCTS");
                          fetchProducts(lender);
                        }

                        post(`deals/${deal?.id}/update/`, {
                          field: "lender_id",
                          value: lender?.id,
                          ...optionals,
                        }).then((response) => {
                          if (lender === null) {
                            toast(`Lender has been unset.`);
                            toast(`product has been unset.`);
                            updateDeal({
                              ...deal,
                              lender: null,
                              product: null,
                            });
                          } else {
                            toast(
                              `The lender has been set to ${lender?.business_name}.`
                            );
                          }
                        });
                      }}
                      getOptionLabel={(l: Lender) => `${l.business_name}`}
                      disabled={
                        deal.status === "settled" &&
                        !!deal?.lender &&
                        !!deal?.product
                      }
                    />
                  </div>
                ),
                "Loan Product": (
                  <div className="flex justify-center w-full h-full">
                    <DropDownField
                      required={true}
                      placeholder={
                        deal.lender === null
                          ? "Please select a lender first"
                          : deal?.status === "settled"
                          ? deal?.product?.name
                          : `Please a product`
                      }
                      options={(deal.lender === null
                        ? []
                        : products.length === 0
                        ? deal.lender_products.length > 0
                          ? deal.lender_products
                          : []
                        : products
                      ).map((e: any) => {
                        return { text: e.name, value: e.id };
                      })}
                      disabled={
                        (deal?.status === "settled" && !!deal?.product) ||
                        (deal.lender === null &&
                          products.length == 0 &&
                          deal.lender_products.length == 0)
                      }
                      loading={loading}
                      value={
                        deal.lender === null ? undefined : selectedProductId
                      }
                      placeholderDisabled={false}
                      onChange={(product_id: number) => {
                        updateDeal({
                          ...deal,
                          product: {
                            id: product_id,
                          },
                        });

                        setSelectedProductId(product_id);

                        post(`deals/${deal?.id}/update/`, {
                          field: "product_id",
                          value: product_id,
                        }).then((response) => {
                          toast(`The product has been updated.`);
                          //
                        });
                      }}
                    />
                  </div>
                ),
              }}
            />
            <InfoBox
              title={"Overview"}
              icon={
                <IconBase src={homeIcon} color={constants.colors.primary_600} />
              }
              info={{
                "Finstead ID": deal?.id,
                // "Pipedrive ID": (
                //   <a
                //     href={`https://finstead3.pipedrive.com/deal/${deal?.pipedrive_id}`}
                //     target="_blank"
                //     className="text-blue-700"
                //     data-tip={`Open deal ${deal?.pipedrive_id} in PipeDrive`}
                //   >
                //     {deal?.pipedrive_id}
                //   </a>
                // ),
                "Lender Application ID": lenderApplicationIdText,
                "Lend ID": lendIdText,
                Title: headerText,
                Type: (
                  <div className="flex w-full justify-center items-center">
                    <DropDownField
                      placeholder={""}
                      value={deal?.asset_type}
                      options={deal?.deal_types?.map((e: any) => {
                        return { text: e, value: e };
                      })}
                      onChange={(new_type: any) => {
                        updateDeal({
                          ...deal,
                          asset_type: new_type,
                        });
                        post(`deals/${deal?.id}/update/`, {
                          field: "asset_type",
                          value: new_type,
                        });
                        toast.success(
                          `The deal type has been updated to ${new_type}.`
                        );
                      }}
                      // disabled={loading}
                      // loading={loading}
                    />
                  </div>
                ),
                NAF: NAFText,
              }}
            />
            {/* <InfoBox
              title={"Deal Momentum"}
              icon={
                <IconBase src={homeIcon} color={constants.colors.primary_600} />
              }
              info={{ Assigned: "b", "Loan Type": "c" }}
            /> */}
            {!!deal.person && (
              <InfoBox
                title={"Applicant"}
                titleClicked={() => {
                  navigate(`/applicants/${deal?.person?.id}`);
                }}
                icon={
                  <IconBase
                    src={homeIcon}
                    color={constants.colors.primary_600}
                  />
                }
                info={{
                  "First Name": applicantFirstNameText,
                  "Last Name": applicantLastNameText,
                  "Contact Number": applicantContactNumber,
                  "Email Address": applicantEmailAddress,
                }}
              />
            )}
            {!!deal.organisation && (
              <InfoBox
                title={"Organisation"}
                titleClicked={() => {
                  navigate(`/organisations/${deal?.organisation?.id}`);
                }}
                icon={
                  <IconBase
                    src={homeIcon}
                    color={constants.colors.primary_600}
                  />
                }
                info={{
                  Name: organisationNameText,
                  State: organisationStateText,
                  Postcode: organisationPostCodeText,
                  "Street address": organisationStreetAddressText,
                  ABN: organisationABNText,
                }}
              />
            )}
            {!!deal.partner && (
              <InfoBox
                title={"Referral Partner"}
                icon={
                  <IconBase
                    src={homeIcon}
                    color={constants.colors.primary_600}
                  />
                }
                info={{
                  name: deal.partner?.business_name || "",
                }}
              />
            )}
          </div>

          {/* Work Space */}
          <div className="flex-1">
            {/* Prompt broker to call client */}
            {/* {!deal.first_contact_made && <FirstCallPromptBox />} */}
            {/* {!deal.first_contact_report_complete && deal.status !== "lost" && (
              <FirstContactMadePromptBox
                openFirstContactReport={() => openFirstContactReport()}
              />
            )} */}

            {deal.status === "lost" && (
              <ReOpenDealPromptBox markOpen={() => markOpen()} />
            )}

            {/* Tabs Control */}
            <Tabs
              tabs={[
                {
                  name: "Note",
                  component: (
                    <>
                      {notesEditor}
                      <div className="pt-2 text-right w-100">
                        <Button
                          text={notePosting ? "Saving" : "Save Note"}
                          onClick={postNote}
                          disabled={notePosting}
                        />
                      </div>
                    </>
                  ),
                },
                {
                  name: "Activities",
                  component: (
                    <>
                      <SectionHeader
                        noDivider
                        title={"Activities"}
                        filters={
                          <Button
                            text={"New Activity"}
                            onClick={newActivity}
                          ></Button>
                        }
                      />
                      {activitiesSection}
                      {/* <DealTimeline
                        events={activities}
                        noEventsMessage={"This deal has no activities."}
                        setEvents={setActivities}
                        handleChange={(activity: any, value: any) => {
                          post(`activities/${activity.pk}/update/`, {
                            field: "done",
                            value: value,
                          });
                        }}
                      /> */}
                    </>
                  ),
                },
                {
                  name: "Files",
                  component: (
                    <div>
                      <div className="w-full mb-2 text-right">
                        <SlidingPane
                          className="overlay-pane-content"
                          overlayClassName="overlay-pane"
                          isOpen={fileFormOpen}
                          title={`New File`}
                          width="800px"
                          subtitle={`Complete the form below to upload a new file to the "${deal.title}" deal.`}
                          onRequestClose={() => {
                            // triggered on "<" on left top click or on outside click
                            setFileFormOpen(false);
                            setTriggerFileListReload(!triggerFileListReload);
                          }}
                          cancelPromptTitle={"Are you sure?"}
                          cancelPromptMessage={
                            "Are you sure you'd like to abandon this form?"
                          }
                        >
                          <FileUploadForm
                            presignUrlEndpoint={`deals/${dealId}/files/pre-sign-upload/`}
                            filesPath={`deals/${dealId}/`}
                            closeForm={() => {
                              setFileFormOpen(false);
                              setTriggerFileListReload(!triggerFileListReload);
                            }}
                          />
                        </SlidingPane>
                        <Button
                          text="New File"
                          onClick={() => setFileFormOpen(true)}
                          primary
                        />
                      </div>
                      <TableComponent
                        title={"files"}
                        endpoint={`deals/${dealId}/files/`}
                        triggerReload={triggerFileListReload}
                        fields={{
                          id: { name: "ID" },
                          key: { name: "Key" },
                          creator: {
                            name: "Creator",
                            middleware: (row: any) =>
                              `${row.creator.first_name} ${row.creator.last_name}`,
                          },
                          created_at: { name: "created" },
                          updated_at: { name: "updated" },
                        }}
                        onRowClick={handleFileRowClicked}
                      />
                    </div>
                  ),
                },
                {
                  name: "API",
                  component: <APICallLogData dealId={dealId} />,
                },
                // { name: "Insurance Referral" },
              ]}
            />

            {/* Deal Timeline */}
            <br />
            <hr />
            <br />
            <SectionHeader title={"Timeline"} noDivider />
            <DealTimeline events={timeline} />
          </div>
        </div>
      </Section>
    </MainWrapper>
  );
};

export default DealDetailPage;
