import { useEffect, useRef, useState } from "react";
import Button from "components/core/Button";
import { Form } from "pages/NoAuthWrapperPage/LoginPage.styled";
import AuthCode from "react-auth-code-input";
import { useNavigate } from "react-router-dom";
import useQuery from "hooks/useQuery";
import { post } from "utilities/Network";
import { setLocalStorageItem } from "utilities/LocalStorage";
import axios from "axios";

type ChallengeMFAProps = {
  isPartnerPortal?: boolean;
};

const ChallengeMFA = ({ isPartnerPortal }: ChallengeMFAProps) => {
  const navigate = useNavigate();
  const query = useQuery();
  const token = query.get("token");
  const authRef = useRef<any>();
  const [lockout, setLockout] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);
  const [setSigninComplete, signinComplete] = useState<boolean>(false);
  const [code, setCode] = useState<string>();

  useEffect(() => {
    if (!code || code.length != 6) return;
    setLoading(true);
    setErrorMessage(null);
    let verifyMFAUrl = "authentication/verify-mfa/";
    if (isPartnerPortal) {
      verifyMFAUrl = "authentication/partners/verify-mfa/";
    }

    post(verifyMFAUrl, {
      token,
      code,
    })
      .then((response) => {
        setLocalStorageItem("auth", response.data);
        axios.defaults.headers.common.authorization = response.data.token;
        navigate("/");
      })
      .catch((error) => {
        console.log(error.response.data);
        if (error.response?.data?.mfa_lockout) {
          setErrorMessage(
            "To many invalid codes, you have been locked out. Please contact an Administrator."
          );
          setLockout(true);
        } else {
          setErrorMessage("Invalid code, please try again.");
          setLoading(false);
        }

        setCode("");

        console.log("authref");
        console.log(authRef.current);
        authRef.current.clear();
        authRef.current.focus();
        // handle error
      });
  }, [code, navigate, token, isPartnerPortal]);

  return (
    <>
      <Form>
        <div className="w-full text-center text-sm mb-10">
          <h1 className="text-lg font-bold mb-2">Multi Factor Challenge</h1>
          {!lockout && <p>Please enter a code from your authenticator app.</p>}
        </div>
      </Form>
      <div>
        {!lockout && (
          <AuthCode
            containerClassName="w-full h-[20px] mb-10 flex gap-2 m-auto justify-center"
            inputClassName="w-[35px] h-[35px] border border-2-black text-center"
            allowedCharacters="numeric"
            onChange={setCode}
            disabled={loading}
            ref={authRef}
          />
        )}
      </div>
      <div className="w-full text-center">
        <p className="text-red-600">{errorMessage}</p>
      </div>
    </>
  );
};

export default ChallengeMFA;
