import React from "react";
import {
  Pagination,
  PaginationItem,
  PaginationLink,
} from "./PaginationBar.styled";

export type PaginationBarProps = {
  pageCount: number;
  limit: number;
  page: number;
  offset: number;
  setPage: any;
  pageSize: number;
  count: number;
};

const PaginationBar: React.FC<PaginationBarProps> = ({
  pageCount,
  limit,
  page,
  offset,
  setPage,
  count,
  pageSize,
}) => {
  const goToFirstPage = () => {
    setPage(0);
  };

  const goToLastPage = () => {
    setPage(pageCount - 1, (pageCount - 1) * limit);
  };

  const goToNextPage = () => {
    if (page < pageCount) {
      setPage(page);
    }
  };

  const goToPreviousPage = () => {
    if (page > 1) {
      setPage(page - 2);
    }
  };

  const maxPageLinks = 5;
  let rightPages = [];
  let leftPages = [];
  for (let i = page - 1; i >= 0 && i >= page - maxPageLinks - 1; --i) {
    leftPages.unshift(i);
  }

  for (let i = page + 1; i < page + maxPageLinks && i <= pageCount; ++i) {
    rightPages.push(i);
  }
  const lmax = maxPageLinks - 1 - Math.min(2, rightPages.length);
  const rmax = maxPageLinks - 1 - Math.min(2, leftPages.length);
  leftPages = leftPages.slice(Math.max(leftPages.length - lmax, 1));
  rightPages = rightPages.slice(0, rmax);

  return (
    <div className="bg-white px-4 py-3 flex items-center justify-between border-t border-gray-200 sm:px-6">
      <div className="hidden sm:flex-1 sm:flex sm:items-center sm:justify-between">
        <div>
          <p className="text-sm text-gray-700">
            {"Showing "}
            <span className="font-medium"> {offset + 1} </span>
            {"to "}
            <span className="font-medium">
              {" "}
              {Math.min(offset + 1 + pageSize, count)}{" "}
            </span>
            {"of "}
            <span className="font-medium"> {count} </span>
            {"results "}
          </p>
        </div>
        <div>
          <nav
            className="relative z-0 inline-flex rounded-md shadow-sm -space-x-px"
            aria-label="Pagination"
          >
            <a
              href="#"
              onClick={goToPreviousPage}
              className="relative inline-flex items-center px-2 py-2 rounded-l-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50"
            >
              <span className="sr-only">Previous</span>
              <svg
                className="h-5 w-5"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
                aria-hidden="true"
              >
                <path
                  fill-rule="evenodd"
                  d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z"
                  clip-rule="evenodd"
                />
              </svg>
            </a>

            {leftPages.map((pageNumber) => (
              <a
                href="#"
                onClick={() => setPage(pageNumber - 1)}
                className="bg-white border-gray-300 text-gray-500 hover:bg-gray-50 relative inline-flex items-center px-4 py-2 border text-sm font-medium"
              >
                {pageNumber}
              </a>
            ))}

            <a
              href="#"
              aria-current="page"
              className="z-10 bg-amber-50 border-amber-500 text-amber-600 relative inline-flex items-center px-4 py-2 border text-sm font-medium"
            >
              {page}
            </a>

            {rightPages.map((pageNumber) => (
              <a
                href="#"
                onClick={() => setPage(pageNumber - 1)}
                className="bg-white border-gray-300 text-gray-500 hover:bg-gray-50 relative inline-flex items-center px-4 py-2 border text-sm font-medium"
              >
                {pageNumber}
              </a>
            ))}

            <a
              href="#"
              onClick={goToNextPage}
              className="relative inline-flex items-center px-2 py-2 rounded-r-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50"
            >
              <span className="sr-only">Next</span>
              <svg
                className="h-5 w-5"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
                aria-hidden="true"
              >
                <path
                  fill-rule="evenodd"
                  d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                  clip-rule="evenodd"
                />
              </svg>
            </a>
          </nav>
        </div>
      </div>
    </div>
  );
};

export default PaginationBar;
