import React, { useEffect } from "react";
import {
  Styled,
  Styled1,
  Divider,
  Styled2,
  StepIconBase,
  Connector,
  ConnectorWrap,
  Text,
  SupportingText,
  TextAndSupportingText,
  StepBase,
  CurrentStepDot,
  CompletedStepTick,
  ProgressStepsProgressIconsWithText,
  Content,
  Container,
  Section,
  ChildContainer,
  SidebarNavigationWrapper,
} from "./StepProcessWrapper.styled";

import { Container as PageContainer } from "components/layout/AppContainer/AppContainer.styled";
import Logo from "components/core/Logo";
import IconBase from "components/core/IconBase";
import { ReactComponent as tickIcon } from "assets/icons/tick.svg";

export type StepProps = {
  title: string;
  subtitle: string;
};

export type StepProcessWrapperProps = {
  steps: Array<StepProps>;
  currentStep: number;
  children?: JSX.Element;
  currentStepLoading?: boolean;
};

const StepProcessWrapper: React.FC<StepProcessWrapperProps> = ({
  steps,
  currentStep,
  children,
  currentStepLoading,
}) => {
  return (
    <PageContainer>
      <SidebarNavigationWrapper>
        <Divider>
          <Styled1>
            <Styled d="M 1 5000 L 0 5000 L 0 0 L 1 0 L 1 5000 Z" />
          </Styled1>
        </Divider>
        <Section>
          <Container>
            <Logo width={"200px"} />
            <Content>
              <ProgressStepsProgressIconsWithText>
                {steps.map((step, index) => {
                  return (
                    <StepBase>
                      <ConnectorWrap>
                        <StepIconBase>
                          {currentStep == index && currentStepLoading ? (
                            <div className="loader-2">
                              <span></span>
                            </div>
                          ) : (
                            <Styled2 />
                          )}
                          {currentStep == index && (
                            <>
                              <CurrentStepDot />
                            </>
                          )}
                          {currentStep > index && (
                            <CompletedStepTick>
                              <IconBase
                                src={tickIcon}
                                color={"#AE7222"}
                              ></IconBase>
                            </CompletedStepTick>
                          )}
                        </StepIconBase>
                        {index !== steps.length - 1 && <Connector />}
                      </ConnectorWrap>
                      <TextAndSupportingText>
                        <Text>{step.title}</Text>
                        <SupportingText>{step.subtitle}</SupportingText>
                      </TextAndSupportingText>
                    </StepBase>
                  );
                })}
              </ProgressStepsProgressIconsWithText>
            </Content>
          </Container>
        </Section>
      </SidebarNavigationWrapper>
      <ChildContainer>{children}</ChildContainer>
    </PageContainer>
  );
};

export default StepProcessWrapper;
