import React, { useState, useEffect } from "react";
import TableComponent from "components/core/TableComponent";
import PageHeader from "components/layout/PageHeader";
import FilterBar from "components/core/FilterBar";
import { promiseEnums } from "utilities/Enums";
import { get } from "utilities/Network";
import {
  MainWrapper,
  HeaderSection,
  Container,
  Divider,
  Section,
} from "./TeamPage.styled";
import Button from "components/core/Button";
import { TableMetaProps } from "components/core/TableComponent/types";
import Badge, { BadgeType } from "components/core/Badge";
import { useNavigate } from "react-router-dom";

export type UsersPageProps = {};

type User = {
  first_name: string;
  last_name: string;
  email_address: string;
  created_at: string;
};

const TeamPage: React.FC<UsersPageProps> = ({}) => {
  const [filter, setFilter] = useState();
  const navigate = useNavigate();

  const newTeamMember = () => {};

  return (
    <MainWrapper>
      <Container>
        <PageHeader
          title={"Team"}
          subtitle={"The Finstead Team"}
          // rightHandSideChildren={
          //   <Button onClick={newTeamMember} text={"New Member"} primary />
          // }
        />
      </Container>
      <Section>
        {/* <FilterBar setFilter={setFilter} /> */}
        <TableComponent
          title={"Finstead Team members"}
          onRowClick={(row: any) => navigate(`/team/${row.id}`)}
          endpoint="authentication/team/"
          filter={filter}
          fields={{
            id: { name: "Id" },
            first_name: { name: "First Name" },
            last_name: { name: "Last Name" },
            personal_mobile: { name: "Personal Mobile" },
            email_address: { name: "Email Address" },
            // groups: { name: "Permission Groups" },
            // last_access: { name: "Last Access" },
            password_age: { name: "Password Age" },
            last_active: { name: "Last Active" },
            activity: {
              name: "Activity (24hs)",
              middleware: (row: any) => {
                if (!row?.activity || row?.activity.length == 0) {
                  return "";
                }

                return (
                  <td className="p-2 ">
                    <div className="w-full h-full flex justify-center">
                      <div className="w-full h-full max-h-[50px] flex flex-row">
                        {row.activity.map((h: number) => {
                          return (
                            <div className="h-full w-full flex flex-col">
                              {[...Array(50 - h)].map((x) => (
                                <div className="w-full h-[1px] "></div>
                              ))}
                              {[...Array(h)].map((x) => (
                                <div className="w-full h-[1px] bg-green-600"></div>
                              ))}
                            </div>
                          );
                        })}
                      </div>
                      {/* {row.activity.map((hourCount: number) => {
                        return (
                          <div className="max-h-[15px] w-full max-w-[3px] flex-col">
                            
                          </div>
                        );
                      })} */}
                    </div>
                  </td>
                );
                // return row?.activity[0];
              },
            },
            mfa_enabled: {
              name: "MFA Enabled",
              middleware: (row: any) => {
                if (row?.mfa_enabled) {
                  return <Badge type={BadgeType.success} text={"Enabled"} />;
                } else {
                  return <Badge type={BadgeType.danger} text={"Not Enabled"} />;
                }
              },
            },
          }}
        />
      </Section>
    </MainWrapper>
  );
};

export default TeamPage;
