import styled from "styled-components";
import { TextMdMedium } from "styles/mixins";

export type NavItemBaseWrapperStyledComponentProps = {
  selected?: boolean;
};

export const NavItemBaseWrapper = styled("div")<{
  selected: boolean;
  collapsed: boolean;
}>`
  display: flex;
  border-radius: 6px;
  overflow: hidden;
  align-self: stretch;
  flex-grow: 1;
  align-items: center;
  padding: 8px 12px 8px 12px;
  gap: 105px;
  cursor: pointer;
  width: 242px;

  &:hover {
    background-color: ${(props) => props.theme.colors.gray_700};
    /* ${(props) => (props.collapsed ? "z-index: 9999;" : "")} */
  }

  background: ${(props) =>
    props.selected
      ? ` ${props.theme.colors.gray_700}`
      : "rgba(255, 255, 255, 0.1)"}; //"rgba(16, 24, 40, 1)"};
`;
export const Content = styled.div`
  display: flex;
  width: fit-content;
  align-items: center;
  gap: 12px;
`;
export const Icon = styled.img`
  overflow: hidden;
  width: 24px;
  height: 24px;
  display: flex;
  flex-direction: column;
  padding: 2px 4px;
`;
export const Text = styled.span`
  text-align: left;
  white-space: pre-line;
  color: ${({ theme }) => theme.colors.gray_100};

  ${TextMdMedium}
`;
