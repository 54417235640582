import React from "react";
import { ErrorMessage } from "../InputField/InputField.styled";
import {
  Label,
  TextArea,
  InputWithLabel,
  InputFieldBase,
  InputFieldWrapper,
} from "./TextArea.styled";

export type InputFieldProps = {
  label?: string;
  placeholder?: string;
  value?: string;
  onChange?: (v: any) => void;
  disabled?: boolean;
  required?: boolean;
  errorMessage?: string;
  formControlProps?: any;
  loading?: boolean;
  className?: string;
};

const InputField: React.FC<InputFieldProps> = ({
  label,
  placeholder,
  value,
  onChange,
  errorMessage,
  loading = false,
  disabled = false,
  required = false,
  formControlProps = {},
  className = "",
}) => {
  return (
    <>
      <InputFieldWrapper>
        <InputFieldBase>
          <InputWithLabel>
            {label && (
              <Label>
                {label} {required && "*"}
              </Label>
            )}
            <TextArea
              {...formControlProps}
              className={className}
              placeholder={loading ? "" : placeholder}
              value={value}
              hasError={!!errorMessage}
              onChange={(e) => onChange && onChange(e.target.value)}
              disabled={disabled}
              loading={loading}
            ></TextArea>
          </InputWithLabel>
        </InputFieldBase>
      </InputFieldWrapper>
      <ErrorMessage>{errorMessage}</ErrorMessage>
    </>
  );
};

export default InputField;
