import React, { useCallback, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import InputField from "components/inputs/InputField";
import Button from "components/core/Button";
import { get, post } from "utilities/Network";
import LoaderLogo from "components/core/LoaderLogo";
import { ReactComponent as checkCircle } from "assets/icons/check-circle.svg";
import IconBase from "components/core/IconBase";
import useFileManager from "hooks/useFileManager";
import validateABN from "utilities/Forms/validators/abnValidator";

export type FormData = {
  invoiceDate: string;
  referrerTradingName: string;
  customerTradingName: string;
  customerABN: string;
  amountPayable: number;
  referrerBankName: string;
  referrerAccountName: string;
  referrerBSB: string;
  referrerAccountNumber: string;
};

const FormSchema = yup.object().shape({
  invoiceDate: yup.date().required("Invoice Date is required"),
  referrerTradingName: yup
    .string()
    .required("Referrer Trading Name is required")
    .min(1, "Referrer Trading Name must be at least 1 character long")
    .max(255, "Referrer Trading Name can be at most 255 characters long"),
  customerTradingName: yup
    .string()
    .required("Customer Trading Name is required")
    .min(1, "Customer Trading Name must be at least 1 character long")
    .max(255, "Customer Trading Name can be at most 255 characters long"),
  customerABN: yup
    .string()
    .required("Customer ABN is required")
    .test(
      "validate-australian-business-number",
      "Please provide a valid Australia Business Number",
      (value) => {
        if (!!value) {
          return validateABN(value);
        }
        return true;
      }
    )
    .min(10)
    .max(15),
  amountPayable: yup
    .number()
    .transform((value) => (isNaN(value) ? undefined : value))
    .required("Amount Payable $ is required")
    .positive("Amount Payable $ must be a positive number"),
  referrerBankName: yup
    .string()
    .required("Referrer Bank Name is required")
    .min(1, "Referrer Bank Name must be at least 1 character long")
    .max(255, "Referrer Bank Name can be at most 255 characters long"),
  referrerAccountName: yup
    .string()
    .required("Referrer Account Name is required")
    .min(1, "Referrer Account Name must be at least 1 character long")
    .max(255, "Referrer Account Name can be at most 255 characters long"),
  referrerBSB: yup
    .string()
    .required("Referrer BSB is required")
    .min(1, "Referrer BSB must be at least 1 character long")
    .max(255, "Referrer BSB can be at most 255 characters long"),
  referrerAccountNumber: yup
    .string()
    .required("Referrer Account Number is required")
    .min(1, "Referrer Account Number must be at least 1 character long")
    .max(255, "Referrer Account Number can be at most 255 characters long"),
});

type Props = {
  dealId: number;
};

const ReferrerRCTIForm: React.FC<Props> = ({ dealId }) => {
  const [loadingContext, setLoadingContext] = useState(true);
  const [generating, setGenerating] = useState(false);
  const [generated, setGenerated] = useState(false);
  const [fileId, setFileId] = useState();
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<FormData>({
    resolver: yupResolver(FormSchema),
  });

  const { downloadFile, deleteFile, updateFile } = useFileManager({
    fileId: fileId,
  });

  const fetchPrefillData = useCallback(() => {
    setLoadingContext(true);
    get(`files/deals/${dealId}/referrer-rcti/form-context/`)
      .then((response: any) => {
        reset(response.data.context);
        setLoadingContext(false);
      })
      .catch(() => {
        setLoadingContext(false);
      });
  }, [dealId, reset]);

  useEffect(fetchPrefillData, [fetchPrefillData]);

  const onSubmit = (formData: FormData) => {
    setGenerating(true);

    post(`files/deals/${dealId}/referrer-rcti/generate/`, {
      ...formData,
    }).then((response) => {
      setFileId(response.data.file_id);
      setGenerating(false);
      setGenerated(true);
    });

    console.log(formData);
  };

  if (loadingContext) {
    return (
      <div className="w-full h-full flex justify-center items-center  flex-col">
        <LoaderLogo light />
        <br />
        <p>Loading deal context to prefill the form...</p>
      </div>
    );
  }

  if (generating) {
    return (
      <div className="w-full h-full flex justify-center items-center  flex-col">
        <LoaderLogo light />
        <br />
        <p>Generating Invoice - Please wait this will take a few moments....</p>
      </div>
    );
  }

  if (generated) {
    return (
      <div className="w-full h-full flex justify-center items-center flex-col">
        <div className="max-h-[150px]">
          <IconBase src={checkCircle} color="green" />
        </div>
        <h1 className="font-bold text-lg">Success</h1>
        <br />
        <p>
          The Referrer RCTI has been generated and added as a file to this deal.
        </p>
        <Button
          text={"Download Now"}
          width={"250px"}
          className="mt-4 text-white"
          info
          onClick={() => {
            downloadFile();
          }}
        ></Button>
      </div>
    );
  }

  return (
    <form className="pb-4 max-w-[700px]" onSubmit={handleSubmit(onSubmit)}>
      <InputField
        formControlProps={register("invoiceDate")}
        label={"Invoice Date"}
        type={"date"}
        errorMessage={errors.invoiceDate?.message}
      />

      <InputField
        formControlProps={register("amountPayable")}
        label="Amount Payable $"
        errorMessage={errors.amountPayable?.message}
        type={"number"}
        step={"0.01"}
      />

      <InputField
        formControlProps={register("customerTradingName")}
        label="Customer Trading Name"
        errorMessage={errors.customerTradingName?.message}
      />
      <InputField
        formControlProps={register("customerABN")}
        label="Customer ABN"
        errorMessage={errors.customerABN?.message}
      />
      <InputField
        formControlProps={register("referrerTradingName")}
        label="Referrer Trading Name"
        errorMessage={errors.referrerTradingName?.message}
      />
      <InputField
        formControlProps={register("referrerBankName")}
        label="Referrer Bank Name"
        errorMessage={errors.referrerBankName?.message}
      />
      <InputField
        formControlProps={register("referrerAccountName")}
        label="Referrer Account Name"
        errorMessage={errors.referrerAccountName?.message}
      />
      <InputField
        formControlProps={register("referrerBSB")}
        label="Referrer BSB"
        errorMessage={errors.referrerBSB?.message}
      />
      <InputField
        formControlProps={register("referrerAccountNumber")}
        label="Referrer Account Number"
        errorMessage={errors.referrerAccountNumber?.message}
      />

      <div className="w-full text-right">
        <Button
          type={"submit"}
          primary
          text={"Generate Invoice"}
          onClick={() => {}}
          width={"250px"}
        ></Button>
      </div>
    </form>
  );
};

export default ReferrerRCTIForm;
