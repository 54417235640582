import React, { useState, useCallback, useEffect } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import InputField from "components/inputs/InputField";
import Button from "components/core/Button";
import { get, post } from "utilities/Network";
import LoaderLogo from "components/core/LoaderLogo";
import { ReactComponent as checkCircle } from "assets/icons/check-circle.svg";
import IconBase from "components/core/IconBase";
import useFileManager from "hooks/useFileManager";

export type FormData = {
  invoiceDate: string;
  dueDate: string;
  invoiceNumber: string;
  companyTradingName: string;
  companyBillingAddress: string;
  brokerage: number;
  originationFee: number;
};

const FormSchema = yup.object().shape({
  invoiceDate: yup.date().required("Invoice Date is required"),
  dueDate: yup.date().required("Due Date is required"),
  companyTradingName: yup
    .string()
    .required("Company Trading Name is required")
    .min(1, "Company Trading Name must be at least 1 character long")
    .max(255, "Company Trading Name can be at most 255 characters long"),
  companyBillingAddress: yup
    .string()
    .required("Company Billing Address is required")
    .min(1, "Company Billing Address must be at least 1 character long")
    .max(1024, "Company Billing Address can be at most 1024 characters long"),
  brokerage: yup
    .number()
    .transform((value) => (isNaN(value) ? undefined : value))
    .required("Brokerage $ is required")
    .positive("Brokerage $ must be a positive number"),
  originationFee: yup
    .number()
    .transform((value) => (isNaN(value) ? undefined : value))
    .required("Origination Fee $ is required")
    .positive("Origination Fee $ must be a positive number"),
});

type Props = {
  dealId: number;
};

const PrivateSaleInvoiceForm: React.FC<Props> = ({ dealId }) => {
  const [loadingContext, setLoadingContext] = useState(true);
  const [generating, setGenerating] = useState(false);
  const [generated, setGenerated] = useState(false);
  const [fileId, setFileId] = useState();
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<FormData>({
    resolver: yupResolver(FormSchema),
  });

  const { downloadFile, deleteFile, updateFile } = useFileManager({
    fileId: fileId,
  });

  const fetchPrefillData = useCallback(() => {
    setLoadingContext(true);
    get(`files/deals/${dealId}/private-sale-invoice/form-context/`)
      .then((response: any) => {
        reset(response.data.context);
        setLoadingContext(false);
      })
      .catch(() => {
        setLoadingContext(false);
      });
  }, [dealId, reset]);

  useEffect(fetchPrefillData, [fetchPrefillData]);

  const onSubmit = (formData: FormData) => {
    setGenerating(true);

    post(`files/deals/${dealId}/private-sale-invoice/generate/`, {
      ...formData,
    }).then((response) => {
      setFileId(response.data.file_id);
      setGenerating(false);
      setGenerated(true);
    });

    // Handle submission here
    console.log(formData);
  };

  if (loadingContext) {
    return (
      <div className="w-full h-full flex justify-center items-center  flex-col">
        <LoaderLogo light />
        <br />
        <p>Loading deal context to prefill the form...</p>
      </div>
    );
  }

  if (generating) {
    return (
      <div className="w-full h-full flex justify-center items-center  flex-col">
        <LoaderLogo light />
        <br />
        <p>Generating Invoice - Please wait this will take a few moments....</p>
      </div>
    );
  }

  if (generated) {
    return (
      <div className="w-full h-full flex justify-center items-center flex-col">
        <div className="max-h-[150px]">
          <IconBase src={checkCircle} color="green" />
        </div>
        <h1 className="font-bold text-lg">Success</h1>
        <br />
        <p>The invoice has been generated and added as a file to this deal.</p>
        <Button
          text={"Download Now"}
          width={"250px"}
          className="mt-4 text-white"
          info
          onClick={() => {
            downloadFile();
          }}
        ></Button>
      </div>
    );
  }

  return (
    <form className="pb-4 max-w-[700px]" onSubmit={handleSubmit(onSubmit)}>
      <InputField
        formControlProps={register("invoiceDate")}
        label={"Invoice Date"}
        type={"date"}
        errorMessage={errors.invoiceDate?.message}
      />
      <InputField
        formControlProps={register("dueDate")}
        label={"Due Date"}
        type={"date"}
        errorMessage={errors.dueDate?.message}
      />
      <InputField
        formControlProps={register("companyTradingName")}
        label={"Company Trading Name"}
        errorMessage={errors.companyTradingName?.message}
      />
      <InputField
        formControlProps={register("companyBillingAddress")}
        label={"Company Billing Address"}
        errorMessage={errors.companyBillingAddress?.message}
      />
      <InputField
        formControlProps={register("brokerage")}
        label={"Brokerage $"}
        type={"number"}
        errorMessage={errors.brokerage?.message}
      />
      <InputField
        formControlProps={register("originationFee")}
        label={"Origination Fee $"}
        type={"number"}
        errorMessage={errors.originationFee?.message}
      />
      <div className="w-full text-right">
        <Button
          type={"submit"}
          primary
          text={"Generate Invoice"}
          onClick={() => {}}
          width={"250px"}
        ></Button>
      </div>
    </form>
  );
};

export default PrivateSaleInvoiceForm;
