import useEditableText from "hooks/useEditableText";
import { toast } from "react-toastify";
import { post } from "utilities/Network";

const useOrganisationManager = ({ organisation }: any) => {
  const editableOrganisationNameText = useEditableText({
    defaultText: organisation?.name,
    handleSave: (value, cb: any) => {
      post(`organisations/${organisation?.id}/update/`, {
        field: "name",
        value,
      })
        .then(() => {
          toast.success("Organisation name updated.");
          cb();
        })
        .catch(() => cb(false));
    },
    validator: (value: string) => {
      let error = "";
      let valid = false;

      if (value.length < 3) {
        valid = false;
        error = "Organisation name must be at least 3 characters";
      }

      return { valid, error };
    },
  });
  const organisationNameText = editableOrganisationNameText;

  const editableOrganisationStateText = useEditableText({
    defaultText: organisation?.state,
    handleSave: (value, cb: any) => {
      post(`organisations/${organisation?.id}/update/`, {
        field: "state",
        value,
      })
        .then(() => {
          toast.success("Organisation state updated.");
          cb();
        })
        .catch(() => cb(false));
    },
    validator: (value: string) => {
      let error = "";
      let valid = false;

      if (value.length < 3) {
        valid = false;
        error = "Organisation state must be at least 3 characters";
      }

      return { valid, error };
    },
  });
  const organisationStateText = editableOrganisationStateText;

  const editableOrganisationStreetAddressText = useEditableText({
    defaultText: organisation?.street_address,
    handleSave: (value, cb: any) => {
      post(`organisations/${organisation?.id}/update/`, {
        field: "street_address",
        value,
      })
        .then(() => {
          toast.success("Organisation street address updated.");
          cb();
        })
        .catch(() => cb(false));
    },
    validator: (value: string) => {
      let error = "";
      let valid = false;

      if (value.length < 3) {
        valid = false;
        error = "Organisation street address must be at least 3 characters";
      }

      return { valid, error };
    },
  });
  const organisationStreetAddressText = editableOrganisationStreetAddressText;

  const editableOrganisationABNText = useEditableText({
    defaultText: organisation?.abn,
    handleSave: (value, cb: any) => {
      post(`organisations/${organisation?.id}/update/`, {
        field: "abn",
        value,
      })
        .then(() => {
          toast.success("Organisation ABN updated.");
          cb();
        })
        .catch(() => cb(false));
    },
    validator: (value: string) => {
      let error = "";
      let valid = false;

      if (value.length < 3) {
        valid = false;
        error = "Organisation abn must be at least 3 characters";
      }

      return { valid, error };
    },
  });
  const organisationABNText = editableOrganisationABNText;

  const editableOrganisationPostCodeText = useEditableText({
    defaultText: organisation?.post_code,
    handleSave: (value, cb: any) => {
      post(`organisations/${organisation?.id}/update/`, {
        field: "post_code",
        value,
      })
        .then(() => {
          toast.success("Organisation post code updated.");
          cb();
        })
        .catch(() => cb(false));
    },
    validator: (value: string) => {
      let error = "";
      let valid = false;

      if (value.length < 3) {
        valid = false;
        error = "Organisation post code must be at least 3 characters";
      }

      return { valid, error };
    },
  });
  const organisationPostCodeText = editableOrganisationPostCodeText;

  const editableOrganisationCityText = useEditableText({
    defaultText: organisation?.abn,
    handleSave: (value, cb: any) => {
      post(`organisations/${organisation?.id}/update/`, {
        field: "city",
        value,
      })
        .then(() => {
          toast.success("Organisation city updated.");
          cb();
        })
        .catch(() => cb(false));
    },
    validator: (value: string) => {
      let error = "";
      let valid = false;

      if (value.length < 3) {
        valid = false;
        error = "Organisation city must be at least 3 characters";
      }

      return { valid, error };
    },
  });
  const organisationCityText = editableOrganisationCityText;

  return {
    organisationNameText,
    organisationStateText,
    organisationPostCodeText,
    organisationCityText,
    organisationStreetAddressText,
    organisationABNText,
  };
};

export default useOrganisationManager;
