import React from "react";
import InputFieldWrapper from "components/inputs/InputFieldWrapper";
import { Select } from "./DropDownField.styled";
import classNames from "classnames";

export type DropDownOption = {
  text: string;
  value: any;
};

export type DropDownFieldProps = {
  label?: string;
  subtitle?: string;
  placeholder?: string;
  value?: string;
  onChange?: (v: any) => void;
  disabled?: boolean;
  required?: boolean;
  errorMessage?: string;
  placeholderDisabled?: boolean;
  formControlProps?: any;
  loading?: boolean;
  options: Array<DropDownOption>;
  defaultValue?: string;
};

const DropDownField: React.FC<DropDownFieldProps> = ({
  label,
  subtitle,
  placeholder,
  value,
  onChange,
  errorMessage,
  options,
  defaultValue,
  loading = false,
  disabled = false,
  required = false,
  placeholderDisabled = true,
  formControlProps = {},
}) => {
  return (
    <InputFieldWrapper
      label={label}
      required={required}
      errorMessage={errorMessage}
    >
      <>
        {subtitle && <label>{subtitle}</label>}
        <Select
          loading={loading}
          placeholder={loading ? "" : placeholder}
          value={value}
          onChange={(e) => onChange && onChange(e.target.value)}
          disabled={disabled}
          hasError={!!errorMessage}
          className={classNames(
            {
              "bg-[#FAFAFA]": disabled,
            },
            "h-[46px]"
          )}
          {...formControlProps}
        >
          {!!placeholder && (
            <option disabled={placeholderDisabled} selected value="">
              {placeholder}
            </option>
          )}
          {options.map((option: DropDownOption) => (
            <option value={option.value} selected={value == option.value}>
              {option.text}
            </option>
          ))}
        </Select>
      </>
    </InputFieldWrapper>
  );
};

export default DropDownField;
