import React from "react";
import { TableCellWrapper, Text } from "./BasicTableCell.styled";

export type BasicTableCellProps = {
  text: any;
  color?: string;
};

const BasicTableCell: React.FC<BasicTableCellProps> = ({ text, color }) => {
  return (
    <TableCellWrapper>
      <Text style={!!color ? { color: color } : {}}>{text}</Text>
    </TableCellWrapper>
  );
};

export default BasicTableCell;
