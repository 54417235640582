import useEditableText from "hooks/useEditableText";
import { toast } from "react-toastify";
import { post } from "utilities/Network";

const useLenderManager = ({ lender }: any) => {
  const editableBusinessNameText = useEditableText({
    defaultText: lender?.business_name,
    handleSave: (value, cb: any) => {
      post(`lenders/${lender?.id}/update/`, {
        field: "business_name",
        value,
      })
        .then(() => {
          toast.success("Lender business name updated.");
          cb();
        })
        .catch(() => cb(false));
    },
    validator: (value: string) => {
      let error = "";
      let valid = false;

      if (value.length < 3) {
        valid = false;
        error = "Lender business name must be at least 3 characters";
      }

      return { valid, error };
    },
  });
  const lenderBusinessName = editableBusinessNameText;

  const editableEntityNameText = useEditableText({
    defaultText: lender?.entity_name,
    handleSave: (value, cb: any) => {
      post(`lenders/${lender?.id}/update/`, {
        field: "entity_name",
        value,
      })
        .then(() => {
          toast.success("Lender entity name updated.");
          cb();
        })
        .catch(() => cb(false));
    },
    validator: (value: string) => {
      let error = "";
      let valid = false;

      if (value.length < 3) {
        valid = false;
        error = "Lender entity name must be at least 3 characters";
      }

      return { valid, error };
    },
  });
  const lenderEntityName = editableEntityNameText;

  const editableABNText = useEditableText({
    defaultText: lender?.abn,
    handleSave: (value, cb: any) => {
      post(`lenders/${lender?.id}/update/`, {
        field: "abn",
        value,
      })
        .then(() => {
          toast.success("Organisation entity name updated.");
          cb();
        })
        .catch(() => cb(false));
    },
    validator: (value: string) => {
      let error = "";
      let valid = false;

      if (value.length < 10) {
        valid = false;
        error = "Lender abn must be at least 10 characters";
      }

      return { valid, error };
    },
  });
  const lenderABN = editableABNText;

  const editableContactFirstNameText = useEditableText({
    defaultText: lender?.contact_first_name,
    handleSave: (value, cb: any) => {
      post(`lenders/${lender?.id}/update/`, {
        field: "contact_first_name",
        value,
      })
        .then(() => {
          toast.success("Lender contact first name updated.");
          cb();
        })
        .catch(() => cb(false));
    },
    validator: (value: string) => {
      let error = "";
      let valid = false;

      if (value.length < 3) {
        valid = false;
        error = "Lender contact first name must be at least 3 characters";
      }

      return { valid, error };
    },
  });
  const lenderContactFirstName = editableContactFirstNameText;

  const editableContactLastNameText = useEditableText({
    defaultText: lender?.contact_last_name,
    handleSave: (value, cb: any) => {
      post(`lenders/${lender?.id}/update/`, {
        field: "contact_last_name",
        value,
      })
        .then(() => {
          toast.success("Lender contact last name updated.");
          cb();
        })
        .catch(() => cb(false));
    },
    validator: (value: string) => {
      let error = "";
      let valid = false;

      if (value.length < 3) {
        valid = false;
        error = "Lender contact first name must be at least 3 characters";
      }

      return { valid, error };
    },
  });
  const lenderContactLastName = editableContactLastNameText;

  const editableContactNumberText = useEditableText({
    defaultText: lender?.contact_number,
    handleSave: (value, cb: any) => {
      post(`lenders/${lender?.id}/update/`, {
        field: "contact_number",
        value,
      })
        .then(() => {
          toast.success("Lender contact number updated.");
          cb();
        })
        .catch(() => cb(false));
    },
    validator: (value: string) => {
      let error = "";
      let valid = false;

      if (value.length < 3) {
        valid = false;
        error = "Lender contact number must be at least 3 characters";
      }

      return { valid, error };
    },
  });
  const lenderContactNumber = editableContactNumberText;

  const editableEmailText = useEditableText({
    defaultText: lender?.contact_email_address,
    handleSave: (value, cb: any) => {
      post(`lenders/${lender?.id}/update/`, {
        field: "contact_email_address",
        value,
      })
        .then(() => {
          toast.success("Lender email address updated.");
          cb();
        })
        .catch(() => cb(false));
    },
    validator: (value: string) => {
      let error = "";
      let valid = false;

      if (value.length < 3) {
        valid = false;
        error = "Lender email address must be at least 3 characters";
      }

      return { valid, error };
    },
  });
  const lenderEmailAddress = editableEmailText;

  return {
    lenderBusinessName,
    lenderEntityName,
    lenderABN,
    lenderContactFirstName,
    lenderContactLastName,
    lenderContactNumber,
    lenderEmailAddress,
  };
};

export default useLenderManager;
