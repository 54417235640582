import Modal from "components/core/Modal";
import Button from "components/core/Button";
import { useMemo, useState, useRef } from "react";

const useAreYouSure = () => {
  const act = useRef<() => void | null>();
  const [open, setOpen] = useState(false);
  const [statement, setStatement] = useState("Are you sure?");
  const [confirmationText, setConfirmationText] = useState("Yes");
  const [abandonText, setAbandonText] = useState("No");

  const areYouSureModal = useMemo(() => {
    return (
      <Modal
        isOpen={open}
        title={"Are You Sure?"}
        onRequestClose={() => setOpen(false)}
      >
        <div className="text-center p-5 w-full h-full">
          <h2>{statement}</h2>
          <div className="mt-8 flex flex-row gap-6">
            <div className="w-1/2 flex items-center justify-center">
              <Button
                wide
                warning
                text={confirmationText}
                onClick={() => {
                  if (!!act.current) {
                    act.current();
                  }
                  setOpen(false);
                }}
              />
            </div>
            <div className="w-1/2 flex items-center justify-center">
              <Button wide text={abandonText} onClick={() => setOpen(false)} />
            </div>
          </div>
        </div>
      </Modal>
    );
  }, [abandonText, confirmationText, open, statement]);

  const areYouSure = (
    _act: any,
    _statement: string,
    _confirmationText = "Yes",
    _abandonText = "Cancel"
  ) => {
    setStatement(_statement);
    setConfirmationText(_confirmationText);
    setAbandonText(_abandonText);
    act.current = _act;
    setOpen(true);
  };

  return { areYouSure, areYouSureModal };
};

export default useAreYouSure;
