import React from "react";
import {
  Text,
  SupportingText,
  TextAndSupportingText,
  Divider,
  SectionHeaderWrapper,
} from "./SectionHeader.styled";

export type SectionHeaderProps = {
  title: string;
  subtitle?: string;
  filters?: any;
  actions?: any;
  noDivider?: boolean;
};

const SectionHeader: React.FC<SectionHeaderProps> = ({
  title,
  subtitle,
  filters,
  noDivider = false,
}) => {
  return (
    <SectionHeaderWrapper>
      <div className="flex w-full">
        <TextAndSupportingText className="w-full">
          <Text>{title}</Text>
          <SupportingText>{subtitle}</SupportingText>
        </TextAndSupportingText>

        {filters && (
          <div className="w-full">
            <div className="flex justify-end">{filters}</div>
          </div>
        )}
      </div>
      {!noDivider && <Divider />}
    </SectionHeaderWrapper>
  );
};

export default SectionHeader;
