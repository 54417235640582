// form.tsx
// Form Definition
import { useState, useEffect } from "react";
import * as yup from "yup";
import validateABN from "utilities/Forms/validators/abnValidator";
import InputField from "components/inputs/InputField";
import Button from "components/core/Button";
import DropDownField from "components/inputs/DropDownField";
import { get, post } from "utilities/Network";
import { useNavigate } from "react-router-dom";
import SearchSelectField from "components/inputs/SelectSearchField";
import { Applicant, Deal, Organisation, Referrer } from "types";
import classNames from "classnames";
import { Label } from "components/inputs/InputField/InputField.styled";
import ReactTooltip from "react-tooltip";
import validateEmail from "utilities/Forms/validators/emailValidator";
import {
  getOrganisationSearchSelectLabel,
  getApplicantSearchSelectLabel,
  getReferrerSearchSelectLabel,
  getDealSearchSelectLabel,
} from "utilities/Strings";

export type FormData = {
  applicant: any;
  applicant_first_name: string;
  applicant_last_name: string;
  applicant_email_address: string;
  applicant_contact_number: string;
  asset_type: string;
  finance_value: number;
  business_name: string;
  business_abn: string;
  business_state: string;
  referral_partner: any;
};

const BUSINESS_TYPES = [
  "Consumer",
  "Approved Deposit Fund",
  "APRA Regulated Non-Public Offer Fund",
  "APRA Regulated Public Offer Fund",
  "APRA Regulated Fund (Fund Type Unknown)",
  "ATO Regulated Self-Managed Superannuation Fund",
  "Australian Private Company",
  "Australian Public Company",
  "Cash Management Trust",
  "Commonwealth Government APRA Regulated Public Sector Fund",
  "Commonwealth Government APRA Regulated Public Sector Scheme",
  "Commonwealth Government Cash Management Trust",
  "Commonwealth Government Company",
  "Commonwealth Government Co-operative",
  "Commonwealth Government Corporate Unit Trust",
  "Commonwealth Government Discretionary Investment Trust",
  "Commonwealth Government Discretionary Services Management Trust",
  "Commonwealth Government Discretionary Trading Trust",
  "Commonwealth Government Entity",
  "Commonwealth Government Fixed Trust",
  "Commonwealth Government Fixed Unit Trust",
  "Commonwealth Government Hybrid Trust",
  "Commonwealth Government Limited Partnership",
  "Commonwealth Government Listed Public Unit Trust",
  "Commonwealth Government Non-Regulated Super Fund",
  "Commonwealth Government Other Incorporated Entity",
  "Commonwealth Government Other Unincorporated Entity",
  "Commonwealth Government Partnership",
  "Commonwealth Government Pooled Development Fund",
  "Commonwealth Government Private Company",
  "Commonwealth Government Public Company",
  "Commonwealth Government Public Trading Trust",
  "Commonwealth Government Statutory Authority",
  "Commonwealth Government Strata Title",
  "Commonwealth Government Super Fund",
  "Commonwealth Government Trust",
  "Commonwealth Government Unlisted Public Unit Trust",
  "Co-operative",
  "Corporate Collective Investment Vehicle (CCIV) Sub-Fund",
  "Corporate Unit Trust",
  "Deceased Estate",
  "Diplomatic/Consulate Body or High Commission",
  "Discretionary Investment Trust",
  "Discretionary Services Management Trust",
  "Discretionary Trading Trust",
  "Family Partnership",
  "First Home Saver Accounts (FHSA) Trust",
  "Fixed Trust",
  "Fixed Unit Trust",
  "Hybrid Trust",
  "Individual/Sole Trader",
  "Limited Partnership",
  "Listed Public Unit Trust",
  "Local Government APRA Regulated Public Sector Fund",
  "Local Government APRA Regulated Public Sector Scheme",
  "Local Government Cash Management Trust",
  "Local Government Company",
  "Local Government Co-operative",
  "Local Government Corporate Unit Trust",
  "Local Government Discretionary Investment Trust",
  "Local Government Discretionary Services Management Trust",
  "Local Government Discretionary Trading Trust",
  "Local Government Entity",
  "Local Government Fixed Trust",
  "Local Government Fixed Unit Trust",
  "Local Government Hybrid Trust",
  "Local Government Limited Partnership",
  "Local Government Listed Public Unit Trust",
  "Local Government Non-Regulated Super Fund",
  "Local Government Other Incorporated Entity",
  "Local Government Other Unincorporated Entity",
  "Local Government Partnership",
  "Local Government Pooled Development Fund",
  "Local Government Private Company",
  "Local Government Public Company",
  "Local Government Public Trading Trust",
  "Local Government Statutory Authority",
  "Local Government Strata Title",
  "Local Government Trust",
  "Local Government Unlisted Public Unit Trust",
  "Non-Regulated Superannuation Fund",
  "Other Incorporated Entity",
  "Other Partnership",
  "Other Trust",
  "Other Unincorporated Entity",
  "Pooled Development Fund",
  "Pooled Superannuation Trust",
  "Public Trading Trust",
  "Small APRA Fund",
  "State Government APRA Regulated Public Sector Scheme",
  "State Government Cash Management Trust",
  "State Government Company",
  "State Government Co-operative",
  "State Government Corporate Unit Trust",
  "State Government Discretionary Investment Trust",
  "State Government Discretionary Services Management Trust",
  "State Government Discretionary Trading Trust",
  "State Government Entity",
  "State Government Fixed Trust",
  "State Government Fixed Unit Trust",
  "State Government Hybrid Trust",
  "State Government Limited Partnership",
  "State Government Listed Public Unit Trust",
  "State Government Non-Regulated Super Fund",
  "State Government Other Incorporated Entity",
  "State Government Other Unincorporated Entity",
  "State Government Partnership",
  "State Government Pooled Development Fund",
  "State Government Private Company",
  "State Government Public Company",
  "State Government Public Trading Trust",
  "State Government Statutory Authority",
  "State Government Strata Title",
  "State Government Trust",
  "State Government Unlisted Public Unit Trust",
  "State Government APRA Regulated Public Sector Fund",
  "Strata-title",
  "Super fund",
  "Territory Government APRA Regulated Public Sector Fund",
  "Territory Government APRA Regulated Public Sector Scheme",
  "Territory Government Cash Management Trust",
  "Territory Government Co-operative",
  "Territory Government Corporate Unit Trust",
  "Territory Government Discretionary Investment Trust",
  "Territory Government Discretionary Services Management Trust",
  "Territory Government Discretionary Trading Trust",
  "Territory Government Entity",
  "Territory Government Fixed Trust",
  "Territory Government Fixed Unit Trust",
  "Territory Government Hybrid Trust",
  "Territory Government Limited Partnership",
  "Territory Government Listed Public Unit Trust",
  "Territory Government Non-Regulated Super Fund",
  "Territory Government Other Incorporated Entity",
  "Territory Government Other Unincorporated Entity",
  "Territory Government Partnership",
  "Territory Government Pooled Development Fund",
  "Territory Government Private Company",
  "Territory Government Public Company",
  "Territory Government Public Trading Trust",
  "Territory Government Statutory Authority",
  "Territory Government Strata Title",
  "Territory Government Trust",
  "Territory Government Unlisted Public Unit Trust",
  "Unlisted Public Unit Trust",
];
const AUSTRALIAN_STATES = ["NSW", "QLD", "ACT", "VIC", "SA", "NT", "TAS", "WA"];
const ASSET_TYPES = [
  "Business Loan",
  "Personal Loan",
  "Passenger Vehicle",
  "Motorcycle",
  "Boat",
  "Caravan",
  "Commercial Vehicle Heavier Than 4.5t",
  "Commercial Vehicle Lighter Than 4.5t",
  "Plant and Equipment",
];

export const FormSchema = yup.object({
  applicant: yup.object(),
  applicant_first_name: yup.string().min(2).max(255).required(),
  applicant_last_name: yup.string().min(2).max(255).required(),
  applicant_email_address: yup.string().email().required(),
  applicant_contact_number: yup.string().min(2).max(255).required(),
  asset_type: yup.string().oneOf(ASSET_TYPES).required(),
  finance_value: yup.number().required(),
  business_name: yup.string().min(2).max(255).required(),
  referral_partner: yup
    .number()
    .transform((currentValue, originalValue) => {
      return originalValue === "" ? null : currentValue;
    })
    .nullable(),
  business_state: yup
    .string()
    .oneOf(AUSTRALIAN_STATES)
    .min(2)
    .max(255)
    .required(),
  business_abn: yup
    .string()
    .nullable()
    .test(
      "validate-australian-business-number",
      "Please provide a valid Australia Business Number",
      (value) => {
        if (!!value) {
          return validateABN(value);
        }
        return true;
      }
    ),
});

export const RequiredFieldsMap = {
  applicant_first_name: true,
  applicant_last_name: true,
  applicant_email_address: true,
  applicant_contact_number: true,
  asset_type: true,
  finance_value: true,
  business_name: true,
  business_state: true,
  business_abn: false,
  referral_partner: false,
};

export enum SectionState {
  new,
  search,
  existing,
}

const sectionStateToString = (state: SectionState) => SectionState[state];

// component.tsx
// usage in component

type BrokerNewDealFormProps = {
  defaultOrganisationState?: SectionState;
  defaultApplicantState?: SectionState;
  defaultReferrerState?: SectionState;

  defaultOrganisation?: Organisation;
  defaultApplicant?: Applicant;
  defaultReferrer?: Referrer;

  newOrganisationDefault?: boolean;
  newApplicantDefault?: boolean;
  duplicate?: boolean;
  onSubmit?: (cb: any) => void;
  originalDeal?: Deal;
};

const BrokerNewDealForm = ({
  defaultOrganisationState = SectionState.new,
  defaultApplicantState = SectionState.new,
  defaultReferrerState = SectionState.search,
  defaultOrganisation,
  defaultApplicant,
  defaultReferrer,
  onSubmit,
  originalDeal,

  newOrganisationDefault = true,
  newApplicantDefault = true,
  duplicate = false,
}: BrokerNewDealFormProps) => {
  const navigate = useNavigate();
  const [internalDeal, setInternalDeal] = useState(duplicate);
  const [submitting, setSubmitting] = useState(false);
  const [loading, setLoading] = useState(false);
  const [submitAttempted, setSubmitAttempted] = useState(false);

  const [formData, setFormData] = useState<any>({
    applicant: {
      first_name: "",
      last_name: "",
      email_address: "",
      contact_number: "",
    },
    organisation: {
      business_name: "",
      abn: "",
      state: AUSTRALIAN_STATES[0],
      type: BUSINESS_TYPES[0],
    },
    asset: {
      type: ASSET_TYPES[0],
      finance_value: null,
    },
    deal: {
      name: "",
    },
  });

  const [sourceDeal, setSourceDeal] = useState<Deal | undefined>();
  const [organisationState, setOrganisationState] = useState<SectionState>(
    defaultOrganisationState
  );
  const [applicantState, setApplicantState] = useState<SectionState>(
    defaultApplicantState
  );
  const [referrerState, setReferrerState] =
    useState<SectionState>(defaultReferrerState);

  const [organisation, setOrganisation] = useState<Organisation | undefined>(
    defaultOrganisation
  );
  const [applicant, setApplicant] = useState<Applicant | undefined>(
    defaultApplicant
  );
  const [referrer, setReferrer] = useState<Referrer | undefined>();

  const [applicantOrganisations, setApplicantOrganisations] =
    useState<Array<Organisation> | null>(null);
  const [loadingApplicantOrganisations, setLoadingApplicantOrganisations] =
    useState(false);

  useEffect(() => {
    // fetch applicants organisations
    if (!!applicant) {
      setLoadingApplicantOrganisations(true);
      get(`organisations/people/${applicant.id}/organisations/`)
        .then((response) => {
          setApplicantOrganisations(response.data);
          setLoadingApplicantOrganisations(false);
        })
        .catch((e) => {
          setLoadingApplicantOrganisations(false);
        });
    } else {
      setApplicantOrganisations([]);
    }
  }, [applicant]);

  const handleSubmit = (e: any) => {
    if (!!e) e.preventDefault();
    setSubmitAttempted(true);

    // partner validation
    if (internalDeal) {
      // validation for internalDeal
      if (!sourceDeal) return;
    } else {
      // validation for referred deal
      if (!referrer) return;
    }

    // organisation validation
    if (organisationState === SectionState.new) {
      // new organisation, validate all organisation fields provided
      const businessNameValid =
        !!formData.organisation?.business_name &&
        formData.organisation?.business_name !== "";
      const abnValid =
        !!formData.organisation.abn ||
        formData.organisation.abn === "" ||
        validateABN(formData.organisation.abn);

      // if the abn or the business name isn't valid exit
      if (!abnValid || !businessNameValid) return;
    } else {
      // existing or search, so an organisation must be selected, if not exit
      if (!organisation) return;
    }

    // applicant validation
    if (applicantState === SectionState.new) {
      // new applicant, validate all applicant fields provided
      const applicantFirstNameValid =
        !!formData.applicant?.first_name &&
        formData.applicant?.first_name !== "";

      const applicantLastNameValid =
        !!formData.applicant?.last_name && formData.applicant?.last_name !== "";

      const applicantEmailAddressValid =
        !!formData.applicant?.email_address &&
        validateEmail(formData.applicant?.email_address);

      const applicantContactNumberValid =
        !!formData.applicant?.contact_number &&
        formData.applicant?.contact_number !== "";

      // if first_name, last_name, email_address or contact_number isn't valid, exit.
      if (
        !applicantFirstNameValid ||
        !applicantLastNameValid ||
        !applicantEmailAddressValid ||
        !applicantContactNumberValid
      )
        return;
    } else {
      // existing or search, so an applicant must be selected, if not exit
      if (!applicant) return;
    }

    // asset validation
    const assetFinanceValueValid = !(
      formData.asset?.finance_value === null ||
      formData.asset?.finance_value === 0 ||
      formData.asset?.finance_value === ""
    );

    // if asset value isn't valid, exit.
    if (!assetFinanceValueValid) return;

    // if the deal isn't named, exit
    if (formData.deal?.name === null || formData.deal?.name === "") return;

    const payload = {
      organisationState: sectionStateToString(organisationState),
      applicantState: sectionStateToString(applicantState),
      sourceDeal: sourceDeal?.id,
      referrer: referrer?.id,
      applicant: applicant?.id,
      organisation: organisation?.id,
      formData,
    };
    // console.log(payload);

    setSubmitting(true);
    post(`deals/create/v2/`, payload).then((response) => {
      setSubmitting(false);
      if (!!onSubmit) {
        onSubmit(() => {
          navigate(`/deals/${response?.data.deal_id}`, {});
        });
      } else {
        navigate(`/deals/${response?.data.deal_id}`);
      }
    });
  };

  return (
    <>
      {/* Form Container */}
      <ReactTooltip className="max-w-[200px]" />
      <form onSubmit={handleSubmit} autoComplete="off">
        <InputField
          label={"Deal Name"}
          type={"string"}
          required={true}
          errorMessage={
            (submitAttempted &&
              (formData.deal?.name === null || formData.deal?.name === "") &&
              "Please provide a name for this deal.") ||
            ""
          }
          disabled={submitting || loading}
          loading={loading}
          value={formData.deal.name}
          onChange={(v) => {
            setFormData({
              ...formData,
              deal: {
                ...formData.deal,
                name: v,
              },
            });
          }}
        />

        {/* REFERRER */}

        <>
          <div className="mb-6">
            <h2 className={"inline text-xl"}>
              {"Referral Partner"}
              {defaultReferrer &&
              defaultReferrer.id !== referrer?.id &&
              !internalDeal ? (
                <span
                  className={"text-sm text-blue-600 cursor-pointer"}
                  onClick={() => {
                    setReferrer(defaultReferrer);
                  }}
                >
                  {" "}
                  - Click here to use the same referral partner: [
                  {defaultReferrer?.id}] {defaultReferrer?.business_name}
                </span>
              ) : (
                internalDeal && (
                  <span
                    className="text-sm text-blue-600 cursor-pointer"
                    onClick={() => {
                      setInternalDeal(false);
                    }}
                  >
                    {" "}
                    - Click here to go back to standard referral partner search
                  </span>
                )
              )}
            </h2>
          </div>

          <SearchSelectField
            valid={!!referrer}
            errorMessage={
              (submitAttempted &&
                !internalDeal &&
                !referrer &&
                "You must provide a referrer. If they are not in our system you need to onboard them.") ||
              ""
            }
            required={true}
            label={"Search Referral Partners"}
            info={
              "You shouldn't typically specify a referral partner if this is a repeat deal and the customer has come back to you directly."
            }
            placeholder={
              internalDeal
                ? "Assigned as Internal To Finstead Capital Pty Ltd"
                : "Search Partners"
            }
            ep={`partners/search/`}
            defaultValue={referrer}
            onChange={(partner: Referrer) => {
              setReferrer(partner);
            }}
            getOptionLabel={getReferrerSearchSelectLabel}
            disabled={submitting || loading || internalDeal}
            {...(internalDeal
              ? {}
              : {
                  newOptionLabel: "Finstead Deal",
                  newOptionCb: () => {
                    if (!internalDeal) {
                      setInternalDeal(true);
                      setReferrer(null);
                    }
                  },
                })}
          />
        </>

        {internalDeal && (
          <>
            <div className="mt-6 mb-6">
              <h2 className="inline text-xl">
                {"Deal Source"}
                {!!originalDeal && (
                  <span
                    className={"text-sm text-blue-600 cursor-pointer"}
                    onClick={() => {
                      setSourceDeal(originalDeal);
                    }}
                  >
                    {" "}
                    - Click here to use:{" "}
                    {getDealSearchSelectLabel(originalDeal)}
                  </span>
                )}
              </h2>
            </div>
            <SearchSelectField
              valid={!!sourceDeal}
              errorMessage={
                (submitAttempted &&
                  !sourceDeal &&
                  "You must specify a source deal.") ||
                ""
              }
              label={"Search Deals"}
              placeholder={"Search deals for the source deal"}
              ep={`deals/search/`}
              defaultValue={sourceDeal}
              onChange={(deal: any) => {
                setSourceDeal(deal);
              }}
              getOptionLabel={getDealSearchSelectLabel}
              disabled={submitting || loading}
              // newOptionLabel={"use Finstead Capital as the referral partner"}
              // newOptionCb={() => {
              //   setInternalDeal(!internalDeal);
              // }}
            />
          </>
        )}

        {internalDeal && (
          <p className="italic text-sm border-l-4 border-cyan-400 pl-4 min-h-[10px]">
            Please specify the deal from which this deal originated. This can be
            this client's previous deal or another deal which you may have even
            lost.
          </p>
        )}

        {!!referrer && (
          <p className="italic text-sm border-l-4 border-cyan-400 pl-4 min-h-[10px]">
            The new deal will be immediately available within the referral
            partners Finstead Partner Portal. Please ensure this is actually a
            referral from {referrer?.business_name}.
          </p>
        )}

        {/* APPLICANT */}
        <div className="mt-8 mb-6">
          <h2 className="inline text-xl">
            {"Applicant"}
            {applicantState !== SectionState.new &&
              !!defaultApplicant &&
              applicant?.id !== defaultApplicant.id && (
                <span
                  className="text-sm text-blue-600 cursor-pointer"
                  onClick={() => {
                    setApplicant(defaultApplicant);
                    setApplicantState(SectionState.existing);
                  }}
                >
                  {" "}
                  - Click here to use the same applicant: [
                  {defaultApplicant?.id}] {defaultApplicant?.name}
                </span>
              )}
            {applicantState === SectionState.new && (
              <span
                className="text-sm text-blue-600 cursor-pointer"
                onClick={() => {
                  if (!!defaultApplicant) {
                    setApplicant(defaultApplicant);
                  }
                  setApplicantState(SectionState.search);
                }}
              >
                {" "}
                - Click here to use an existing applicant
              </span>
            )}
          </h2>
        </div>

        {(applicantState === SectionState.existing ||
          applicantState === SectionState.search) && (
          <>
            <SearchSelectField
              valid={!!applicant}
              label={"Search and select an existing applicant"}
              required={!!RequiredFieldsMap.business_name}
              placeholder={"Search applicants"}
              defaultValue={applicant}
              ep={`organisations/people/search/`}
              onChange={setApplicant}
              getOptionLabel={getApplicantSearchSelectLabel}
              newOptionLabel={"New Applicant"}
              disabled={submitting || loading}
              newOptionCb={() => {
                setApplicantState(SectionState.new);
              }}
              errorMessage={
                (submitAttempted &&
                  !applicant &&
                  "Please select an applicant or click 'New Applicant' to create a new one.") ||
                ""
              }
            />
            {}
          </>
        )}

        {applicantState === SectionState.new && (
          <>
            <InputField
              label={"Applicant First Name"}
              autoFocus={!duplicate}
              required={!!RequiredFieldsMap.applicant_first_name}
              disabled={submitting || loading}
              loading={loading}
              value={formData.applicant.first_name}
              errorMessage={
                (submitAttempted &&
                  (!formData.applicant.first_name ||
                    formData.applicant.first_name === "") &&
                  "You must provide the applicant's first name.") ||
                ""
              }
              onChange={(v) => {
                setFormData({
                  ...formData,
                  applicant: {
                    ...formData.applicant,
                    first_name: v,
                  },
                });
              }}
            />

            <InputField
              label={"Applicant Last Name"}
              required={!!RequiredFieldsMap.applicant_last_name}
              disabled={submitting || loading}
              loading={loading}
              value={formData.applicant.last_name}
              errorMessage={
                (submitAttempted &&
                  (!formData.applicant.last_name ||
                    formData.applicant.last_name === "") &&
                  "You must provide the applicant's last name.") ||
                ""
              }
              onChange={(v) => {
                setFormData({
                  ...formData,
                  applicant: {
                    ...formData.applicant,
                    last_name: v,
                  },
                });
              }}
            />

            <InputField
              label={"Applicant Email Address"}
              required={!!RequiredFieldsMap.applicant_email_address}
              disabled={submitting || loading}
              loading={loading}
              value={formData.applicant.email_address}
              errorMessage={
                (submitAttempted &&
                  (!formData.applicant.email_address ||
                    formData.applicant.email_address === "") &&
                  "You must provide the applicant's email address.") ||
                (submitAttempted &&
                  !validateEmail(formData.applicant.email_address) &&
                  "Please provide a valid email address.") ||
                ""
              }
              onChange={(v) => {
                setFormData({
                  ...formData,
                  applicant: {
                    ...formData.applicant,
                    email_address: v,
                  },
                });
              }}
            />

            <InputField
              label={"Applicant Contact Number"}
              required={!!RequiredFieldsMap.applicant_contact_number}
              disabled={submitting || loading}
              loading={loading}
              value={formData.applicant.contact_number}
              errorMessage={
                (submitAttempted &&
                  (!formData.applicant.contact_number ||
                    formData.applicant.contact_number === "") &&
                  "You must provide the applicant's contact_number.") ||
                ""
              }
              onChange={(v) => {
                setFormData({
                  ...formData,
                  applicant: {
                    ...formData.applicant,
                    contact_number: v,
                  },
                });
              }}
            />
          </>
        )}

        {/* ORGANISATION */}
        <div className="mt-6 mb-6">
          <h2 className="inline text-xl">
            {"Organisation"}
            {organisationState !== SectionState.new &&
              !!defaultOrganisation &&
              organisation?.id !== defaultOrganisation.id && (
                <span
                  className="text-sm text-blue-600 cursor-pointer"
                  onClick={() => {
                    setOrganisation(defaultOrganisation);
                    setOrganisationState(SectionState.existing);
                  }}
                >
                  {" "}
                  - Click here to use the same organisation: [
                  {defaultOrganisation?.id}] {defaultOrganisation?.name}
                </span>
              )}
            {organisationState === SectionState.new && (
              <span
                className="text-sm text-blue-600 cursor-pointer"
                onClick={() => {
                  if (!!defaultOrganisation) {
                    setOrganisation(defaultOrganisation);
                  }
                  setOrganisationState(SectionState.search);
                }}
              >
                {" "}
                - Click here to use an existing organisation
              </span>
            )}
          </h2>
        </div>

        {(organisationState === SectionState.existing ||
          organisationState === SectionState.search) && (
          <>
            <SearchSelectField
              valid={!!organisation}
              label={"Search and select an existing Organisation"}
              required={!!RequiredFieldsMap.business_name}
              placeholder={"Search organisations"}
              defaultValue={organisation}
              ep={`organisations/search/`}
              onChange={setOrganisation}
              getOptionLabel={getOrganisationSearchSelectLabel}
              disabled={submitting || loading}
              newOptionLabel={"New Organisation"}
              newOptionCb={() => {
                setOrganisationState(SectionState.new);
              }}
              errorMessage={
                (submitAttempted &&
                  !organisation &&
                  "Please select an organisation or click 'New Organisation' to create a new one.") ||
                ""
              }
            />

            {!!applicant &&
              applicantState !== SectionState.new &&
              !!applicantOrganisations &&
              applicantOrganisations.length > 1 && (
                <>
                  <Label className="text-bold">{`Here are all of ${applicant?.name}'s organisations for quick selection:`}</Label>
                  <div className="w-full h-full flex flex-wrap gap-4 mt-2 mb-8">
                    {applicantOrganisations?.map(
                      (_organisation: Organisation) => (
                        <div
                          className={classNames(
                            "text-xs hover:drop-shadow-lg w-[100px] h-[100px] border border-slate-300 hover:border-slate-500 rounded-md flex items-center justify-center cursor-pointer text-center flex-wrap",
                            {
                              "bg-green-200":
                                _organisation.id === organisation?.id,
                            }
                          )}
                          onClick={() => {
                            setOrganisation(_organisation);
                          }}
                        >
                          <div>
                            [{_organisation.id}] {_organisation.name}
                            <br />
                            {_organisation.id === organisation?.id && (
                              <div className="text-xs">(selected)</div>
                            )}
                          </div>
                        </div>
                      )
                    )}
                  </div>
                </>
              )}
          </>
        )}
        {organisationState === SectionState.new && (
          <>
            <InputField
              label={"Business Name"}
              required={true}
              disabled={submitting || loading}
              loading={loading}
              value={formData.organisation.business_name}
              errorMessage={
                (submitAttempted &&
                  (formData.organisation?.business_name === null ||
                    formData.organisation?.business_name === "") &&
                  "Please provide a business name") ||
                ""
              }
              onChange={(v) => {
                setFormData({
                  ...formData,
                  organisation: {
                    ...formData.organisation,
                    business_name: v,
                  },
                });
              }}
            />
            <InputField
              label={"Business ABN"}
              required={false}
              disabled={submitting || loading}
              loading={loading}
              errorMessage={
                (submitAttempted &&
                  formData.organisation?.abn !== null &&
                  formData.organisation?.abn !== "" &&
                  !validateABN(formData.organisation?.abn) &&
                  "Please provide a valid ABN.") ||
                ""
              }
              value={formData.organisation.abn}
              onChange={(v) => {
                setFormData({
                  ...formData,
                  organisation: {
                    ...formData.organisation,
                    abn: v,
                  },
                });
              }}
            />
            <DropDownField
              label={"Business Type"}
              required={true}
              placeholder={"Please select a business type"}
              options={BUSINESS_TYPES.map((e: any) => {
                return { text: e, value: e };
              })}
              disabled={submitting || loading}
              loading={loading}
              value={formData.organisation.type}
              onChange={(v) => {
                setFormData({
                  ...formData,
                  organisation: {
                    ...formData.organisation,
                    type: v,
                  },
                });
              }}
            />
            <DropDownField
              label={"Business State"}
              required={true}
              placeholder={"Please select a state"}
              options={AUSTRALIAN_STATES.map((e: any) => {
                return { text: e, value: e };
              })}
              disabled={submitting || loading}
              loading={loading}
              value={formData.organisation.state}
              onChange={(v) => {
                setFormData({
                  ...formData,
                  organisation: {
                    ...formData.organisation,
                    state: v,
                  },
                });
              }}
            />
          </>
        )}

        <div className="mt-6 mb-6">
          <h2 className="inline text-xl">{"Asset"}</h2>
        </div>

        <DropDownField
          label={"Asset Type"}
          required={!!RequiredFieldsMap.asset_type}
          // errorMessage={submitAttempted && }
          placeholder={"Please select an asset type"}
          options={ASSET_TYPES.map((e) => {
            return { text: e, value: e };
          })}
          disabled={submitting || loading}
          loading={loading}
          value={formData.asset.type}
          onChange={(v) => {
            setFormData({
              ...formData,
              asset: {
                ...formData.asset,
                type: v,
              },
            });
          }}
        />

        <InputField
          label={"Indicative Finance Value (NAF)"}
          type={"number"}
          required={!!RequiredFieldsMap.finance_value}
          errorMessage={
            (submitAttempted &&
              (formData.asset?.finance_value === null ||
                formData.asset?.finance_value === 0 ||
                formData.asset?.finance_value === "") &&
              "Please provide an indicative finance value.") ||
            ""
          }
          disabled={submitting || loading}
          loading={loading}
          value={formData.asset.finance_value}
          onChange={(v) => {
            setFormData({
              ...formData,
              asset: {
                ...formData.asset,
                finance_value: v,
              },
            });
          }}
        />

        <div className="w-full mb-2 text-sm text-right">
          <p>Once created you'll redirect to the new deal's page.</p>
          <br />
          <Button
            text={submitting ? "Creating..." : "Create Deal"}
            disabled={submitting || loading}
            type={"submit"}
            onClick={() => {}}
            primary
          />
        </div>
      </form>
    </>
  );
};

export default BrokerNewDealForm;
