import React, { useState, useEffect, useMemo } from "react";
import InputFieldWrapper from "components/inputs/InputFieldWrapper";
import { Select } from "./DropDownField.styled";
import { get } from "utilities/Network";

export type DropDownFieldProps = {
  label?: string;
  subtitle?: string;
  placeholder?: string;
  value?: any;
  onChange?: (v: any) => void;
  disabled?: boolean;
  endpoint: string;
  required?: boolean;
  errorMessage?: string;
  formControlProps?: any;
  loading?: boolean;
  allowDefault?: boolean;
  useIdForValue?: boolean;
  defaultAll?: boolean;
};

const BrokerDropDownField: React.FC<DropDownFieldProps> = ({
  placeholder,
  value,
  onChange,
  errorMessage,
  endpoint,
  label,
  disabled = false,
  required = false,
  allowDefault = false,
  useIdForValue = false,
  formControlProps = {},
  defaultAll = false,
}) => {
  const defaultAllValue = useMemo(() => {
    return { id: "All", name: "All" };
  }, []);

  const [loading, setLoading] = useState(true);
  const [options, setOptions] = useState(defaultAll ? [defaultAllValue] : []);

  useEffect(() => {
    setLoading(true);
    get(endpoint).then((response) => {
      setLoading(false);
      if (defaultAll) {
        setOptions([defaultAllValue, ...response.data]);
      } else {
        setOptions(response.data);
      }
    });
  }, [endpoint, defaultAll, defaultAllValue]);

  if (loading) {
    return null;
  }

  return (
    <Select
      style={{
        display: "inline-block",
        height: "45px",
        flex: 1,
      }}
      loading={loading}
      placeholder={loading ? "" : placeholder}
      value={value}
      onChange={(e: any) => onChange && onChange(e.target.value)}
      disabled={disabled}
      hasError={!!errorMessage}
      {...formControlProps}
    >
      {!!placeholder && (
        <option disabled={!allowDefault} selected value={undefined}>
          {placeholder}
        </option>
      )}
      {options.map((option: any) => (
        <option value={option.id}>{option.name}</option>
      ))}
    </Select>
  );
};

export default BrokerDropDownField;
