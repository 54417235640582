import React from "react";
import SubTitle from "components/core/SubTitle";

type InfoBoxProps = {
  title: string;
  info: any;
  icon: any;
  w?: string;
  titleClicked?: () => void;
};

export const InfoBox: React.FC<InfoBoxProps> = ({
  title,
  info,
  icon,
  w = "300px",
  titleClicked = () => {},
}) => {
  return (
    <div
      className={`max-w-100 w-[${w}] min-h-[150px] mb-2 p-2 rounded-md border border-slate-300`}
    >
      <div className="flex mb-2">
        <div className="inline-block mr-2 pt-[2px]">{icon}</div>
        <div className="inline-block h-[100%] pt-1">
          <SubTitle text={title} onClick={titleClicked} />
        </div>
      </div>

      <hr />
      <table className="w-[100%] p-2">
        <tbody>
          {Object.keys(info).map((k) => {
            return (
              <tr
                key={k}
                className="h-[35px] bottom border-gray-400 hover:bg-slate-100"
              >
                <td className="w-[50%] text-right pr-2">
                  {k
                    .split("_")
                    .map((x) => x.charAt(0).toUpperCase() + x.slice(1))
                    .join(" ")}
                </td>
                <td className="w-[50%] text-left pl-2">{info[k]}</td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default InfoBox;
