import React, { useState, useEffect } from "react";
import {
  MainWrapper,
  Container,
  Section,
} from "pages/TeamPage/TeamPage.styled";

import TableComponent from "components/core/TableComponent";
import Badge, { BadgeType } from "components/core/Badge";

import { ReactComponent as userIcon } from "assets/icons/user.svg";
import PageHeader from "components/layout/PageHeader";
import { useParams, useNavigate } from "react-router-dom";
import { get } from "utilities/Network";
import InfoBox from "components/core/InfoBox";
import { ReactComponent as infoIcon } from "assets/icons/info.svg";
import IconBase from "components/core/IconBase";
import constants from "styles/constants";
import Tabs from "components/core/Tabs";

import useOrganisationManager from "hooks/useOrganisationManager";
import useApplicantManager from "hooks/useApplicantManager";

const ApplicantDetailPage = ({}: any) => {
  const navigate = useNavigate();
  const { applicantId } = useParams();
  const [applicant, setApplicant] = useState<any>();

  const fetch = () => {
    get(`organisations/people/${applicantId}/`).then((response) =>
      setApplicant(response.data)
    );
  };
  useEffect(fetch, [applicantId]);

  const {
    organisationNameText,
    organisationStateText,
    organisationPostCodeText,
    organisationStreetAddressText,
    organisationCityText,
    organisationABNText,
  } = useOrganisationManager({ organisation: applicant?.organisation });

  const {
    applicantFirstNameText,
    applicantLastNameText,
    applicantContactNumber,
    applicantEmailAddress,
  } = useApplicantManager({ applicant: applicant });

  return (
    <MainWrapper>
      <Container>
        <PageHeader
          title={`Applicant ${applicant?.first_name}`}
          subtitle={<>{applicantId}</>}
          breadcrumbs={[
            {
              title: "Applicants",
              link: "/applicants",
            },
          ]}
        />
      </Container>
      <Section>
        <div className="flex flex-wrap-reverse w-full gap-8 pt-4">
          <Tabs
            tabs={[
              {
                name: "Details",
                component: (
                  <>
                    <div className="w-1/2">
                      <InfoBox
                        title={"Applicant Details"}
                        icon={
                          <IconBase
                            src={infoIcon}
                            color={constants.colors.primary_600}
                          />
                        }
                        info={{
                          id: applicant?.id,
                          pipedrive_id: applicant?.pipedrive_id,
                          "First Name": applicantFirstNameText,
                          "Last Name": applicantLastNameText,
                          "Email Address": applicantEmailAddress,
                          "Contact Number": applicantContactNumber,
                        }}
                        w={"100%"}
                      />
                      <InfoBox
                        title={"Organisation Details"}
                        icon={
                          <IconBase
                            src={infoIcon}
                            color={constants.colors.primary_600}
                          />
                        }
                        info={{
                          name: organisationNameText,
                          abn: organisationABNText,
                          state: organisationStateText,
                          "Post Code": organisationPostCodeText,
                          "Street Address": organisationStreetAddressText,
                          City: organisationCityText,
                          "Pipedrive Id": applicant?.organisation?.pipedrive_id,
                          id: applicant?.organisation?.id,
                          "Primary Contact": `${applicant?.organisation?.primary_contact?.first_name} ${applicant?.organisation?.primary_contact?.last_name}`,
                        }}
                        w={"100%"}
                      />
                    </div>
                  </>
                ),
              },
              {
                name: "Deals",
                component: (
                  <TableComponent
                    endpoint={`organisations/people/${applicantId}/deals/`}
                    onRowClick={(row: any, newTab = false) => {
                      if (newTab) {
                        window.open(
                          `https://crm.finstead.com.au/deals/${row.id}`,
                          "_blank",
                          "noreferrer"
                        );
                      } else {
                        navigate(`/deals/${row.id}`);
                      }
                    }}
                    fields={{
                      id: {
                        name: "Id",
                      },
                      pipedrive_id: {
                        name: "Pipedrive Id",
                      },
                      broker: {
                        name: "Broker",
                        middleware: (row: any) => (
                          <>
                            {!!row.broker_profile_picture_url ? (
                              <img
                                src={row.broker_profile_picture_url}
                                className="w-[25px] h-[25px] object-cover rounded-full inline-block mr-2"
                              />
                            ) : (
                              <span className="inline-block mr-2 border rounded-full border-slate-100">
                                <IconBase src={userIcon} />
                              </span>
                            )}
                            <span>{row.broker}</span>
                          </>
                        ),
                      },
                      title: {
                        name: "Title",
                      },
                      add_time: {
                        name: "Lead In",
                      },
                      value: {
                        name: "Value",
                      },
                      status: {
                        name: "Status",
                        middleware: (row: any) => {
                          switch (row.status) {
                            case "":
                              return "";
                            case "open":
                              return (
                                <Badge type={BadgeType.info} text="Open" />
                              );
                            case "lost":
                              return (
                                <Badge type={BadgeType.danger} text="Lost" />
                              );
                            case "pending assignment":
                              return (
                                <Badge type={BadgeType.info} text={"Lead In"} />
                              );
                            case "won":
                            case "settled":
                              return (
                                <Badge
                                  type={BadgeType.success}
                                  text={"Settled"}
                                />
                              );
                            default:
                              return (
                                <Badge
                                  type={BadgeType.warning}
                                  text={"Unknown ?"}
                                />
                              );
                          }
                        },
                      },
                    }}
                  />
                ),
              },
            ]}
          />
        </div>
      </Section>
    </MainWrapper>
  );
};

export default ApplicantDetailPage;
