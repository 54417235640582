import styled from "styled-components";
import { DisplayMdSemibold, TextMdMedium, TextSmMedium } from "styles/mixins";

export const BoxMetricWrapper = styled("div")<{ wide: boolean }>`
  display: inline-block;
  background: rgba(255, 255, 255, 1);
  filter: drop-shadow(inset 0px 1px 2px rgba(16, 24, 40, 0.06))
    drop-shadow(inset 0px 1px 3px rgba(16, 24, 40, 0.1));
  border: 1px solid rgb(228, 231, 236);
  border-radius: 8px;
  width: auto;
  min-width: ${(props) => (props.wide ? "300px" : "208px")};
  height: 178px;
  flex-direction: column;
  gap: 30px;
  padding: 24px;
  margin-right: 30px;
  margin-bottom: 10px;
`;
export const HeadingContainer = styled.div`
  display: flex;
  width: 190px;
  align-items: flex-start;
  gap: 14px;
`;
export const Heading = styled.span`
  text-align: left;
  width: auto;
  color: ${({ theme }) => theme.colors.gray_900};

  ${TextMdMedium}
`;
export const ValueContainer = styled.div`
  display: flex;
  width: 150px;
  align-items: flex-end;
  gap: 16px;
`;
export const NumberAndBadge = styled.div`
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
`;
export const Number = styled.span`
  text-align: left;
  align-self: stretch;
  color: ${({ theme }) => theme.colors.gray_900};

  ${DisplayMdSemibold}
`;
export const ChangeAndText = styled.div`
  /* display: flex; */
  align-self: stretch;
  align-items: center;
  gap: 8px;
`;
export const Change = styled.div`
  display: flex;
  width: fit-content;
  justify-content: center;
  align-items: center;
  gap: 4px;
`;
export const Styled = styled.img`
  overflow: hidden;
  width: 20px;
  height: 20px;
  display: flex;
  flex-direction: column;
  padding: 4.166666030883789px;
  fill: red;
`;
export const ChangeValue = styled.span<{ is_positive: boolean }>`
  text-align: center;
  white-space: pre-line;
  color: ${(props) =>
    props.is_positive
      ? props.theme.colors.success_500
      : props.theme.colors.error_500};

  ${TextSmMedium}
`;
export const PeriodDescription = styled.span`
  margin-left: 5px;
  text-align: left;
  flex-grow: 1;
  color: ${({ theme }) => theme.colors.gray_500};

  ${TextSmMedium}
`;
