import React, { BlockquoteHTMLAttributes } from "react";
import Button from "components/core/Button";
import Lottie from "react-lottie-player";

import * as fireworksAnimationData from "./fireworks.json";

import Modal from "react-modal";
import "./index.css";
import { ReactComponent as CloseIcon } from "assets/icons/x.svg";

type ModalButton = {
  text: string;
  onClick: () => void;
};

type ModalProps = {
  title?: any;
  congratulate?: boolean;
  isOpen?: boolean;
  confirmationButton?: ModalButton;
  children?: any;
  onRequestClose?: () => void;
};

const FsModal: React.FC<ModalProps> = ({
  title,
  confirmationButton,
  isOpen = false,
  congratulate = false,
  onRequestClose,
  children,
}) => {
  return (
    <div
      className="fixed top-0 left-0 w-full h-full z-20"
      style={{
        backdropFilter: isOpen ? "blur(4px)" : "",
        backgroundColor: isOpen ? "rgba(0,0,0,0.7)" : "rgba(0,0,0,0)",
        ...(isOpen ? {} : { pointerEvents: "none" }),
      }}
    >
      {isOpen && congratulate && (
        <div className="fixed w-full justify-center pointer-events-none z-20 mt-[0px]">
          <Lottie
            animationData={fireworksAnimationData}
            style={{ width: "100%", height: "100%" }}
            loop={false}
            play={true}
          />
        </div>
      )}
      <div className="absolute z-40 max-w-full w-[700px]">
        <Modal
          isOpen={isOpen}
          closeTimeoutMS={500}
          contentLabel={title}
          style={{
            overlay: {
              backgroundColor: "rgba(0,0,0,0)",
            },
            content: {
              zIndex: 90,
              width: "800px",
              height: "550px",
              margin: "auto auto",
            },
          }}
        >
          <div className="flex w-full pb-1 mb-2 border-b border-gray-300">
            <div className="flex-1 text-lg font-bold">{title}</div>
            <div className="text-right">
              <CloseIcon
                className="cursor-pointer hover:shadow-md"
                onClick={onRequestClose}
              />
            </div>
          </div>

          <div className="w-full h-full">{children}</div>
        </Modal>
      </div>
    </div>
  );
};

export default FsModal;
