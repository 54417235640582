import React from "react";
import { MainWrapper, Container } from "pages/TeamPage/TeamPage.styled";

import PageHeader from "components/layout/PageHeader";
type DocumentsPageProps = {};

const DocumentsPage: React.FC<DocumentsPageProps> = ({}) => {
  return (
    <MainWrapper>
      <Container>
        <PageHeader
          title={`Document Templates`}
          subtitle={
            "View, Fill out or request others to fill out any of the Finstead document templates"
          }
        />
      </Container>
    </MainWrapper>
  );
};

export default DocumentsPage;
