import React from "react";
import { AvatarWrapper } from "./Avatar.styled";

export type AvatarProps = {
  src: string;
};

const Avatar: React.FC<AvatarProps> = ({ src }) => {
  return <AvatarWrapper src={src} />;
};

export default Avatar;
