import React, { useState, useEffect } from "react";
import * as yup from "yup";
import InputField from "components/inputs/InputField";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import TextArea from "components/inputs/TextArea";
import DropDownField from "components/inputs/DropDownField";
import { get, post } from "utilities/Network";
import Button from "components/core/Button";

type FormData = {
  name: string;
  report_template: string;
  recipient_group: string;
  report_frequency: string;
};

const FormSchema = yup.object({
  name: yup
    .string()
    .required("You must specify a name for this schedule.")
    .min(3)
    .max(255),
  report_template: yup.string().required("You must select a report template."),
  recipient_group: yup.string().required("You must select a recipient group."),
  report_frequency: yup
    .string()
    .required("You must select a report frequency."),
});

const RequiredFieldsMap = {
  name: true,
  report_template: true,
  recipient_group: true,
  report_frequency: true,
};

const ReportScheduleForm = ({ cb }: any) => {
  const [loading, setLoading] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [reportTemplates, setReportTemplates] = useState<any[]>([]);
  const [recipientGroups, setRecipientGroups] = useState<any[]>([]);

  useEffect(() => {
    get("reports/templates/all/").then((res) => {
      setReportTemplates(res.data);
    });
  }, []);
  useEffect(() => {
    get("reports/recipient-groups/all/").then((res) => {
      setRecipientGroups(res.data);
    });
  }, []);

  // use Form Hook
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<FormData>({
    resolver: yupResolver(FormSchema),
  });
  const onSubmit = (formData: FormData) => {
    setSubmitting(true);

    post("reports/schedules/create/", formData).then((response) => {
      setSubmitting(false);
      if (!!cb) cb(response.data);
    });
  };

  return (
    <form className="pb-4 max-w-[700px]" onSubmit={handleSubmit(onSubmit)}>
      <InputField
        formControlProps={register("name")}
        label={"Schedule Name"}
        disabled={submitting}
        errorMessage={errors.name?.message}
        required={!!RequiredFieldsMap.name}
      />

      <DropDownField
        formControlProps={register("report_template")}
        label={"Report Template"}
        loading={submitting}
        disabled={submitting || reportTemplates.length == 0}
        errorMessage={errors.report_template?.message}
        required={!!RequiredFieldsMap.report_template}
        placeholder={"Please select a report template."}
        options={(reportTemplates || []).map((reportTemplate) => {
          return {
            text: reportTemplate.name,
            value: reportTemplate.id,
          };
        })}
      />

      <DropDownField
        formControlProps={register("recipient_group")}
        label={"Recipient Group"}
        loading={submitting}
        disabled={submitting || recipientGroups.length == 0}
        errorMessage={errors.recipient_group?.message}
        required={!!RequiredFieldsMap.recipient_group}
        placeholder={"Please select a recipient group for the report schedule."}
        options={(recipientGroups || []).map((recipientGroup) => {
          return {
            text: recipientGroup.name,
            value: recipientGroup.id,
          };
        })}
      />

      <DropDownField
        formControlProps={register("report_frequency")}
        label={"Report Frequency"}
        subtitle="Reports will be generated at 9am AEST the day after the end of the preceeding period."
        loading={submitting}
        disabled={submitting || recipientGroups.length == 0}
        errorMessage={errors.report_frequency?.message}
        required={!!RequiredFieldsMap.report_frequency}
        placeholder={"Please select a frequency for this schedule."}
        options={[
          { text: "daily", value: "daily" },
          { text: "weekly", value: "weekly" },
          { text: "fortnightly", value: "fortnightly" },
          { text: "monthly", value: "monthly" },
          { text: "quarterly", value: "quarterly" },
          { text: "yearly", value: "yearly" },
          {
            text: "Every 5 Minutes (for demo)",
            value: "demo_every_five_minutes",
          },
        ]}
      />

      <div className="w-full text-right">
        <Button
          type={"submit"}
          disabled={submitting}
          primary
          text={"Create"}
          onClick={() => {}}
        ></Button>
      </div>
    </form>
  );
};

export default ReportScheduleForm;
