import React, { useMemo } from "react";
import { DataReport } from "types";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  BarChart,
  Pie,
  ComposedChart,
  Bar,
  PieChart,
} from "recharts";
import BoxMetric from "components/metrics/BoxMetric";
import { formatUnderCase, timeString, formatMoney } from "utilities/Strings/";

const colorArray = [
  "#1f77b4",
  "#aec7e8",
  "#ff7f0e",
  "#ffbb78",
  "#2ca02c",
  "#98df8a",
  "#d62728",
  "#ff9896",
  "#9467bd",
  "#c5b0d5",
  "#8c564b",
  "#c49c94",
  "#e377c2",
  "#f7b6d2",
  "#7f7f7f",
  "#c7c7c7",
  "#bcbd22",
  "#dbdb8d",
  "#17becf",
  "#9edae5",
  "#6b6ecf",
  "#b5b8ff",
  "#b15928",
  "#ffab73",
  "#018571",
  "#80cdc1",
  "#fdae61",
  "#998244",
  "#d8b365",
  "#5ab4ac",
];

type BIReportProps = {
  report: DataReport;
};

type LeadMetric = {
  value: any;
  delta: any;
  isUp: boolean;
};

type MetricData = {
  allLeads: LeadMetric;
  digitalLeads: LeadMetric;
  repeatLeads: LeadMetric;
  directLeads: LeadMetric;
  referrerLeads: LeadMetric;
  [key: string]: LeadMetric;
};

type Metric = {
  name: string;
  data: MetricData;
};

// Introduce a new type for the lender data
type LenderMetric = {
  [key: string]: {
    value: any;
    delta: any;
    isUp: boolean;
  };
};

type Lender = {
  name: string;
  data: LenderMetric;
};

interface LenderWithBrokers {
  name: string;
  data: LenderMetric;
  brokers: Broker[];
}

type ProductMetric = {
  value: number;
  delta: number;
  isUp: boolean;
};

type ProductData = {
  allDeals: ProductMetric;
  workingCapital: ProductMetric;
  consumer: ProductMetric;
  commercialProperty: ProductMetric;
  acquisition: ProductMetric;
};

interface Broker {
  name: string;
  data: MetricData | LenderMetric;
}

interface MetricWithBrokers {
  name: string;
  data: MetricData;
  brokers: Broker[];
}

type Product = {
  name: string;
  lender: string;
  data: ProductData;
};

interface ProductWithBrokers {
  name: string;
  lender: string;
  data: ProductData;
  brokers: Broker[];
}

interface ReferrerWithBrokers {
  name: string;
  manager: string;
  data: any;
  brokers: Broker[];
}

const ChevronUp = ({ fill = "green" }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill={fill}
    viewBox="0 0 24 24"
    stroke={fill}
    width="16" // added width and height attributes
    height="16"
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      fill={fill}
      d="M5 15l7-7 7 7"
    />
  </svg>
);

const ChevronDown = ({ fill = "red" }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill={fill}
    viewBox="0 0 24 24"
    stroke={fill}
    width="16" // added width and height attributes
    height="16"
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      fill={fill}
      strokeWidth={2}
      d="M19 9l-7 7-7-7"
    />
  </svg>
);

const BIReport: React.FC<BIReportProps> = ({ report }) => {
  const {
    performance,
    products,
    lenders,
    referrers,
    brokers,
    PTDSettledNafData,
    PTDBrokerSettledNafData,
    PTDPartnerSettledData,
    metrics,
  } = report.data;

  // Calculate brokerNames dynamically
  const brokerNames = useMemo(() => {
    const namesSet: Set<string> = new Set();

    // Fill up the brokerNames
    PTDBrokerSettledNafData.forEach((entry: any) => {
      Object.keys(entry).forEach((key) => {
        if (key !== "date" && !namesSet.has(key)) {
          namesSet.add(key);
        }
      });
    });

    return namesSet;
  }, [PTDBrokerSettledNafData]);

  // Calculate partnerNames dynamically
  const partnerNames = useMemo(() => {
    const namesSet: Set<string> = new Set();

    // Fill up the partnerNames
    PTDPartnerSettledData.forEach((entry: any) => {
      Object.keys(entry).forEach((key) => {
        if (key !== "date" && !namesSet.has(key)) {
          namesSet.add(key);
        }
      });
    });

    // Convert the Set to Array
    return Array.from(namesSet);
  }, [PTDPartnerSettledData]);

  return (
    <div className="w-full h-full">
      {/* Period To Date Settled NAF */}
      <div className="border border-slate-300 w-full min-h-[300px] p-2 rounded-md shadow-md mb-12">
        <h3 className="mb-4 font-bold text-center text-lg uppercase">
          NAF & Commissions
        </h3>
        <div className="flex">
          <div className="relative w-full overflow-x-auto min-h-[300px]">
            <ResponsiveContainer width="100%" height="100%">
              <ComposedChart
                width={1100}
                height={300}
                data={PTDSettledNafData}
                margin={{
                  top: 5,
                  right: 30,
                  left: 40,
                  bottom: 5,
                }}
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="name" />
                <YAxis
                  yAxisId="left"
                  orientation="left"
                  // minTickGap={1000000}
                  // allowDecimals={false}
                  tickFormatter={(tick) => {
                    return `$${tick.toLocaleString()}`;
                  }}
                />
                <YAxis
                  yAxisId="right"
                  orientation="right"
                  // allowDecimals={false}
                  // minTickGap={50000}
                  tickFormatter={(tick) => {
                    return `$${tick.toLocaleString()}`;
                  }}
                />
                <Tooltip />
                <Legend />
                <Bar
                  yAxisId="left"
                  isAnimationActive={false}
                  dataKey="NAF"
                  stroke="#000000"
                  fill="#D9963D"
                />
                <Bar
                  yAxisId="right"
                  isAnimationActive={false}
                  dataKey="Brokerage"
                  stroke="#01161E"
                  fill="#"
                />
              </ComposedChart>
            </ResponsiveContainer>
          </div>
        </div>
      </div>

      <div className="border border-slate-300 w-full min-h-[500px] p-2 rounded-md shadow-md mb-12 ">
        <h3 className="mb-4 font-bold text-center text-lg uppercase">
          Broker Commissions
        </h3>
        <div className="flex">
          <div className="relative w-full overflow-x-auto min-h-[500px]">
            <ResponsiveContainer width="100%" height="100%">
              <ComposedChart
                width={1200}
                height={300}
                data={PTDBrokerSettledNafData}
                margin={{
                  top: 5,
                  right: 30,
                  left: 20,
                  bottom: 5,
                }}
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="date" />
                <YAxis
                  tickFormatter={(tick) => {
                    return `$${tick.toLocaleString()}`;
                  }}
                />
                <Tooltip />
                <Legend
                  payload={Array.from(brokerNames).map((broker, index) => ({
                    id: broker,
                    type: "square",
                    color: colorArray[index % colorArray.length],
                    value: broker,
                  }))}
                />
                {Array.from(brokerNames).map((broker) => (
                  <Bar
                    key={`bar-${broker}`}
                    dataKey={broker}
                    fill={
                      colorArray[
                        Array.from(brokerNames).indexOf(broker) %
                          colorArray.length
                      ]
                    }
                  />
                ))}

                {/* {Array.from(brokerNames).map((broker) => (
                  <Line
                    type="linear"
                    key={`line-${broker}`}
                    dataKey={broker}
                    stroke={
                      colorArray[
                        Array.from(brokerNames).indexOf(broker) %
                          colorArray.length
                      ]
                    }
                  />
                ))} */}
              </ComposedChart>
            </ResponsiveContainer>
          </div>
        </div>
      </div>
      <div className="pagebreak"> </div>

      <div className="border border-slate-300 w-full min-h-[500px] p-2 rounded-md shadow-md mb-12 ">
        <h3 className="mb-4 font-bold text-center text-lg uppercase">
          Partner Performance Chart
        </h3>
        <div className="flex">
          <div className="relative w-full overflow-x-auto min-h-[500px]">
            <ResponsiveContainer width="100%" height="100%">
              <ComposedChart
                width={1200}
                height={300}
                data={PTDPartnerSettledData}
                margin={{
                  top: 5,
                  right: 30,
                  left: 20,
                  bottom: 5,
                }}
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="date" />
                <YAxis
                  tickFormatter={(tick) => {
                    return `$${tick.toLocaleString()}`;
                  }}
                />
                <Tooltip />
                <Legend />
                {partnerNames.map((partner: string, index: number) => (
                  <Bar
                    key={partner}
                    isAnimationActive={false}
                    dataKey={partner}
                    fill={colorArray[index % colorArray.length]}
                  />
                ))}
              </ComposedChart>
            </ResponsiveContainer>
          </div>
        </div>
      </div>

      <div className="border border-slate-300 w-full min-h-[300px] p-2 rounded-md shadow-md mb-12">
        <h3 className="mb-4 font-bold text-center text-lg uppercase">
          Metrics
        </h3>
        <table className="w-full text-sm text-left text-gray-500">
          <thead className="text-xs text-gray-700 uppercase bg-gray-50 ">
            <tr>
              <th scope="col" className="px-6 py-3">
                METRIC
              </th>
              <th scope="col" className="px-6 py-3">
                VALUE
              </th>
              <th scope="col" className="px-6 py-3">
                DELTA
              </th>
            </tr>
          </thead>
          <tbody>
            {[
              {
                title: "Leads In",
                value: metrics.leads_in?.value,
                delta_value: metrics?.leads_in?.delta_value,
                delta_is_positive: metrics?.leads_in?.delta_is_positive,
                inverse_delta: false,
              },
              {
                title: "Settled",
                value: metrics.settled?.value,
                delta_value: metrics?.settled?.delta_value,
                delta_is_positive: metrics?.settled?.delta_is_positive,
                inverse_delta: false,
              },
              {
                title: "Lost",
                value: metrics.lost?.value,
                delta_value: metrics?.lost?.delta_value,
                delta_is_positive: metrics?.lost?.delta_is_positive,
                inverse_delta: true,
              },
              {
                title: "Time To Lost",
                value: timeString(metrics.avg_time_before_lost?.value),
                delta_value: timeString(
                  metrics?.avg_time_before_lost?.delta_value
                ),
                delta_is_positive:
                  metrics?.avg_time_before_lost?.delta_is_positive,
                inverse_delta: true,
              },
              {
                title: "Time To Settled",
                value: timeString(metrics.avg_time_before_settlement?.value),
                delta_value: timeString(
                  metrics?.avg_time_before_settlement?.delta_value
                ),
                delta_is_positive:
                  metrics?.avg_time_before_settlement?.delta_is_positive,
                inverse_delta: true,
              },
              {
                title: "Commission",
                value: formatMoney(metrics.commission?.value),
                delta_value: formatMoney(metrics?.commission?.delta_value),
                delta_is_positive: metrics?.commission?.delta_is_positive,
                inverse_delta: false,
              },
              {
                title: "Lead NAF",
                value: formatMoney(metrics.total_naf?.value),
                delta_value: formatMoney(metrics?.total_naf?.delta_value),
                delta_is_positive: metrics?.total_naf?.delta_is_positive,
                inverse_delta: false,
              },
              {
                title: "Settled NAF",
                value: formatMoney(metrics.settled_naf?.value),
                delta_value: formatMoney(metrics?.settled_naf?.delta_value),
                delta_is_positive: metrics?.settled_naf?.delta_is_positive,
                inverse_delta: false,
              },
              // {
              //   title: "Cumulative Brokerage",
              //   value: formatMoney(metrics.cumulative_brokerage?.value || 0),
              //   delta_value: null,
              // },
            ].map((metric, index) => (
              <tr key={index} className={`bg-gray-100 text-xs border-b`}>
                <th
                  scope="row"
                  className="px-6 py-2 font-extrabold text-md uppercase"
                >
                  {metric.title}
                </th>
                <td className="px-6 py-2">{metric.value}</td>
                <td className="px-6 py-2">
                  <div className="flex items-center text-xs">
                    {metric.delta_value}
                    {metric.delta_value !== null && (
                      <>
                        {metric.delta_is_positive ? (
                          <ChevronUp
                            fill={metric.inverse_delta ? "red" : "green"}
                          />
                        ) : (
                          <ChevronDown
                            fill={metric.inverse_delta ? "green" : "red"}
                          />
                        )}
                      </>
                    )}
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div className="pagebreak"> </div>

      {/* Performance Metrics  */}
      {/* <div className="border border-slate-300 w-full min-h-[300px] p-2 rounded-md shadow-md mb-12">
        <h3 className="mb-4 font-bold text-center text-lg uppercase">
          Performance Metrics
        </h3>
        <div className="flex">
          <div className="relative w-full overflow-x-auto">
            <table className="w-full text-sm text-left text-gray-500">
              <thead className="text-xs text-gray-700 uppercase bg-gray-50 ">
                <tr>
                  <th scope="col" className="px-6 py-3">
                    METRIC
                  </th>
                  <th scope="col" className="px-6 py-3">
                    All Leads
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Digital Leads
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Referrer Leads
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Repeat Leads
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Direct Leads
                  </th>
                </tr>
              </thead>
              <tbody>
                {performance.map((metric: any, index: any) => (
                  <>
                    
                    <tr key={index} className={`bg-gray-100 text-xs border-b`}>
                      <th
                        scope="row"
                        className="px-6 py-2 font-extrabold text-md uppercase"
                      >
                        {metric.name}
                      </th>
                      {[
                        "allLeads",
                        "digitalLeads",
                        "referrerLeads",
                        "repeatLeads",
                        "directLeads",
                      ].map((leadType) => (
                        <td key={leadType} className="px-6 py-2">
                          {metric.data[leadType as keyof MetricData].value}
                          <div className="flex items-center text-xs">
                            {metric.data[leadType as keyof MetricData].delta}
                            {metric.data[leadType as keyof MetricData].isUp ? (
                              <ChevronUp />
                            ) : (
                              <ChevronDown />
                            )}
                          </div>
                        </td>
                      ))}
                    </tr>

                    
                    {metric.brokers.map((broker: any, brokerIndex: any) => (
                      <tr
                        key={`${index}-${brokerIndex}`}
                        className={`text-xs border-b`}
                      >
                        <th scope="row" className="px-6 py-2 pl-10">
                          {broker.name}
                        </th>
                        {[
                          "allLeads",
                          "digitalLeads",
                          "referrerLeads",
                          "repeatLeads",
                          "directLeads",
                        ].map((leadType) => (
                          <td key={leadType} className="px-6 py-2">
                            {broker.data[leadType as keyof MetricData].value}
                            <div className="flex items-center text-xs">
                              {broker.data[leadType as keyof MetricData].delta}
                              {broker.data[leadType as keyof MetricData]
                                .isUp ? (
                                <ChevronUp />
                              ) : (
                                <ChevronDown />
                              )}
                            </div>
                          </td>
                        ))}
                      </tr>
                    ))}
                  </>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div className="pagebreak"> </div> */}

      {/* Settled Lender NAF  */}
      {/* <div className="border border-slate-300 w-full min-h-[300px] p-2 rounded-md shadow-md mb-12">
        <h3 className="mb-4 font-bold text-center text-lg uppercase">
          Settled Lender NAF
        </h3>
        <div className="flex">
          <div className="relative w-full overflow-x-auto">
            <table className="w-full text-sm text-left text-gray-500">
              <thead className="text-xs text-gray-700 uppercase bg-gray-50 ">
                <tr>
                  <th scope="col" className="px-6 py-3">
                    Lender
                  </th>
                  <th scope="col" className="px-6 py-3">
                    All
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Working Capital
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Consumer
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Commercial Property
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Acquisition
                  </th>
                </tr>
              </thead>
              <tbody>
                {lenders.map((lender: any, index: any) => (
                  <>
                    <tr key={index} className={`bg-gray-100 text-xs border-b`}>
                      <th
                        scope="row"
                        className="px-6 py-2 font-extrabold text-md uppercase"
                      >
                        {lender.name}
                      </th>
                      {[
                        "allLeads",
                        "workingCapital",
                        "consumer",
                        "commercialProperty",
                        "acquisition",
                      ].map((metricType: string) => (
                        <td key={metricType} className="px-6 py-4">
                          {lender.data[metricType].value}{" "}
                          <div className="flex items-center text-xs">
                            {lender.data[metricType].delta}
                            {lender.data[metricType].isUp ? (
                              <ChevronUp />
                            ) : (
                              <ChevronDown />
                            )}{" "}
                          </div>
                        </td>
                      ))}
                    </tr>

                    
                    {lender.brokers.map((broker: any, brokerIndex: any) => (
                      <tr
                        key={`${index}-${brokerIndex}`}
                        className={`text-xs border-b`}
                      >
                        <th scope="row" className="px-6 py-2 pl-10">
                          {broker.name}
                        </th>
                        {[
                          "allLeads",
                          "workingCapital",
                          "consumer",
                          "commercialProperty",
                          "acquisition",
                        ].map((leadType) => (
                          <td key={leadType} className="px-6 py-2">
                            {broker.data[leadType].value}
                            <div className="flex items-center text-xs">
                              {broker.data[leadType].delta}
                              {broker.data[leadType].isUp ? (
                                <ChevronUp />
                              ) : (
                                <ChevronDown />
                              )}
                            </div>
                          </td>
                        ))}
                      </tr>
                    ))}
                  </>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div className="pagebreak"> </div> */}

      {/* Referrer Reports */}
      {/* <div className="border border-slate-300 w-full min-h-[300px] p-2 rounded-md shadow-md mb-12">
        <h3 className="mb-4 font-bold text-center text-lg uppercase">
          Settled Product NAF
        </h3>
        <div className="flex">
          <div className="relative w-full overflow-x-auto">
            <table className="w-full text-sm text-left text-gray-500">
              <thead className="text-xs text-gray-700 uppercase bg-gray-50 ">
                <tr>
                  <th scope="col" className="px-6 py-3">
                    Product
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Lender
                  </th>
                  <th scope="col" className="px-6 py-3">
                    All
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Working Capital
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Consumer
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Commercial Property
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Acquisition
                  </th>
                </tr>
              </thead>
              <tbody>
                {products.map((product: any, index: any) => (
                  <>
                    <tr key={index} className={`bg-gray-100 text-xs border-b`}>
                      <th
                        scope="row"
                        className="px-6 py-2 font-extrabold text-md uppercase"
                      >
                        {product.name}
                      </th>
                      <td className="px-6 py-4">{product.lender}</td>
                      {[
                        "allDeals",
                        "workingCapital",
                        "consumer",
                        "commercialProperty",
                        "acquisition",
                      ].map((productType: string) => (
                        <td key={productType} className="px-6 py-4">
                          {product.data[productType as keyof ProductData].value}{" "}
                          <div className="flex items-center text-xs">
                            {
                              product.data[productType as keyof ProductData]
                                .delta
                            }{" "}
                            {product.data[productType as keyof ProductData]
                              .isUp ? (
                              <ChevronUp />
                            ) : (
                              <ChevronDown />
                            )}{" "}
                          </div>
                        </td>
                      ))}
                    </tr>
                    {product.brokers.map((broker: any, brokerIndex: any) => (
                      <tr
                        key={`${index}-${brokerIndex}`}
                        className={`text-xs border-b`}
                      >
                        <th scope="row" className="px-6 py-2 pl-10">
                          {broker.name}
                        </th>
                        <td className="px-6 py-4">{}</td>
                        {[
                          "allDeals",
                          "workingCapital",
                          "consumer",
                          "commercialProperty",
                          "acquisition",
                        ].map((leadType) => (
                          <td key={leadType} className="px-6 py-2">
                            {broker.data[leadType].value}
                            <div className="flex items-center text-xs">
                              {broker.data[leadType].delta}
                              {broker.data[leadType].isUp ? (
                                <ChevronUp />
                              ) : (
                                <ChevronDown />
                              )}
                            </div>
                          </td>
                        ))}
                      </tr>
                    ))}
                  </>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div className="pagebreak"> </div> */}

      {/* Partner Report */}
      <div className="border border-slate-300 w-full min-h-[300px] p-2 rounded-md shadow-md mb-12">
        <h3 className="mb-4 font-bold text-center text-lg uppercase">
          Referrer Performance
        </h3>
        <div className="flex">
          <div className="relative w-full overflow-x-auto">
            <table className="w-full text-sm text-left text-gray-500">
              <thead className="text-xs text-gray-700 uppercase bg-gray-50 ">
                <tr>
                  <th scope="col" className="px-6 py-3">
                    Referrer
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Manager
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Leads In
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Settled
                  </th>
                  <th scope="col" className="px-6 py-3">
                    NAF
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Brokerage
                  </th>
                </tr>
              </thead>
              <tbody>
                {referrers.map((referrer: any, index: any) => (
                  <>
                    <tr key={index} className={`bg-gray-100 text-xs border-b`}>
                      <th
                        scope="row"
                        className="px-6 py-2 font-extrabold text-md uppercase"
                      >
                        {referrer.name}
                      </th>
                      <td className="px-6 py-4">{referrer.manager}</td>
                      {["leadsIn", "settled", "naf", "brokerage"].map(
                        (_type: string) => (
                          <td key={_type} className="px-6 py-4">
                            {["naf", "brokerage"].indexOf(_type) !== -1
                              ? formatMoney(referrer.data[_type].value)
                              : referrer.data[_type].value}{" "}
                            <div className="flex items-center text-xs">
                              {["naf", "brokerage"].indexOf(_type) !== -1
                                ? formatMoney(referrer.data[_type].delta)
                                : referrer.data[_type].delta}
                              {referrer.data[_type].isUp ? (
                                <ChevronUp />
                              ) : (
                                <ChevronDown />
                              )}{" "}
                            </div>
                          </td>
                        )
                      )}
                    </tr>
                    {/* {referrer.brokers.map((broker: any, brokerIndex: any) => (
                      <tr
                        key={`${index}-${brokerIndex}`}
                        className={`text-xs border-b`}
                      >
                        <th scope="row" className="px-6 py-2 pl-10">
                          {broker.name}
                        </th>
                        <td className="px-6 py-4">{}</td>
                        {["leadsIn", "settled", "naf", "brokerage"].map(
                          (leadType) => (
                            <td key={leadType} className="px-6 py-2">
                              {broker.data[leadType].value}
                              <div className="flex items-center text-xs">
                                {broker.data[leadType].delta}
                                {broker.data[leadType].isUp ? (
                                  <ChevronUp />
                                ) : (
                                  <ChevronDown />
                                )}
                              </div>
                            </td>
                          )
                        )}
                      </tr>
                    ))} */}
                  </>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>

      {/* Broker Report */}
      <div className="border border-slate-300 w-full min-h-[300px] p-2 rounded-md shadow-md mb-12">
        <h3 className="mb-4 font-bold text-center text-lg uppercase">
          Broker Report
        </h3>
        <div className="flex">
          <div className="relative w-full overflow-x-auto">
            <table className="w-full text-sm text-left text-gray-500">
              <thead className="text-xs text-gray-700 uppercase bg-gray-50 ">
                <tr>
                  <th scope="col" className="px-6 py-3">
                    Broker
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Leads In
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Settled
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Settled NAF
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Brokerage
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Avg Referrer Payaway
                  </th>
                  {/* <th scope="col" className="px-6 py-3">
                    Avg Start Time
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Avg End Time
                  </th> */}
                </tr>
              </thead>
              <tbody>
                {brokers.map((referrer: any, index: any) => (
                  <>
                    {/* Main row */}
                    <tr key={index} className={`bg-gray-100 text-xs border-b`}>
                      <th
                        scope="row"
                        className="px-6 py-2 font-extrabold text-md uppercase"
                      >
                        {referrer.name}
                      </th>
                      {[
                        "leadsIn",
                        "settled",
                        "naf",
                        "brokerage",
                        "average_payaway_percentage",
                        // "averageDailyStartTime",
                        // "averageDailyEndTime",
                      ].map((_type: string) => (
                        <td key={_type} className="px-6 py-4">
                          {referrer.data[_type].value}{" "}
                          {/* Add type assertion here */}
                          <div className="flex items-center text-xs">
                            {referrer.data[_type].delta} {/* And here */}
                            {referrer.data[_type].isUp ? (
                              <ChevronUp />
                            ) : (
                              <ChevronDown />
                            )}{" "}
                            {/* And here */}
                          </div>
                        </td>
                      ))}
                    </tr>
                  </>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>

      {/* Asset Finance Revenue Report Table */}
      {/* <div className="border border-slate-300 w-full min-h-[300px] p-2 rounded-md shadow-md mb-12">
        <h3 className="mb-4 font-bold text-center text-md">
          Asset Finance Revenue
        </h3>
        <div className="flex">
          <div className="relative w-1/2 overflow-x-auto">
            <table className="w-full text-sm text-left text-gray-500 ">
              <thead className="text-xs text-gray-700 uppercase bg-gray-50 ">
                <tr>
                  <th scope="col" className="px-6 py-3"></th>
                  <th scope="col" className="px-6 py-3">
                    Period
                  </th>
                  <th scope="col" className="px-6 py-3">
                    YTD
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr className="bg-white border-b ">
                  <th
                    scope="row"
                    className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap "
                  >
                    Gross Revenue
                  </th>
                  <td className="px-6 py-4">$254,000</td>
                  <td className="px-6 py-4">$1,150,000</td>
                </tr>
                <tr className="bg-white border-b ">
                  <th
                    scope="row"
                    className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap "
                  >
                    Net Revenue
                  </th>
                  <td className="px-6 py-4">$203,200</td>
                  <td className="px-6 py-4">$920,000</td>
                </tr>
                <tr className="bg-white ">
                  <th
                    scope="row"
                    className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap "
                  >
                    Budget
                  </th>
                  <td className="px-6 py-4">$198,000</td>
                  <td className="px-6 py-4">$937,000</td>
                </tr>
                <tr className="bg-white ">
                  <th
                    scope="row"
                    className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap "
                  >
                    Variance
                  </th>
                  <td className="px-6 py-4">$5,200</td>
                  <td className="px-6 py-4">-$17,000</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="w-1/2">
            <ResponsiveContainer width="100%" height="100%">
              <ComposedChart
                width={500}
                height={100}
                data={data}
                margin={{
                  top: 5,
                  right: 30,
                  left: 20,
                  bottom: 5,
                }}
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="name" />
                <YAxis />
                <Tooltip />
                <Legend />
                <Bar
                  isAnimationActive={false}
                  type="monotone"
                  dataKey="pv"
                  stroke="#000000"
                  fill="#D9963D"
                />
                <Line
                  isAnimationActive={false}
                  type="monotone"
                  dataKey="uv"
                  stroke="#000000"
                />
              </ComposedChart>
            </ResponsiveContainer>
          </div>
        </div>
      </div> */}

      {/* Commercial Property Revenue Report Table */}
      {/* <div className="border border-slate-300 w-full min-h-[300px] p-2 rounded-md shadow-md mb-12">
        <h3 className="mb-4 font-bold text-center text-md">
          Commercial Property Revenue
        </h3>
        <div className="flex">
          <div className="relative w-1/2 overflow-x-auto">
            <table className="w-full text-sm text-left text-gray-500 ">
              <thead className="text-xs text-gray-700 uppercase bg-gray-50 ">
                <tr>
                  <th scope="col" className="px-6 py-3"></th>
                  <th scope="col" className="px-6 py-3">
                    Period
                  </th>
                  <th scope="col" className="px-6 py-3">
                    YTD
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr className="bg-white border-b ">
                  <th
                    scope="row"
                    className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap "
                  >
                    Gross Revenue
                  </th>
                  <td className="px-6 py-4">$254,000</td>
                  <td className="px-6 py-4">$1,150,000</td>
                </tr>
                <tr className="bg-white border-b ">
                  <th
                    scope="row"
                    className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap "
                  >
                    Net Revenue
                  </th>
                  <td className="px-6 py-4">$203,200</td>
                  <td className="px-6 py-4">$920,000</td>
                </tr>
                <tr className="bg-white ">
                  <th
                    scope="row"
                    className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap "
                  >
                    Budget
                  </th>
                  <td className="px-6 py-4">$198,000</td>
                  <td className="px-6 py-4">$937,000</td>
                </tr>
                <tr className="bg-white ">
                  <th
                    scope="row"
                    className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap "
                  >
                    Variance
                  </th>
                  <td className="px-6 py-4">$5,200</td>
                  <td className="px-6 py-4">-$17,000</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="w-1/2">
            <ResponsiveContainer width="100%" height="100%">
              <ComposedChart
                width={500}
                height={100}
                data={data}
                margin={{
                  top: 5,
                  right: 30,
                  left: 20,
                  bottom: 5,
                }}
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="name" />
                <YAxis />
                <Tooltip />
                <Legend />
                <Bar
                  isAnimationActive={false}
                  type="monotone"
                  dataKey="pv"
                  stroke="#000000"
                  fill="#D9963D"
                />
                <Line
                  isAnimationActive={false}
                  type="monotone"
                  dataKey="uv"
                  stroke="#000000"
                />
              </ComposedChart>
            </ResponsiveContainer>
          </div>
        </div>
      </div> */}

      {/* Asset Finance Lender NAF Pie Chart */}
      {/* <div className="border border-slate-300 w-full min-h-[300px] p-2 rounded-md shadow-md mb-12">
        <h3 className="mb-4 font-bold text-center text-md">
          Asset Finance Lender NAF Pie Chart
        </h3>
        <div className="flex">
          <div className="relative w-1/2 overflow-x-auto">
            <table className="w-full text-sm text-left text-gray-500 ">
              <thead className="text-xs text-gray-700 uppercase bg-gray-50 ">
                <tr>
                  <th scope="col" className="px-6 py-3">
                    Lender
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Period
                  </th>
                  <th scope="col" className="px-6 py-3">
                    YTD
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr className="bg-white border-b ">
                  <th
                    scope="row"
                    className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap "
                  >
                    Pepper Finance
                  </th>
                  <td className="px-6 py-4">$55,000</td>
                  <td className="px-6 py-4">$674,000</td>
                </tr>
                <tr className="bg-white border-b ">
                  <th
                    scope="row"
                    className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap "
                  >
                    Pepper Finance
                  </th>
                  <td className="px-6 py-4">$55,000</td>
                  <td className="px-6 py-4">$674,000</td>
                </tr>
                <tr className="bg-white border-b ">
                  <th
                    scope="row"
                    className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap "
                  >
                    Pepper Finance
                  </th>
                  <td className="px-6 py-4">$55,000</td>
                  <td className="px-6 py-4">$674,000</td>
                </tr>
                <tr className="bg-white border-b ">
                  <th
                    scope="row"
                    className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap "
                  >
                    Pepper Finance
                  </th>
                  <td className="px-6 py-4">$55,000</td>
                  <td className="px-6 py-4">$674,000</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="w-1/2">
            <ResponsiveContainer width="100%" height="100%">
              <PieChart width={730} height={250}>
                <Pie
                  data={data01}
                  dataKey="value"
                  nameKey="name"
                  cx="50%"
                  cy="50%"
                  outerRadius={50}
                  fill="#8884d8"
                />
                <Pie
                  data={data02}
                  dataKey="value"
                  nameKey="name"
                  cx="50%"
                  cy="50%"
                  innerRadius={60}
                  outerRadius={80}
                  fill="#82ca9d"
                  label
                />
              </PieChart>
            </ResponsiveContainer>
          </div>
        </div>
      </div> */}

      {/* Asset Finance Leads In Chart  */}
      {/* <div className="border border-slate-300 w-full min-h-[300px] p-2 rounded-md shadow-md mb-12">
        <h3 className="mb-4 font-bold text-center text-md">
          Asset Finance Leads In
        </h3>
        <div style={{ height: "300px" }}>
          <ResponsiveContainer width="100%" height="100%">
            <ComposedChart
              width={500}
              height={100}
              data={data}
              margin={{
                top: 5,
                right: 30,
                left: 20,
                bottom: 5,
              }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="name" />
              <YAxis />
              <Tooltip />
              <Legend />
              <Bar
                isAnimationActive={false}
                type="monotone"
                dataKey="pv"
                stroke="#000000"
                fill="#D9963D"
              />
              <Line
                isAnimationActive={false}
                type="monotone"
                dataKey="uv"
                stroke="#000000"
              />
            </ComposedChart>
          </ResponsiveContainer>
        </div>
      </div> */}

      {/* Asset Finance Broker Report */}
      {/* <div className="border border-slate-300 w-full min-h-[300px] p-2 rounded-md shadow-md mb-12">
        <h3 className="mb-4 font-bold text-center text-md">
          Asset Finance Broker Report
        </h3>
        <div className="relative overflow-x-auto">
          <table className="w-full text-sm text-left text-gray-500 ">
            <thead className="text-xs text-gray-700 uppercase bg-gray-50 ">
              <tr>
                <th scope="col" className="px-6 py-3">
                  Broker name
                </th>
                <th scope="col" className="px-6 py-3">
                  Lead NAF
                </th>
                <th scope="col" className="px-6 py-3">
                  Settled NAF
                </th>
                <th scope="col" className="px-6 py-3">
                  Gross Commissions
                </th>
              </tr>
            </thead>
            <tbody>
              <tr className="bg-white border-b ">
                <th
                  scope="row"
                  className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap "
                >
                  Joe Blogs
                </th>
                <td className="px-6 py-4">$256,000</td>
                <td className="px-6 py-4">$7,680</td>
                <td className="px-6 py-4">$540</td>
              </tr>
              <tr className="bg-white border-b ">
                <th
                  scope="row"
                  className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap "
                >
                  Joe Blogs
                </th>
                <td className="px-6 py-4">$256,000</td>
                <td className="px-6 py-4">$7,680</td>
                <td className="px-6 py-4">$540</td>
              </tr>
              <tr className="bg-white ">
                <th
                  scope="row"
                  className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap "
                >
                  Joe Blogs
                </th>
                <td className="px-6 py-4">$256,000</td>
                <td className="px-6 py-4">$7,680</td>
                <td className="px-6 py-4">$540</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>  */}
    </div>
  );
};

export default BIReport;
