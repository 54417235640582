import React, { useState, useEffect } from "react";
import RuleTable from "components/RuleTable";
import WeekDayComponent from "../WeekDayComponent";
import { put, post, destroy } from "utilities/Network";
import ReactTooltip from "react-tooltip";

const UserLeadDistributionConfigurationComponent = ({
  triggerFetch,
  userEmail,
  config,
}: any) => {
  const [rules, setRules] = useState(config);
  const [selectedRule, setSelectedRule] = useState(config[config.length - 1]);
  const [
    selectedRuleInitialConfiguration,
    setSelectedRuleInitialConfiguration,
  ] = useState(config[config.length - 1].configuration);

  const rule = JSON.parse(selectedRule.configuration);

  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());

  const [loading, setLoading] = useState(false);
  const [errored, setErrored] = useState(false);

  useEffect(() => {
    console.log("updated start and end date");
    if (!selectedRule.start_date && !selectedRule.end_date) {
      setStartDate(new Date());
      setEndDate(new Date());
    } else {
      setStartDate(new Date(selectedRule.start_date));
      setEndDate(new Date(selectedRule.end_date));
    }
  }, [selectedRule]);

  const formatDate = (date: any) => {
    const d = new Date(date);
    let month = "" + (d.getMonth() + 1);
    let day = "" + d.getDate();
    const year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [year, month, day].join("-");
  };

  // handle the update of a single rule
  const updateRule = (configuration: any, weekday: any) =>
    setSelectedRule({
      ...selectedRule,
      configuration: JSON.stringify({ ...rule, [weekday]: configuration }),
    });

  // persist changes to rule
  const saveRule = () => {
    setLoading(true);
    let optionals = {};

    // don't save the start and end date if editing the default rule
    if (!!selectedRule.start_date && !!selectedRule.end_date) {
      optionals = {
        start_date: formatDate(startDate),
        end_date: formatDate(endDate),
      };
    }
    put(`leads/distribution-configuration-rules/${selectedRule.id}/`, {
      ...selectedRule,
      ...optionals,
    })
      .then((response) => {
        setRules(
          rules.map((thisRule: any) =>
            thisRule.id == selectedRule.id ? selectedRule : thisRule
          )
        );
        setLoading(false);
        triggerFetch(); // trigger a fetch to refresh the rule
      })
      .catch((error) => {
        setLoading(false);
        setErrored(true);
      });
  };

  const removeRule = () => {
    setLoading(true);
    if (!selectedRule.start_date && !selectedRule.end_date) {
      setLoading(false);
    }
    destroy(`leads/distribution-configuration-rules/${selectedRule.id}/`)
      .then((response) => {
        setRules(
          rules.filter((thisRule: any) => thisRule.id !== selectedRule.id)
        );
        setLoading(false);
        setSelectedRule(config[config.length - 1]);
        setSelectedRuleInitialConfiguration(
          config[config.length - 1].configuration
        );
      })
      .catch((error) => {
        setLoading(false);
        setErrored(true);
      });
  };

  const saveAsNewRule = () => {
    setLoading(true);
    let thisRule = selectedRule;
    delete thisRule.id;

    thisRule = {
      ...thisRule,
      start_date: formatDate(startDate),
      end_date: formatDate(endDate),
    };

    post(`leads/distribution-configuration-rules/`, thisRule)
      .then((response) => {
        setRules(rules.concat([response.data])); // add the new rule
        triggerFetch(); // trigger a fetch to refresh the rule
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        setErrored(true);
      });
  };

  return (
    <>
      {/* <ReactTooltip /> */}
      <div className="mb-4 card border rounded-md border-slate-400 mb-2 p-2 ">
        {/* Email Header */}
        <div className="card-body" style={{ overflowX: "auto" }}>
          <h3 className="text-lg font-medium">{userEmail}</h3>
          {/* <hr />
                <h4>{"Rule Timeline"}</h4>
                
                <p>Select a lead distribution rule to configure or create a new one</p>
                
                <RuleTable rules={rules} selectedRule={selectedRule} disabled={loading} setSelectedRule={r => {
                    setSelectedRule(r)
                    setSelectedRuleInitialConfiguration(r.configuration)
                }}/>
                <br />
                <hr /> */}
          <div className="mb-3 text-right">
            {/* <button outline={!selectedRule.start_date && !selectedRule.end_date} color={'danger'} className='mr-2' onClick={removeRule} disabled={loading || !selectedRule.start_date && !selectedRule.end_date}>{loading ? "Removing..." : "Remove Rule"}</button> */}
            <button
              className="bg-slate-200 p-2 rounded-md"
              onClick={saveRule}
              disabled={loading}
            >
              {loading ? "Saving..." : "Save Rule"}
            </button>
          </div>
          <table style={{ minWidth: "100%" }}>
            <thead>
              <tr className="text-center">
                <th>{"Monday"}</th>
                <th>{"Tuesday"}</th>
                <th>{"Wednesday"}</th>
                <th>{"Thursday"}</th>
                <th>{"Friday"}</th>
                <th>{"Saturday"}</th>
                <th>{"Sunday"}</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                {[
                  "monday",
                  "tuesday",
                  "wednesday",
                  "thursday",
                  "friday",
                  "saturday",
                  "sunday",
                ].map((weekday, i) => {
                  // return <td>{JSON.stringify(rule[weekday])}</td>
                  return (
                    <td key={i}>
                      <WeekDayComponent
                        {...rule[weekday]}
                        loading={loading}
                        updateRule={(configuration: any) =>
                          updateRule(configuration, weekday)
                        }
                      />
                    </td>
                  );
                })}
              </tr>
            </tbody>
          </table>
          {/* <div className='mt-4 text-right'>
                    {
                        !selectedRule.start_date && !selectedRule.end_date ? <div className='mb-2'><small>(You're editing the default rule, there is no need to set a start and or end date)</small></div> : null
                    }
                    
                    <input disabled={loading} type="date" className='mr-2' data-tip="start date" value={startDate.toString()} onChange={e => setStartDate(e.target.value)}/>
                    <input disabled={loading} type="date" data-tip="end date" value={endDate.toString()} onChange={e => setEndDate(e.target.value)}/>
                    <div>
                        <small>(end date is exclusive)</small>
                    </div>
                    <button outline color="success" className='mt-2' onClick={saveAsNewRule} disabled={loading}>Save As New Rule</button>
                    
                    
                </div> */}
        </div>
      </div>
    </>
  );
};

export default UserLeadDistributionConfigurationComponent;
