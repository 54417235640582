import React, { useEffect, useState } from "react";
import SideMenu from "components/layout/SideMenu";
import { useNavigate, useLocation } from "react-router-dom";
import axios from "axios";
import { Container, PageContainer } from "./AppContainer.styled";
import { ReactComponent as DashboardIcon } from "assets/icons/layout.svg";
import { ReactComponent as usersIcon } from "assets/icons/users.svg";
import { ReactComponent as reportsIcon } from "assets/icons/trending-up.svg";
import { ReactComponent as settlementsIcon } from "assets/icons/coin-stack.svg";
import { ReactComponent as lendersIcon } from "assets/icons/coins.svg";
import { ReactComponent as partnersIcon } from "assets/icons/hexagon.svg";
import { ReactComponent as dealsIcon } from "assets/icons/zap.svg";
import { ReactComponent as taskIcon } from "assets/icons/clipboard.svg";
import { ReactComponent as groupsIcon } from "assets/icons/globe.svg";
import { ReactComponent as organisationIcon } from "assets/icons/list.svg";
import { ReactComponent as applicantsIcon } from "assets/icons/users.svg";
import { ReactComponent as distributionIcon } from "assets/icons/share-2.svg";
import { ReactComponent as activitiesIcon } from "assets/icons/calendar.svg";

import {
  getLocalStorageItem,
  setLocalStorageItem,
} from "utilities/LocalStorage";

import { clearSession } from "utilities/Authentication";

export type AppContainerProps = {
  children?: JSX.Element;
};

const locationsToHideSideMenu = [
  "/login",
  "/referral",
  "/redirect",
  "/reset-password/",
  "/reset-mfa/",
  "/challenge-mfa/",
];
const noAuthLocations = [
  "/login",
  "/referral",
  "/redirect",
  "/test",
  "/reset-password/",
  "/reset-mfa/",
  "/challenge-mfa/",
];

const AppContainer: React.FC<AppContainerProps> = ({ children }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const [isAdmin, setIsAdmin] = useState(false);
  const [loading, setLoading] = useState(true);
  const [collapsed, setCollapsed] = useState(true);

  const [sideMenuHidden, setSideMenuHidden] = useState(
    locationsToHideSideMenu.indexOf(location.pathname) !== -1
  );

  useEffect(() => {
    setSideMenuHidden(
      locationsToHideSideMenu.indexOf(location.pathname) !== -1 ||
        location.pathname.indexOf("/report/") !== -1 ||
        location.pathname.indexOf("/pdf/") !== -1
    );

    // check auth
    let auth;
    if (false && !!process.env.REACT_APP_AUTH_OVERRIDE) {
      auth = {
        token: process.env.REACT_APP_AUTH_OVERRIDE,
        scope: ["dashboard-administrator"],
        user: {
          name: {
            first: "Alexander",
            last: "Collins",
          },
          email: "alex@finstead.com.au",
          profile_picture_url: "https://cdn.finstead.com.au/profiles/alex.jpg",
          id: 1,
        },
      };
      setLocalStorageItem("auth", auth);
    } else {
      auth = getLocalStorageItem("auth");
    }

    if (auth !== undefined && auth !== null) {
      // authenticated
      axios.defaults.headers.common.authorization = auth?.token;
      setLoading(false);
    } else if (
      noAuthLocations.indexOf(location.pathname) === -1 &&
      location.pathname.indexOf("/report/") === -1 &&
      location.pathname.indexOf("/pdf/") === -1
    ) {
      // if not authenticated and on an authenticated route
      clearSession();
      console.log(`noAuthLocations: ${noAuthLocations}`);
      console.log(`location.pathname: ${location.pathname}`);
      if (noAuthLocations.indexOf(location.pathname) === -1) {
        navigate("/login");
      }
      setLoading(false);
    } else {
      setLoading(false);
    }

    const adminPerm = auth?.scope?.indexOf("dashboard-administrator");
    setIsAdmin(!(adminPerm === undefined || adminPerm === -1));
  }, [location.pathname, navigate]);

  if (
    location.pathname.indexOf("/report/") !== -1 ||
    location.pathname.indexOf("/pdf/") !== -1
  ) {
    return <>{children}</>;
  }

  // let preNavItems = [];
  // if (isAdmin) {
  //   preNavItems = [

  //   ];
  // } else {
  //   preNavItems = [
  //     {
  //       title: "Dashboard",
  //       icon_source: DashboardIcon,
  //       path: "/dashboard",
  //     },
  //     {
  //       title: "Deals",
  //       icon_source: dealsIcon,
  //       path: "/",
  //     },
  //   ];
  // }

  return (
    <Container>
      <SideMenu
        setCollapsed={setCollapsed}
        collapsed={collapsed}
        hidden={sideMenuHidden}
        nav_item_props={[
          // ...preNavItems,
          {
            title: !!isAdmin ? "Admin Dashboard" : "Dashboard",
            icon_source: DashboardIcon,
            path: "/",
          },
          {
            title: "Deals",
            icon_source: dealsIcon,
            path: "/deals",
          },
          {
            title: "Activities",
            icon_source: activitiesIcon,
            path: "/activities",
          },
          // {
          //   title: "Tasks",
          //   icon_source: taskIcon,
          //   path: "/tasks",
          // },
          {
            title: "Partners",
            icon_source: partnersIcon,
            path: "/partners",
          },
          {
            title: "Organisations",
            icon_source: organisationIcon,
            path: "/organisations",
          },
          {
            title: "Applicants",
            icon_source: applicantsIcon,
            path: "/applicants",
          },
          {
            title: "Lenders & Product",
            icon_source: lendersIcon,
            path: "/lenders",
          },
          {
            title: "Settlements",
            icon_source: settlementsIcon,
            path: "/settlements",
          },
          ...(isAdmin
            ? [
                {
                  title: "Lead Distribution",
                  icon_source: distributionIcon,
                  path: "/lead-distribution",
                },
                {
                  title: "Reports",
                  icon_source: reportsIcon,
                  path: "/reports",
                },
                // {
                //   title: "Team",
                //   icon_source: groupsIcon,
                //   path: "/team",
                // },
                // {
                //   title: "Security",
                //   icon_source: SecurityIcon,
                //   path: "/security",
                // },
              ]
            : []),

          // {
          //   title: "Forms",
          //   icon_source: formIcon,
          //   path: "/forms",
          // },
          // {
          //   title: "Team Groups",
          //   icon_source: groupsIcon,
          //   path: "/groups",
          // },
          // {
          //   title: "Team",
          //   icon_source: usersIcon,
          //   path: "/team",
          // },
          // {
          //   title: "Mobile App",
          //   icon_source: MobilePhoneIcon,
          //   path: "/mobile-app",
          // },
          // {
          //   title: "Settings",
          //   icon_source: SettingsIcon,
          //   path: "/settings",
          // },
        ]}
      />
      <PageContainer
        wide={sideMenuHidden}
        noMargin={sideMenuHidden}
        collapsed={collapsed}
      >
        {!loading && children}
      </PageContainer>
    </Container>
  );
};

export default AppContainer;
