import React from "react";
import { ErrorMessage } from "components/inputs/InputField/InputField.styled";
import {
  Label,
  InputWithLabel,
  InputFieldBase,
  Wrapper,
} from "./InputFieldWrapper.styled";

type InputFieldWrapperProps = {
  label?: string;
  errorMessage?: string;
  required?: boolean;
  children: JSX.Element;
};
const InputFieldWrapper: React.FC<InputFieldWrapperProps> = ({
  label,
  errorMessage,
  required = false,
  children,
}) => {
  return (
    <>
      <Wrapper>
        <InputFieldBase>
          <InputWithLabel>
            {label && (
              <Label className="text-bold">
                {label} {required && "*"}
              </Label>
            )}
            {children}
          </InputWithLabel>
        </InputFieldBase>
      </Wrapper>
      <ErrorMessage>{errorMessage}</ErrorMessage>
    </>
  );
};

export default InputFieldWrapper;
