import React, { useState, useEffect } from "react";
import { get } from "utilities/Network";
import { promiseEnums } from "utilities/Enums";
import constants from "styles/constants";
import PaginationBar from "components/core/PaginationBar";
import LoadingBar from "react-top-loading-bar";
import BasicTableCell from "./components/BasicTableCell";
import classNames from "classnames";
import {
  TH,
  THContentWrapper,
  THText,
  TR,
  THR,
  TBody,
  THead,
  Table,
  TableContainer,
} from "./TableComponent.styled";
import LoaderLogo from "../LoaderLogo";
import { TableMetaProps } from "./types";

export type TableComponentProps = {
  title?: string;
  fields: any;
  endpoint: string;
  filter?: string;
  loading?: boolean;
  onRowClick?: (row: any, newTab?: boolean) => void;
  triggerReload?: boolean;
  textColourOverride?: boolean;
};

type SortedColumnType = {
  field: string;
  order: boolean;
};

const TableComponent: React.FC<TableComponentProps> = ({
  title,
  fields,
  filter,
  endpoint,
  onRowClick,
  triggerReload,
  textColourOverride,
}) => {
  const [data, setData] = useState<TableMetaProps>();
  const [firstLoad, setFirstLoad] = useState<boolean>(true);
  const [tableState, setTableState] = useState(promiseEnums.ready);
  const [loading, setLoading] = useState(true);
  const [progress, setProgress] = useState(0);
  const [page, setPage] = useState(0);
  const [sortedColumn, setSortedColumn] = useState<
    SortedColumnType | undefined
  >();

  const updateRow = (newRow: any, rowIndex: any) => {
    if (!!data) {
      setData({
        ...data,
        results: [
          ...data?.results.slice(0, rowIndex),
          {
            ...data?.results[rowIndex],
            ...newRow,
          },
          ...data?.results.slice(rowIndex + 1),
        ],
      });
    }
  };

  const updateSortedColumn = (fieldKey: string) => {
    if (!!sortedColumn && sortedColumn.field === fieldKey) {
      if (!!sortedColumn.order) {
        setSortedColumn(undefined);
      } else {
        setSortedColumn({ ...sortedColumn, order: !sortedColumn.order });
      }
    } else {
      setSortedColumn({ field: fieldKey, order: false });
    }
  };

  useEffect(() => {
    setLoading(tableState === promiseEnums.pending);
  }, [tableState]);

  useEffect(() => {
    console.log("reload triggered");
    if (firstLoad) {
      setTableState(promiseEnums.pending);
    }
    setProgress(75);
    get(endpoint + "?" + (filter || "") + `&offset=${page * 20}`)
      .then((response) => {
        setData(response.data);
        setTableState(promiseEnums.success);
        setFirstLoad(false);
        setLoading(false);
        setProgress(100);
        setTimeout(() => {
          setProgress(0);
        }, 300);
      })
      .catch(() => {
        setTableState(promiseEnums.errored);
      });
  }, [endpoint, filter, firstLoad, page, triggerReload]);

  return (
    <TableContainer>
      {loading && (
        <LoadingBar
          color={constants.colors.primary_600}
          progress={progress}
          onLoaderFinished={() => setProgress(-1)}
          loaderSpeed={1000}
        />
      )}
      {/* Header */}
      {/* <CardHeaderWrapper>
        <Content>
          <TextAndBadge>
            <Text>{title}</Text>
            <Badge>
              <BadgeBase>
                <Text1>
                  {data?.count ? recordCountDescriptionFunc(data?.count) : ""}
                </Text1>
              </BadgeBase>
            </Badge>
          </TextAndBadge>
        </Content>

        <Divider />
      </CardHeaderWrapper> */}

      {/* Table Content */}
      {/* <Divider /> */}

      <Table>
        <THead>
          <THR>
            {Object.keys(fields).map((fieldKey) => (
              <TH>
                <THContentWrapper>
                  <THText onClick={() => updateSortedColumn(fieldKey)}>
                    {fields[fieldKey].name}
                    {/* {!!sortedColumn &&
                      fieldKey === sortedColumn.field &&
                      (sortedColumn.order ? (
                        <ChevronUp
                          style={{
                            margin: "0px",
                            display: "inline",
                            position: "relative",
                            width: "20px",
                            height: "20px",
                          }}
                        />
                      ) : (
                        <ChevronDown
                          style={{
                            margin: "0px",
                            display: "inline",
                            position: "relative",
                            width: "20px",
                            height: "20px",
                          }}
                        />
                      ))} */}
                  </THText>
                </THContentWrapper>
              </TH>
            ))}
          </THR>
        </THead>

        {!loading && (
          <TBody>
            {data?.results.map((row, rowIndex) => (
              <TR
                onAuxClick={() => !!onRowClick && onRowClick(row, true)}
                {...(!!onRowClick
                  ? {
                      onClick: (e) => {
                        onRowClick(row);
                      },
                    }
                  : {})}
                style={
                  textColourOverride ? { color: row.text_colour_override } : {}
                }
                pointer={onRowClick !== undefined}
              >
                {/* {row?.textColourOverride} */}
                {Object.keys(fields).map((key) =>
                  !!fields[key].middleware ? (
                    typeof fields[key].middleware(row) === "string" ||
                    fields[key].useCell ? (
                      <BasicTableCell
                        text={fields[key].middleware(row, (updates: any) => {
                          updateRow(updates, rowIndex);
                        })}
                        color={
                          textColourOverride ? row.text_colour_override : null
                        }
                      />
                    ) : (
                      fields[key].middleware(row, (updates: any) => {
                        updateRow(updates, rowIndex);
                      })
                    )
                  ) : (
                    <BasicTableCell
                      text={row[key] || "-"}
                      color={
                        textColourOverride ? row.text_colour_override : null
                      }
                    />
                  )
                )}
              </TR>
            ))}
          </TBody>
        )}
      </Table>

      {loading && (
        <div className="flex justify-center w-full ">
          <LoaderLogo />
        </div>
      )}

      {/* Pagination */}
      {data && (
        <PaginationBar
          pageCount={data.page_count}
          limit={data.limit}
          page={data.page + 1}
          count={data.count}
          pageSize={data.page_size}
          offset={data.offset}
          setPage={setPage}
        />
      )}
    </TableContainer>
  );
};

export default TableComponent;
