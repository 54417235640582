import { useEffect, useRef, useState } from "react";
import Button from "components/core/Button";
import { Form } from "pages/NoAuthWrapperPage/LoginPage.styled";
import QRCode from "qrcode";
import AuthCode from "react-auth-code-input";
import Lottie from "react-lottie-player";
import * as MFASetup from "./MFASetup.json";
import useQuery from "hooks/useQuery";
import { post } from "utilities/Network";
import { useNavigate } from "react-router-dom";
import { setLocalStorageItem } from "utilities/LocalStorage";
import axios from "axios";

type SetupMFAProps = {
  isPartnerPortal?: boolean;
};

const SetupMFA = ({ isPartnerPortal }: SetupMFAProps) => {
  const navigate = useNavigate();
  const query = useQuery();
  const token = query.get("token");
  const mfaUri = query.get("mfaUri");
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);
  const authRef = useRef<any>();

  const canvas = useRef<any>();
  const [setupComplete, setSetupComplete] = useState<boolean>(false);
  const [code, setCode] = useState<string>();

  const handleOnChange = (res: string) => {
    setCode(res);
  };

  useEffect(() => {
    if (!code || code.length !== 6 || !token) {
      return;
    }
    setErrorMessage(null);
    setLoading(true);
    let verifyMFAUrl = "authentication/verify-mfa/";
    if (isPartnerPortal) {
      verifyMFAUrl = "authentication/partners/verify-mfa/";
    }
    post(verifyMFAUrl, {
      token,
      code,
    })
      .then((response) => {
        setLoading(false);
        setSetupComplete(true);
        setLocalStorageItem("auth", response.data);
        axios.defaults.headers.common.authorization = response.data.token;
        navigate("/");
      })
      .catch((response) => {
        setLoading(false);
        setCode("");
        console.log("authref");
        console.log(authRef.current);
        authRef.current.clear();
        authRef.current.focus();
        setErrorMessage("Invalid code, please try again.");
      });
  }, [code, token, navigate, isPartnerPortal]);

  useEffect(() => {
    console.log(window.atob(mfaUri as any));
    if (!!canvas.current) {
      QRCode.toCanvas(
        canvas.current,
        window.atob(mfaUri as any),
        function (error: any) {
          if (error) console.error(error);
          console.log("success!");
        }
      );
    }
  }, [mfaUri]);

  if (setupComplete) {
    return (
      <>
        <Form>
          <div className="w-full text-center text-sm mb-10">
            <h1 className="text-lg font-bold mb-2">
              Multi Factor Authentication Setup Success!
            </h1>
            <div className="w-full flex items-center justify-center">
              <Lottie
                animationData={MFASetup}
                className="w-[200px] h-full relative top-0 left-0"
                loop={false}
                play={true}
              />
            </div>
            <p>
              Well done, you've successfully setup MFA for your Finstead
              account. This will help to keep your account secure.
            </p>
            <Button
              className={"mt-8"}
              primary
              text="Continue To Dashboard"
              wide
              disabled={loading}
              onClick={confirm}
            ></Button>
          </div>
        </Form>
      </>
    );
  }

  return (
    <>
      <Form>
        <div className="w-full text-center text-sm mb-10">
          <h1 className="text-lg font-bold mb-2">
            Multi Factor Authentication Setup
          </h1>
          <p>
            Please Scan 2FA QR Code with your authenticator app and then enter a
            code to confirm.
          </p>
        </div>
        <div className="w-full h-full flex items-center justify-center mb-10">
          <canvas ref={canvas} />
        </div>
      </Form>
      <div>
        <AuthCode
          containerClassName="w-full h-[20px] mb-10 flex gap-2 m-auto justify-center"
          inputClassName="w-[35px] h-[35px] border border-2-black text-center"
          allowedCharacters="numeric"
          onChange={handleOnChange}
          ref={authRef}
          disabled={loading}
        />
      </div>
      <div className="w-full text-center">
        {!!errorMessage && <p className="text-red-600">{errorMessage}</p>}
      </div>
    </>
  );
};

export default SetupMFA;
