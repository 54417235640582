import React from "react";
import { DataReport } from "types";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  BarChart,
  Pie,
  ComposedChart,
  Bar,
  PieChart,
} from "recharts";

const data01 = [
  {
    name: "Group A",
    value: 400,
  },
  {
    name: "Group B",
    value: 300,
  },
  {
    name: "Group C",
    value: 300,
  },
  {
    name: "Group D",
    value: 200,
  },
  {
    name: "Group E",
    value: 278,
  },
  {
    name: "Group F",
    value: 189,
  },
];
const data02 = [
  {
    name: "Group A",
    value: 2400,
  },
  {
    name: "Group B",
    value: 4567,
  },
  {
    name: "Group C",
    value: 1398,
  },
  {
    name: "Group D",
    value: 9800,
  },
  {
    name: "Group E",
    value: 3908,
  },
  {
    name: "Group F",
    value: 4800,
  },
];

const data = [
  {
    name: "July",
    uv: 4000,
    pv: 2400,
  },
  {
    name: "August",
    uv: 3000,
    pv: 1398,
  },
  {
    name: "September",
    uv: 7000,
    pv: 9800,
  },
  {
    name: "October",
    uv: 6000,
    pv: 5360,
  },
  {
    name: "November",
    uv: 15020,
    pv: 15020,
  },
  {
    name: "December",
    uv: 14000,
    pv: 13670,
  },
];

type Props = {
  report: DataReport;
};

const DemoReport: React.FC<Props> = ({ report }) => {
  return (
    <>
      <div className="w-full p-2 mb-12 border rounded-md shadow-md border-slate-300">
        <h3 className="mb-4 font-bold text-center text-md">Report Statement</h3>
        <p>
          This is a demo report. It uses real data and is an exhibition of our
          systems capabilities.
        </p>
      </div>

      {/* Group Revenue Report Table */}
      <div className="border border-slate-300 w-full min-h-[300px] p-2 rounded-md shadow-md mb-12">
        <h3 className="mb-4 font-bold text-center text-md">Group Revenue</h3>
        <div className="flex">
          <div className="relative w-1/2 overflow-x-auto">
            <table className="w-full text-sm text-left text-gray-500">
              <thead className="text-xs text-gray-700 uppercase bg-gray-50 ">
                <tr>
                  <th scope="col" className="px-6 py-3"></th>
                  <th scope="col" className="px-6 py-3">
                    Period
                  </th>
                  <th scope="col" className="px-6 py-3">
                    YTD
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr className="bg-white border-b">
                  <th
                    scope="row"
                    className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap"
                  >
                    Gross Revenue
                  </th>
                  <td className="px-6 py-4">$254,000</td>
                  <td className="px-6 py-4">$1,150,000</td>
                </tr>
                <tr className="bg-white border-b ">
                  <th
                    scope="row"
                    className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap"
                  >
                    Net Revenue
                  </th>
                  <td className="px-6 py-4">$203,200</td>
                  <td className="px-6 py-4">$920,000</td>
                </tr>
                <tr className="bg-white ">
                  <th
                    scope="row"
                    className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap"
                  >
                    Budget
                  </th>
                  <td className="px-6 py-4">$198,000</td>
                  <td className="px-6 py-4">$937,000</td>
                </tr>
                <tr className="bg-white ">
                  <th
                    scope="row"
                    className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap "
                  >
                    Variance
                  </th>
                  <td className="px-6 py-4">$5,200</td>
                  <td className="px-6 py-4">-$17,000</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="w-1/2">
            <ResponsiveContainer width="100%" height="100%">
              <ComposedChart
                width={500}
                height={100}
                data={data}
                margin={{
                  top: 5,
                  right: 30,
                  left: 20,
                  bottom: 5,
                }}
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="name" />
                <YAxis />
                <Tooltip />
                <Legend />
                <Bar
                  isAnimationActive={false}
                  type="monotone"
                  dataKey="pv"
                  stroke="#000000"
                  fill="#D9963D"
                />
                <Line
                  isAnimationActive={false}
                  type="monotone"
                  dataKey="uv"
                  stroke="#000000"
                />
              </ComposedChart>
            </ResponsiveContainer>
          </div>
        </div>
      </div>

      {/* Asset Finance Revenue Report Table */}
      <div className="border border-slate-300 w-full min-h-[300px] p-2 rounded-md shadow-md mb-12">
        <h3 className="mb-4 font-bold text-center text-md">
          Asset Finance Revenue
        </h3>
        <div className="flex">
          <div className="relative w-1/2 overflow-x-auto">
            <table className="w-full text-sm text-left text-gray-500 ">
              <thead className="text-xs text-gray-700 uppercase bg-gray-50 ">
                <tr>
                  <th scope="col" className="px-6 py-3"></th>
                  <th scope="col" className="px-6 py-3">
                    Period
                  </th>
                  <th scope="col" className="px-6 py-3">
                    YTD
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr className="bg-white border-b ">
                  <th
                    scope="row"
                    className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap "
                  >
                    Gross Revenue
                  </th>
                  <td className="px-6 py-4">$254,000</td>
                  <td className="px-6 py-4">$1,150,000</td>
                </tr>
                <tr className="bg-white border-b ">
                  <th
                    scope="row"
                    className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap "
                  >
                    Net Revenue
                  </th>
                  <td className="px-6 py-4">$203,200</td>
                  <td className="px-6 py-4">$920,000</td>
                </tr>
                <tr className="bg-white ">
                  <th
                    scope="row"
                    className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap "
                  >
                    Budget
                  </th>
                  <td className="px-6 py-4">$198,000</td>
                  <td className="px-6 py-4">$937,000</td>
                </tr>
                <tr className="bg-white ">
                  <th
                    scope="row"
                    className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap "
                  >
                    Variance
                  </th>
                  <td className="px-6 py-4">$5,200</td>
                  <td className="px-6 py-4">-$17,000</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="w-1/2">
            <ResponsiveContainer width="100%" height="100%">
              <ComposedChart
                width={500}
                height={100}
                data={data}
                margin={{
                  top: 5,
                  right: 30,
                  left: 20,
                  bottom: 5,
                }}
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="name" />
                <YAxis />
                <Tooltip />
                <Legend />
                <Bar
                  isAnimationActive={false}
                  type="monotone"
                  dataKey="pv"
                  stroke="#000000"
                  fill="#D9963D"
                />
                <Line
                  isAnimationActive={false}
                  type="monotone"
                  dataKey="uv"
                  stroke="#000000"
                />
              </ComposedChart>
            </ResponsiveContainer>
          </div>
        </div>
      </div>

      {/* Commercial Property Revenue Report Table */}
      <div className="border border-slate-300 w-full min-h-[300px] p-2 rounded-md shadow-md mb-12">
        <h3 className="mb-4 font-bold text-center text-md">
          Commercial Property Revenue
        </h3>
        <div className="flex">
          <div className="relative w-1/2 overflow-x-auto">
            <table className="w-full text-sm text-left text-gray-500 ">
              <thead className="text-xs text-gray-700 uppercase bg-gray-50 ">
                <tr>
                  <th scope="col" className="px-6 py-3"></th>
                  <th scope="col" className="px-6 py-3">
                    Period
                  </th>
                  <th scope="col" className="px-6 py-3">
                    YTD
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr className="bg-white border-b ">
                  <th
                    scope="row"
                    className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap "
                  >
                    Gross Revenue
                  </th>
                  <td className="px-6 py-4">$254,000</td>
                  <td className="px-6 py-4">$1,150,000</td>
                </tr>
                <tr className="bg-white border-b ">
                  <th
                    scope="row"
                    className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap "
                  >
                    Net Revenue
                  </th>
                  <td className="px-6 py-4">$203,200</td>
                  <td className="px-6 py-4">$920,000</td>
                </tr>
                <tr className="bg-white ">
                  <th
                    scope="row"
                    className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap "
                  >
                    Budget
                  </th>
                  <td className="px-6 py-4">$198,000</td>
                  <td className="px-6 py-4">$937,000</td>
                </tr>
                <tr className="bg-white ">
                  <th
                    scope="row"
                    className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap "
                  >
                    Variance
                  </th>
                  <td className="px-6 py-4">$5,200</td>
                  <td className="px-6 py-4">-$17,000</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="w-1/2">
            <ResponsiveContainer width="100%" height="100%">
              <ComposedChart
                width={500}
                height={100}
                data={data}
                margin={{
                  top: 5,
                  right: 30,
                  left: 20,
                  bottom: 5,
                }}
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="name" />
                <YAxis />
                <Tooltip />
                <Legend />
                <Bar
                  isAnimationActive={false}
                  type="monotone"
                  dataKey="pv"
                  stroke="#000000"
                  fill="#D9963D"
                />
                <Line
                  isAnimationActive={false}
                  type="monotone"
                  dataKey="uv"
                  stroke="#000000"
                />
              </ComposedChart>
            </ResponsiveContainer>
          </div>
        </div>
      </div>

      {/* Asset Finance Lender NAF Pie Chart */}
      <div className="border border-slate-300 w-full min-h-[300px] p-2 rounded-md shadow-md mb-12">
        <h3 className="mb-4 font-bold text-center text-md">
          Asset Finance Lender NAF Pie Chart
        </h3>
        <div className="flex">
          <div className="relative w-1/2 overflow-x-auto">
            <table className="w-full text-sm text-left text-gray-500 ">
              <thead className="text-xs text-gray-700 uppercase bg-gray-50 ">
                <tr>
                  <th scope="col" className="px-6 py-3">
                    Lender
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Period
                  </th>
                  <th scope="col" className="px-6 py-3">
                    YTD
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr className="bg-white border-b ">
                  <th
                    scope="row"
                    className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap "
                  >
                    Pepper Finance
                  </th>
                  <td className="px-6 py-4">$55,000</td>
                  <td className="px-6 py-4">$674,000</td>
                </tr>
                <tr className="bg-white border-b ">
                  <th
                    scope="row"
                    className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap "
                  >
                    Pepper Finance
                  </th>
                  <td className="px-6 py-4">$55,000</td>
                  <td className="px-6 py-4">$674,000</td>
                </tr>
                <tr className="bg-white border-b ">
                  <th
                    scope="row"
                    className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap "
                  >
                    Pepper Finance
                  </th>
                  <td className="px-6 py-4">$55,000</td>
                  <td className="px-6 py-4">$674,000</td>
                </tr>
                <tr className="bg-white border-b ">
                  <th
                    scope="row"
                    className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap "
                  >
                    Pepper Finance
                  </th>
                  <td className="px-6 py-4">$55,000</td>
                  <td className="px-6 py-4">$674,000</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="w-1/2">
            <ResponsiveContainer width="100%" height="100%">
              <PieChart width={730} height={250}>
                <Pie
                  data={data01}
                  dataKey="value"
                  nameKey="name"
                  cx="50%"
                  cy="50%"
                  outerRadius={50}
                  fill="#8884d8"
                />
                <Pie
                  data={data02}
                  dataKey="value"
                  nameKey="name"
                  cx="50%"
                  cy="50%"
                  innerRadius={60}
                  outerRadius={80}
                  fill="#82ca9d"
                  label
                />
              </PieChart>
            </ResponsiveContainer>
          </div>
        </div>
      </div>

      {/* Asset Finance Leads In Chart  */}
      <div className="border border-slate-300 w-full min-h-[300px] p-2 rounded-md shadow-md mb-12">
        <h3 className="mb-4 font-bold text-center text-md">
          Asset Finance Leads In
        </h3>
        <div style={{ height: "300px" }}>
          <ResponsiveContainer width="100%" height="100%">
            <ComposedChart
              width={500}
              height={100}
              data={data}
              margin={{
                top: 5,
                right: 30,
                left: 20,
                bottom: 5,
              }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="name" />
              <YAxis />
              <Tooltip />
              <Legend />
              <Bar
                isAnimationActive={false}
                type="monotone"
                dataKey="pv"
                stroke="#000000"
                fill="#D9963D"
              />
              <Line
                isAnimationActive={false}
                type="monotone"
                dataKey="uv"
                stroke="#000000"
              />
            </ComposedChart>
          </ResponsiveContainer>
        </div>
      </div>

      {/* Asset Finance Broker Report */}
      <div className="border border-slate-300 w-full min-h-[300px] p-2 rounded-md shadow-md mb-12">
        <h3 className="mb-4 font-bold text-center text-md">
          Asset Finance Broker Report
        </h3>
        <div className="relative overflow-x-auto">
          <table className="w-full text-sm text-left text-gray-500 ">
            <thead className="text-xs text-gray-700 uppercase bg-gray-50 ">
              <tr>
                <th scope="col" className="px-6 py-3">
                  Broker name
                </th>
                <th scope="col" className="px-6 py-3">
                  Lead NAF
                </th>
                <th scope="col" className="px-6 py-3">
                  Settled NAF
                </th>
                <th scope="col" className="px-6 py-3">
                  Gross Commissions
                </th>
              </tr>
            </thead>
            <tbody>
              <tr className="bg-white border-b ">
                <th
                  scope="row"
                  className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap "
                >
                  Joe Blogs
                </th>
                <td className="px-6 py-4">$256,000</td>
                <td className="px-6 py-4">$7,680</td>
                <td className="px-6 py-4">$540</td>
              </tr>
              <tr className="bg-white border-b ">
                <th
                  scope="row"
                  className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap "
                >
                  Joe Blogs
                </th>
                <td className="px-6 py-4">$256,000</td>
                <td className="px-6 py-4">$7,680</td>
                <td className="px-6 py-4">$540</td>
              </tr>
              <tr className="bg-white ">
                <th
                  scope="row"
                  className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap "
                >
                  Joe Blogs
                </th>
                <td className="px-6 py-4">$256,000</td>
                <td className="px-6 py-4">$7,680</td>
                <td className="px-6 py-4">$540</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};

export default DemoReport;
