import styled from "styled-components";
import { TextSmMedium, TextSmNormal } from "styles/mixins";

export const SidebarNavigationWrapper = styled.aside`
  display: flex;
  background: rgba(0, 0, 0, 1); //rgba(16, 24, 40, 1);
  overflow: hidden;
  width: 280px;
  height: 100vh;
  align-self: stretch;
  align-items: flex-start;
  position: fixed;

  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
`;
export const Divider = styled.div`
  display: flex;
  width: 1px;
  height: 100%;
`;
export const FooterDivider = styled.div`
  display: flex;
  width: 247px;
  height: 1px;
`;
export const Styled1 = styled.svg`
  fill: ${({ theme }) => theme.colors.primary_600};
  width: 1px;
  height: 100%;
`;
export const StyledSvg = styled.svg`
  fill: ${({ theme }) => theme.colors.gray_600};
  width: 247px;
  height: 1px;
`;
export const StyledPath = styled.path``;
export const ContentWrapper = styled.div`
  display: flex;
  align-self: stretch;
  flex-grow: 1;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
`;
export const Nav = styled.div`
  display: flex;
  align-self: stretch;
  flex-direction: column;
  align-items: flex-start;
  padding: 32px 0px 0px 0px;
  gap: 24px;
`;
export const Header = styled.div`
  display: flex;
  align-self: stretch;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px 20px 0px 24px;
`;
export const Navigation = styled.div`
  display: flex;
  align-self: stretch;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px 16px 0px 16px;
  gap: 4px;
`;
export const Footer = styled.div`
  display: flex;
  align-self: stretch;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px 16px 32px 16px;
  gap: 24px;
`;
export const Account = styled.div`
  display: flex;
  align-self: stretch;
  justify-content: space-between;
  align-items: flex-start;
  padding: 0px 8px 0px 8px;
  gap: 47px;
`;
export const AvatarLabelGroup = styled.div`
  display: flex;
  width: fit-content;
  align-items: center;
  gap: 12px;
`;
export const Icon = styled.div`
  display: block;
  position: relative;
`;
export const Button = styled.div`
  display: flex;
  position: absolute;
  top: -8px;
  left: -8px;
  border-radius: 8px;
  width: fit-content;
  align-items: flex-start;
`;
export const ButtonBase = styled.div`
  display: flex;
  border-radius: 8px;
  overflow: hidden;
  width: fit-content;
  justify-content: center;
  align-items: center;
  padding: 8px 8px 8px 8px;
  gap: 8px;
`;
export const TextAndSupportingText = styled.div`
  display: flex;
  width: fit-content;
  flex-direction: column;
  align-items: flex-start;
`;
export const Text = styled.span`
  text-align: left;
  white-space: nowrap;
  color: ${({ theme }) => theme.colors.white};

  ${TextSmMedium}
`;
export const SupportingText = styled.span`
  text-align: left;
  white-space: nowrap;
  color: ${({ theme }) => theme.colors.gray_100};

  ${TextSmNormal}
`;
