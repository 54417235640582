import React from "react";

type InfoBoxProps = {
  title: string;
  children: any;
  icon: any;
  w?: any;
};

export const Box: React.FC<InfoBoxProps> = ({
  title,
  icon,
  children,
  w = "300px",
}) => {
  return (
    <div
      className={`max-w-[100%] w-[${w}] min-h-[150px] mb-2 p-2 rounded-md border border-slate-300`}
    >
      <div className="flex mb-2">
        <div className="inline-block mr-2 pt-[2px]">{icon}</div>
        <div className="inline-block h-[100%] pt-1">
          <h2 className="inline font-bold">{title}</h2>
        </div>
      </div>

      <hr />
      <div className="w-full h-full p-2">{children}</div>
    </div>
  );
};

export default Box;
