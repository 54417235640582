import React, { useEffect, useState, useCallback } from "react";
import constants from "styles/constants";
import {
  LineChart,
  AreaChart,
  Area,
  CartesianGrid,
  XAxis,
  YAxis,
  Legend,
  Tooltip,
  Line,
  ResponsiveContainer,
} from "recharts";
import { get, post } from "utilities/Network";

export type LineConfig = {
  colour?: string;
  dataKey: string;
};

export type LineChartProps = {
  tooltip?: boolean;
  configuration: any;
  XAxisDataKey: string;
  lines: Array<LineConfig>;
  loading?: boolean;
  isPartner?: boolean;
};

const CustomLineChart: React.FC<LineChartProps> = ({
  tooltip,
  configuration,
  XAxisDataKey,
  lines,
  loading,
  isPartner = false,
}) => {
  const [data, setData] = useState([]);
  const [fetching, setFetching] = useState(false);

  useEffect(() => {
    if (fetching || loading) return;
    setFetching(true);
    let url = "reports/chart-generator/";
    if (isPartner) {
      url = "reports/partners/chart-generator/";
    }
    post(url, configuration).then((response) => {
      setData(response.data.datasets[0].data);
      setFetching(false);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [configuration, loading, configuration?.broker]);

  return (
    <ResponsiveContainer width={"100%"} height={"100%"}>
      <AreaChart data={data} margin={{ top: 5, right: 0, left: 0, bottom: 5 }}>
        <CartesianGrid />
        <XAxis dataKey={XAxisDataKey} />
        <YAxis />
        {tooltip && <Tooltip />}
        <Legend />
        {lines.map((line, key) => {
          return (
            <Area
              type="monotone"
              dataKey={line.dataKey}
              key={key}
              stroke={constants.colors.primary_900}
              fill={constants.colors.primary_400}
            />
          );
        })}
      </AreaChart>
    </ResponsiveContainer>
  );
};

export default CustomLineChart;
