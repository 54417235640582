import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ReadyState } from "react-use-websocket";

const initialState: any = {
  readyState: ReadyState.UNINSTANTIATED,
  lastMessage: {},
};

export const websocketSlice = createSlice({
  name: "websocket",
  initialState,
  reducers: {
    connected: (state) => {
      state.readyState = ReadyState.OPEN;
    },
    connecting: (state) => {
      state.readyState = ReadyState.CONNECTING;
    },
    disconnect: (state) => {
      state.readyState = ReadyState.CLOSED;
    },
    errored: (state) => {
      state.readyState = -1;
    },
    persistWebsocketEvent: (state, action: PayloadAction<any>) => {
      state.lastMessage = action.payload;
    },
  },
});

export const {
  connected,
  connecting,
  disconnect,
  errored,
  persistWebsocketEvent,
} = websocketSlice.actions;
export default websocketSlice.reducer;
