import LoaderLogo from "components/core/LoaderLogo";
import React, { useState, useEffect, useMemo } from "react";
import ReportContainer from "./ReportContainer";
import BIReport from "./reports/BIReport";
import DemoReport from "./reports/DemoReport";
import { useParams } from "react-router-dom";
import { get } from "utilities/Network";
import { DataReport } from "types";

const ReportPage = () => {
  const { reportAccessToken } = useParams();
  const [loading, setLoading] = useState(false);
  const [report, setReport] = useState<DataReport | null>(null);

  // useState({
  //   report_type: "demo_report",
  //   details: {
  //     id: "some id",
  //     title: "BUSINESS INSIGHTS REPORT",
  //     period: "some period",
  //     generated_date: "XX/XX/XXXX",
  //     accessor: "Alex Collins",
  //     accessor_title: "CTO",
  //   },
  // });

  useEffect(() => {
    get(`reports/token/${reportAccessToken}/`).then((response) => {
      setLoading(false);
      setReport(response.data);
    });
  }, [reportAccessToken]);

  const reportComponent = useMemo(() => {
    if (!!report) {
      switch (report.report_type) {
        case "demo_report":
          return <DemoReport report={report} />;
        case "business_insights":
          return <BIReport report={report} />;
      }
    }
    return null;
  }, [report]);

  if (loading || !report) {
    return (
      <div className="flex items-center justify-center w-full h-full bg-white">
        <LoaderLogo withText={true} light={true} />
      </div>
    );
  }
  return <ReportContainer report={report}>{reportComponent}</ReportContainer>;
};

export default ReportPage;
