import ReportContainer from "pages/ReportPage/ReportContainer";
import React from "react";
import LogoSourceDark from "assets/brand/logo-dark.svg";
import InfoBlock from "reports_pages/InfoBlock";

const ApplicantRCTIInvoiceTemplate = ({ pdf }: any) => {
  const formatMoney = (num: any) => {
    if (!num) return "";
    return num.toLocaleString("en-US", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
  };
  return (
    <div className="w-[210mm] h-[297mm] m-auto p-5">
      <div className="w-full mt-6 bg-white mb-6">
        <div className="w-full min-h-[50px] flex flex-row items-start mb-10">
          <div className="w-1/2">
            <img src={LogoSourceDark} className="max-w-[220px]" />
          </div>
          <div className="w-1/2 flex justify-end flex flex-col items-end">
            <h1 className="font-bold text-xl text-black">
              RECIPIENT CREATED TAX INVOICE
            </h1>

            <h1 className="text-sm text-black mt-2">
              Finstead Capital Pty Ltd | ABN: 51 638 390 182
            </h1>
            <h1 className="text-sm text-black">
              Contact Email: {pdf.finstead_contact_email}
            </h1>
            <h1 className="text-sm text-black">Date: {pdf.invoice_date}</h1>
          </div>
        </div>

        <hr className="h-1 bg-black" />

        <div className="w-full text-left mt-16">
          <div className="font-bold text-md">BILL TO:</div>
          <div>{pdf.customer_trading_name}</div>
          <div>{pdf.customer_abn}</div>
        </div>
        <div className="w-full mt-16">
          <table className="table-auto w-full divide-y divide-gray-200">
            <thead className="bg-black">
              <tr>
                {/* <th className="px-4 py-2 text-white text-left">
                  Invoice Number
                </th> */}
                <th className="px-4 py-2 text-white text-left">
                  Asset Description
                </th>
                <th className="px-4 py-2 text-white text-right">
                  Invoice Amount (incl GST)
                </th>
              </tr>
            </thead>
            <tbody className="divide-y divide-gray-200">
              <tr className="bg-gray-200">
                <td className="px-4 py-2 text-left">{pdf.asset_description}</td>
                <td className="px-4 py-2 text-right">
                  ${formatMoney(pdf.amount_payable)}
                </td>
              </tr>
              <tr className="bg-white">
                <td className="px-4 py-2 text-left pt-4"></td>
                <td className="px-4 py-2 text-right pt-4">
                  <table className="table-auto w-full text-right">
                    <tr>
                      <td className="font-bold pr-3">
                        TOTAL INVOICE AMOUNT (INCL GST)
                      </td>
                      <td className="text-lg bg-green-50 font-bold pr-2">
                        ${formatMoney(pdf.amount_payable)}
                      </td>
                    </tr>
                  </table>
                </td>
              </tr>
            </tbody>
          </table>

          <div className="mt-6">
            <h2 className="text-md font-bold uppercase">
              In reference to the following loan type:
            </h2>
          </div>
          <table className="table-auto w-full divide-y divide-gray-200 mt-2">
            <thead className="bg-black">
              <tr>
                {/* <th className="px-4 py-2 text-white text-left">
                  Invoice Number
                </th> */}
                <th className="px-4 py-2 text-white text-left">Loan Type</th>
                <th className="px-4 py-2 text-white text-right">Reference</th>
              </tr>
            </thead>
            <tbody className="divide-y divide-gray-200">
              <tr className="bg-gray-200">
                <td className="px-4 py-2 text-left">{pdf.loan_type}</td>
                <td className="px-4 py-2 text-right">
                  {pdf.loan_reference_number}
                </td>
              </tr>
            </tbody>
          </table>

          <div className="font-italic text-sm mt-6 text-center mb-4 w-full">
            Kindly note funds can take up to one (1) business day to arrive into
            the designated suppliers bank account.
          </div>
        </div>

        <div className="mt-16 w-full text-center text-sm">
          <hr />
          <br />
          <div>Finstead Capital Pty Ltd</div>
          <div>ABN: 51 638 390 182 | ACN: 638 390 182</div>
        </div>
      </div>
    </div>
  );
};

export default ApplicantRCTIInvoiceTemplate;
