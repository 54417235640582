import { useState } from "react";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import InputField from "components/inputs/InputField";
import validateABN from "utilities/Forms/validators/abnValidator";
import Button from "components/core/Button";
import { post } from "utilities/Network";
import { useNavigate, useParams } from "react-router-dom";

export type FormData = {
  name: string;
};

export const FormSchema = yup.object({
  name: yup.string().min(2).max(255).required(),
});

export const RequiredFieldsMap = {
  name: true,
};

const NewProductForm = () => {
  const navigate = useNavigate();
  const { lenderId } = useParams();
  const [submitting, setSubmitting] = useState(false);
  // use Form Hook
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<FormData>({
    resolver: yupResolver(FormSchema),
  });

  const onSubmit = (formData: FormData) => {
    setSubmitting(true);
    post(`lenders/products/`, { ...formData, lender: lenderId }).then(
      (response) => {
        setSubmitting(false);
        navigate(`/lenders/${lenderId}/products/${response.data.id}/`);
      }
    );
  };

  return (
    <>
      {/* Form Container */}
      <form onSubmit={handleSubmit(onSubmit)}>
        <InputField
          formControlProps={register("name")}
          label={"Product Name"}
          errorMessage={errors.name?.message}
          required={!!RequiredFieldsMap.name}
          disabled={submitting}
        />

        <div className="w-full text-sm text-right mb-2">
          <p>Once created you'll be redirect to the new product's page.</p>
          <br />
          <Button
            text={submitting ? "Submitting" : "Create Product"}
            disabled={submitting}
            type={"submit"}
            onClick={() => {}}
            primary
          />
        </div>
      </form>
    </>
  );
};

export default NewProductForm;
