import React from "react";
import Badge, { BadgeType } from "components/core/Badge";

type PartnerStatusBadgeProps = {
  partner?: any;
};

const PartnerStatusBadge: React.FC<PartnerStatusBadgeProps> = ({ partner }) => {
  if (partner?.onboarding_complete) {
    switch (partner?.active) {
      case true:
        return <Badge text={"Active"} type={BadgeType.success} />;
      case false:
        return <Badge text={"Inactive"} type={BadgeType.danger} />;
    }
  } else {
    return <Badge text={"Onboarding In Progress"} type={BadgeType.info} />;
  }
  return null;
};

export default PartnerStatusBadge;
