import React from "react";

type Option = {
  text: string;
  onClick: () => void;
};

type Props = {
  title: string;
  options: Array<Option>;
};

const DropDownButton: React.FC<Props> = ({ title, options }) => {
  return (
    <div className="group inline-block relative">
      <button className=" bg-white border-[1px] border-slate-300 rounded-md focus:ring-4 focus:outline-none font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center dark:bg-blue-600 hover:bg-blue-800 text-white">
        {title}
        <svg
          className="w-2.5 h-2.5 ml-2.5"
          aria-hidden="true"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 10 6"
        >
          <path
            stroke="currentColor"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="m1 1 4 4 4-4"
          />
        </svg>
      </button>
      <div className="absolute left-0 mt-0 w-44 rounded-md shadow-lg bg-white z-10 hidden group-hover:block">
        <ul className="py-2 text-sm text-white">
          {options.map((option, index) => (
            <li key={index} className="cursor-pointer">
              <a
                className="block px-4 py-2 text-black hover:text-white hover:bg-slate-500 text-left"
                onClick={(e) => {
                  e.preventDefault();
                  option.onClick();
                }}
              >
                {option.text}
              </a>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default DropDownButton;
