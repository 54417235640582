import React, { useMemo, useState, useEffect, useCallback } from "react";
import Logo from "components/core/Logo";
export type LoginPageProps = {};
import { LogoWrapper } from "./LoginPage.styled";
import LoaderLogo from "components/core/LoaderLogo";
import Login from "./components/Login";
import PasswordReset from "./components/PasswordReset";
import SetupMFA from "./components/SetupMFA";
import { useNavigate, useParams } from "react-router-dom";
import useQuery from "hooks/useQuery";
import { post } from "utilities/Network";
import IconBase from "components/core/IconBase";
import { ReactComponent as alertIcon } from "assets/icons/alert-triangle.svg";
import ChallengeMFA from "./components/ChallengeMFA";

const enum PageMode {
  login = "login",
  resetPassword = "password",
  resetMFA = "mfa_reset",
  challengeMFA = "mfa_challenge",
}
type NoAuthWrapperPageProps = {
  isPartnerPortal?: boolean;
};

const NoAuthWrapperPage: React.FC<NoAuthWrapperPageProps> = ({
  isPartnerPortal,
}) => {
  const navigate = useNavigate();
  const query = useQuery();
  const token = query.get("token");
  const pathname = window.location.pathname;

  const [ready, setReady] = useState(false);
  const [invalidToken, setInvalidToken] = useState(false);

  const mode = useMemo<PageMode>(() => {
    switch (pathname) {
      case "/login":
        return PageMode.login;
      case "/reset-password/":
      case "/password-reset/":
        return PageMode.resetPassword;
      case "/reset-mfa/":
        return PageMode.resetMFA;
      case "/challenge-mfa/":
        return PageMode.challengeMFA;
      default:
        return PageMode.login;
    }
  }, [pathname]);

  const verifyToken = useCallback(() => {
    if (!token) return;
    setInvalidToken(false);

    let validationUrl = "authentication/validate/";
    if (isPartnerPortal) {
      validationUrl = "authentication/partners/validate/";
    }

    post(validationUrl, {
      token_type: mode,
      token: token,
    })
      .then((response) => {
        setReady(true);
      })
      .catch(() => {
        setInvalidToken(true);
        if (mode === PageMode.challengeMFA) {
          navigate("/login");
        }
      });
  }, [mode, token, navigate, isPartnerPortal]);

  const checkReady = () => {
    switch (mode) {
      case PageMode.login:
        setReady(true);
        break;
      case PageMode.resetMFA:
      case PageMode.challengeMFA:
      case PageMode.resetPassword:
        verifyToken();
        return;
    }
  };

  useEffect(checkReady, [mode, verifyToken]);

  return (
    <div className="w-full h-full flex flex-row flex-wrap overflow-y-hidden">
      <div
        className={`fixed h-full top-0 left-0 z-50 w-[3px] bg-[#AE7222]`}
      ></div>
      <div className="w-full sm:w-2/3 md:w-1/3  flex items-center justify-center h-full p-8 bg-[#000]">
        <div className="w-[400px] bg-slate-100 p-5 rounded-md">
          {invalidToken && mode !== PageMode.login && (
            <div className="w-full h-[300px] flex justify-center items-center align-middle flex-wrap text-center">
              <div className="w-full text-center">
                <div className="w-full text-center flex items-center justify-center mb-5">
                  <IconBase src={alertIcon} />
                </div>
                <h2>This link has expired or is invalid.</h2>
              </div>
            </div>
          )}
          {!ready && !invalidToken && (
            <div className="w-full h-[300px] flex justify-center items-center">
              <LoaderLogo light />
            </div>
          )}
          {ready && (
            <>
              <LogoWrapper>
                <Logo dark={true} stacked={false} width={"300px"} />
              </LogoWrapper>

              {mode === PageMode.login && (
                <Login isPartnerPortal={isPartnerPortal} />
              )}
              {mode === PageMode.resetPassword && (
                <PasswordReset isPartnerPortal={isPartnerPortal} />
              )}
              {mode === PageMode.resetMFA && (
                <SetupMFA isPartnerPortal={isPartnerPortal} />
              )}
              {mode === PageMode.challengeMFA && (
                <ChallengeMFA isPartnerPortal={isPartnerPortal} />
              )}
            </>
          )}
        </div>
      </div>
      {!isPartnerPortal && (
        <div
          className="w-full sm:w-1/3 md:w-2/3"
          style={{
            backgroundImage: `url("https://www.finsteadrs.com.au/img/banner/opera-house.jpg")`,
            backgroundSize: "cover",
            backgroundPosition: "center center",
          }}
        />
      )}
      {isPartnerPortal && (
        <div
          className="w-full sm:w-1/3 md:w-2/3 p-10 flex items-center justify-center"
          // style={{
          //   backgroundImage: `url("https://www.finsteadrs.com.au/img/banner/opera-house.jpg")`,
          //   backgroundSize: "cover",
          //   backgroundPosition: "center center",
          // }}
        >
          <div className="w-full max-w-[800px] text-left bg-slate-200 p-8 rounded-md ">
            <h1 className="text-4xl text-black border-l-4 border-slate-900 pl-8">
              Welcome to the Finstead Partner Program.
            </h1>
            <p className="mt-8 leading-7">
              Finstead Capital's Partner Program offers a unique opportunity to
              work collaboratively with one of Australia's most extensive panels
              of lenders. Our experienced team, combined with our relationships
              with a broad range of businesses, positions us as the partner of
              choice for Australian businesses.
            </p>
            <p className=" mt-2 leading-7">
              We invite you to sign in and explore how we can create sustainable
              value for our customers, partners, and community together. Let's
              build a brighter financial future for Australian businesses, one
              partnership at a time.
            </p>
            <p className="mt-4 text-lg">{" - "}The Finstead Team.</p>
            <br />
            {/* <div className="">
              <div className="flex flex-row justify-start gap-1 mb-8">
                <img
                  width={"40px"}
                  height={"auto"}
                  src={
                    "https://cdn.finstead.com.au/awards/BestBrokerAwards22.png"
                  }
                />
                <img
                  width={"40px"}
                  height={"auto"}
                  src={
                    "https://cdn.finstead.com.au/awards/BestBrokerAwards21.png"
                  }
                />
                <img
                  width={"40px"}
                  height={"auto"}
                  src={
                    "https://cdn.finstead.com.au/awards/BestBrokerAwardsMVP22.png"
                  }
                />
              </div>
            </div> */}
          </div>
        </div>
      )}
    </div>
  );
};

export default NoAuthWrapperPage;
