import { useState } from "react";
import SlidingPane from "components/layout/SlidingPane";
import "react-sliding-pane/dist/react-sliding-pane.css";
import Button from "components/core/Button";
import InputField from "components/inputs/InputField";
import LoaderLogo from "components/core/LoaderLogo";
import { post } from "utilities/Network";
import { toast } from "react-toastify";
import { useCopyToClipboard } from "usehooks-ts";

type Props = {
  isOpen?: boolean;
  toggle?: (state: boolean) => void;
  cb?: () => void;
};

const PartnerNewAPIKeyForm = ({ isOpen, toggle, cb }: Props) => {
  const [name, setName] = useState("");
  const [submitAttempted, setSubmitAttempted] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [key, setKey] = useState();
  const [value, copy] = useCopyToClipboard();

  const submit = () => {
    setSubmitAttempted(true);
    setSubmitting(true);
    post("partners/api/keys/create/", {
      name,
    }).then((response: any) => {
      if (!!cb) {
        cb();
      }
      setSubmitting(false);
      setKey(response.data.key);
    });
  };
  const copyKey = () => {
    if (!!key) {
      copy(key);
      toast.success("Copied to clipboard");
    }
  };
  return (
    <SlidingPane
      className="overlay-pane-content"
      overlayClassName="overlay-pane"
      isOpen={isOpen}
      title="New API Key"
      width="800px"
      subtitle="Create a new API key to access Finstead's API."
      onRequestClose={() => {
        // triggered on "<" on left top click or on outside click
        if (!!toggle) {
          toggle(false);
        }
      }}
      cancelPromptTitle={"Abandon new API Key?"}
      cancelPromptMessage={
        "Are you sure you'd like to abandon this new API Key?"
      }
    >
      {!!key && (
        <div className="w-full h-full flex items-center justify-center flex-col gap-4 text-center">
          <h2 className="text-2xl">Click to copy your private api key</h2>
          <p>
            This key will only be shown once. If you lose your key you must
            generate a new one. Always store your key in a private place.
          </p>
          <br />
          <br />
          <br />
          <div
            className="w-full min-h-[90px] max-w-[500px] bg-gray-300 rounded-xl cursor-pointer h-auto"
            onClick={copyKey}
          >
            <p className="m-4 break-all">{key}</p>
          </div>
          <br />
          <br />
          <Button
            text={"Close"}
            onClick={() => {
              if (!!toggle) {
                toggle(false);
              }
            }}
          ></Button>
        </div>
      )}
      {submitting && <LoaderLogo />}
      {!key && !submitting && (
        <>
          <InputField
            label={"Key Name"}
            required={true}
            disabled={false}
            loading={false}
            value={name}
            errorMessage={""}
            onChange={setName}
          />
          <br />
          <div className="w-full flex justify-end">
            <Button
              onClick={submit}
              text={"Create"}
              disabled={name.length < 3}
            />
          </div>
        </>
      )}
    </SlidingPane>
  );
};
export default PartnerNewAPIKeyForm;
