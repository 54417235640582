export const getLocalStorageItem = (key) => {
  try {
    return JSON.parse(window.localStorage.getItem(key));
  } catch {
    return window.localStorage.getItem(key);
  }
};

export const setRawLocalStorageItem = (key, value) => {
  return window.localStorage.setItem(key, value);
};

export const getRawLocalStorageItem = (key) => {
  return window.localStorage.getItem(key);
};

export const setLocalStorageItem = (key, value) => {
  return window.localStorage.setItem(key, JSON.stringify(value));
};

export const clearLocalStorage = () => {
  window.localStorage.clear();
};
