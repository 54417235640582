import React from "react";
import { Link } from "react-router-dom";
import {
  Text,
  SupportingText,
  TextAndSupportingText,
  Content,
  PageHeaderWrapper,
  Divider,
  RHSChildren,
} from "./PageHeader.styled";

export type PageHeaderProps = {
  title: any;
  subtitle?: any;
  breadcrumbs?: any;
  rightHandSideChildren?: any;
};

const PageHeader: React.FC<PageHeaderProps> = ({
  title,
  subtitle,
  breadcrumbs,
  rightHandSideChildren,
}) => {
  return (
    <PageHeaderWrapper>
      <Content>
        <TextAndSupportingText>
          <Text>{title}</Text>
          <SupportingText>
            {breadcrumbs &&
              breadcrumbs.map((crumb: any, index: any) => (
                <span key={index}>
                  <Link key={index} to={crumb.link} className="hover:underline">
                    {crumb.title}
                  </Link>

                  {" > "}
                </span>
              ))}{" "}
            {subtitle}
          </SupportingText>
        </TextAndSupportingText>
        {!!rightHandSideChildren && (
          <RHSChildren>{rightHandSideChildren}</RHSChildren>
        )}
      </Content>
      <Divider />
    </PageHeaderWrapper>
  );
};

export default PageHeader;
