import React, { useEffect, useMemo } from "react";
import "react-toastify/dist/ReactToastify.css";
import "./index.css";
import styled from "styled-components";
import GlobalStyles from "styles/global";
import constants from "styles/constants";
import { ToastContainer } from "react-toastify";
import { ThemeProvider } from "styled-components";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import AppContainer from "components/layout/AppContainer";
import DashboardPage from "pages/DashboardPage/";
import TeamPage from "pages/TeamPage";
import TeamMemberPage from "pages/TeamMemberPage";
import InsuranceReferralPage from "pages/InsuranceReferralPage";
import NoAuthWrapperPage from "pages/NoAuthWrapperPage";
import RedirectionPage from "pages/RedirectionPage";
import DealPage from "pages/DealPage";
import LendersPage from "pages/LendersPage";
import LenderDetailPage from "pages/LenderDetailPage";
import PartnersPage from "pages/PartnersPage";
import DealDetailPage from "pages/DealDetailPage";
import DocumentsPage from "pages/DocumentsPage";
import TasksPage from "pages/TasksPage";
import SettlementsPage from "pages/SettlementsPage";
import GroupsPage from "pages/GroupsPage";
import PartnerDetailPage from "pages/PartnerDetailsPage";
import ProductDetailPage from "pages/LenderDetailPage/ProductDetailPage";
import LeadDistributionPage from "pages/LeadDistributionPage";
import ReportTemplatesPage from "pages/ReportTemplatesPage";
import OrganisationPage from "pages/OrganisationPage";
import Activities from "pages/ActivitiesPage";
import OrganisationDetailPage from "pages/OrganisationDetailPage";
import ApplicantDetailPage from "pages/ApplicantDetailPage";
import ApplicantPage from "pages/ApplicantPage";
import ReportPage from "pages/ReportPage";
import PartnerApp from "PartnerApp";
import ReportsApp from "ReportsApp";
import ReportScheduleDetailPage from "pages/ReportScheduleDetailPage";
import PDFPage from "pages/PDFPage";

const Container = styled.div`
  height: 100vh;
  width: 100vw;
`;

const App: React.FC = () => {
  // useWebsocket();

  useEffect(() => {
    if (
      !process.env.REACT_APP_LOCAL &&
      window.location.host.indexOf("monty") !== -1
    ) {
      window.location.href = window.location.href.replace("monty", "crm");
    }
  }, []);

  const isPartnerPortal = useMemo(() => {
    // checks if the subdomain is "partners"
    return (
      window.location.host.split(".")[0] === "partners" ||
      window.location.host.split(".")[0] === "staging-partners"
    );
  }, []);

  const isReportsApp = useMemo(() => {
    // checks if the subdomain is "partners"
    return window.location.host.split(".")[0] === "reports-view";
  }, []);

  const isFilesApp = useMemo(() => {
    // checks if the subdomain is "partners"
    return window.location.host.split(".")[0] === "files-view";
  }, []);

  let router;

  if (isPartnerPortal) {
    return <PartnerApp />;
  }

  if (isReportsApp) {
    return (
      <Router>
        <Routes>
          <Route path="/report/:reportAccessToken" element={<ReportPage />} />
        </Routes>
      </Router>
    );
  }

  return (
    <Container>
      <GlobalStyles />
      <ToastContainer pauseOnHover />
      <ThemeProvider theme={constants}>
        <Router>
          <AppContainer>
            <Routes>
              <Route path="/" element={<DashboardPage />} />
              <Route path="/team" element={<TeamPage />} />
              <Route path="/team/:teamMemberId" element={<TeamMemberPage />} />
              <Route
                path="/report/:reportAccessToken"
                element={<ReportPage />}
              />
              <Route path="/pdf/:pdfAccessToken" element={<PDFPage />} />
              <Route path="/document-templates" element={<DocumentsPage />} />
              <Route path="/activities" element={<Activities />} />
              <Route path="/deals" element={<DealPage />} />
              <Route path="/deals/:dealId" element={<DealDetailPage />} />
              <Route path="/lenders" element={<LendersPage />} />
              <Route path="/lenders/:lenderId" element={<LenderDetailPage />} />
              <Route
                path="/lenders/:lenderId/products"
                element={<LenderDetailPage showProductsTab />}
              />
              <Route
                path="/lenders/:lenderId/products/:productId"
                element={<ProductDetailPage />}
              />
              <Route path="/partners" element={<PartnersPage />} />
              <Route
                path="/partners/:partnerId"
                element={<PartnerDetailPage />}
              />
              <Route path="/organisations" element={<OrganisationPage />} />
              <Route
                path="/organisations/:organisationId"
                element={<OrganisationDetailPage />}
              />
              <Route path="/applicants" element={<ApplicantPage />} />
              <Route
                path="/applicants/:applicantId"
                element={<ApplicantDetailPage />}
              />
              <Route path="/login" element={<NoAuthWrapperPage />} />
              <Route path="/reset-password" element={<NoAuthWrapperPage />} />
              <Route path="/reset-mfa" element={<NoAuthWrapperPage />} />
              <Route path="/challenge-mfa" element={<NoAuthWrapperPage />} />
              <Route path="/referral" element={<InsuranceReferralPage />} />
              <Route path="/reports" element={<ReportTemplatesPage />} />
              <Route
                path="/reports/:reportScheduleId"
                element={<ReportScheduleDetailPage />}
              />

              <Route path="/redirect" element={<RedirectionPage />} />
              <Route path="/tasks" element={<TasksPage />} />
              <Route path="/settlements" element={<SettlementsPage />} />
              <Route
                path="/lead-distribution"
                element={<LeadDistributionPage />}
              />
              <Route path="/groups" element={<GroupsPage />} />
            </Routes>
          </AppContainer>
        </Router>
      </ThemeProvider>
    </Container>
  );
};

export default App;
