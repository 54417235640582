/**
 * Checks ABN for validity using the published ABN checksum algorithm.
 * @param  {String|Number} value abn to validate
 * @return {Boolean} Is ABN Valid
 */
const validateABN = (value: string | undefined) => {
  if (!value) return false;
  const weights = [10, 1, 3, 5, 7, 9, 11, 13, 15, 17, 19];
  const abn = value.replace(/[^\d]/, "");
  let result = false;

  // check length is 11 digits
  if (abn.length === 11) {
    // apply ato check method
    let sum = 0,
      weight;

    for (let index = 0; index <= weights.length - 1; index++) {
      weight = weights[index];
      const digit = parseInt(abn.charAt(index)) - (index ? 0 : 1);
      sum += weight * digit;
    }

    result = sum % 89 === 0;
  }

  return result;
};

export default validateABN;
