import React, { useState, useEffect } from "react";
import {
  MainWrapper,
  HeaderSection,
  Container,
  Divider,
  Section,
} from "pages/TeamPage/TeamPage.styled";
import Button from "components/core/Button";
import PageHeader from "components/layout/PageHeader";
import FilterBar from "components/core/FilterBar";
import TableComponent from "components/core/TableComponent";
import CheckBox from "components/core/CheckBox";
import Badge, { BadgeType } from "components/core/Badge";
import { post } from "utilities/Network";
import SlidingPane from "components/layout/SlidingPane";
import "react-sliding-pane/dist/react-sliding-pane.css";
import ActivityForm from "forms/ActivityForm";
import { useNavigate } from "react-router-dom";
import { Text as CellText } from "components/core/TableComponent/components/BasicTableCell/BasicTableCell.styled";
import { toast } from "react-toastify";
import useUser from "hooks/useUser";
import { useActivities } from "hooks/useActivities";

type ActivitiesPageProps = {};

const ActivitiesPage: React.FC<ActivitiesPageProps> = () => {
  const [isPaneOpen, setPaneOpen] = useState<boolean>(false);
  const { activitiesSection } = useActivities({ hideFilterBar: false });

  return (
    <MainWrapper>
      <SlidingPane
        className="overlay-pane-content"
        overlayClassName="overlay-pane"
        isOpen={isPaneOpen}
        title="New Activity"
        width="1200px"
        subtitle="Complete the form to create a new activity."
        onRequestClose={() => {
          // triggered on "<" on left top click or on outside click
          setPaneOpen(false);
        }}
        cancelPromptTitle={"Abandon new Activity?"}
        cancelPromptMessage={
          "Are you sure you'd like to abandon this new Activity form?"
        }
      >
        <ActivityForm
          cb={() => {
            // todo - close pane and reload list view, navigate to activity and flash highlight
          }}
        />
      </SlidingPane>

      <Container>
        <PageHeader
          title={"Activities"}
          rightHandSideChildren={
            <Button
              onClick={() => {
                setPaneOpen(true);
              }}
              text={"New Activity"}
              primary
            />
          }
        />
      </Container>
      <Section className="min-h-full">{activitiesSection}</Section>
    </MainWrapper>
  );
};

export default ActivitiesPage;
