import React, { useState, useEffect } from "react";
import TableComponent from "components/core/TableComponent";
import {
  MainWrapper,
  Container,
  Section,
} from "pages/TeamPage/TeamPage.styled";
import PageHeader from "components/layout/PageHeader";
import { useParams, useNavigate } from "react-router-dom";
import { get } from "utilities/Network";
import InfoBox from "components/core/InfoBox";
import { ReactComponent as infoIcon } from "assets/icons/info.svg";
import { ReactComponent as userIcon } from "assets/icons/user.svg";
import Badge, { BadgeType } from "components/core/Badge";
import IconBase from "components/core/IconBase";
import constants from "styles/constants";
import Tabs from "components/core/Tabs";
import Button from "components/core/Button";

const ReportScheduleDetailPage = ({ showProductsTab = false }: any) => {
  const navigate = useNavigate();
  const { reportScheduleId } = useParams();
  const [schedule, setReportSchedule] = useState<any>();

  const deactivate = () => {};

  const fetchReportSchedule = () => {
    get(`reports/schedules/${reportScheduleId}/`).then((response) =>
      setReportSchedule(response.data)
    );
  };
  useEffect(fetchReportSchedule, [reportScheduleId]);

  return (
    <MainWrapper>
      <Container>
        <PageHeader
          title={`Report Schedule ${schedule?.name}`}
          subtitle={<>{reportScheduleId}</>}
          breadcrumbs={[
            {
              title: "Report Schedules",
              link: "/reports",
            },
          ]}
          rightHandSideChildren={
            <Button onClick={deactivate} text={"Deactive"} danger />
          }
        />
      </Container>
      <Section>
        <div className="flex flex-wrap-reverse w-full gap-8 pt-4">
          <Tabs
            defaultTab={showProductsTab ? 1 : 0}
            tabs={[
              {
                name: "Details",
                component: (
                  <>
                    <div className="w-full max-w-[700px]">
                      <InfoBox
                        title={"Report Schedule Details"}
                        icon={
                          <IconBase
                            src={infoIcon}
                            color={constants.colors.primary_600}
                          />
                        }
                        info={{
                          ID: schedule?.id,
                          "Schedule Name": schedule?.name,
                          "Report Template": schedule?.report_template,
                          "Recipient Group": schedule?.recipient_group,
                          Frequency: schedule?.frequency,
                        }}
                        w={"100%"}
                      />
                    </div>
                  </>
                ),
              },
              {
                name: "Runs",
                component: (
                  <>
                    <TableComponent
                      endpoint={`reports/schedules/${reportScheduleId}/runs/`}
                      //   onRowClick={(row: any, newTab = false) => {
                      //     if (newTab) {
                      //       window.open(
                      //         `https://reports.finstead.com.au/${}${lenderId}/products/${row.id}`,
                      //         "_blank",
                      //         "noreferrer"
                      //       );
                      //     } else {
                      //       navigate(`/lenders/${lenderId}/products/${row.id}`);
                      //     }
                      //   }}
                      fields={{
                        id: { name: "Run ID" },
                        generated_time: { name: "Generated Time" },
                        token: {
                          name: "Link",
                          middleware: (row: any) => (
                            <a href={`/report/${row?.token}`}>Open Report</a>
                          ),
                        },
                        dispatched: { name: "Dispatched" },
                        expires_at: { name: "Expires At" },
                        finstead_user: { name: "Finstead User" },
                        partner_account: { name: "Partner Account" },
                      }}
                    />
                  </>
                ),
              },
            ]}
          />
        </div>
      </Section>
    </MainWrapper>
  );
};

export default ReportScheduleDetailPage;
