import React, { useEffect, useState } from "react";
import constants from "styles/constants";
import { CheckBoxWrapper, CheckboxText } from "./CheckBox.styled";
import IconBase from "components/core/IconBase";

import { ReactComponent as checkboxCheckedIcon } from "assets/icons/check-square.svg";
import { ReactComponent as checkboxUnCheckedIcon } from "assets/icons/square.svg";

export type CheckBoxProps = {
  checked?: boolean;
  onClick?: any;
  handleState?: boolean;
  text?: string;
  onChange?: any;
  onBlur?: any;
  name?: string;
  ref?: React.Ref<any>;
  disabled?: boolean;
  classNames?: string;
};

const CheckBox: React.FC<CheckBoxProps> = ({
  onClick,
  text,
  name,
  onChange,
  disabled,
  checked = false,
  handleState = true,
  classNames = "",
}) => {
  const [checkState, setCheckedState] = useState(checked);

  const handleClick = () => {
    if (disabled) return;
    onChange({
      target: {
        name: name,
        value: !checkState,
      },
    });

    // pass up the state change if onClick handler provided
    if (onClick) {
      onClick(!checkState);
    }

    if (handleState) {
      // only update the internal state if handle state is set to true
      setCheckedState(!checkState);
    }
  };

  useEffect(() => {
    // force update the internal state if the prop's state changes.
    setCheckedState(checked);
  }, [checked]);

  return (
    <CheckBoxWrapper onClick={handleClick} disabled={disabled}>
      {checkState ? (
        <IconBase
          src={checkboxCheckedIcon}
          color={
            disabled ? constants.colors.gray_300 : constants.colors.primary_600
          }
        />
      ) : (
        <IconBase
          src={checkboxUnCheckedIcon}
          size={"24"}
          color={disabled ? constants.colors.gray_300 : constants.colors.black}
        />
      )}
      <CheckboxText disabled={disabled}>{text}</CheckboxText>
    </CheckBoxWrapper>
  );
};

export default CheckBox;
