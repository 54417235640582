import { useState, useEffect } from "react";
import { getLocalStorageItem } from "utilities/LocalStorage";

const useUser = () => {
  const [token, setToken] = useState();
  const [scope, setScope] = useState<any>([]);
  const [user, setUser] = useState<any>();

  useEffect(() => {
    const auth = getLocalStorageItem("auth");
    if (!!auth) {
      console.log(`useUser found local storage item auth:`);
      console.log(auth);
      setToken(auth.token);
      setUser(auth.user);
      setScope(auth.scope || []);
    } else {
      console.log("useUser couldn't find auth local storage item");
    }
  }, []);

  return { user, scope, token };
};

export default useUser;
