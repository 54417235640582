import React, { useEffect, useState } from "react";
import Label from "components/core/Label";
import { get } from "utilities/Network";
import { formatUnderCase } from "utilities/Strings";
import Button from "components/core/Button";
import useFileManager from "hooks/useFileManager";
import Modal from "components/core/Modal";

const FileDetailComponent = ({ endpoint }: any) => {
  const [file, setFile] = useState<any>();
  const [deleteFileModelOpen, setDeleteFileModelOpen] =
    useState<boolean>(false);

  const { downloadFile, deleteFile, updateFile } = useFileManager({
    fileId: file?.id,
  });

  useEffect(() => {
    get(endpoint).then((response) => setFile(response.data));
  }, [setFile, endpoint]);

  return (
    <div className="w-full h-full max-w-[100%]">
      <div className="p-4 rounded-md border border-slate-400">
        <h2 className="font-medium">File Properties</h2>
        <br />
        <Label text={"Id: "} /> <span className="text-sm">{file?.id}</span>
        <br />
        <Label text={"Key: "} /> <span className="text-sm">{file?.key}</span>
        <br />
        <Label text={"Type: "} />{" "}
        <span className="text-sm">{file?.mime_type}</span>
        <br />
        <Label text={"Uploaded By: "} />{" "}
        <span className="text-sm">
          ({file?.creator.id}) {file?.creator.name}
        </span>
        <br />
        <Label text={"Bucket: "} />{" "}
        <span className="text-sm">{file?.bucket}</span>
        <br />
        <Label text={"Path: "} /> <span className="text-sm">{file?.path}</span>
        <br />
        <Label text={"Created At: "} />{" "}
        <span className="text-sm">{file?.created_at}</span>
        <br />
        <Label text={"Updated At: "} />{" "}
        <span className="text-sm">{file?.updated_at}</span>
        <br />
        <Label text={"Number of revisions: "} />{" "}
        <span className="text-sm">{file?.revision_count || 1}</span>
      </div>
      <div className="p-4 mt-4 rounded-md border border-slate-400">
        <h2 className="font-medium">File Actions</h2>
        <br />
        <div className="block mb-2 text-white">
          <Button onClick={downloadFile} text={"Download"} info />
          {/* <br />
          <br />
          <Button
            onClick={updateFile}
            text={"Replace/Update File"}
            warning
            wide
          /> */}
          <br />
          <br />
          {deleteFileModelOpen ? (
            <div className="text-slate-600 text-center">
              <hr />
              <h2 className="mt-2 mb-2">
                Are you sure you want to permanently delete {file?.key} from the
                Finstead CRM. This action can <strong>not</strong> be reversed.
              </h2>
              <Button
                onClick={() => {
                  setDeleteFileModelOpen(false);
                }}
                text="Cancel"
              />
              <div className="text-white mt-1">
                <Button
                  onClick={() => {
                    setDeleteFileModelOpen(false);
                  }}
                  text="Yes, Delete"
                  danger
                />
              </div>
            </div>
          ) : (
            <Button
              onClick={() => {
                setDeleteFileModelOpen(true);
              }}
              text={"Delete"}
              danger
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default FileDetailComponent;
