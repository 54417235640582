import styled from "styled-components";

import {
  DisplaySmSemiBold,
  TextMdNormal,
  TextMdMedium,
  TextSmMedium,
} from "styles/mixins";

export const LoginPageWrapper = styled.div`
  width: 100%;
`;

export const CopyRight = styled.div`
  position: fixed;
  bottom: 20px;
  left: 20px;
  color: ${({ theme }) => theme.colors.gray_900};
`;

export const LogoWrapper = styled.div`
  width: 100%;
  margin-bottom: 50px;
  display: flex;
  justify-content: center;
`;

export const ContentWrapper = styled.div`
  display: flex;
  width: 500px;
  flex-direction: column;
  align-items: center;
  gap: 32px;
  margin: auto auto;
`;
export const Header = styled.div`
  display: flex;
  align-self: stretch;
  flex-direction: column;
  align-items: center;
  gap: 24px;
`;
export const TextAndSupportingText = styled.div`
  display: flex;
  align-self: stretch;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
`;
export const Text = styled.span`
  text-align: center;
  align-self: stretch;
  font-size: 1.6em !important;
  color: ${({ theme }) => theme.colors.gray_900};

  ${DisplaySmSemiBold}
`;
export const SupportingText = styled.span`
  text-align: center;
  align-self: stretch;
  color: ${({ theme }) => theme.colors.gray_500};

  ${TextMdNormal}
`;
export const Content = styled.div`
  display: flex;
  border-radius: 12px;
  align-self: stretch;
  flex-direction: column;
  align-items: center;
  gap: 5px;
`;
export const Form = styled.div`
  display: flex;
  align-self: stretch;
  flex-direction: column;
  align-items: flex-start;
`;
export const Row = styled.div`
  display: flex;
  align-self: stretch;
  justify-content: space-between;
  align-items: center;
`;
export const Actions = styled.div`
  display: flex;
  align-self: stretch;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
`;
export const SocialButtonGroups = styled.div`
  display: flex;
  align-self: stretch;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 12px;
`;
export const SocialButton = styled.div`
  display: flex;
  background: rgba(255, 255, 255, 1);
  filter: drop-shadow(inset 0px 1px 2px rgba(16, 24, 40, 0.05));
  border: 1px solid rgb(208, 213, 221);
  border-radius: 8px;
  overflow: hidden;
  align-self: stretch;
  justify-content: center;
  align-items: center;
  padding: 10px 16px 10px 16px;
  gap: 12px;
`;
export const Styled = styled.img`
  overflow: hidden;
  width: 24px;
  height: 24px;
  display: flex;
  flex-direction: column;
  gap: 1.5px;
`;
export const Text1 = styled.span`
  text-align: left;
  white-space: pre-line;
  color: ${({ theme }) => theme.colors.gray_700};

  ${TextMdMedium}
`;
export const SocialButtonGroups1 = styled.div`
  display: flex;
  align-self: stretch;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 12px;
`;
export const CheckBoxLabel = styled.span`
  width: 320px;
  text-align: left;
  white-space: pre-line;
  color: ${({ theme }) => theme.colors.gray_700};

  ${TextSmMedium}
`;
export const ButtonBase = styled.div`
  display: flex;
  width: fit-content;
  justify-content: center;
  align-items: center;
  gap: 8px;
`;
export const ButtonWrapper = styled.div`
  display: flex;
  width: fit-content;
  align-items: flex-start;
`;

export const ForgotPasswordText = styled.span`
  text-align: left;
  white-space: pre-line;
  color: ${({ theme }) => theme.colors.primary_700};

  ${TextSmMedium}
`;

export const ErrorMessage = styled.p`
  color: ${({ theme }) => theme.colors.error_500};
  margin-bottom: 20px;
`;
