import React from "react";
import { StyledButton } from "./Button.styled";

export type ButtonProps = {
  textColor?: string;
  primary?: boolean;
  warning?: boolean;
  info?: boolean;
  danger?: boolean;
  onClick: () => void;
  text?: any;
  wide?: boolean;
  disabled?: boolean;
  type?: any;
  right?: boolean;
  className?: any;
  width?: string;
};

const Button: React.FC<ButtonProps> = ({
  primary = false,
  warning = false,
  danger = false,
  info = false,
  text,
  onClick,
  width,
  wide = false,
  disabled = false,
  type = "button",
  right = false,
  className = "",
}) => {
  return (
    <StyledButton
      onClick={onClick}
      wide={wide}
      disabled={disabled}
      warning={warning}
      info={info}
      primary={primary}
      danger={danger}
      type={type}
      right={right}
      className={className}
      width={width}
    >
      {text}
    </StyledButton>
  );
};

export default Button;
