import React, { useState } from "react";
import Button from "components/core/Button";
import * as yup from "yup";
import InputField from "components/inputs/InputField";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import SectionHeader from "components/layout/SectionHeader";
import Eventcalendar from "@aldabil/react-scheduler";
import { post } from "utilities/Network";
import SearchSelectField from "components/inputs/SelectSearchField";
import TextArea from "components/inputs/TextArea";
import ReactTooltip from "react-tooltip";
import DropDownField from "components/inputs/DropDownField";
import useUser from "hooks/useUser";

enum ActivityType {
  call = "call",
  meeting = "meeting",
  task = "task",
  deadline = "deadline",
  email = "email",
  lunch = "lunch",
}

export type FormData = {
  subject: string;
  location: string;
  note: string;
  date: string;
  time: string;
  type: ActivityType;
  deal: string;
  duration: number;
};

export const FormSchema = yup.object({
  subject: yup.string().required().min(3).max(255),
  location: yup.string().nullable().max(255),
  note: yup.string().nullable().max(2047),
  date: yup.date().required(),
  time: yup.string().nullable(),
  type: yup.string().required().default(ActivityType.call),
  deal: yup.string().nullable(),
  duration: yup.number().default(30),
});

export const RequiredFieldsMap = {
  subject: true,
  date: true,
  type: true,
  time: false,
  deal: true,
  note: false,
  location: false,
  duration: true,
};

type ActivityFormProps = {
  dealId?: number;
  cb?: (id: any) => void;
};

const ActivityForm: React.FC<ActivityFormProps> = ({ dealId, cb }) => {
  const [submitting, setSubmitting] = useState(false);
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<FormData>({
    resolver: yupResolver(FormSchema),
  });
  const { user } = useUser();

  const formatDate = (d: any) => {
    let month = "" + (d.getMonth() + 1);
    let day = "" + d.getDate();
    const year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [year, month, day].join("-");
  };

  const onSubmit = (formData: FormData) => {
    setSubmitting(true);
    const date = formData?.date;
    post(`activities/create/`, {
      ...formData,
      date: formatDate(date),
      ...(formData.time === "" ? { time: undefined } : {}),

      // optionally inject the deal id
      ...(!!dealId
        ? {
            deal: dealId,
          }
        : {}),
    }).then((response) => {
      setSubmitting(false);
      if (!!cb) cb(response.data);
    });
  };

  return (
    <form className="pb-4 max-w-[700px]" onSubmit={handleSubmit(onSubmit)}>
      {/* Deals */}
      {!dealId && (
        <SearchSelectField
          formControlProps={register("deal")}
          label={"Deal"}
          required={!!RequiredFieldsMap.deal}
          errorMessage={errors.deal?.message}
          placeholder={"Search deals"}
          ep={`deals/search/`}
          getOptionLabel={(deal: any) => (
            <>
              [{deal?.id}] [{deal?.status}] {deal?.title}
            </>
          )}
        />
      )}

      {/* Subject */}
      <InputField
        formControlProps={register("subject")}
        label={"Subject"}
        disabled={submitting}
        errorMessage={errors.subject?.message}
        required={!!RequiredFieldsMap.subject}
      />

      {/* Location */}
      <InputField
        formControlProps={register("location")}
        label={"Location"}
        disabled={submitting}
        errorMessage={errors.location?.message}
        required={!!RequiredFieldsMap.location}
      />

      {/* Date */}
      <InputField
        formControlProps={register("date")}
        label={"Date"}
        type={"date"}
        disabled={submitting}
        errorMessage={errors.date?.message}
        required={!!RequiredFieldsMap.date}
      />

      {/* Time */}
      <InputField
        formControlProps={register("time")}
        label={"Time"}
        type={"time"}
        disabled={submitting}
        errorMessage={errors.time?.message}
        required={!!RequiredFieldsMap.time}
      />

      {/* Duration  */}
      <DropDownField
        formControlProps={register("duration")}
        label={"Duration"}
        loading={submitting}
        disabled={submitting}
        errorMessage={errors.duration?.message}
        required={!!RequiredFieldsMap.type}
        placeholder={"Please select a duration for the activity."}
        options={[
          { text: "5 minutes", value: 5 },
          { text: "10 minutes", value: 10 },
          { text: "15 minutes", value: 15 },
          { text: "20 minutes", value: 20 },
          { text: "30 minutes", value: 30 },
          { text: "1 hour", value: 60 },
          { text: "2 hours", value: 120 },
          { text: "3 hours", value: 180 },
          { text: "4 hours", value: 240 },
        ]}
      />

      {/* Type  */}
      <DropDownField
        formControlProps={register("type")}
        label={"Type"}
        loading={submitting}
        disabled={submitting}
        errorMessage={errors.type?.message}
        required={!!RequiredFieldsMap.type}
        placeholder={"Please select an activity type."}
        options={[
          { text: ActivityType.call, value: ActivityType.call },
          { text: ActivityType.meeting, value: ActivityType.meeting },
          { text: ActivityType.task, value: ActivityType.task },
          { text: ActivityType.deadline, value: ActivityType.deadline },
          { text: ActivityType.email, value: ActivityType.email },
          { text: ActivityType.lunch, value: ActivityType.lunch },
        ]}
      />

      {/* Note */}
      <TextArea
        formControlProps={register("note")}
        placeholder={"Enter some details about this activity"}
        label={"Notes"}
        disabled={submitting}
        errorMessage={errors.note?.message}
        required={!!RequiredFieldsMap.note}
        className="min-h-[250px]"
      />

      <div className="w-full text-right">
        <Button
          type={"submit"}
          disabled={submitting}
          primary
          text={"Done"}
          onClick={() => {}}
        ></Button>
      </div>
    </form>
  );
};

export default ActivityForm;
