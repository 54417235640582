import styled from "styled-components";

import { TextXsNormal } from "styles/mixins";

export const TableCellWrapper = styled.td`
  /* height: 30px; */
  padding: 2px 2px 2px 2px;
  background: rgba(255, 255, 255, 0);
  padding-left: 24px;
`;
export const Text = styled.span`
  text-align: left;
  white-space: pre-line;
  color: ${({ theme }) => theme.colors.black};

  font-size: 12px !important;
  letter-spacing: 0em !important;
  font-family: "Inter" !important;
  line-height: 18px !important;
`;
