import styled from "styled-components";

export const FilterBarContainer = styled.div`
  display: flex;
  margin-bottom: 10px;
  width: 100%;
`;

export const SearchBarContainer = styled.div`
  flex: 1;
  display: flex;
  margin-top: 18px;
`;

export const SearchBarInput = styled.input`
  flex: 1;
  height: 45px;
  border: 1px solid #d0d5dd;
  border-radius: 8px;
  padding-left: 40px;
`;

export const SearchIcon = styled.div`
  position: relative;
  top: 13.5px;
  left: 38px;
  width: 20px;
  /* border: 1px solid red; */
`;

export const LoaderIcon = styled.div`
  position: relative;
  top: 38px;
  left: 5px;
  width: 20px;
`;
