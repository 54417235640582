import ReportContainer from "pages/ReportPage/ReportContainer";
import React from "react";
import LogoSourceDark from "assets/brand/logo-dark.svg";
import InfoBlock from "reports_pages/InfoBlock";

const PrivateSaleInvoiceTemplate = ({ pdf }: any) => {
  const formatMoney = (num: any) => {
    if (!num) return "";
    return num.toLocaleString("en-US", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
  };
  return (
    <div className="w-[210mm] h-[297mm] m-auto p-5">
      <div className="w-full mt-6 bg-white mb-6">
        <div className="w-full min-h-[50px] flex flex-row items-start mb-10">
          <div className="w-1/2">
            <img src={LogoSourceDark} className="max-w-[220px]" />
          </div>
          <div className="w-1/2 flex justify-end flex flex-col items-end">
            <h1 className="font-bold text-2xl text-black">TAX INVOICE</h1>

            <h1 className="text-lg text-black">Finstead Capital Pty Ltd</h1>
            <h1 className="text-lg text-black">INV-{pdf.invoice_id}</h1>
            <h1 className="text-lg text-black">{pdf.deal_title}</h1>
          </div>
        </div>
        <hr className="h-1 bg-black" />
        <div className="b-gray-300 flex flex-row mt-4 mb-4">
          <div className="w-1/2 text-left">
            <div>Finstead Capital Pty Ltd</div>
            <div>Suite 5.08, 12 Century Circuit</div>
            <div>Norwest NSW 2153</div>
          </div>
          <div className="w-1/2 flex flex-col items-end">
            <table className="table-auto">
              <tbody>
                <tr className="">
                  <td className="whitespace-nowrap pr-3">Invoice Number</td>
                  <td className="whitespace-nowrap text-right">
                    INV-{pdf.invoice_id}
                  </td>
                </tr>
                <tr className="">
                  <td className="whitespace-nowrap pr-3">Invoice Date</td>
                  <td className="whitespace-nowrap text-right">
                    {pdf.invoice_date}
                  </td>
                </tr>
                <tr className="">
                  <td className="whitespace-nowrap pr-3">Due Date</td>
                  <td className="whitespace-nowrap text-right">
                    {formatMoney(pdf.due_date)}
                  </td>
                </tr>
                <tr className="">
                  <td className="whitespace-nowrap pr-3">Amount Payable</td>
                  <td className="font-bold whitespace-nowrap text-right">
                    ${formatMoney(pdf.amount_payable)}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <hr className="h-1 bg-black" />
        <div className="b-gray-300 flex flex-row mt-16">
          <div className="w-1/2 text-left">
            <div className="font-bold text-lg">Bill To:</div>
            <div>{pdf.company_trading_name}</div>
            <div>{pdf.company_billing_address}</div>
          </div>
        </div>

        <div className="w-full mt-16">
          <table className="table-auto w-full divide-y divide-gray-200">
            <thead className="bg-black">
              <tr>
                <th className="px-4 py-2 text-white text-left">Description</th>
                <th className="px-4 py-2 text-white text-right">Amount</th>
              </tr>
            </thead>
            <tbody className="divide-y divide-gray-200">
              <tr className="bg-gray-200">
                <td className="px-4 py-2 text-left">Brokerage</td>
                <td className="px-4 py-2 text-right">
                  ${formatMoney(pdf.brokerage)}
                </td>
              </tr>
              <tr>
                <td className="px-4 py-2 text-left">Origination Fee</td>
                <td className="px-4 py-2 text-right">
                  ${formatMoney(pdf.origination_fee)}
                </td>
              </tr>
              <tr className="bg-white">
                <td className="px-4 py-2 text-left pt-4 border-r-2 border-r-gray-200">
                  <table className="table-auto w-full text-left max-w-[500px]">
                    <tr>
                      <td className="font-bold text-lg pb-2">
                        Bank Account Details for Payment
                      </td>
                      <td className="pb-2"></td>
                    </tr>
                    <tr>
                      <td className="font-bold">Account Name:</td>
                      <td className="">Finstead Capital Pty Ltd</td>
                    </tr>
                    <tr>
                      <td className="font-bold">BSB Number:</td>
                      <td className="">{pdf.finstead_bsb_number}</td>
                    </tr>
                    <tr>
                      <td className="font-bold">Account Number:</td>
                      <td className="">{pdf.finstead_account_number}</td>
                    </tr>
                    <tr></tr>
                  </table>
                </td>
                <td className="px-4 py-2 text-right pt-4">
                  <table className="table-auto w-full text-right">
                    <tr>
                      <td className="font-bold text-lg pb-2 text-left text-white">
                        Totals
                      </td>
                      <td className="pb-2"></td>
                    </tr>
                    <tr>
                      <td className="font-bold pr-3">Sub Total</td>
                      <td>${formatMoney(pdf.net_amount)}</td>
                    </tr>
                    <tr>
                      <td className="font-bold pr-3">GST</td>
                      <td>${formatMoney(pdf.gst_component)}</td>
                    </tr>
                    <tr>
                      <td className="font-bold pr-3">Total</td>
                      <td className="text-lg bg-green-50 font-bold">
                        ${formatMoney(pdf.amount_payable)}
                      </td>
                    </tr>
                  </table>
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <div className="mt-16 w-full text-center text-sm">
          <hr />
          <br />
          <div>Finstead Capital Pty Ltd</div>
          <div>ABN: 51 638 390 182 | ACN: 638 390 182</div>
        </div>
      </div>
    </div>
  );
};

export default PrivateSaleInvoiceTemplate;
