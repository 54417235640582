import React, { useEffect, useState, useCallback } from "react";
import PageHeader from "components/layout/PageHeader";
import LineChart from "components/metrics/LineChart";
import InputFieldWrapper from "components/inputs/InputFieldWrapper";
import DateRangeSelect from "components/inputs/DateRangeSelect";
import {
  Container,
  Content,
  Container1,
  Section,
  MainWrapper,
  BrokerageChartWrapper,
  MetricSection,
  BoxMetricsContainer,
} from "./DashboardPage.styled";
import { formatUnderCase, timeString, formatMoney } from "utilities/Strings/";
import SectionHeader from "components/layout/SectionHeader";
import BoxMetric from "components/metrics/BoxMetric";
import constants from "styles/constants";
import Badge, { BadgeType } from "components/core/Badge";
import BrokerDropDownField from "components/inputs/BrokerDropDownField";
import useUser from "hooks/useUser";
import Tabs from "components/core/Tabs";
import DropDownField from "components/inputs/DropDownField";
import { get, post } from "utilities/Network";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import { useActivities } from "hooks/useActivities";
import Avatar from "components/core/Avatar";
import "./styles.css";
import SearchSelectField from "components/inputs/SelectSearchField";
import useIsAdmin, { DashboardModes } from "hooks/useIsAdmin";

export type DashboardPageProps = {};

const DashboardPage: React.FC<DashboardPageProps> = ({}) => {
  const [broker, setBroker] = useState<any>();
  const [userId, setUserId] = useState();
  const [partner, setPartner] = useState<any>();
  const [periodRangeStart, setPeriodRangeStart] = useState<Date>();
  const [periodRangeEnd, setPeriodRangeEnd] = useState<Date>();
  const [brokerDashboardStats, setBrokerDashboardStats] = useState<any>({});
  const [period, setPeriod] = useState<any>({
    title: "This Month",
    value: "this_month",
  });
  const { isAdmin } = useIsAdmin();
  const { user } = useUser();

  useEffect(() => {
    if (isAdmin && !!broker) {
      setUserId(broker.id);
    } else if (!isAdmin && !!user) {
      setUserId(user.id);
    }
  }, [broker, user, isAdmin]);

  const fetchBrokerMetrics = useCallback(
    (cb: any) => {
      post("reports/broker-metrics/", {
        period: period?.value,
        period_range: {
          start: periodRangeStart,
          end: periodRangeEnd,
        },
        broker: userId,
        partner: partner?.id,
      }).then((response: any) => {
        cb(response);
      });
    },
    [period?.value, periodRangeEnd, periodRangeStart, userId, partner]
  );

  useEffect(() => {
    console.log(period);
    if (!isAdmin && !userId) return;

    fetchBrokerMetrics((response: any) => {
      setBrokerDashboardStats(response.data);
      if (period?.value !== "custom_range") {
        setPeriodRangeStart(new Date(response.data.period.start));
        setPeriodRangeEnd(new Date(response.data.period.end));
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [period, isAdmin, userId]);

  useEffect(() => {
    fetchBrokerMetrics((response: any) => {
      setBrokerDashboardStats(response.data);
    });
  }, [periodRangeStart, periodRangeEnd, fetchBrokerMetrics]);

  const getChartGranularity = (periodType: string) => {
    if (periodType === "today") {
      return "hour";
    } else {
      return "day";
    }
  };

  const getDate = () => {
    const leadZ = (x: any) => ("0" + `${x}`).slice(-2);
    const d = new Date();
    return `${leadZ(d.getDay())}/${leadZ(d.getMonth())}/${leadZ(
      d.getFullYear()
    )}`;
  };

  let adminFilters;
  let customRangeFilter = null;
  if (isAdmin) {
    adminFilters = (
      <>
        <InputFieldWrapper label={"Broker"}>
          <BrokerDropDownField
            onChange={setUserId}
            value={userId}
            endpoint={"team/brokers/"}
            defaultAll
          />
        </InputFieldWrapper>
      </>
    );
    if (period.value === "custom_range") {
      customRangeFilter = (
        <DateRangeSelect
          close={() => setPeriod({ value: "this_week", text: "This Week" })}
          start={periodRangeStart}
          end={periodRangeEnd}
          setStart={setPeriodRangeStart}
          setEnd={setPeriodRangeEnd}
        />
      );
    }
  }

  const brokerDash = (
    <Section className="pt-3">
      <Container1>
        <Tabs
          optionsToRight={
            <>
              <div className="flex gap-4">
                {adminFilters}
                <SearchSelectField
                  onChange={setPartner}
                  label={"Partner"}
                  placeholder={"Search partners"}
                  ep={`partners/search/`}
                  getOptionLabel={(_partner: any) => (
                    <>
                      [{_partner?.id}] {_partner?.name}
                    </>
                  )}
                />
                {customRangeFilter === null && (
                  <DropDownField
                    label={"Period"}
                    value={period.value}
                    onChange={(v) =>
                      setPeriod({ value: v, text: formatUnderCase(v) })
                    }
                    options={[
                      {
                        text: "Today",
                        value: "today",
                      },
                      {
                        text: "This Week",
                        value: "this_week",
                      },
                      {
                        text: "This Month",
                        value: "this_month",
                      },
                      {
                        text: "This Quarter",
                        value: "this_quarter",
                      },
                      {
                        text: "This Year",
                        value: "this_year",
                      },
                      ...(isAdmin
                        ? [
                            {
                              text: "Custom Range",
                              value: "custom_range",
                            },
                          ]
                        : []),
                    ]}
                  />
                )}
                {customRangeFilter}
              </div>
            </>
          }
          tabs={[
            {
              name: "Performance",
              component: (
                <>
                  <Content>
                    <Section className="">
                      <SectionHeader
                        title={"Metrics"}
                        subtitle={"High level metrics describing performance"}
                      />
                      <BoxMetricsContainer className="flex flex-wrap items-center justify-center w-full gap-4">
                        <BoxMetric
                          title={"Leads In"}
                          value={brokerDashboardStats?.leads_in?.value}
                          delta_value={
                            brokerDashboardStats?.leads_in?.delta_value
                          }
                          delta_type={
                            brokerDashboardStats?.leads_in?.delta_type
                          }
                          delta_is_positive={
                            brokerDashboardStats?.leads_in?.delta_is_positive
                          }
                          period_description={
                            brokerDashboardStats?.leads_in
                              ?.delta_period_description
                          }
                        ></BoxMetric>
                        <BoxMetric
                          title={"Rolling Open"}
                          value={brokerDashboardStats?.open?.value}
                          delta_value={brokerDashboardStats?.open?.delta_value}
                          delta_type={brokerDashboardStats?.open?.delta_type}
                          delta_is_positive={
                            brokerDashboardStats?.open?.delta_is_positive
                          }
                          period_description={
                            brokerDashboardStats?.open?.delta_period_description
                          }
                        ></BoxMetric>
                        <BoxMetric
                          title={"Rolling Lost"}
                          inverse_delta={true}
                          value={brokerDashboardStats?.lost?.value}
                          delta_value={brokerDashboardStats?.lost?.delta_value}
                          delta_type={brokerDashboardStats?.lost?.delta_type}
                          delta_is_positive={
                            brokerDashboardStats?.lost?.delta_is_positive
                          }
                          period_description={
                            brokerDashboardStats?.lost?.delta_period_description
                          }
                        ></BoxMetric>
                        <BoxMetric
                          title={"Rolling Apps Submitted"}
                          value={brokerDashboardStats?.apps_submitted?.value}
                          delta_value={
                            brokerDashboardStats?.apps_submitted?.delta_value
                          }
                          delta_type={
                            brokerDashboardStats?.apps_submitted?.delta_type
                          }
                          delta_is_positive={
                            brokerDashboardStats?.apps_submitted
                              ?.delta_is_positive
                          }
                          period_description={
                            brokerDashboardStats?.apps_submitted
                              ?.delta_period_description
                          }
                        ></BoxMetric>
                        <BoxMetric
                          title={"Rolling Settled"}
                          value={brokerDashboardStats?.settled?.value}
                          delta_value={
                            brokerDashboardStats?.settled?.delta_value
                          }
                          delta_type={brokerDashboardStats?.settled?.delta_type}
                          delta_is_positive={
                            brokerDashboardStats?.settled?.delta_is_positive
                          }
                          period_description={
                            brokerDashboardStats?.settled
                              ?.delta_period_description
                          }
                        ></BoxMetric>
                        <BoxMetric
                          title={"Rolling Commission"}
                          value={formatMoney(
                            brokerDashboardStats?.commission?.value
                          )}
                          delta_value={formatMoney(
                            brokerDashboardStats?.commission?.delta_value
                          )}
                          delta_type={
                            brokerDashboardStats?.commission?.delta_type
                          }
                          delta_is_positive={
                            brokerDashboardStats?.commission?.delta_is_positive
                          }
                          period_description={
                            brokerDashboardStats?.commission
                              ?.delta_period_description
                          }
                        ></BoxMetric>
                        <BoxMetric
                          title={"Rolling Activities Completed"}
                          value={brokerDashboardStats?.leads_in?.value}
                          delta_value={
                            brokerDashboardStats?.leads_in?.delta_value
                          }
                          delta_type={
                            brokerDashboardStats?.leads_in?.delta_type
                          }
                          delta_is_positive={
                            brokerDashboardStats?.leads_in?.delta_is_positive
                          }
                          period_description={
                            brokerDashboardStats?.leads_in
                              ?.delta_period_description
                          }
                        ></BoxMetric>
                        {isAdmin && (
                          <>
                            <BoxMetric
                              wide
                              inverse_delta
                              disclaimer={
                                "Rolling Average TTS (Time To Settlement). Excludes all deals settled in under a 15 minute time frame."
                              }
                              title={"Rolling Avg TTS"}
                              value={timeString(
                                brokerDashboardStats?.avg_time_before_settled
                                  ?.value
                              )}
                              delta_value={timeString(
                                brokerDashboardStats?.avg_time_before_settled
                                  ?.delta_value
                              )}
                              delta_type={
                                brokerDashboardStats?.avg_time_before_settled
                                  ?.delta_type
                              }
                              delta_is_positive={
                                brokerDashboardStats?.avg_time_before_settled
                                  ?.delta_is_positive
                              }
                              period_description={
                                brokerDashboardStats?.avg_time_before_settled
                                  ?.delta_period_description
                              }
                            ></BoxMetric>
                            <BoxMetric
                              wide
                              disclaimer={
                                "The 'rolling' settlement rate represents the leads in during the period / ALL settlements during the period. This may include deals settled which came in prior to this period. This metric is a work in progress."
                              }
                              title={"Rolling Settlement Rate"}
                              value={`${brokerDashboardStats?.settled_rate?.value}%`}
                              delta_value={`${brokerDashboardStats?.settled_rate?.delta_value}%`}
                              delta_type={
                                brokerDashboardStats?.settled_rate?.delta_type
                              }
                              delta_is_positive={
                                brokerDashboardStats?.settled_rate
                                  ?.delta_is_positive
                              }
                              period_description={
                                brokerDashboardStats?.settled_rate
                                  ?.delta_period_description
                              }
                            ></BoxMetric>

                            <BoxMetric
                              wide
                              disclaimer={
                                "This average was calculated excluding all leads with initial NAF greater than $400,000."
                              }
                              title={"Avg Lead NAF"}
                              value={formatMoney(
                                brokerDashboardStats?.avg_naf?.value
                              )}
                              delta_value={formatMoney(
                                brokerDashboardStats?.avg_naf?.delta_value
                              )}
                              delta_type={
                                brokerDashboardStats?.avg_naf?.delta_type
                              }
                              delta_is_positive={
                                brokerDashboardStats?.avg_naf?.delta_is_positive
                              }
                              period_description={
                                brokerDashboardStats?.avg_naf
                                  ?.delta_period_description
                              }
                            ></BoxMetric>
                            <BoxMetric
                              wide
                              disclaimer={
                                "This average was calculated excluding all leads with settlement NAF greater than $400,000."
                              }
                              title={"Rolling Avg Settled NAF"}
                              value={formatMoney(
                                brokerDashboardStats?.avg_settled_naf?.value
                              )}
                              delta_value={formatMoney(
                                brokerDashboardStats?.avg_settled_naf
                                  ?.delta_value
                              )}
                              delta_type={
                                brokerDashboardStats?.avg_settled_naf
                                  ?.delta_type
                              }
                              delta_is_positive={
                                brokerDashboardStats?.avg_settled_naf
                                  ?.delta_is_positive
                              }
                              period_description={
                                brokerDashboardStats?.avg_settled_naf
                                  ?.delta_period_description
                              }
                            ></BoxMetric>
                          </>
                        )}
                      </BoxMetricsContainer>

                      <div className="flex w-full gap-8">
                        <div className="flex-1">
                          <Section>
                            <SectionHeader
                              title={"Leads In"}
                              subtitle={
                                "Leads assigned to you during the performance period."
                              }
                            />
                            <BrokerageChartWrapper>
                              <LineChart
                                XAxisDataKey={"label"}
                                lines={[
                                  {
                                    dataKey: "Leads In",
                                    colour: constants.colors.success_700,
                                  },
                                ]}
                                tooltip={true}
                                loading={!user}
                                configuration={{
                                  period: period.value,
                                  period_range: {
                                    start: periodRangeStart,
                                    end: periodRangeEnd,
                                  },
                                  query_date_field: "created_at",
                                  model: "Deal",
                                  app: "deals",
                                  granularity: getChartGranularity(
                                    period.value
                                  ),
                                  segregate: false,
                                  data_key: "Leads In",
                                  broker: userId,
                                }}
                              />
                            </BrokerageChartWrapper>
                          </Section>
                          <Section className="mt-4">
                            <SectionHeader
                              title={"Rolling Apps Submitted"}
                              subtitle={
                                "Deals which have been submitted for application during the performance period."
                              }
                            />
                            <BrokerageChartWrapper>
                              <LineChart
                                XAxisDataKey={"label"}
                                lines={[
                                  {
                                    dataKey: "Apps Submitted",
                                    colour: constants.colors.success_700,
                                  },
                                ]}
                                tooltip={true}
                                loading={!user}
                                configuration={{
                                  period: period.value,
                                  period_range: {
                                    start: periodRangeStart,
                                    end: periodRangeEnd,
                                  },
                                  query_date_field:
                                    "application_submitted_to_lender_report_complete_time",
                                  model: "Deal",
                                  app: "deals",
                                  granularity: getChartGranularity(
                                    period.value
                                  ),
                                  segregate: false,
                                  data_key: "Apps Submitted",
                                  broker: userId,
                                }}
                              />
                            </BrokerageChartWrapper>
                          </Section>
                          <Section className="mt-4">
                            <SectionHeader
                              title={"Settlements"}
                              subtitle={
                                "Deals which have settled during the performance period."
                              }
                            />
                            <BrokerageChartWrapper>
                              <LineChart
                                XAxisDataKey={"label"}
                                lines={[
                                  {
                                    dataKey: "Settlements",
                                    colour: constants.colors.success_700,
                                  },
                                ]}
                                tooltip={true}
                                loading={!user}
                                configuration={{
                                  period: period.value,
                                  period_range: {
                                    start: periodRangeStart,
                                    end: periodRangeEnd,
                                  },
                                  query_date_field:
                                    "settled_report_complete_time",
                                  model: "Deal",
                                  app: "deals",
                                  granularity: getChartGranularity(
                                    period.value
                                  ),
                                  segregate: false,
                                  data_key: "Settlements",
                                  broker: userId,
                                }}
                              />
                            </BrokerageChartWrapper>
                          </Section>
                        </div>
                      </div>
                    </Section>
                  </Content>
                </>
              ),
            },
            {
              name: "Stats",
              component: (
                <>
                  <Content>
                    <Section className="">
                      <SectionHeader
                        title={"Financial Insights"}
                        subtitle={"These stats can help to provide insight."}
                      />
                      <BoxMetricsContainer className="flex flex-wrap items-center justify-center w-full gap-4">
                        <BoxMetric
                          wide
                          inverse_delta
                          title={"Avg Time To Settlement"}
                          value={timeString(
                            brokerDashboardStats?.avg_time_before_settled?.value
                          )}
                          delta_value={timeString(
                            brokerDashboardStats?.avg_time_before_settled
                              ?.delta_value
                          )}
                          delta_type={
                            brokerDashboardStats?.avg_time_before_settled
                              ?.delta_type
                          }
                          delta_is_positive={
                            brokerDashboardStats?.avg_time_before_settled
                              ?.delta_is_positive
                          }
                          period_description={
                            brokerDashboardStats?.avg_time_before_settled
                              ?.delta_period_description
                          }
                        ></BoxMetric>
                        <BoxMetric
                          wide
                          inverse_delta
                          title={"Avg Time Before Lost"}
                          value={timeString(
                            brokerDashboardStats?.avg_time_before_lost?.value
                          )}
                          delta_value={timeString(
                            brokerDashboardStats?.avg_time_before_lost
                              ?.delta_value
                          )}
                          delta_type={
                            brokerDashboardStats?.avg_time_before_lost
                              ?.delta_type
                          }
                          delta_is_positive={
                            brokerDashboardStats?.avg_time_before_lost
                              ?.delta_is_positive
                          }
                          period_description={
                            brokerDashboardStats?.avg_time_before_lost
                              ?.delta_period_description
                          }
                        ></BoxMetric>
                        <BoxMetric
                          wide
                          title={"Settlement Rate"}
                          value={`${brokerDashboardStats?.settled_rate?.value}%`}
                          delta_value={`${brokerDashboardStats?.settled_rate?.delta_value}%`}
                          delta_type={
                            brokerDashboardStats?.settled_rate?.delta_type
                          }
                          delta_is_positive={
                            brokerDashboardStats?.settled_rate
                              ?.delta_is_positive
                          }
                          period_description={
                            brokerDashboardStats?.settled_rate
                              ?.delta_period_description
                          }
                        ></BoxMetric>

                        <BoxMetric
                          wide
                          title={"Avg Lead NAF"}
                          value={formatMoney(
                            brokerDashboardStats?.avg_naf?.value
                          )}
                          delta_value={formatMoney(
                            brokerDashboardStats?.avg_naf?.delta_value
                          )}
                          delta_type={brokerDashboardStats?.avg_naf?.delta_type}
                          delta_is_positive={
                            brokerDashboardStats?.avg_naf?.delta_is_positive
                          }
                          period_description={
                            brokerDashboardStats?.avg_naf
                              ?.delta_period_description
                          }
                        ></BoxMetric>
                        <BoxMetric
                          wide
                          title={"Aggregated Lead NAF"}
                          value={formatMoney(
                            brokerDashboardStats?.agg_lead_naf?.value
                          )}
                          delta_value={formatMoney(
                            brokerDashboardStats?.agg_lead_naf?.delta_value
                          )}
                          delta_type={
                            brokerDashboardStats?.agg_lead_naf?.delta_type
                          }
                          delta_is_positive={
                            brokerDashboardStats?.agg_lead_naf
                              ?.delta_is_positive
                          }
                          period_description={
                            brokerDashboardStats?.agg_lead_naf
                              ?.delta_period_description
                          }
                        ></BoxMetric>
                        <BoxMetric
                          wide
                          title={"Avg Settled NAF"}
                          value={formatMoney(
                            brokerDashboardStats?.avg_settled_naf?.value
                          )}
                          delta_value={formatMoney(
                            brokerDashboardStats?.avg_settled_naf?.delta_value
                          )}
                          delta_type={
                            brokerDashboardStats?.avg_settled_naf?.delta_type
                          }
                          delta_is_positive={
                            brokerDashboardStats?.avg_settled_naf
                              ?.delta_is_positive
                          }
                          period_description={
                            brokerDashboardStats?.avg_settled_naf
                              ?.delta_period_description
                          }
                        ></BoxMetric>
                        <BoxMetric
                          wide
                          title={"Aggregated Settled NAF"}
                          value={formatMoney(
                            brokerDashboardStats?.agg_settled_naf?.value
                          )}
                          delta_value={formatMoney(
                            brokerDashboardStats?.agg_settled_naf?.delta_value
                          )}
                          delta_type={
                            brokerDashboardStats?.agg_settled_naf?.delta_type
                          }
                          delta_is_positive={
                            brokerDashboardStats?.agg_settled_naf
                              ?.delta_is_positive
                          }
                          period_description={
                            brokerDashboardStats?.agg_settled_naf
                              ?.delta_period_description
                          }
                        ></BoxMetric>
                      </BoxMetricsContainer>
                    </Section>
                  </Content>
                </>
              ),
            },
            // {
            //   name: "Activity Feed",
            //   component: (
            //     <>
            //       <SectionHeader
            //         title={"Activity Feed Today"}
            //         subtitle={
            //           "Track notifications, tasks and upcoming events relevant to you."
            //         }
            //       />
            //       <div className="m-2 rounded-md border border-slate-300 w-full min-h-[100px] p-2 shadow-md">
            //         <h1 className="text-lg">
            //           <Badge type={BadgeType.warning} text="Reminder!" />
            //         </h1>
            //         <hr />
            //         <div className="w-full mt-2 mb-2">
            //           <p>
            //             (Call James in 15 minutes): <br />
            //             Reminding you to call James who might now be
            //             interested in Finance again.
            //           </p>
            //         </div>
            //         <Button text={"Open"} wide onClick={() => {}} />
            //       </div>
            //       <div className="m-2 rounded-md border border-slate-300 w-full min-h-[100px] p-2 shadow-md">
            //         <h1 className="text-lg">
            //           <Badge type={BadgeType.success} text="Task Complete!" />
            //         </h1>
            //         <hr />
            //         <div className="w-full mt-2 mb-2">
            //           <p>
            //             (2 minutes ago): <br />
            //             Byron completed task "Settlement docs" for deal "XYZ
            //             Consulting PTY LTD".
            //           </p>
            //         </div>
            //         <Button text={"Open"} wide onClick={() => {}} />
            //       </div>
            //       <div className="m-2 rounded-md border border-slate-300 w-full min-h-[100px] p-2 shadow-md">
            //         <h1 className="text-lg">
            //           <Badge type={BadgeType.info} text="New Lead" />
            //         </h1>
            //         <hr />
            //         <div className="w-full mt-2 mb-2">
            //           <p>
            //             (5 minutes ago): <br />
            //             You've received a new lead from Lend.
            //           </p>
            //         </div>
            //         <Button text={"Open"} wide onClick={() => {}} />
            //       </div>
            //       <div className="m-2 rounded-md border border-slate-300 w-full min-h-[100px] p-2 shadow-md">
            //         <h1 className="text-lg">
            //           <Badge type={BadgeType.info} text="New Lead" />
            //         </h1>
            //         <hr />
            //         <div className="w-full mt-2 mb-2">
            //           <p>
            //             (15 minutes ago): <br />
            //             You've received a new lead.
            //           </p>
            //         </div>
            //         <Button text={"Open"} wide onClick={() => {}} />
            //       </div>
            //       <div className="m-2 rounded-md border border-slate-300 w-full min-h-[100px] p-2 shadow-md">
            //         <h1 className="text-lg">
            //           <Badge type={BadgeType.success} text="Settled!" />
            //         </h1>
            //         <hr />
            //         <div className="w-full mt-2 mb-2">
            //           <p>
            //             (25 minutes ago): <br />
            //             Your settled a deal for $7890.00, well done!
            //           </p>
            //         </div>
            //         <Button text={"Open"} wide onClick={() => {}} />
            //       </div>
            //     </>
            //   ),
            // },
          ]}
        />
      </Container1>
    </Section>
  );

  // admin here
  let header;
  if (isAdmin) {
    header = (
      <PageHeader
        title={"Administrator Dashboard"}
        subtitle={
          "Welcome to the administrator dashboard view, here you can see a full team overview."
        }
      />
    );
  } else {
    header = (
      <PageHeader
        title={"Your Dashboard"}
        subtitle={"Welcome back to your personalised dashboard!"}
        rightHandSideChildren={<Badge type={BadgeType.info} text={getDate()} />}
      />
    );
  }

  return (
    <MainWrapper>
      <Container>{header}</Container>

      {brokerDash}
    </MainWrapper>
  );
};
export default DashboardPage;
