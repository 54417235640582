export default {
  colors: {
    white: "rgb(255, 255, 255)",
    success_500: "#027A48",
    error_500: "#F04438",
    gray_500: "rgb(102, 112, 133)",
    success_700: "rgb(2, 122, 72)",
    primary_400: "rgb(217, 150, 61)",
    gray_900: "rgb(16, 24, 40)",
    gray_700: "rgb(84, 84, 84)",
    gray_600: "#475467",
    gray_300: "#D0D5DD",
    gray_100: "rgb(242, 244, 247)",
    primary_600: "rgb(174, 114, 34)",
    primary_700: "rgb(160, 108, 13)",
    gray_200: "rgb(228, 231, 236)",
    primary_50: "rgb(255, 255, 255)",
    gray_50: "rgb(249, 250, 251)",
    black: "rgb(0, 0, 0)",
    warning_400: "rgb(253, 176, 34)",
    primary_100: "rgb(255, 255, 255)",
    primary_900: "rgb(119, 81, 10)",
    primary_200: "rgb(245, 227, 203)",
    blue_50: "rgb(239, 248, 255)",
    blue_700: "rgb(23, 92, 211)",
    blue_400: "rgb(60, 110, 255)",
    indigo_50: "rgb(238, 244, 255)",
    indigo_700: "rgb(53, 56, 205)",
    pink_50: "rgb(253, 242, 250)",
    pink_700: "rgb(193, 21, 116)",
    orange_50: "rgb(255, 246, 237)",
    orange_700: "rgb(196, 50, 10)",
    success_600: "rgb(3, 152, 85)",
    avatar_user_square_olivia_rhye_color_background_: "rgb(199, 185, 218)",
    success_50: "rgb(236, 253, 243)",
    primary_500: "rgb(194, 127, 38)",
    danger_500: "rgb(255, 120, 120)",
    gradient_grey_900_600_45deg_: `linear-gradient(45deg, rgba(16, 24, 40, 1) 0%, rgba(71, 84, 103, 1) 100%)`,
    warning_300: "rgb(254, 200, 75)",
  },
};
