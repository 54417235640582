import { useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  setDeal,
  setDealLoading,
  setDealContactMade,
  resetDeal,
} from "reducers/deal";
import { dealAPI, constants } from "api";
import { get } from "utilities/Network";
import useUser from "./useUser";

type UseDealProps = {
  isPartnerView?: boolean;
};

/**
 *
 * @returns { fetchDeal, deal, loading, setFirstContactCompleted, clearDeal }
 */
const useDeal = ({ isPartnerView = false }: UseDealProps = {}) => {
  const dispatch = useDispatch();
  const { deal, loading } = useSelector((state: any) => state.deal);

  /**
   * Allows mutation of the current deal state without hitting the api
   */
  const updateDeal = (updatedDeal: any) => {
    console.log("updated deal");
    console.log(updatedDeal);
    dispatch(setDeal(updatedDeal));
  };

  const setFirstContactCompleted = () => {
    dispatch(setDealContactMade());
  };

  const clearDeal = useCallback(() => {
    dispatch(resetDeal());
  }, [dispatch]);

  const fetchDeal = useCallback(
    (id: any) => {
      dispatch(setDealLoading(constants.LoadingState.pending));
      dispatch(setDeal({}));

      (isPartnerView ? get(`deals/partners/${id}/`) : get(`deals/${id}/`))
        .then((response) => {
          dispatch(setDeal(response.data));
          dispatch(setDealLoading(constants.LoadingState.success));
        })
        .catch(() => {
          dispatch(setDealLoading(constants.LoadingState.failed));
          setTimeout(
            () => dispatch(setDealLoading(constants.LoadingState.failed)),
            5000
          );
        });
    },
    [dispatch, isPartnerView]
  );

  return {
    fetchDeal,
    deal,
    loading,
    setFirstContactCompleted,
    clearDeal,
    updateDeal,
  };
};

export default useDeal;
