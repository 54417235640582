import styled from "styled-components";

export const BoxMetricsContainer = styled.div`
  & div {
    margin-right: 0px;
  }
  margin-bottom: 20px;
  display: flex;
`;
export const MainWrapper = styled.div`
  background: rgba(255, 255, 255, 1);
  padding: 0px 0px 48px 0px;
  gap: 30px;
  width: 100%;
`;
export const HeaderSection = styled.div`
  display: flex;
  align-self: stretch;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  margin-bottom: 32px;
`;
export const Container = styled.div`
  display: flex;
  align-self: stretch;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px 32px 0px 32px;
  gap: 24px;
`;
export const Divider = styled.div`
  display: flex;
  background: rgba(228, 231, 236, 1);
  width: 100%;
  height: 1px;
`;
export const Section = styled.div`
  display: flex;
  align-self: stretch;
  flex-direction: column;
  align-items: flex-start;
`;
export const MetricGroup = styled.div`
  width: 100%;
`;
export const Container1 = styled.div`
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  padding: 0px 32px 0px 32px;
  gap: 32px;
`;
export const Sidebar = styled.div`
  display: flex;
  width: 348px;
  min-height: 300px;
  flex-direction: column;
  align-items: center;
  gap: 0px;
  border-right: 1px solid ${({ theme }) => theme.colors.gray_200};
  padding-right: 32px;
`;
export const Content = styled.div`
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  align-items: flex-start;
  gap: 32px;
`;

export const BrokerageChartWrapper = styled.div`
  width: 100%;
  height: 300px;
`;

export const MetricSection = styled.div`
  width: 100%;
`;
