import axios from "axios";
import { toast } from "react-toastify";
import { clearSession } from "utilities/Authentication";

// response interceptor to check for 401 and force logout if required
axios.interceptors.response.use(
  (res) => res,
  (error) => {
    if (
      (error.response.status === 403 || error.response.status === 401) &&
      error.response.request.responseURL.split("/").indexOf("login") === -1
    ) {
      toast.error(
        error.response.data.error_message
          ? error.response.data.error_message
          : "Sorry you don't have permission to perform that action.",
        {
          theme: "dark",
        }
      );
      // response 403, logout, clear axios headers and clear session
      if (error.response.status === 403) {
        axios.defaults.headers.common.authorization = "";
        window.location.pathname = "/login";
        clearSession();
      }
    }
    return Promise.reject(error);
  }
);

export const host = process.env.REACT_APP_FINSTEAD_HOST;

export const fsGetEndpoint = (ep) => {
  return `${host}/${ep}`;
};

export const isPartnerSite = () => {
  let subdomain = /:\/\/([^\/]+)/.exec(window.location.href)[1].split(".")[0];
  return (
    subdomain !== "finstead" &&
    subdomain !== "localhost:3000" &&
    subdomain !== "internal"
  );
};

export const fetch = (ep, config) => axios.get(fsGetEndpoint(ep), config);
export const post = (ep, pl, config = {}) =>
  axios.post(fsGetEndpoint(ep), pl, config);
export const put = (ep, pl) => axios.put(fsGetEndpoint(ep), pl);
export const get = (ep) => axios.get(fsGetEndpoint(ep));
export const destroy = (ep) => axios.delete(fsGetEndpoint(ep));
export const options = (ep) => axios.options(fsGetEndpoint(ep));
