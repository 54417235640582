import BrokerDropDownField from "components/inputs/BrokerDropDownField";
import React, { useState, useEffect } from "react";
import LoadingBar from "react-top-loading-bar";
import constants from "styles/constants";
import {
  FilterBarContainer,
  SearchBarContainer,
  SearchBarInput,
  SearchIcon,
  LoaderIcon,
} from "./FilterBar.styled";

type FilterBarProps = {
  setFilter: (value: any) => void;
  brokers?: boolean;
  defaultBrokerId?: number;
  filterInjection?: any;
  rightItem?: any;
};

const FilterBar: React.FC<FilterBarProps> = ({
  setFilter,
  brokers,
  defaultBrokerId,
  filterInjection,
  rightItem,
}) => {
  const [searchQuery, setSearchQuery] = useState("");
  const [brokerId, setBrokerId] = useState(defaultBrokerId);
  const [applyFilterTimeout, setApplyFilterTimeout] = useState<any>();

  const updateSearchQuery = (e: any) => {
    if (!!applyFilterTimeout) {
      clearTimeout(applyFilterTimeout);
    }
    setSearchQuery(e.target.value);
  };

  useEffect(() => {
    setApplyFilterTimeout(
      setTimeout(() => {
        let brokerFilter = "";
        if (!!brokerId) {
          brokerFilter = `&bid=${brokerId}`;
        }
        if (!!filterInjection) {
          let obj = Object.fromEntries(
            new URLSearchParams(`s=${searchQuery}${brokerFilter}`)
          );
          obj = {
            ...obj,
            ...filterInjection,
          };
          setFilter(new URLSearchParams(obj).toString());
        } else {
          setFilter(`s=${searchQuery}${brokerFilter}`);
        }
      }, 300)
    );
  }, [searchQuery, setFilter, brokerId, filterInjection]);

  return (
    <FilterBarContainer>
      <SearchBarContainer className="gap-3">
        <SearchIcon className="pl-2">
          <svg
            width="18"
            height="18"
            viewBox="0 0 18 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M16.5 16.5L12.875 12.875M14.8333 8.16667C14.8333 11.8486 11.8486 14.8333 8.16667 14.8333C4.48477 14.8333 1.5 11.8486 1.5 8.16667C1.5 4.48477 4.48477 1.5 8.16667 1.5C11.8486 1.5 14.8333 4.48477 14.8333 8.16667Z"
              stroke="#667085"
              stroke-width="1.66667"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </SearchIcon>
        <SearchBarInput
          placeholder={"Search"}
          onChange={updateSearchQuery}
          value={searchQuery}
        />
        {brokers && (
          <BrokerDropDownField
            onChange={setBrokerId}
            allowDefault={true}
            placeholder={"All Brokers"}
            value={brokerId || `${defaultBrokerId}`}
            endpoint={"team/brokers/"}
          />
        )}
        {!!rightItem && rightItem}
      </SearchBarContainer>
    </FilterBarContainer>
  );
};

export default FilterBar;
