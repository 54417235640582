import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import Tabs from "components/core/Tabs";
import {
  MainWrapper,
  Container,
  Section,
} from "pages/TeamPage/TeamPage.styled";
import PageHeader from "components/layout/PageHeader";
import { get } from "utilities/Network";
import InfoBox from "components/core/InfoBox";
import IconBase from "components/core/IconBase";
import Badge, { BadgeType } from "components/core/Badge";
import TableComponent from "components/core/TableComponent";
import { ReactComponent as infoIcon } from "assets/icons/info.svg";
import { ReactComponent as userIcon } from "assets/icons/user.svg";
import constants from "styles/constants";
import { useNavigate } from "react-router-dom";

const ProductDetailPage = () => {
  const navigate = useNavigate();
  const { productId, lenderId } = useParams();
  const [product, setProduct] = useState<any>();
  const [lender, setLender] = useState<any>();

  const fetchProduct = () => {
    get(`lenders/products/${productId}`).then((response) =>
      setProduct(response.data)
    );
  };
  const fetchLender = () => {
    get(`lenders/${lenderId}/`).then((response) => setLender(response.data));
  };
  useEffect(fetchLender, [lenderId]);
  useEffect(fetchProduct, [productId, lenderId]);

  return (
    <MainWrapper>
      <Container>
        <PageHeader
          title={`${product?.name} (Lender: ${lender?.business_name})`}
          subtitle={<>{productId}</>}
          breadcrumbs={[
            {
              title: "Lenders",
              link: "/lenders",
            },
            {
              title: `${lenderId}`,
              link: `/lenders/${lenderId}`,
            },
            {
              title: `Products`,
              link: `/lenders/${lenderId}/products/`,
            },
          ]}
        />
      </Container>
      <Section>
        <div className="flex flex-wrap-reverse gap-8 w-full pt-4">
          <Tabs
            tabs={[
              {
                name: "Details",
                component: (
                  <div className="w-1/2">
                    <InfoBox
                      title={"Product Details"}
                      icon={
                        <IconBase
                          src={infoIcon}
                          color={constants.colors.primary_600}
                        />
                      }
                      info={product || {}}
                    />
                  </div>
                ),
              },
              {
                name: "Deals",
                component: (
                  <>
                    <TableComponent
                      endpoint={`lenders/products/${productId}/deals/`}
                      onRowClick={(row: any, newTab = false) => {
                        if (newTab) {
                          window.open(
                            `https://crm.finstead.com.au/deals/${row.id}`,
                            "_blank",
                            "noreferrer"
                          );
                        } else {
                          navigate(`/deals/${row.id}`);
                        }
                      }}
                      fields={{
                        id: {
                          name: "Id",
                        },
                        pipedrive_id: {
                          name: "Pipedrive Id",
                        },
                        broker: {
                          name: "Broker",
                          middleware: (row: any) => (
                            <>
                              {!!row.broker_profile_picture_url ? (
                                <img
                                  src={row.broker_profile_picture_url}
                                  className="w-[25px] h-[25px] object-cover rounded-full inline-block mr-2"
                                />
                              ) : (
                                <span className="inline-block mr-2 border border-slate-100 rounded-full">
                                  <IconBase src={userIcon} />
                                </span>
                              )}
                              <span>{row.broker}</span>
                            </>
                          ),
                        },
                        title: {
                          name: "Title",
                        },
                        add_time: {
                          name: "Lead In",
                        },
                        value: {
                          name: "Value",
                        },
                        status: {
                          name: "Status",
                          middleware: (row: any) => {
                            switch (row.status) {
                              case "":
                                return "";
                              case "open":
                                return (
                                  <Badge type={BadgeType.info} text="Open" />
                                );
                              case "lost":
                                return (
                                  <Badge type={BadgeType.danger} text="Lost" />
                                );
                              case "pending assignment":
                                return (
                                  <Badge
                                    type={BadgeType.info}
                                    text={"Lead In"}
                                  />
                                );
                              case "won":
                              case "settled":
                                return (
                                  <Badge
                                    type={BadgeType.success}
                                    text={"Settled"}
                                  />
                                );
                              default:
                                return (
                                  <Badge
                                    type={BadgeType.warning}
                                    text={"Unknown ?"}
                                  />
                                );
                            }
                          },
                        },
                      }}
                    />
                  </>
                ),
              },
            ]}
          />
        </div>
      </Section>
    </MainWrapper>
  );
};

export default ProductDetailPage;
