import React, { useState } from "react";
import Button from "components/core/Button";
import * as yup from "yup";
import { useForm, useWatch } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import DropDownField from "components/inputs/DropDownField";
import { post } from "utilities/Network";

export type FormData = {
  lost_reason: string;
};

export const FormSchema = yup.object({
  lost_reason: yup
    .string()
    .oneOf(["no_longer_required", "no_contact", "other"])
    .required("You must provide a lost reason"),
});

export const RequiredFieldsMap = {
  lost_reason: false,
};

type LostReportProps = {
  deal: any;
  triggerModalClose: any;
};

const LostReportForm: React.FC<LostReportProps> = ({
  deal,
  triggerModalClose,
}) => {
  const [submitting, setSubmitting] = useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormData>({
    resolver: yupResolver(FormSchema),
  });
  const onSubmit = (formData: FormData) => {
    setSubmitting(false);
    post(`deals/${deal.id}/lost-report/`, formData).then((reponse) => {
      triggerModalClose(true);
    });
  };

  return (
    <form>
      <DropDownField
        formControlProps={register("lost_reason")}
        label={"Lost Reason"}
        disabled={submitting}
        errorMessage={errors.lost_reason?.message}
        required={!!RequiredFieldsMap.lost_reason}
        placeholder={"Please select a lost reason."}
        options={[
          { text: "No Longer Required", value: "no_longer_required" },
          { text: "No Contact", value: "no_contact" },
          { text: "Other", value: "other" },
        ]}
      />

      <Button
        onClick={handleSubmit(onSubmit)}
        text={"Confirm Deal Lost"}
        primary
        disabled={submitting}
        wide={true}
      />
    </form>
  );
};

export default LostReportForm;
