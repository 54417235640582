import React, { useMemo } from "react";
import GlobalStyles from "styles/global";
import { ToastContainer } from "react-toastify";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import NoAuthWrapperPage from "pages/NoAuthWrapperPage";
import PartnerAppContainer from "partner_pages/PartnerAppContainer";
import styled from "styled-components";
import { ThemeProvider } from "styled-components";
import constants from "styles/constants";
import Intercom from "partner_pages/components/Intercom";
import PartnerDealsPage from "partner_pages/PartnerDealsPage";
import PartnerDealDetailView from "partner_pages/PartnerDealDetailView";
import PartnerDashboardPage from "partner_pages/PartnerDashboardPage";
import { useWindowSize } from "usehooks-ts";
import Logo from "components/core/Logo";
import PartnerAppAPIPage from "partner_pages/PartnerAppAPIPage";
import PartnerWelcomePage from "partner_pages/PartnerWelcomePage";

const Container = styled.div`
  height: 100vh;
  width: 100vw;
`;

const PartnerApp = () => {
  const { width } = useWindowSize();

  const isMobile = useMemo(() => width < 768, [width]);

  if (isMobile) {
    return (
      <div className="w-[100vw] h-[100vh] flex items-center justify-center bg-black text-white font-xl flex-col gap-8">
        <Logo />
        <h1 className="max-w-[350px] text-center">
          Our partner platform isn't currently available on mobile sized
          displays.
        </h1>
      </div>
    );
  }

  return (
    <Container>
      <GlobalStyles />
      <ToastContainer pauseOnHover style={{ zIndex: "999999999" }} />
      <Intercom />

      <ThemeProvider theme={constants}>
        <Router>
          <PartnerAppContainer>
            <Routes>
              <Route path="/" element={<PartnerDashboardPage />} />
              <Route path="/welcome/:token" element={<PartnerWelcomePage />} />
              <Route path="/deals" element={<PartnerDealsPage />} />
              <Route
                path="/deals/:dealId"
                element={<PartnerDealDetailView />}
              />
              <Route path="/api" element={<PartnerAppAPIPage />} />
              <Route
                path="/login"
                element={<NoAuthWrapperPage isPartnerPortal={true} />}
              />
              <Route
                path="/password-reset"
                element={<NoAuthWrapperPage isPartnerPortal={true} />}
              />
              <Route
                path="/reset-mfa"
                element={<NoAuthWrapperPage isPartnerPortal={true} />}
              />
              <Route
                path="/challenge-mfa"
                element={<NoAuthWrapperPage isPartnerPortal={true} />}
              />
            </Routes>
          </PartnerAppContainer>
        </Router>
      </ThemeProvider>
    </Container>
  );
};

export default PartnerApp;
