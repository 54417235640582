import React, { useState, useCallback } from "react";
import Button from "components/core/Button";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import axios from "axios";
import FileDropZone from "components/inputs/FileDropZone";
import InputField from "components/inputs/InputField";
import { get, post } from "utilities/Network";
import LoadingBar from "react-top-loading-bar";
import constants from "styles/constants";
import { ReactComponent as checkIcon } from "assets/icons/check-circle.svg";
import ProgressBar from "@ramonak/react-progress-bar";
import IconBase from "components/core/IconBase";

export type FormData = {
  name: string;
  description: string;
  file: any;
};

export const FormSchema = yup.object({
  name: yup.string().min(2).required("You must give this new file a name"),
  description: yup.string().nullable(),
  file: yup.mixed().required("You must provide a file to upload"),
});

export const RequiredFieldsMap = {
  name: true,
  description: false,
  file: true,
};

const FileUploadForm = ({ presignUrlEndpoint, filesPath, closeForm }: any) => {
  const [submitting, setSubmitting] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [uploadComplete, setUploadComplete] = useState(false);

  const onSubmit = (formData: FormData) => {
    // fetch a presigned url for the files to upload
    setSubmitting(true);

    post(presignUrlEndpoint, [
      {
        path: filesPath,
        key: formData.name,
        mime_type: formData.file.type,
      },
    ]).then((response) => {
      console.log("presigned_urls");
      console.log();

      // upload the files to the presigned urls
      console.log("performing upload");
      axios
        .put(
          response.data.presigned_upload_urls[formData.name].presigned_url,
          formData.file,
          {
            onUploadProgress: (e) => {
              setUploadProgress(Math.round((e.loaded * 100) / e.total));
            },
            headers: {
              "Content-Type": formData.file.type,
            },
            transformRequest: [
              (data, headers) => {
                delete (headers!.common as any).authorization;
                console.log(headers!.common);
                return data;
              },
            ],
          }
        )
        .then(() => {
          setUploadComplete(true);
        });
    });
  };

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
    setValue,
  } = useForm<FormData>({
    resolver: yupResolver(FormSchema),
  });

  const onDrop = useCallback(
    (file: any) => {
      // Do something with the files
      setValue("file", file);
    },
    [setValue]
  );

  if (uploadComplete) {
    return (
      <div className="flex justify-center items-center w-full h-full">
        <div className="text-center">
          <div className="w-full flex justify-center items-center">
            <IconBase
              src={checkIcon}
              color={constants.colors.success_500}
              size={"50px"}
            />
          </div>
          <br />
          <p className="max-w-[200px]">
            The file was uploaded successfully, you may now close this side
            pane.
          </p>
          <br />
          <Button text="Close" onClick={closeForm} />
        </div>
      </div>
    );
  }

  return (
    <form>
      <InputField
        formControlProps={register("name")}
        label={"File Name"}
        disabled={submitting}
        errorMessage={errors.name?.message}
        required={!!RequiredFieldsMap.name}
        placeholder={"Provide a name for the file"}
      />
      <InputField
        formControlProps={register("description")}
        label={"Short Description"}
        disabled={submitting}
        errorMessage={errors.description?.message}
        required={!!RequiredFieldsMap.description}
        placeholder={"Provide a short description of the file contents"}
      />
      <FileDropZone
        formControlProps={register("file")}
        label={"File Upload"}
        disabled={submitting}
        errorMessage={`${errors.file?.message}`}
        required={!!RequiredFieldsMap.file}
        onDrop={onDrop}
      />
      {submitting && (
        <div className="mt-2 mb-2">
          <LoadingBar
            color={constants.colors.primary_600}
            progress={uploadProgress}
            onLoaderFinished={() => setUploadProgress(-1)}
            loaderSpeed={1000}
          />
          <ProgressBar
            completed={uploadProgress}
            bgColor={constants.colors.primary_600}
          />
        </div>
      )}
      <Button
        onClick={handleSubmit(onSubmit)}
        text={"Upload File"}
        primary
        disabled={submitting}
        wide={true}
      />
    </form>
  );
};

export default FileUploadForm;
