import React from "react";
import classNames from "classnames";

export enum BadgeType {
  success = "bg-green-500",
  danger = "bg-red-500",
  warning = "bg-orange-500",
  info = "bg-blue-500",
  secondary = "bg-slate-500",
}

type BadgeProps = {
  text: string;
  type: BadgeType;
  sm?: boolean;
  rounded?: boolean;
  onClick?: () => void;
};

const Badge: React.FC<BadgeProps> = ({ text, type, sm, rounded, onClick }) => {
  return (
    <div
      className={classNames(
        {
          "text-sm": sm,
          "rounded-md": rounded,
          "cursor-pointer": !!onClick,
        },
        type,
        "text-white",
        "min-w[30px]",
        "text-center",
        "m-1",
        "p-1",
        "pl-2",
        "pr-2"
      )}
      onClick={onClick}
    >
      {text}
    </div>
  );
};

export default Badge;
