import React, { useEffect, useState } from "react";
import PageContainer from "containers/PageContainer";
import DataProvider from "containers/DataProvider";
import UserLeadDistributionConfigurationComponent from "./components/UserLeadDistributionConfigurationComponent";
import {
  MainWrapper,
  Container,
  Section,
} from "pages/TeamPage/TeamPage.styled";
import PageHeader from "components/layout/PageHeader";

import "./styles.css";
import { get } from "utilities/Network";

const LeadDistributionPage = () => {
  const [userDistributionConfigurations, setUserDistributionConfigurations] =
    useState<any>();
  const [fetchTrigger, setFetchTrigger] = useState(false);
  const triggerFetch = () => {
    setFetchTrigger(!fetchTrigger);
  };

  const crumbs = [
    {
      label: "Overview",
    },
  ];

  const renderConfigurations = () =>
    Object.keys(userDistributionConfigurations).map((userEmail, i) => (
      <div className="mb-4 border-b border-b-slate-400">
        <UserLeadDistributionConfigurationComponent
          triggerFetch={triggerFetch}
          key={i}
          userEmail={userEmail}
          config={userDistributionConfigurations[userEmail]}
        />
      </div>
    ));

  useEffect(() => {
    get("leads/distribution-configuration-rules").then((response) =>
      setUserDistributionConfigurations(response.data)
    );
  }, [fetchTrigger]);

  return (
    <>
      <MainWrapper>
        <Container>
          <PageHeader
            title={"Lead Distribution"}
            subtitle={
              "Here you can manage which broker receive leads on a given week day."
            }
          />
        </Container>
        <Section>
          {userDistributionConfigurations ? renderConfigurations() : null}
        </Section>
      </MainWrapper>
    </>
  );
};

export default LeadDistributionPage;
