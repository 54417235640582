import React, { useState } from "react";
import {
  MainWrapper,
  Container,
  Section,
} from "pages/TeamPage/TeamPage.styled";
import Button from "components/core/Button";
import TableComponent from "components/core/TableComponent";
import PageHeader from "components/layout/PageHeader";
import FilterBar from "components/core/FilterBar";
import Badge, { BadgeType } from "components/core/Badge";

type TasksPageProps = {};

const TasksPage: React.FC<TasksPageProps> = ({}) => {
  const newTask = () => {};
  const [filter, setFilter] = useState();
  return (
    <MainWrapper>
      <Container>
        <PageHeader
          title={"Tasks"}
          subtitle={"Shared tasks"}
          rightHandSideChildren={
            <Button onClick={newTask} text={"New Task"} primary />
          }
        />
      </Container>
      <Section>
        <FilterBar setFilter={setFilter} />
        <TableComponent
          title={"Tasks"}
          endpoint="tasks/"
          filter={filter}
          fields={{
            id: { name: "Task ID" },
            task_type: { name: "Task Type" },
            team_group: { name: "Assigned To" },
            name: { name: "Name" },
            age: { name: "Created" },
            completed: {
              name: "Completed",
              middleware: (row: any) =>
                row.completed ? (
                  <Badge type={BadgeType.success} text={"Completed"} />
                ) : (
                  <Badge type={BadgeType.info} text={"Pending"} />
                ),
            },
          }}
        />
      </Section>
    </MainWrapper>
  );
};

export default TasksPage;
