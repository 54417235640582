import InfoBox from "components/core/InfoBox";
import LoaderLogo from "components/core/LoaderLogo";
import React, { useEffect, useState } from "react";
import { get } from "utilities/Network";

type Props = {
  dealId: any;
};
const DealSettlementSummary: React.FC<Props> = ({ dealId }) => {
  const [notSettled, setNotSettled] = useState(false);
  const [settlement, setSettlement] = useState<any>();
  const [error, setError] = useState(false);
  useEffect(() => {
    get(`deals/${dealId}/settlement-details/`)
      .then((response) => {
        if (!response.data.settled) {
          setNotSettled(true);
        }
        setSettlement(response.data);
      })
      .catch((response) => {
        setError(true);
      });
  }, [dealId]);

  if (notSettled) {
    return (
      <div>
        <p>This deal has not been settled yet.</p>
        <img
          width="100%"
          src="https://media.tenor.com/lx2WSGRk8bcAAAAC/pulp-fiction-john-travolta.gif"
        />
      </div>
    );
  }

  if (error) {
    return (
      <div>
        <p className="text-red-600">
          Something went wrong loading the settlement review.
        </p>
      </div>
    );
  }

  if (!settlement) {
    return (
      <div className="w-full h-full p-4 flex items-center justify-center">
        <LoaderLogo />
      </div>
    );
  }

  return (
    <div className="w-full h-full p-4">
      <div className="w-full flex flex-row items-center gap-6">
        <div className="text-lg w-1/3 text-right">Time to Settlement: </div>
        <h2 className="text-xl w-2/4 text-left">{settlement.deal_duration}</h2>
      </div>
      <div className="w-full flex flex-row items-center gap-6">
        <div className="text-lg w-1/3 text-right">
          Settlement Rank (Speed of Settlement):{" "}
        </div>
        <h2 className="text-xl w-2/4 text-left">
          #{settlement.settlement_rank}
        </h2>
      </div>

      <div className="w-full flex flex-row items-center gap-6 mt-1">
        <div className="text-lg w-1/3 text-right">Lender: </div>
        <h2 className="text-xl w-2/4 text-left">{settlement?.lender?.name}</h2>
      </div>

      <div className="w-full flex flex-row items-center gap-6 mt-1">
        <div className="text-lg w-1/3 text-right">Product: </div>
        <h2 className="text-xl w-2/4 text-left">{settlement?.product?.name}</h2>
      </div>

      {!!settlement.referrer?.name && (
        <div className="w-full flex flex-row items-center gap-6 mt-1">
          <div className="text-lg w-1/3 text-right">Referrer: </div>
          <h2 className="text-xl w-2/4 text-left">
            {settlement.referrer?.name || "unknown"}
          </h2>
        </div>
      )}

      <div className="w-full flex flex-row items-center gap-6 mt-1">
        <div className="text-lg w-1/3 text-right">Term: </div>
        <h2 className="text-xl w-2/4 text-left">{settlement.loan.term}</h2>
      </div>

      <div className="w-full flex flex-row items-center gap-6 mt-1">
        <div className="text-lg w-1/3 text-right">Interest Rate: </div>
        <h2 className="text-xl w-2/4 text-left">
          {settlement.loan.interest_rate}
        </h2>
      </div>

      <div className="w-full flex flex-row items-center gap-6 mt-1">
        <div className="text-lg w-1/3 text-right">Balloon: </div>
        <h2 className="text-xl w-2/4 text-left">{settlement.loan.balloon}</h2>
      </div>

      <div className="w-full flex flex-row items-center gap-6 mt-1">
        <div className="text-lg w-1/3 text-right">Brokerage: </div>
        <h2 className="text-xl w-2/4 text-left">{settlement.loan.brokerage}</h2>
      </div>

      <div className="w-full flex flex-row items-center gap-6 mt-1">
        <div className="text-lg w-1/3 text-right">Deposit: </div>
        <h2 className="text-xl w-2/4 text-left">{settlement.loan.deposit}</h2>
      </div>

      <div className="w-full flex flex-row items-center gap-6 mt-1">
        <div className="text-lg w-1/3 text-right">
          Net Amount Financed (NAF):{" "}
        </div>
        <h2 className="text-xl w-2/4 text-left">{settlement.loan.naf}</h2>
      </div>

      <div className="w-full flex flex-row items-center gap-6 mt-1">
        <div className="text-lg w-1/3 text-right">
          Dealer Origination Fee (DOF):{" "}
        </div>
        <h2 className="text-xl w-2/4 text-left">{settlement.loan.dof}</h2>
      </div>

      <div className="w-full flex flex-row items-center gap-6 mt-1">
        <div className="text-lg w-1/3 text-right">Referrer Commission: </div>
        <h2 className="text-xl w-2/4 text-left">
          {settlement.loan.referral_partner_commission}
        </h2>
      </div>
    </div>
  );
};

export default DealSettlementSummary;
