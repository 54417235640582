import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import InputField from "components/inputs/InputField";
import Button from "components/core/Button";
import { post } from "utilities/Network";
import LoaderLogo from "components/core/LoaderLogo";
import { ReactComponent as checkCircle } from "assets/icons/check-circle.svg";
import IconBase from "components/core/IconBase";
import useFileManager from "hooks/useFileManager";

type FormData = {
  invoiceDate: string;
  customerTradingName: string;
  customerABN: string;
  assetDescription: string;
  amountPayable: number;
  loanType: string;
  loanReference: string;
};

const FormSchema = yup.object().shape({
  invoiceDate: yup.date().required("Invoice Date is required"),
  customerTradingName: yup
    .string()
    .required("Customer Trading Name is required")
    .min(1, "Customer Trading Name must be at least 1 character long")
    .max(255, "Customer Trading Name can be at most 255 characters long"),
  customerABN: yup
    .string()
    .required("Customer ABN is required")
    .min(1, "Customer ABN must be at least 1 character long")
    .max(255, "Customer ABN can be at most 255 characters long"),
  assetDescription: yup
    .string()
    .required("Asset Description is required")
    .min(1, "Asset Description must be at least 1 character long")
    .max(255, "Asset Description can be at most 255 characters long"),
  amountPayable: yup
    .number()
    .transform((value) => (isNaN(value) ? undefined : value))
    .required("Amount Payable $ is required")
    .positive("Amount Payable $ must be a positive number"),
  loanType: yup
    .string()
    .required("Loan Type is required")
    .min(1, "Loan Type must be at least 1 character long")
    .max(255, "Loan Type can be at most 255 characters long"),
  loanReference: yup
    .string()
    .required("Loan Reference is required")
    .min(1, "Loan Reference must be at least 1 character long")
    .max(255, "Loan Reference can be at most 255 characters long"),
});

type Props = {
  dealId: number;
};

const ApplicantRCTIForm: React.FC<Props> = ({ dealId }) => {
  const [generating, setGenerating] = useState(false);
  const [generated, setGenerated] = useState(false);
  const [fileId, setFileId] = useState<number | undefined>();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormData>({
    resolver: yupResolver(FormSchema),
  });

  const { downloadFile, deleteFile, updateFile } = useFileManager({
    fileId: fileId,
  });

  const onSubmit = (formData: FormData) => {
    setGenerating(true);

    post(`files/deals/${dealId}/applicant-rcti/generate/`, {
      ...formData,
    }).then((response) => {
      setFileId(response.data.file_id);
      setGenerating(false);
      setGenerated(true);
    });

    console.log(formData);
  };

  if (generating) {
    return (
      <div className="w-full h-full flex justify-center items-center  flex-col">
        <LoaderLogo light />
        <br />
        <p>Generating Invoice - Please wait this will take a few moments....</p>
      </div>
    );
  }

  if (generated) {
    return (
      <div className="w-full h-full flex justify-center items-center flex-col">
        <div className="max-h-[150px]">
          <IconBase src={checkCircle} color="green" />
        </div>
        <h1 className="font-bold text-lg">Success</h1>
        <br />
        <p>
          The Applicant RCTI has been generated and added as a file to this
          deal.
        </p>
        <Button
          text={"Download Now"}
          width={"250px"}
          className="mt-4 text-white"
          info
          onClick={() => {
            downloadFile();
          }}
        ></Button>
      </div>
    );
  }

  return (
    <form className="pb-4 max-w-[700px]" onSubmit={handleSubmit(onSubmit)}>
      <InputField
        formControlProps={register("invoiceDate")}
        label={"Invoice Date"}
        type={"date"}
        errorMessage={errors.invoiceDate?.message}
      />
      <InputField
        formControlProps={register("customerTradingName")}
        label="Customer Trading Name"
        errorMessage={errors.customerTradingName?.message}
      />
      <InputField
        formControlProps={register("customerABN")}
        label="Customer ABN"
        errorMessage={errors.customerABN?.message}
      />
      <InputField
        formControlProps={register("assetDescription")}
        label="Asset Description"
        errorMessage={errors.assetDescription?.message}
      />
      <InputField
        formControlProps={register("amountPayable")}
        label="Amount Payable $"
        errorMessage={errors.amountPayable?.message}
        type={"number"}
        step={"0.01"}
      />
      <InputField
        formControlProps={register("loanType")}
        label="Loan Type"
        errorMessage={errors.loanType?.message}
      />
      <InputField
        formControlProps={register("loanReference")}
        label="Loan Reference"
        errorMessage={errors.loanReference?.message}
      />

      <div className="w-full text-right">
        <Button
          type={"submit"}
          primary
          text={"Generate Invoice"}
          onClick={() => {}}
          width={"250px"}
        ></Button>
      </div>
    </form>
  );
};

export default ApplicantRCTIForm;
