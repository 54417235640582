import React, { useEffect, useState } from "react";
import SideMenu from "components/layout/SideMenu";

import { useNavigate, useLocation } from "react-router-dom";
import axios from "axios";
import {
  Container,
  PageContainer,
} from "components/layout/AppContainer/AppContainer.styled";
import { ReactComponent as DashboardIcon } from "assets/icons/layout.svg";
import { ReactComponent as usersIcon } from "assets/icons/users.svg";
import { ReactComponent as reportsIcon } from "assets/icons/trending-up.svg";
import { ReactComponent as settlementsIcon } from "assets/icons/coin-stack.svg";
import { ReactComponent as lendersIcon } from "assets/icons/coins.svg";
import { ReactComponent as partnersIcon } from "assets/icons/hexagon.svg";
import { ReactComponent as dealsIcon } from "assets/icons/zap.svg";
import { ReactComponent as taskIcon } from "assets/icons/clipboard.svg";
import { ReactComponent as groupsIcon } from "assets/icons/globe.svg";
import { ReactComponent as organisationIcon } from "assets/icons/list.svg";
import { ReactComponent as applicantsIcon } from "assets/icons/users.svg";
import { ReactComponent as distributionIcon } from "assets/icons/share-2.svg";
import { ReactComponent as activitiesIcon } from "assets/icons/calendar.svg";

import {
  getLocalStorageItem,
  setLocalStorageItem,
} from "utilities/LocalStorage";

import { clearSession } from "utilities/Authentication";

export type AppContainerProps = {
  children?: JSX.Element;
};

const locationsToHideSideMenu = [
  "/login",
  "/redirect",
  "/reset-password/",
  "/password-reset",
  "/reset-mfa/",
  "/challenge-mfa/",
  "/welcome/",
  "/report",
];
const noAuthLocations = [
  "/login",
  "/redirect",
  "/reset-password",
  "/password-reset",
  "/reset-mfa",
  "/challenge-mfa",
  "/welcome",
  "/report",
];

const PartnerAppContainer: React.FC<AppContainerProps> = ({ children }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [collapsed, setCollapsed] = useState(false);

  const [sideMenuHidden, setSideMenuHidden] = useState(
    // locationsToHideSideMenu.indexOf(location.pathname) !== -1
    false
  );

  const checkIfLocationIsNoAuth = (loc: string) => {
    let noAuth = false;
    noAuthLocations.forEach((noAuthLocation) => {
      if (loc.indexOf(noAuthLocation) !== -1) {
        noAuth = true;
      }
    });
    return noAuth;
  };

  const checkIfLocationIsHideSideMenu = (loc: string) => {
    let hideSideMenu = false;
    locationsToHideSideMenu.forEach((hideSideMenuLocation) => {
      if (loc.indexOf(hideSideMenuLocation) !== -1) {
        hideSideMenu = true;
      }
    });
    return hideSideMenu;
  };

  useEffect(() => {
    setSideMenuHidden(checkIfLocationIsHideSideMenu(location.pathname));

    // check auth
    let auth;
    if (false && !!process.env.REACT_APP_PARTNER_AUTH_OVERRIDE) {
      auth = {
        token: process.env.REACT_APP_AUTH_OVERRIDE,
        scope: [],
        user: {
          name: {
            first: "Alexander",
            last: "Collins",
          },
          email: "alex@finstead.com.au",
          profile_picture_url: "https://cdn.finstead.com.au/profiles/alex.jpg",
          id: 1,
        },
      };
      setLocalStorageItem("auth", auth);
    } else {
      auth = getLocalStorageItem("auth");
    }

    if (auth !== undefined && auth !== null) {
      // authenticated
      console.log("set auth default headers");
      axios.defaults.headers.common.authorization = auth?.token;
      setLoading(false);
    } else if (!checkIfLocationIsNoAuth(location.pathname)) {
      // if not authenticated and on an authenticated route
      clearSession();
      console.log(`noAuthLocations: ${noAuthLocations}`);
      console.log(`location.pathname: ${location.pathname}`);
      navigate("/login");
      setLoading(false);
    } else {
      setLoading(false);
    }
  }, [location.pathname, navigate]);

  return (
    <Container>
      <SideMenu
        setCollapsed={setCollapsed}
        collapsed={collapsed}
        hidden={sideMenuHidden}
        nav_item_props={[
          {
            title: "Dashboard",
            icon_source: reportsIcon,
            path: "/",
          },
          {
            title: "Deals",
            icon_source: dealsIcon,
            path: "/deals",
          },
          // {
          //   title: "Clients",
          //   icon_source: organisationIcon,
          //   path: "/clients",
          // },
          // {
          //   title: "Team",
          //   icon_source: usersIcon,
          //   path: "/team",
          // },
          {
            title: "API",
            icon_source: distributionIcon,
            path: "/api",
          },
          // {
          //   title: "Settlements",
          //   icon_source: settlementsIcon,
          //   path: "/settlements",
          // },
        ]}
      />
      <PageContainer
        wide={sideMenuHidden}
        noMargin={sideMenuHidden}
        collapsed={collapsed}
      >
        {!loading && children}
      </PageContainer>
    </Container>
  );
};

export default PartnerAppContainer;
