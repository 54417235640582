import { useEffect, useRef, useState, useMemo, useCallback } from "react";
import Button from "components/core/Button";
import { Form } from "pages/NoAuthWrapperPage/LoginPage.styled";
import InputField from "components/inputs/InputField";
import { post } from "utilities/Network";
import useQuery from "hooks/useQuery";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { setLocalStorageItem } from "utilities/LocalStorage";
import axios from "axios";

export const renderCheck = (check: boolean, message: string) => {
  let icon = (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="0.5" y="0.5" width="19" height="19" rx="9.5" stroke="black" />
    </svg>
  );
  if (check) {
    icon = (
      <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clip-path="url(#clip0_13_3)">
          <path
            d="M10 20C15.5228 20 20 15.5228 20 10C20 4.47715 15.5228 0 10 0C4.47715 0 0 4.47715 0 10C0 15.5228 4.47715 20 10 20Z"
            fill="#2A9D8F"
          />
          <path
            d="M6.87753 14.877L3.22021 10.9626C2.7195 10.4267 2.74824 9.57883 3.28415 9.07812C3.82005 8.57741 4.66793 8.60623 5.16863 9.14206L7.98974 12.1614L12.4552 7.98922C12.4953 7.95166 12.5373 7.91725 12.5807 7.8856L14.6806 5.92363C15.2165 5.42292 16.0645 5.45174 16.5651 5.98765C17.0658 6.52348 17.037 7.37143 16.5012 7.87214L10.0875 13.8647L10.0806 13.8573L7.86202 15.9302L6.87753 14.877Z"
            fill="white"
          />
        </g>
        <defs>
          <clipPath id="clip0_13_3">
            <rect width="20" height="20" fill="white" />
          </clipPath>
        </defs>
      </svg>
    );
  }
  return (
    <div className="flex gap-4 h-full">
      <div className="flex flex-row gap-4 mb-2 ml-1">
        {icon} {message}
      </div>
    </div>
  );
};

const isNumeric = (str: any) => {
  if (typeof str != "string") return false; // we only process strings!
  return !isNaN(Number(str)); // ...and ensure strings of whitespace fail
};

const checkStringForNumbers = (input: string) => {
  for (let i = 0; i < input.length; i++) {
    if (isNumeric(input.charAt(i))) {
      return true;
    }
  }
  return false;
};

const stringHasUpperCase = (input: string) => {
  const str = String(input);
  for (let i = 0; i < str.length; i++) {
    if (isNumeric(input.charAt(i))) {
      continue;
    } else if (str.charAt(i) === str.charAt(i).toUpperCase()) {
      return true;
    }
  }
  return false;
};

const stringHasLowerCase = (input: string) => {
  const str = String(input);
  for (let i = 0; i < str.length; i++) {
    if (isNumeric(input.charAt(i))) {
      continue;
    } else if (str.charAt(i) == str.charAt(i).toLowerCase()) {
      return true;
    }
  }
  return false;
};

type PasswordResetProps = {
  isPartnerPortal?: boolean;
};

const PasswordReset = ({ isPartnerPortal }: PasswordResetProps) => {
  const query = useQuery();
  const token = query.get("token");
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [primaryPassword, setPrimaryPassword] = useState<string>("");
  const [secondaryPassword, setSecondaryPassword] = useState<string>("");

  const correctLength =
    primaryPassword.length >= 12 && primaryPassword.length <= 99;
  const hasLowerCase = stringHasLowerCase(primaryPassword);
  const hasUpperCase = stringHasUpperCase(primaryPassword);
  const hasNumbers = checkStringForNumbers(primaryPassword);
  const passwordsMatch =
    primaryPassword === secondaryPassword && primaryPassword.length >= 1;

  const valid = useMemo(
    () =>
      correctLength &&
      hasLowerCase &&
      hasUpperCase &&
      hasNumbers &&
      passwordsMatch,
    [correctLength, hasLowerCase, hasUpperCase, hasNumbers, passwordsMatch]
  );

  const setNewPassword = useCallback(() => {
    let setPasswordUrl = "authentication/set-password/";
    if (isPartnerPortal) {
      setPasswordUrl = `authentication/partners/set-password/`;
    }
    post(setPasswordUrl, {
      token,
      password: primaryPassword,
    })
      .then((response) => {
        if (isPartnerPortal) {
          toast.success("Your password has been set! Please login.", {
            theme: "dark",
          });
          navigate("/login");
          return;
        }
        toast.success("Your password has been set! Welcome back.", {
          theme: "dark",
        });
        setLocalStorageItem("auth", response.data);

        // set axios default headers
        axios.defaults.headers.common.authorization = response.data.token;

        // redirect to dashboard
        navigate("/");
      })
      .catch(() => {
        toast.error("Something went wrong setting your new password.", {
          theme: "dark",
        });
        setLoading(false);
      });
  }, [primaryPassword, token, navigate, isPartnerPortal]);

  const confirm = () => {
    setLoading(true);
    setNewPassword();
  };

  return (
    <>
      <Form>
        <div className="w-full text-center text-sm mb-10">
          <div className="mb-10">
            <h1 className="text-lg font-bold mb-2">Password Reset</h1>
            <p>Please enter a new password</p>
          </div>
          <InputField
            label={"Password"}
            placeholder={"New Password"}
            type={"password"}
            value={primaryPassword}
            onChange={setPrimaryPassword}
            disabled={loading}
          />
          <InputField
            label={"Repeat Password"}
            placeholder={"New Password"}
            type={"password"}
            value={secondaryPassword}
            onChange={setSecondaryPassword}
            disabled={loading}
          />
          {renderCheck(
            correctLength,
            "Must be between 12 and 99 characters long."
          )}
          {renderCheck(
            hasLowerCase,
            "Must contain one or more lower case letters."
          )}
          {renderCheck(
            hasUpperCase,
            "Must contain one or more upper case letters."
          )}
          {renderCheck(hasNumbers, "Must contain one or more numbers.")}
          {renderCheck(passwordsMatch, "Passwords must match.")}
        </div>
      </Form>
      <Button
        disabled={!valid || loading}
        primary
        text="Confirm"
        wide
        onClick={confirm}
      ></Button>
    </>
  );
};

export default PasswordReset;
