import { createGlobalStyle } from "styled-components";
import fontFaces from "styles/fontFaces";

export default createGlobalStyle`
    ${fontFaces}
    *{
        margin: 0;
        padding: 0;
        outline: 0;
        box-sizing: border-box;
        font-family: 'OpenSans', sans-serif;
    }
    #root{
        margin:0 auto;
    }

    table {
        border-collapse: collapse;
    }
    
`;
