import React from "react";
import { Text, Content, NavItemBaseWrapper } from "./NavItem.styled";
import IconBase from "components/core/IconBase";
import { useNavigate, useLocation } from "react-router-dom";

export type NavItemProps = {
  title: string;
  icon_source: React.FC;
  path: string;
  collapsed?: boolean;
};

const NavItem: React.FC<NavItemProps> = ({
  title,
  icon_source,
  path,
  collapsed = false,
}) => {
  const navigate = useNavigate();
  const location = useLocation();

  return (
    <NavItemBaseWrapper
      onClick={() => navigate(path)}
      selected={location.pathname == path}
      collapsed={collapsed}
      data-tip={title}
    >
      <Content>
        <IconBase src={icon_source} color={"white"} />
        <Text>{title}</Text>
      </Content>
    </NavItemBaseWrapper>
  );
};

export default NavItem;
