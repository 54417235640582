import styled from "styled-components";
import { TextSmMedium, TextSmNormal } from "styles/mixins";

export const AvatarWrapper = styled.img`
  object-fit: cover;
  border-radius: 200px;
  height: 40px;
  width: 40px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const TextAndSupportingText = styled.div`
  display: flex;
  width: fit-content;
  flex-direction: column;
  align-items: flex-start;
`;
export const Text = styled.span`
  text-align: left;
  color: ${({ theme }) => theme.colors.white};

  ${TextSmMedium}
`;
export const SupportingText = styled.span`
  text-align: left;
  color: ${({ theme }) => theme.colors.gray_100};

  ${TextSmNormal}
`;
