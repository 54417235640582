import { useCallback } from "react";
import { get, post } from "utilities/Network";
import Badge, { BadgeType } from "components/core/Badge";
import SettledReport from "pages/DealDetailPage/SettledReport";
import LostReportForm from "pages/DealDetailPage/LostReport";

import useEditableText from "hooks/useEditableText";
import { TaskForm } from "forms/TaskForm";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import useUser from "./useUser";

const useDealStateManager = ({
  deal,
  setModalProps,
  setShowModal,
  fetchDeal,
  dealId,
  setTimeline,
  updateDeal,
  onKanban = false,
  isPartnerDeal = false,
}: any) => {
  const navigate = useNavigate();
  const { user } = useUser();
  /**
   * Load Timeline
   */
  const loadTimeLine = useCallback(() => {
    if (!dealId || !user) return;
    if (isPartnerDeal) {
      get(`deals/partners/${dealId}/timeline/`).then((response) =>
        setTimeline(response.data.timeline)
      );
    } else {
      get(`deals/${dealId}/timeline/`).then((response) =>
        setTimeline(response.data.timeline)
      );
    }
  }, [dealId, setTimeline, isPartnerDeal, user]);

  /**
   * update Assigned Broker
   */
  const updateAssignedBroker = useCallback(
    (brokerId: any) => {
      post(`deals/${dealId}/assign/`, { brokerId }).then((response: any) => {
        updateDeal({ ...deal, ...response.data });
        loadTimeLine();
      });
    },
    [deal, dealId, updateDeal, loadTimeLine]
  );

  /**
   * Load Deal function
   */
  const loadDeal = useCallback(() => {
    if (!dealId || !user) return;
    fetchDeal(parseInt(dealId));
    loadTimeLine();
  }, [dealId, fetchDeal, loadTimeLine, user]);

  /**
   * Create a new task
   */
  const openNewTaskForm = useCallback(() => {
    setModalProps({
      title: "New Task",
      children: (
        <TaskForm
          dealId={deal.id}
          onSubmit={() => {
            setShowModal(false);
          }}
        ></TaskForm>
      ),
    });
    setShowModal(true);
  }, [deal?.id, setModalProps, setShowModal]);

  /**
   * Open Approved Report
   */
  const openSettledReport = useCallback(
    (cb?: any) => {
      if (!deal.lender_and_product_set && onKanban) {
        navigate(`/deals/${deal.id}`);
        return;
      }

      console.log("open settled in report");
      setModalProps({
        rocket: false,
        fireworks: true,
        factFind: false,
        congratulate: true,
        title: (
          <div className="w-full text-center font-bold">
            Deal Settlement <br />({deal?.title})
          </div>
        ),
        children: (
          <SettledReport
            deal={deal}
            triggerModalClose={(success: boolean) => {
              if (!!cb && success) cb();
              setShowModal(false);
              loadDeal();
            }}
          />
        ),
      });
      setShowModal(true);
    },
    [deal, loadDeal, setModalProps, setShowModal, navigate, onKanban]
  );

  const markLost = useCallback(() => {
    setModalProps({
      rocket: false,
      fireworks: true,
      factFind: false,
      congratulate: false,
      title: <Badge type={BadgeType.danger} text={"Deal Lost"} />,
      children: (
        <LostReportForm
          deal={deal}
          triggerModalClose={(success: boolean) => {
            setShowModal(false);
            loadDeal();
          }}
        />
      ),
    });
    setShowModal(true);
  }, [deal, loadDeal, setModalProps, setShowModal]);

  const getStepsState: any = useCallback(
    (_deal: any) => {
      return {
        lead_in: {
          active: true,
          complete: true,
          title: "Lead In",
          subtitle: _deal.deal_age,
        },
        contact: {
          active: _deal.first_contact_report_complete,
          complete: _deal.first_contact_report_complete,
          actionable: !_deal.first_contact_report_complete,
          // _deal.first_contact_made && !_deal.first_contact_report_complete,
          onClick: () => {},
          title: _deal.first_contact_report_complete
            ? "Contact Made"
            : "Contact",
          // subtitle: _deal.first_contact_report_complete
          //   ? `In Contact for ${_deal.time_in_first_contact_made_seconds_human_readable}.`
          //   : "",
        },
        fact_find: {
          active: _deal.fact_find_report_complete,
          complete: _deal.fact_find_report_complete,
          title: _deal.fact_find_report_complete
            ? "Fact Find Complete"
            : "Fact Find Complete",
          actionable: _deal.first_contact_report_complete,
          onClick: () => {},
          // subtitle: _deal.fact_find_report_complete
          //   ? `In Fact Find for ${_deal.time_in_fact_find_seconds_human_readable}.`
          //   : "<set time put into contact stage>",
          error:
            "Please move the deal through to contact made before marking the fact find complete.",
        },
        submitted: {
          active: _deal.application_submitted_to_lender_report_complete,
          complete: _deal.application_submitted_to_lender_report_complete,
          title: "Submitted",
          // subtitle: "12 days ago for 15 minutes",
          actionable: _deal.first_contact_report_complete,
          onClick: () => {},
          error:
            "Please move the deal through to fact find complete before marking as submitted.",
        },
        approved: {
          active: _deal.application_approved_by_lender_report_complete,
          complete: _deal.application_approved_by_lender_report_complete,
          title: "Approved",
          // subtitle: "12 days ago for 15 minutes",
          actionable: _deal.first_contact_report_complete, //_deal.application_submitted_to_lender_report_complete,
          onClick: () => {},
          error:
            "Please move the deal through to submitted before marking as approved.",
        },
        docs_out: {
          active: _deal.documents_out_report_complete,
          complete: _deal.documents_out_report_complete,
          title: "Docs Out",
          // subtitle: "12 days ago for 15 minutes",
          actionable: _deal.first_contact_report_complete, //_deal.application_approved_by_lender_report_complete,
          onClick: () => {},
          error:
            "Please move the deal through to approved before marking as docs out.",
        },
        in_for_settlement: {
          active: _deal.in_for_settlement_report_complete,
          complete: _deal.in_for_settlement_report_complete,
          title: "In for settlement",
          // subtitle: "12 days ago for 15 minutes",
          actionable: _deal.first_contact_report_complete, //_deal.documents_out_report_complete,
          onClick: () => {},
          error:
            "Please move the deal through to docs out before marking as in for settlement.",
        },
        settled: {
          active: _deal.settled_report_complete,
          complete: _deal.settled_report_complete,
          title: "Settlement",
          // subtitle: "12 days ago for 15 minutes",
          actionable: _deal.first_contact_report_complete, //_deal.in_for_settlement_report_complete,
          onClick: openSettledReport,
          error:
            "Please move the deal through to docs in before settling the deal.",
        },
      };
    },
    [openSettledReport]
  );

  const lenderApplicationIdTextHook = useEditableText({
    defaultText: deal?.lender_application_id,
    handleSave: (value, cb: any) => {
      post(`deals/${deal?.id}/update/`, {
        field: "lender_application_id",
        value,
      })
        .then(() => {
          toast.success("Deal Lender Application ID updated.");
          cb();
        })
        .catch(() => cb(false));
    },
    validator: (value: string) => {
      const error = "";
      const valid = true;

      return { valid, error };
    },
  });

  const lenderApplicationIdText = lenderApplicationIdTextHook;

  const editableTextHeaderHook = useEditableText({
    defaultText: deal?.title,
    handleSave: (value, cb: any) => {
      post(`deals/${deal?.id}/update/`, {
        field: "title",
        value,
      })
        .then(() => {
          toast.success("Deal title updated.");
          cb();
        })
        .catch(() => cb(false));
    },
    validator: (value: string) => {
      let error = "";
      let valid = false;

      if (value.length < 3) {
        valid = false;
        error = "Deal title must be at least 3 characters";
      }

      return { valid, error };
    },
  });

  const headerText = editableTextHeaderHook;

  const editableNafText = useEditableText({
    defaultText: deal?.value,
    handleSave: (value, cb: any) => {
      post(`deals/${deal?.id}/update/`, {
        field: "value",
        value,
      })
        .then(() => {
          toast.success("Deal NAF updated.");
          cb();
        })
        .catch(() => cb(false));
    },
  });
  const NAFText = editableNafText;

  const editableLendIdText = useEditableText({
    defaultText: deal?.lend_id,
    handleSave: (value, cb: any) => {
      post(`deals/${deal?.id}/update/`, {
        field: "lend_id",
        value,
      })
        .then(() => {
          toast.success("Deal Lend Id updated.");
          cb();
        })
        .catch(() => cb(false));
    },
  });
  const lendIdText = editableLendIdText;

  return {
    headerText,
    openSettledReport,
    openNewTaskForm,
    markLost,
    loadDeal,
    updateAssignedBroker,
    loadTimeLine,
    lenderApplicationIdText,
    getStepsState,
    NAFText,
    lendIdText,
  };
};

export default useDealStateManager;
