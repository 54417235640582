import React, { useState, useEffect, useMemo, useCallback } from "react";
import { Section } from "pages/TeamPage/TeamPage.styled";
import FilterBar from "components/core/FilterBar";
import TableComponent from "components/core/TableComponent";
import CheckBox from "components/core/CheckBox";
import Badge, { BadgeType } from "components/core/Badge";
import { get, post } from "utilities/Network";
import "react-sliding-pane/dist/react-sliding-pane.css";
import { toast } from "react-toastify";
import useUser from "hooks/useUser";
import { useNavigate } from "react-router-dom";

enum FilterPeriods {
  ALL,
  TODAY,
  TOMORROW,
}

type Props = {
  hideFilterBar?: boolean;
  dealId?: any;
};

export const useActivities = ({ hideFilterBar = false, dealId }: Props) => {
  const navigate = useNavigate();
  const [timersMap, setTimersMap] = useState<any>({});
  const [activities, setActivities] = useState([]);
  const { user } = useUser();
  const [period, setPeriod] = useState<any>(FilterPeriods.ALL);
  const [filter, setFilter] = useState<any>(
    (!!user && `bid=${user?.id}`) || ""
  );

  useEffect(() => {
    let newFilter = "";
    if (!!user) {
      newFilter = `bid=${user?.id}`;
    }
    if (dealId !== undefined) {
      console.log(`dealId: ${dealId}`);
      newFilter = `${newFilter !== "" ? newFilter + "&" : ""}dealId=${dealId}`;
    }

    console.log(`newFilter: ${newFilter}`);
    if (newFilter !== "") {
      setFilter(newFilter);
    }
  }, [user, dealId]);

  const setFilterPeriod = useCallback(
    (filterPeriod: FilterPeriods) => {
      console.log(`setFilterPeriod`);

      setPeriod(filterPeriod);
      let obj = Object.fromEntries(new URLSearchParams(filter || ""));
      obj = {
        ...obj,
        period: filterPeriod as any,
      };
      setFilter(new URLSearchParams(obj).toString());
    },
    [filter]
  );

  // useEffect(() => {
  //   let query = "";
  //   if (!!dealId) {
  //     query = `?deal=${dealId}`;
  //   }
  //   get(`activities/today/${query}`).then((response: any) =>
  //     setActivities(response.data.activities)
  //   );
  // }, []);

  const updateDate = (activityRow: any, newDate: any) => {
    post(`activities/${activityRow.id}/update/`, {
      field: "date",
      value: newDate,
    }).then((response) => {
      toast(`Activity <${activityRow.subject}> due date set to ${newDate}.`);
    });
  };

  const updateSubject = useCallback(
    (activityRow: any, newSubject: any) => {
      if (!!timersMap[activityRow.id]) {
        clearTimeout(timersMap[activityRow.id]);
      }

      // persist
      setTimersMap({
        ...timersMap,
        [activityRow.id]: setTimeout(() => {
          post(`activities/${activityRow.id}/update/`, {
            field: "subject",
            value: newSubject,
          }).then((response) => {
            toast(`Activity subject set to ${newSubject}.`);
          });
        }, 1000),
      });
    },
    [timersMap]
  );

  const updateTime = (activityRow: any, newTime: any) => {
    post(`activities/${activityRow.id}/update/`, {
      field: "time",
      value: newTime,
    }).then((response) => {
      toast(`Activity <${activityRow.subject}> due time set to ${newTime}.`);
    });
  };

  const activitiesSection = useMemo(
    () => (
      <>
        {!!user && !hideFilterBar && (
          <FilterBar
            setFilter={setFilter}
            filterInjection={{ period }}
            brokers
            defaultBrokerId={user?.id}
          />
        )}
        <div className="flex gap-1 w-full justify-end mb-2">
          <Badge
            onClick={() => {
              setFilterPeriod(FilterPeriods.TODAY);
            }}
            type={
              period === FilterPeriods.TODAY
                ? BadgeType.success
                : BadgeType.secondary
            }
            text={"Today"}
            rounded
          />
          <Badge
            onClick={() => {
              setFilterPeriod(FilterPeriods.TOMORROW);
            }}
            type={
              period === FilterPeriods.TOMORROW
                ? BadgeType.success
                : BadgeType.secondary
            }
            text={"Tomorrow"}
            rounded
          />
          <Badge
            onClick={() => {
              setFilterPeriod(FilterPeriods.ALL);
            }}
            type={
              period === FilterPeriods.ALL
                ? BadgeType.success
                : BadgeType.secondary
            }
            text={"All"}
            rounded
          />
        </div>
        {!!user && !!filter && (
          <TableComponent
            textColourOverride={true}
            title="Activities"
            endpoint="activities/"
            filter={filter}
            fields={{
              done: {
                name: "Done",
                middleware: (row: any, updateRow?: any) => (
                  <td className="p-2 flex justify-left items-center display">
                    <CheckBox
                      checked={row.done}
                      classNames="w-full"
                      onChange={(event: any) => {
                        if (!!updateRow) {
                          const optionals: any = {};
                          console.log(typeof row.date);
                          if (event.target.value) {
                            optionals.text_colour_override = "black";
                          }
                          // todo more colour quick updates
                          updateRow({
                            done: event.target.value,
                            ...optionals,
                          });
                        }
                        post(`activities/${row.id}/update/`, {
                          field: "done",
                          value: event.target.value,
                        })
                          .then(() => {})
                          .catch(() => {
                            updateRow({
                              done: !event.target.value,
                            });
                          });
                      }}
                    />
                  </td>
                ),
              },
              subject: {
                name: "Subject",
                useCell: true,
                middleware: (row: any) => (
                  <input
                    type={"text"}
                    defaultValue={row.subject}
                    onChange={(event) => updateSubject(row, event.target.value)}
                  />
                ),
              },
              // is_follow_up: {
              //   name: "Follow Up",
              //   middleware: (row: any) =>
              //     row.subject.toLowerCase() === "fu" ||
              //     row.subject.toLowerCase().indexOf("fu ") !== -1 ||
              //     row.subject.toLowerCase().indexOf("fup") !== -1 ||
              //     row.subject.toLowerCase().indexOf("f/up") !== -1 ||
              //     row.subject.toLowerCase().indexOf("456") !== -1 ||
              //     row.subject.toLowerCase().indexOf("2nd") !== -1 ||
              //     row.subject.toLowerCase().indexOf("follow") !== -1
              //       ? "Yes"
              //       : "-",
              // },
              deal: {
                name: "Deal",
                useCell: true,
                middleware: (row: any) =>
                  row.deal === "-" ? (
                    "-"
                  ) : (
                    <span
                      className="cursor-pointer text-blue-700"
                      onClick={() => {
                        navigate(`/deals/${row.deal.id}`);
                      }}
                    >
                      {row.deal.title}
                    </span>
                  ),
              },
              contact_person: {
                name: "Contact Person",
                middleware: (row: any) => row?.contact_person?.name || "-",
              },
              email: {
                name: "Email",
                middleware: (row: any) => row?.contact_person?.email || "-",
              },
              phone: {
                name: "Phone",
                middleware: (row: any) => row?.contact_person?.phone || "-",
              },
              organisation: {
                name: "Organisation",
                middleware: (row: any) => row.organisation,
              },
              date: {
                name: "Due Date",
                useCell: true,
                middleware: (row: any) => (
                  <input
                    type={"date"}
                    defaultValue={row.date}
                    onChange={(event) => updateDate(row, event.target.value)}
                  />
                ),
              },
              time: {
                name: "time",
                useCell: true,
                middleware: (row: any) => (
                  <input
                    type={"time"}
                    defaultValue={row.time}
                    onChange={(event) => updateTime(row, event.target.value)}
                  />
                ),
              },
              // duration: {
              //   name: "Duration",
              //   middleware: (row: any) =>
              //     row.duration === 0 ? "-" : `${row.duration / 60} minutes`,
              // },
              assigned_to: { name: "Assigned to user" },
              // type: { name: "Type" },
              // sync: { name: "Synced" },
            }}
          />
        )}
      </>
    ),
    [
      filter,
      navigate,
      period,
      setFilterPeriod,
      user,
      hideFilterBar,
      updateSubject,
    ]
  );

  return {
    activities,
    setFilterPeriod,
    period,
    filter,
    user,
    updateDate,
    updateTime,
    setFilter,
    FilterPeriods,
    activitiesSection,
  };
};
