import React, { useState, useEffect } from "react";
import { get } from "utilities/Network";
import JSONPretty from "react-json-pretty";

const APICallLogData = ({ dealId }: any) => {
  const [apiLog, setAPILog] = useState<any>(undefined);
  const [noLog, setNoLog] = useState(false);
  useEffect(() => {
    get(`deals/${dealId}/api-log/`).then((response) => {
      if (response.data.no_log) {
        setNoLog(true);
      } else {
        delete response.data?.no_log;
        delete response.data?.cost;
        setAPILog(response.data);
      }
    });
  }, [dealId]);

  if (!!noLog) {
    return <div>Deal did not originate via the Finstead API</div>;
  }

  if (!apiLog) {
    return <div className="w-full text-center">Checking for API Logs...</div>;
  }

  return (
    <div>
      <JSONPretty id="json-pretty" data={apiLog}></JSONPretty>
    </div>
  );
};

export default APICallLogData;
