import React from "react";
import { LabelComponent } from "./styles";

type LabelProps = {
  text: any;
};
const Label: React.FC<LabelProps> = ({ text }) => {
  return <LabelComponent>{text}</LabelComponent>;
};

export default Label;
