import React, { useState, useEffect } from "react";
import "./index.css";

type TabsProps = {
  tabs: Array<any>;
  defaultTab?: number;
  optionsToRight?: any;
  onChange?: (activeIndex: number) => void;
};

const Tabs: React.FC<TabsProps> = ({
  tabs,
  defaultTab = 0,
  optionsToRight,
  onChange,
}) => {
  const [active, setActive] = useState(defaultTab);

  useEffect(() => {
    if (!!onChange) {
      onChange(active);
    }
  }, [active, onChange]);

  return (
    <div className="w-full">
      <div className="h-[40px] w-full text-sm flex flex-row mb-4">
        <div className="w-2/3 text-lg">
          {tabs.map((tab, index) => {
            return (
              <div
                key={index}
                className={` tab ${index === active ? "active" : ""}`}
                onClick={() => setActive(index)}
              >
                {tab.name}
              </div>
            );
          })}
        </div>

        {!!optionsToRight && (
          <div className="z-40 w-[100%] h-[100px] items-right">
            {optionsToRight}
          </div>
        )}
      </div>
      {tabs[active].component}
    </div>
  );
};

export default Tabs;
