import React, { useState, useEffect } from "react";
import animationData from "assets/animations/fs-logo-loader.json";
import logoAnimatedWithText from "assets/animations/fs-logo-stacked-loader-dark.json";
import logoAnimatedWithTextLight from "assets/animations/fs-logo-stacked-loader.json";
import Lottie from "react-lottie-player";

export type LoaderLogoProps = {
  width?: number;
  height?: number;
  withText?: boolean;
  light?: boolean;
  loop?: boolean;
};

const LoaderLogo: React.FC<LoaderLogoProps> = ({
  height,
  width = 300,
  withText = true,
  light = false,
  loop = true,
}) => {
  return (
    <Lottie
      animationData={
        withText
          ? light
            ? logoAnimatedWithTextLight
            : logoAnimatedWithText
          : animationData
      }
      style={{ margin: "0", padding: "0", width: width, height: height }}
      loop={loop}
      play
    />
  );
};

export default LoaderLogo;
