import * as yup from "yup";

export type FormData = {
  firstName: string;
  lastName: string;
  emailAddress: string;
  phoneNumber: string;
  organisationName: string;
  organisationABN: string;
  organisationAddress: string;
  notes: string;
  interestedParty: string;
  occupation: string;
  // insurances: Map<string, boolean>;
};

export const FormSchema = yup
  .object({
    firstName: yup.string().min(2).max(64).required(),
    lastName: yup.string().min(2).max(64).required(),
    emailAddress: yup.string().email().required(),
    phoneNumber: yup.string().min(9).required(),
    organisationName: yup.string().min(2).required(),
    organisationABN: yup.string().min(11).max(11).nullable(true),
    organisationAddress: yup.string().min(2).max(255).nullable(true),
    notes: yup.string().max(512).nullable(true),
    interestedParty: yup.string().max(512).nullable(true).required(),
    occupation: yup.string().max(512).nullable(true).required(),
    insurances: yup
      .object({
        insurance_a: yup.boolean().notRequired(),
        insurance_b: yup.boolean().notRequired(),
        insurance_c: yup.boolean().notRequired(),
      })
      .nullable(true),
  })
  .required();

export const RequiredFieldsMap = {
  firstName: true,
  lastName: true,
  emailAddress: true,
  phoneNumber: true,
  organisationName: true,
  organisationABN: false,
  organisationAddress: false,
  notes: false,
  insurances: false,
  interestedParty: false,
  occupation: false,
};
