import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

/**
 * UseCallerInfo tracks the websocket connection for any information regarding the current user's active call
 * and relay's that via call info
 * @returns { callInfo }
 */
const useCallerInfo = () => {
  const lastMessage = useSelector((state: any) => state.websocket.lastMessage);
  const [callInfo, setCallInfo] = useState<any>();

  useEffect(() => {
    if (!!lastMessage && lastMessage.type == "call") {
      setCallInfo(lastMessage.data);
      if (lastMessage.data.status === "completed") {
        setTimeout(() => {
          setCallInfo({});
        }, 5000);
      }
    }
  }, [lastMessage]);

  return { callInfo };
};

export default useCallerInfo;
