import React, {
  useState,
  useMemo,
  useEffect,
  useRef,
  useCallback,
} from "react";

import { toast } from "react-toastify";

type EditableTextProps = {
  defaultText?: string;
  handleSave: (value: string, cb: () => void) => void;
  validator?: (value: string) => { valid: boolean; error?: string };
};

const useEditableText = ({
  defaultText,
  handleSave,
  validator,
}: EditableTextProps) => {
  const [errored, setErrored] = useState(false);
  const [value, setValue] = useState<any>(defaultText);
  const [ready, setReady] = useState(false);
  const previousValue = useRef<any>(defaultText);
  const inputRef = useRef<any>(null);
  const timer = useRef<any>(null);
  const [textMemoTrigger, setTextMemoTrigger] = useState<boolean>();
  const [persisting, setPersisting] = useState(false);
  const [inputError, setError] = useState<string | null>(null);

  useEffect(() => {
    setValue(defaultText);
    setTextMemoTrigger((t) => !t);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultText]);

  const persist = useCallback(
    (shouldFocus = false) => {
      if (!ready) return;
      setErrored(false);
      console.log("persist");
      setError(null);

      // validate
      if (!!validator) {
        const { valid, error } = validator(value);
        if (!valid && !!error) {
          setError(error);
          toast.error(error);
          return;
        }
      }
      previousValue.current = value;

      setPersisting(true);
      handleSave(value, (success = true) => {
        setErrored(!success);
        setTimeout(() => {
          setPersisting(false);
          setTimeout(() => {
            setPersisting(true);
            setTimeout(() => {
              setPersisting(false);
              if (!!inputRef.current && shouldFocus) inputRef.current.focus();
            }, 60);
          }, 60);
        }, 60);
      });
    },
    [ready, handleSave, value, validator]
  );

  const onBlur = useCallback(() => {
    if (!!timer.current) {
      clearTimeout(timer.current);
    }
    setReady(false);
    setPersisting(false);
    setError(null);
    // setValue(previousValue.current);
    persist(false);
  }, [persist]);

  useEffect(() => {
    if (!!timer.current) {
      clearTimeout(timer.current);
    }

    timer.current = setTimeout(() => persist(true), 1000);

    return () => {
      if (!!timer.current) {
        clearTimeout(timer.current);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value, timer]);

  return (
    <input
      disabled={persisting}
      ref={inputRef}
      onBlur={onBlur}
      style={{
        ...(persisting
          ? {
              backgroundColor: errored
                ? "rgba(255,0,0,0.4)"
                : "rgba(0,255,0,0.6)",
            }
          : {}),
        ...(!!inputError ? { backgroundColor: "rgba(255,0,0,0.4)" } : {}),
      }}
      className="w-full"
      onChange={(e) => {
        if (!ready) setReady(true);
        if (value === e.target.value) return;
        setValue(e.target.value);
      }}
      value={value}
    />
  );
};

export default useEditableText;
