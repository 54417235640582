import React, { useEffect, useState } from "react";
import {
  MainWrapper,
  HeaderSection,
  Container,
  Divider,
  Section,
} from "pages/TeamPage/TeamPage.styled";
import Tabs from "components/core/Tabs";
import Modal from "components/core/Modal";
import { getSession } from "utilities/Authentication/";
import PageHeader from "components/layout/PageHeader";
import TableComponent from "components/core/TableComponent";
import FilterBar from "components/core/FilterBar";
import KanBanComponent from "components/core/KanBanComponent";
import { useNavigate } from "react-router-dom";
import IconBase from "components/core/IconBase";
import Badge, { BadgeType } from "components/core/Badge";
import { ReactComponent as userIcon } from "assets/icons/user.svg";
import { ReactComponent as ListIcon } from "assets/icons/list.svg";
import { ReactComponent as KanbanIcon } from "assets/icons/trello.svg";
import Button from "components/core/Button";
import SlidingPane from "components/layout/SlidingPane";
import "react-sliding-pane/dist/react-sliding-pane.css";
import useAreYouSure from "hooks/useAreYouSure";

import BrokerNewDealForm from "./BrokerNewDealForm";
import useDealStateManager from "hooks/useDealStateManager";
import useDeal from "hooks/useDeal";
import DealDetailPage from "pages/DealDetailPage";

export type DealPageProps = {};

export type Deal = {
  id: string;
  title: string;
  value: string;
  status: string;
};

const DealPage: React.FC<DealPageProps> = ({}) => {
  const navigate = useNavigate();
  const [filter, setFilter] = useState<string | undefined>();
  const [kanbanMode, setKanbanMode] = useState(true);
  const [focusedDeal, setFocusedDeal] = useState<any | undefined>();
  const { fetchDeal, updateDeal } = useDeal({ isPartnerView: false });

  const [isPaneOpen, setPaneOpen] = useState(false);

  const [triggerRefresh, setTriggerRefresh] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [modalProps, setModalProps] = useState<any>({});
  const [timeline, setTimeline] = useState([]);

  const { openSettledReport } = useDealStateManager({
    deal: focusedDeal,
    dealId: focusedDeal?.id,
    setModalProps,
    setShowModal,
    fetchDeal,
    updateDeal,
    setTimeline,
    onKanban: true,
  });

  return (
    <MainWrapper>
      <SlidingPane
        className="overlay-pane-content"
        overlayClassName="overlay-pane"
        isOpen={isPaneOpen}
        title="New Deal"
        width="800px"
        subtitle="Complete the form to create a new deal."
        onRequestClose={() => {
          // triggered on "<" on left top click or on outside click
          setPaneOpen(false);
        }}
        cancelPromptTitle={"Abandon new Deal?"}
        cancelPromptMessage={"Are you sure you'd like to abandon this form?"}
      >
        <BrokerNewDealForm
          onSubmit={(cb: any) => {
            setPaneOpen(false);
            if (!!cb) {
              cb();
            }
          }}
        />
      </SlidingPane>

      {/* <Container>
        <PageHeader
          title={"Deals"}
          subtitle={"Finstead Asset Finance Deals"}
          rightHandSideChildren={
            <Button
              onClick={() => {
                setPaneOpen(true);
              }}
              text={"New Deal"}
              primary
            />
          }
        />
      </Container> */}
      <Section className="min-h-full">
        <div className="flex w-full">
          <div className="pt-5">
            <button
              className="p-2 rounded-md bg-slate-200"
              onClick={() => setKanbanMode(!kanbanMode)}
            >
              {kanbanMode ? <ListIcon /> : <KanbanIcon />}
            </button>
          </div>
          <div className="flex-1">
            <FilterBar
              setFilter={setFilter}
              brokers={true}
              defaultBrokerId={getSession()?.user?.id}
              rightItem={
                <Button
                  onClick={() => {
                    setPaneOpen(true);
                  }}
                  text={"New Deal"}
                  primary
                />
              }
            />
          </div>
        </div>

        <SlidingPane
          className="overlay-pane-content max-w-full"
          overlayClassName="overlay-pane"
          title={modalProps.title}
          isOpen={showModal}
          width="1200px"
          onRequestClose={() => {
            setShowModal(false);
          }}
          cancelPromptTitle={"Are you sure?"}
          cancelPromptMessage={"Are you sure you'd like to abandon this form?"}
        >
          {modalProps.children}
        </SlidingPane>

        {kanbanMode ? (
          !!filter && (
            <KanBanComponent
              title={"Deals"}
              endpoint={"deals/kanban/Opportunity%20Workflow/"}
              filter={filter}
              triggerRefresh={triggerRefresh}
              handleDealFocused={setFocusedDeal}
              lastLaneAction={(cb?: () => void) => {
                // todo - open deal settlement form.
                openSettledReport(() => {
                  setTriggerRefresh(!triggerRefresh);
                  if (!!cb) cb();
                });
              }}
              is_settled_stages={[13]}
            />
          )
        ) : (
          <TableComponent
            onRowClick={(row: any, newTab = false) => {
              if (newTab) {
                window.open(
                  `https://crm.finstead.com.au/deals/${row.id}`,
                  "_blank",
                  "noreferrer"
                );
              } else {
                navigate(`/deals/${row.id}`);
              }
            }}
            title={"Deals"}
            endpoint={"deals/"}
            filter={filter}
            fields={{
              id: {
                name: "Id",
              },
              pipedrive_id: {
                name: "Pipedrive Id",
              },
              broker: {
                name: "Broker",
                middleware: (row: any) => (
                  <>
                    {!!row.broker_profile_picture_url ? (
                      <img
                        src={row.broker_profile_picture_url}
                        className="w-[25px] h-[25px] object-cover rounded-full inline-block mr-2"
                      />
                    ) : (
                      <span className="inline-block mr-2 border rounded-full border-slate-100">
                        <IconBase src={userIcon} />
                      </span>
                    )}
                    <span>{row.broker}</span>
                  </>
                ),
              },
              title: {
                name: "Title",
              },
              add_time: {
                name: "Lead In",
              },
              value: {
                name: "Value",
              },
              current_stage: {
                name: "Current Stage",
              },
              status: {
                name: "Status",
                middleware: (row: any) => {
                  switch (row.status) {
                    case "":
                      return "";
                    case "open":
                      return <Badge type={BadgeType.info} text="Open" />;
                    case "lost":
                      return <Badge type={BadgeType.danger} text="Lost" />;
                    case "pending assignment":
                      return <Badge type={BadgeType.info} text={"Lead In"} />;
                    case "won":
                    case "settled":
                      return (
                        <Badge type={BadgeType.success} text={"Settled"} />
                      );
                    default:
                      return (
                        <Badge type={BadgeType.warning} text={"Unknown ?"} />
                      );
                  }
                },
              },
            }}
          />
        )}
      </Section>
    </MainWrapper>
  );
};

export default DealPage;
