import { Applicant, Lender, Organisation, Referrer } from "types";
import { number } from "yup/lib/locale";

export const formatUnderCase = (s: string) =>
  s
    .split("_")
    .map((t: string) => t.charAt(0).toUpperCase() + t.slice(1))
    .join(" ");

/**
 * Format bytes as human-readable text.
 *
 * @param bytes Number of bytes.
 * @param si True to use metric (SI) units, aka powers of 1000. False to use
 *           binary (IEC), aka powers of 1024.
 * @param dp Number of decimal places to display.
 *
 * @return Formatted string.
 */
export const humanFileSize = (bytes: any, si = false, dp = 1) => {
  const thresh = si ? 1000 : 1024;

  if (Math.abs(bytes) < thresh) {
    return bytes + " B";
  }

  const units = si
    ? ["kB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]
    : ["KiB", "MiB", "GiB", "TiB", "PiB", "EiB", "ZiB", "YiB"];
  let u = -1;
  const r = 10 ** dp;

  do {
    bytes /= thresh;
    ++u;
  } while (
    Math.round(Math.abs(bytes) * r) / r >= thresh &&
    u < units.length - 1
  );

  return bytes.toFixed(dp) + " " + units[u];
};

/**
 * Human readable duration
 */
export const timeString = (seconds: number) => {
  const hours = Math.trunc(seconds / 3600);
  const minutes = Math.trunc(
    Math.trunc(seconds - Math.trunc(hours * 3600)) / 60
  );
  const newSeconds = Math.trunc(seconds - Math.trunc(minutes * 60));

  let res = "";

  if (hours > 0) {
    res = `${hours}h`;
    if (minutes > 0) {
      res = `${res} ${minutes}m`;
    }
  } else if (minutes > 0) {
    res = `${minutes}m`;
    if (newSeconds > 0) {
      res = `${res} ${newSeconds}s`;
    }
  } else {
    res = `${newSeconds}s`;
  }
  return res;
};

export const formatMoney = (value: number) => {
  try {
    if (value < 0) {
      return `- $${Math.abs(value).toLocaleString(undefined, {
        minimumFractionDigits: 2,
      })}`;
    }
    return `$${value.toLocaleString(undefined, { minimumFractionDigits: 2 })}`;
  } catch (e) {
    return value;
  }
};

export const getLenderSearchSelectLabel = (_lender: Lender) =>
  `[${_lender?.id}] ${_lender?.business_name}`;

export const getOrganisationSearchSelectLabel = (_organisation: Organisation) =>
  `[${_organisation?.id}] ${_organisation?.name}`;

export const getApplicantSearchSelectLabel = (_applicant: Applicant) =>
  `[${_applicant.id}] ${_applicant.first_name} ${_applicant.last_name}`;

export const getReferrerSearchSelectLabel = (_referrer: Referrer) =>
  `[${_referrer.id}] ${_referrer?.business_name}`;

export const getDealSearchSelectLabel = (deal: any) =>
  `[${deal.id}] ${deal?.title}`;
