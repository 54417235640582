import React, { useState } from "react";
import { DateSelect } from "./DateRangeSelect.styled";
import InputFieldWrapper from "components/inputs/InputFieldWrapper";
import { ReactComponent as CloseIcon } from "assets/icons/x.svg";

type DateRangeSelectProps = {
  start?: any;
  end?: any;
  setStart?: any;
  setEnd?: any;
  close?: () => void;
};

const DateRangeSelect: React.FC<DateRangeSelectProps> = ({
  start,
  setStart,
  end,
  setEnd,
  close = () => {},
}) => {
  return (
    <>
      <InputFieldWrapper label={"Period Start"}>
        {/* Start Range */}
        <DateSelect
          type="date"
          value={start?.toISOString().substring(0, 10)}
          onChange={(e: any) => setStart(new Date(e.target.value))}
        />
      </InputFieldWrapper>

      <InputFieldWrapper label={"Period End"}>
        {/* End Range */}
        <DateSelect
          type="date"
          value={end?.toISOString().substring(0, 10)}
          onChange={(e: any) => {
            setEnd(new Date(e.target.value));
          }}
        />
      </InputFieldWrapper>
      {!!close && (
        <div className="pt-7">
          <button className="p-[7px] rounded-md bg-slate-100" onClick={close}>
            {<CloseIcon />}
          </button>
        </div>
      )}
    </>
  );
};

export default DateRangeSelect;
