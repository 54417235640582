import React, { FC } from "react";

interface InfoObject {
  [key: string]: string;
}

interface InfoBlockProps {
  info: InfoObject;
}

const InfoBlock: FC<InfoBlockProps> = ({ info }) => {
  // Generate the JSX
  const infoElements = Object.entries(info).map(([key, value]) => {
    return (
      <div className="w-full flex justify-between text-left" key={key}>
        <span>{key}</span>
        <span className="font-bold ml-4">{value}</span>
      </div>
    );
  });

  // Return the final JSX
  return <div className="flex flex-col min-w-[250px]">{infoElements}</div>;
};

export default InfoBlock;
