import styled from "styled-components";

export const TableContainer = styled.div`
  width: 100%;

  border: 1px solid rgba(228, 231, 236, 1);
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  overflow: scroll;

  -webkit-transition: all 2s ease-out;
  -moz-transition: all 2s ease-out;
  -o-transition: all 2s ease-out;
  transition: all 2s ease-out;
`;

import { TextLgMedium, TextXsMedium, TextSmMedium } from "styles/mixins";

export const ChevronWrapper = styled.div`
  padding: 0px;
  margin: 0px;
  position: relative;
  left: -5px;
  top: 20px;
  z-index: 2;
`;

export const CardHeaderWrapper = styled.div`
  display: flex;
  background: rgba(255, 255, 255, 1);
  align-self: stretch;
  flex-direction: column;
  align-items: flex-start;
`;
export const Content = styled.div`
  display: flex;
  align-self: stretch;
  align-items: center;
  padding: 20px 24px 19px 24px;
  gap: 16px;
`;
export const TextAndBadge = styled.div`
  display: flex;
  flex-grow: 1;
  align-items: center;
  gap: 8px;
`;
export const Text = styled.span`
  text-align: left;
  white-space: pre-line;
  color: ${({ theme }) => theme.colors.gray_900};

  ${TextLgMedium}
`;
export const Badge = styled.div`
  display: flex;
  width: fit-content;
  align-items: flex-start;
`;
export const BadgeBase = styled.div`
  display: flex;
  background: rgba(255, 255, 255, 1);
  border-radius: 16px;
  width: fit-content;
  justify-content: center;
  align-items: center;
  padding: 2px 8px 2px 8px;
`;
export const Text1 = styled.span`
  text-align: center;
  white-space: pre-line;
  color: ${({ theme }) => theme.colors.primary_700};

  ${TextXsMedium}
`;
export const Divider = styled.div`
  display: flex;
  background: rgba(228, 231, 236, 1);
  width: 100%;
  height: 1px;
`;

export const TH = styled.th`
  background: rgba(249, 250, 251, 1);
  height: 44px;
  padding: 12px 24px 12px 24px;
  gap: 12px;
  &:first-child {
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
  }
  &:last-child {
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
  }
`;

export const THContentWrapper = styled.div`
  display: flex;
  width: fit-content;
  align-items: center;
  gap: 4px;
`;

export const THead = styled.thead`
  border-radius: 10px;
`;

export const THText = styled.span`
  text-align: left;
  flex: 1;
  /* white-space: pre-line; */
  color: ${({ theme }) => theme.colors.black};
  cursor: pointer;
  ${TextSmMedium}
`;

export const THR = styled.tr`
  width: 100%;
  border-bottom: 1px solid rgba(249, 250, 251, 1);
  height: 20px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
`;

type StyledTR = {
  pointer?: boolean;
};

export const TR = styled("tr")<StyledTR>`
  width: 100%;
  border-bottom: 1px solid rgba(249, 250, 251, 1);
  height: 20px;
  &:hover {
    background-color: rgba(0, 0, 0, 0.05);
  }
  ${(props) => (!!props.pointer ? "cursor: pointer" : "")}
`;

export const TBody = styled.tbody`
  width: 100%;
  border-radius: 10px;
`;

export const Table = styled.table`
  width: 100%;

  //-webkit-touch-callout: none; /* iOS Safari */
  //-webkit-user-select: none; /* Safari */
  //-khtml-user-select: none; /* Konqueror HTML */
  //-moz-user-select: none; /* Old versions of Firefox */
  //-ms-user-select: none; /* Internet Explorer/Edge */
  //user-select: none; /* Non-prefixed version, currently supported by Chrome, Edge, Opera and Firefox */
`;
