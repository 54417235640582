const WeekDayComponent = ({ enabled, quota, updateRule, loading }: any) => {
  const handleChange = (k: any, v: any) => {
    updateRule({
      enabled: enabled,
      quota: quota,
      [k]: v,
    });
  };

  return (
    <div>
      <div
        className={`weekday-overlay text-center ${
          enabled ? "bg-green-500" : "bg-slate-700"
        }`}
      >
        <div
          className={`mb-2 text-white text-center ${
            enabled ? "bg-green-500" : "bg-slate-700"
          }`}
        >
          <p className="pt-2 lead">
            {loading ? "..." : enabled ? "Enabled" : "Disabled"}
          </p>
          <button
            color={"white"}
            disabled={loading}
            onClick={() => handleChange("enabled", !enabled)}
          >
            {enabled ? "Disable" : "Enable"}
          </button>
        </div>
      </div>

      <div className="mt-2">
        <label>{"Lead Quota:"}</label>
        <input
          type="number"
          min={0}
          value={quota}
          disabled={!enabled}
          onChange={(e: any) => handleChange("quota", e.target.value)}
        ></input>
      </div>
    </div>
  );
};

export default WeekDayComponent;
