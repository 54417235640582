/**
 * Checks Email for validity
 * @param  {String} email to validate
 * @return {Boolean} Is Email Valid
 */
const validateEmail = (email: string) => {
  return String(email)
    .toLowerCase()
    .match(/^\S+@\S+\.\S+$/);
};

export default validateEmail;
