import styled from "styled-components";

import { TextSmMedium } from "styles/mixins";

export const LabelComponent = styled.span`
  text-align: left;
  max-width: 100%;
  color: ${({ theme }) => theme.colors.gray_700};

  ${TextSmMedium}
`;
