import React, { useState, useEffect, useCallback } from "react";
import { useParams } from "react-router-dom";
import constants from "styles/constants";
import { LoadingState } from "api/constants";
import Modal from "components/core/Modal";
import BrokerNewDealForm, {
  SectionState,
} from "pages/DealPage/BrokerNewDealForm";

import IconBase from "components/core/IconBase";
import Button from "components/core/Button";
import DealStatusBadge from "pages/DealDetailPage/DealStatusBadge";
import TableComponent from "components/core/TableComponent";
import SlidingPane from "components/layout/SlidingPane";
import "react-sliding-pane/dist/react-sliding-pane.css";
import DealSettlementSummary from "frames/DealSettlementSummary";
import {
  formatMoney,
  formatUnderCase,
  getLenderSearchSelectLabel,
} from "utilities/Strings";
import {
  MainWrapper,
  Container,
  Section,
} from "pages/TeamPage/TeamPage.styled";
import { toast } from "react-toastify";
import Stepper from "components/core/Stepper";
import PageHeader from "components/layout/PageHeader";
import InfoBox from "components/core/InfoBox";
import Box from "components/core/Box";
import BrokerDropDownField from "components/inputs/BrokerDropDownField";
import { ReactComponent as homeIcon } from "assets/icons/home.svg";
import { ReactComponent as trophyIcon } from "assets/icons/zap.svg";
import { ReactComponent as PlusIcon } from "assets/icons/plus.svg";
import Tabs from "components/core/Tabs";
import { Editor, EditorState } from "draft-js";
import DealTimeline from "components/core/DealTimeline";
import Badge, { BadgeType } from "components/core/Badge";
import useDeal from "hooks/useDeal";
import LoaderLogo from "components/core/LoaderLogo";
// import FirstContactMadePromptBox from "./FirstContactMadePromptBox";
import ReOpenDealPromptBox from "pages/DealDetailPage/ReOpenDealPromptBox";
// import DealSettlementForm from "forms/DealSettlementForm";
import { get, post } from "utilities/Network";
import useDealStateManager from "hooks/useDealStateManager";
import useEditableText from "hooks/useEditableText";
import FileUploadForm from "pages/DealDetailPage/FileUploadForm";
import FileDetailComponent from "pages/DealDetailPage/FileDetailComponent";
import useApplicantManager from "hooks/useApplicantManager";
import useOrganisationManager from "hooks/useOrganisationManager";
import { useNavigate } from "react-router-dom";
import DropDownField from "components/inputs/DropDownField";
import ReactTooltip from "react-tooltip";
import SectionHeader from "components/layout/SectionHeader";
import ActivityForm from "forms/ActivityForm";
import useAreYouSure from "hooks/useAreYouSure";
import useAlert from "hooks/useAlert";
import useTextEditor from "hooks/useTextEditor";
import { useActivities } from "hooks/useActivities";
import SearchSelectField from "components/inputs/SelectSearchField";
import { Lender, Product } from "types";

type ComponentProps = {};
const PartnerDealDetailView: React.FC<ComponentProps> = () => {
  // deal state
  const navigate = useNavigate();
  const { dealId } = useParams();

  const [showSettlementReview, setShowSettlementReview] = useState(false);
  const [showDuplicationForm, setShowDuplicationForm] = useState(false);

  const [timeline, setTimeline] = useState([]);
  const { deal, loading, fetchDeal, updateDeal } = useDeal({
    isPartnerView: true,
  });

  // Deal State manager Hook
  const { loadDeal, headerText, NAFText } = useDealStateManager({
    deal,
    dealId,
    fetchDeal,
    setTimeline,
    updateDeal,
    isPartnerDeal: true,
  });

  // load the deal
  useEffect(loadDeal, [dealId, fetchDeal, loadDeal]);

  if (deal === null || loading === LoadingState.pending) {
    return (
      <MainWrapper>
        <Container>
          <PageHeader
            title={`Deal ${dealId} ...`}
            subtitle={`Loading deal ${dealId}`}
            breadcrumbs={[
              {
                title: "Deals",
                link: "/deals",
              },
            ]}
          />
        </Container>
        <Section style={{ alignItems: "center" }}>
          <LoaderLogo />
        </Section>
      </MainWrapper>
    );
  }

  return (
    <MainWrapper>
      <ReactTooltip />
      <Container>
        <PageHeader
          title={deal?.title}
          subtitle={
            <>
              {dealId}
              <br />
              <span className="text-sm">
                {deal &&
                  deal?.asset_type &&
                  formatUnderCase(deal?.asset_type) + "/"}{" "}
                {deal?.value} / lead in {deal && deal?.deal_age}.
              </span>
            </>
          }
          breadcrumbs={[
            {
              title: "Deals",
              link: "/deals",
            },
          ]}
          rightHandSideChildren={
            <div className="min-w-[150px]">
              <div className="w-full flex justify-end mb-4">
                <div className="min-w-[200px]">
                  <DealStatusBadge status={deal?.status} />
                </div>
              </div>
            </div>
          }
        />
      </Container>

      {/* Settlement Review Modal */}
      <Modal
        title={`Settlement Review - Deal ${dealId} ${deal.title}`}
        isOpen={showSettlementReview}
        congratulate
        onRequestClose={() => {
          setShowSettlementReview(false);
        }}
      >
        <DealSettlementSummary dealId={dealId} />
      </Modal>

      {/* Deal State Stepper */}
      <Stepper
        steps={deal?.stages}
        currentStep={deal?.stage?.id}
        handleClick={(stage: any, is_settled_stage?: boolean) => {}}
      />

      <Section>
        <div className="w-full h-full flex flex-row flex-wrap">
          <div className="w-full lg:w-1/2 flex-row flex-wrap gap-4 lg:pr-2">
            <div className="w-full text-center rounded-md mb-2 p-4 border border-slate-300">
              <h2 className="mb-4 font-medium">
                Your Assigned Finstead Broker
              </h2>
              <hr className="mb-3" />
              <br />
              <img
                className="rounded-full w-[100px] h-[100px] drop-shadow-md"
                style={{ margin: "auto auto" }}
                src={deal.assigned_person_image}
              ></img>
              <br />
              {deal.assigned_person}
              <br />
              {deal.assigned_person_contact_number || ""}
            </div>
            <InfoBox
              title={"Overview"}
              icon={
                <IconBase src={homeIcon} color={constants.colors.primary_600} />
              }
              info={{
                "Finstead ID": deal?.id,
                Title: deal?.title,
                "Suggested NAF": deal?.naf,
              }}
            />
          </div>
          <div className="w-full lg:w-1/2 flex flex-col lg:pl-2">
            <SectionHeader title={"Deal's Timeline"} noDivider />
            <DealTimeline events={timeline} />
          </div>
        </div>
      </Section>
    </MainWrapper>
  );
};

export default PartnerDealDetailView;
