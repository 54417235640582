import styled from "styled-components";

export const MainWrapper = styled.div`
  background: rgba(255, 255, 255, 1);
  padding: 0px 0px 48px 0px;
  gap: 32px;
  width: 100%;
  height: 100%;
`;
export const HeaderSection = styled.div`
  display: flex;
  align-self: stretch;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  margin-bottom: 20px;
`;
export const Container = styled.div`
  display: flex;
  align-self: stretch;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px 32px 0px 32px;
  gap: 24px;
`;
export const Divider = styled.div`
  display: flex;
  background: rgba(228, 231, 236, 1);
  width: 1096px;
  height: 1px;
`;
export const Section = styled.div`
  display: flex;
  align-self: stretch;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px 32px 0px 32px;
`;
