import React, { useEffect, useState } from "react";
import {
  MainWrapper,
  HeaderSection,
  Container,
  Divider,
  Section,
} from "pages/TeamPage/TeamPage.styled";
import PageHeader from "components/layout/PageHeader";
import TableComponent from "components/core/TableComponent";
import Button from "components/core/Button";
import FilterBar from "components/core/FilterBar";
import NewPartnerForm from "./NewPartnerForm";

import SlidingPane from "components/layout/SlidingPane";
import "react-sliding-pane/dist/react-sliding-pane.css";
import { useNavigate } from "react-router-dom";

export type PartnersPageProps = {};

export type Contact = {
  id: string;
  name: string;
  email_address: string;
  link: string;
  label: string;
  job_title: string;
  contact_number: string;
  has_activated_account: boolean;
  partner_company: string;
};

export type Partner = {
  id: string;
  business_name: string;
  primary_contact: Contact;
  created: string;
  last_login: string;
  premium_partner: boolean;
};

const PartnersPage: React.FC<PartnersPageProps> = ({}) => {
  const navigate = useNavigate();
  const [newPartnerSlidingPaneOpen, setNewPartnerSlidingPaneOpen] =
    useState(false);
  const [filter, setFilter] = useState<string | undefined>();
  const newPartner = () => {
    setNewPartnerSlidingPaneOpen(true);
  };
  return (
    <MainWrapper>
      <Container>
        <PageHeader
          title={"Partners"}
          subtitle={"Manage finstead referral partners here"}
          rightHandSideChildren={
            <Button onClick={newPartner} text={"New Partner"} primary />
          }
        />
      </Container>
      <Section>
        <SlidingPane
          className="overlay-pane-content"
          overlayClassName="overlay-pane"
          isOpen={newPartnerSlidingPaneOpen}
          title={`New Partner`}
          width={"800px"}
          subtitle="Complete the form to onboard a new partner"
          onRequestClose={() => {
            setNewPartnerSlidingPaneOpen(false);
          }}
          cancelPromptTitle={"Are you sure?"}
          cancelPromptMessage={"Are you sure you'd like to abandon this form?"}
        >
          <NewPartnerForm
            cb={(newPartnerId) => {
              setNewPartnerSlidingPaneOpen(false);
              navigate(`/partners/${newPartnerId}/`);
            }}
          />
        </SlidingPane>
        <FilterBar setFilter={setFilter} />
        <TableComponent
          title={"Partners"}
          endpoint="partners/"
          onRowClick={(row: any, newTab = false) => {
            if (newTab) {
              window.open(
                `https://crm.finstead.com.au/partners/${row.id}`,
                "_blank",
                "noreferrer"
              );
            } else {
              navigate(`/partners/${row.id}`);
            }
          }}
          filter={filter}
          fields={{
            id: {
              name: "Id",
            },
            business_name: {
              name: "Business Name",
            },
            deals: {
              name: "Referrals",
            },
            settlements: {
              name: "Settlements",
            },
            primary_contact: {
              name: "Primary Contact",
              middleware: (contact: any) => contact?.primary_contact?.name,
            },
            phone: {
              name: "Phone",
              middleware: (contact: any) =>
                contact?.primary_contact?.contact_number,
            },
            email: {
              name: "Email",
              middleware: (contact: any) =>
                contact?.primary_contact?.email_address,
            },
            last_login: {
              name: "Last Login",
              middleware: (row: any) => row?.last_login?.label,
            },
          }}
        />
      </Section>
    </MainWrapper>
  );
};

export default PartnersPage;
