import React, { useState } from "react";
import classNames from "classnames";
import { useSpring, animated } from "react-spring";

import { EditorState, convertFromRaw } from "draft-js";
import { DraftailEditor } from "draftail";

import "./index.css";
import CheckBox from "../CheckBox";
import Button from "../Button";
type DealTimelineProps = {
  events?: any;
  noEventsMessage?: any;
  setEvents?: any;
  handleChange?: any;
};

/**
 *
 *
 * block types: note, events, support tasks,
 * file request, emails, state update, sms, calls
 *
 * @returns
 */

const DealTimeline: React.FC<DealTimelineProps> = ({
  events,
  noEventsMessage,
  setEvents,
  handleChange,
}) => {
  const [editingNoteKey, setEditingNoteKey] = useState();
  const fadeInProps = useSpring({
    opacity: 1,
    transform: "translate(0px, 0px)",
    from: { opacity: 0, transform: "translate(-20px, -20px)" },
  });

  return (
    <div className="w-full min-h-[55px]">
      <div className="timeline">
        {events.length === 0 && (
          <div className="mt-4 mb-4 timeline-item">
            <div className="timeline-item-container">
              <div className="timeline-item-content text-slate-800">
                {noEventsMessage ? (
                  noEventsMessage
                ) : (
                  <>
                    There are no timeline events for this deal as it was likely
                    imported from PipeDrive. <br />
                    Any new timeline events will appear here.
                  </>
                )}
              </div>
            </div>
          </div>
        )}
        {events.map((event: any, key: any) => {
          return (
            <animated.div
              className="timeline-item"
              style={fadeInProps}
              key={key}
            >
              <div className="timeline-item-container">
                <div
                  className={classNames(
                    "timeline-item-content text-slate-800",
                    {
                      "bg-yellow-100":
                        event.type === "note_created" && !event.note,
                      "bg-purple-100":
                        event.type === "note_created" && !!event.note,
                      "bg-blue-100": event.type === "assigned_broker_change",
                      "bg-red-200":
                        event.type === "activity" &&
                        event.overdue &&
                        !event.done,
                      "bg-green-200": event.type === "activity" && event.today,
                      "bg-white": event.type === "activity" && event.done,
                    }
                  )}
                >
                  {event.type === "activity" ? (
                    <>
                      <div className="inline-block max-h-[30px] mr-2">
                        <CheckBox
                          checked={event.done}
                          classNames="w-full"
                          onChange={(e: any) => {
                            handleChange(event, e.target.value);
                            setEvents([
                              ...events.slice(0, key),
                              { ...events[key], done: e.target.value },
                              ...events.slice(key + 1),
                            ]);
                          }}
                        />
                      </div>
                      <div className="inline-block relative top-[-24px]">
                        <span
                          className={classNames({ "line-through": event.done })}
                        >
                          {event.text}
                        </span>
                      </div>

                      <h2 className="text-xs">
                        {event.subtext && `${event.subtext}`}
                      </h2>
                    </>
                  ) : (
                    <>
                      {!event.note && (
                        <p dangerouslySetInnerHTML={{ __html: event.text }} />
                      )}
                      {!!event.note && (
                        <div className="w-full">
                          <div className="w-full text-right z-20 mb-4">
                            {key !== editingNoteKey ? (
                              // <button
                              //   className="w-[50px] border-1 border-slate-500 bg-white rounded-md"
                              //   onClick={() => setEditingNoteKey(key)}
                              // >
                              //   Edit
                              // </button>
                              <></>
                            ) : (
                              <div>
                                <button
                                  className="w-[50px] border-1 border-slate-500 bg-white rounded-md mr-2"
                                  onClick={() => setEditingNoteKey(key)}
                                >
                                  Save
                                </button>

                                <button
                                  className="w-[50px] border-1 border-slate-500 bg-white rounded-md"
                                  onClick={() => setEditingNoteKey(key)}
                                >
                                  Cancel
                                </button>
                              </div>
                            )}
                          </div>
                          <DraftailEditor
                            readOnly={key !== editingNoteKey}
                            editorState={EditorState.createWithContent(
                              convertFromRaw(event.note.state)
                            )}
                          />
                        </div>
                      )}
                      <h2 className="text-xs mt-2">
                        {event.subtext && `${event.subtext} - `}
                        {new Date(event.created_at).toLocaleDateString()}{" "}
                        {new Date(event.created_at).toLocaleTimeString()}
                      </h2>
                    </>
                  )}
                </div>
              </div>
            </animated.div>
          );
        })}
      </div>
    </div>
  );
};

export default DealTimeline;
