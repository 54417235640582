import styled from "styled-components";

import { TextMdMedium, TextMdNormal, TextSmNormal } from "styles/mixins";

export const ChildContainer = styled.div`
  flex: 1;
  padding: 32px 32px 48px 32px;
  z-index: 990;
  overflow-y: scroll;
`;
export const SidebarNavigationWrapper = styled.div`
  display: flex;
  background: rgba(16, 24, 40, 1);
  overflow-y: scroll;
  width: 469px;
  height: 100%;
  align-items: flex-start;
`;
export const Divider = styled.div`
  display: flex;
  width: 1px;
  height: 100%;
`;
export const Styled1 = styled.svg`
  fill: ${({ theme }) => theme.colors.primary_600};
  width: 1px;
  height: 100%;
`;
export const Styled = styled.path``;
export const Section = styled.div`
  display: flex;
  background: rgba(16, 24, 40, 1);
  align-self: stretch;
  width: fit-content;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
`;
export const Container = styled.div`
  display: flex;
  width: fit-content;
  flex-direction: column;
  align-items: flex-start;
  padding: 48px 16px 0px 48px;
  gap: 80px;
`;
export const Content = styled.div`
  display: flex;
  align-self: stretch;
  flex-direction: column;
  align-items: flex-start;
  gap: 32px;
`;
export const ProgressStepsProgressIconsWithText = styled.div`
  display: flex;
  width: 400px;
  flex-direction: column;
  align-items: flex-start;
`;
export const StepBase = styled.div`
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  gap: 16px;
`;
export const CurrentStepDot = styled.div`
  background-color: rgb(245, 227, 203);
  width: 16px;
  height: 16px;
  margin-top: 8px;
  margin-left: 8px;
  border-radius: 16px;
  position: absolute;
`;
export const CompletedStepTick = styled.div`
  background-color: ${({ theme }) => theme.colors.white};
  width: 32px;
  height: 32px;
  border-radius: 16px;
  padding-top: 8px;
  padding-left: 8px;
  position: absolute;
`;
export const ConnectorWrap = styled.div`
  display: flex;
  align-self: stretch;
  width: fit-content;
  flex-direction: column;
  align-items: center;
  padding: 0px 0px 4px 0px;
  gap: 4px;
`;
export const StepIconBase = styled.div`
  display: flex;
  background: rgba(119, 81, 10, 1);
  border-radius: 16px;
  overflow: hidden;
  width: 32px;
  height: 32px;
  flex-direction: column;
`;
export const Styled2 = styled.div`
  border: 2px solid rgb(245, 227, 203);
  border-radius: 16px;
  width: 32px;
  height: 32px;
  display: flex;
  flex-direction: column;
  padding: 11px;
`;
export const Connector = styled.div`
  display: flex;
  background: rgba(245, 227, 203, 1);
  border-radius: 2px;
  width: 2px;
  height: 46px;
`;
export const TextAndSupportingText = styled.div`
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  align-items: flex-start;
  padding: 4px 0px 32px 0px;
  gap: 2px;
`;
export const Text = styled.span`
  text-align: left;
  align-self: stretch;
  color: ${({ theme }) => theme.colors.white};

  ${TextMdMedium}
`;
export const SupportingText = styled.span`
  text-align: left;
  align-self: stretch;
  color: ${({ theme }) => theme.colors.primary_200};

  ${TextMdNormal}
`;
export const StepBase1 = styled.div`
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  gap: 16px;
`;
export const ConnectorWrap1 = styled.div`
  display: flex;
  align-self: stretch;
  width: fit-content;
  flex-direction: column;
  align-items: center;
  padding: 0px 0px 4px 0px;
  gap: 4px;
`;
export const TextAndSupportingText1 = styled.div`
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  align-items: flex-start;
  padding: 4px 0px 32px 0px;
  gap: 2px;
`;
export const Text1 = styled.span`
  text-align: left;
  align-self: stretch;
  color: ${({ theme }) => theme.colors.white};

  ${TextMdMedium}
`;
export const SupportingText1 = styled.span`
  text-align: left;
  align-self: stretch;
  color: ${({ theme }) => theme.colors.primary_200};

  ${TextMdNormal}
`;
export const Footer = styled.div`
  display: flex;
  align-self: stretch;
  height: 96px;
  justify-content: space-between;
  align-items: flex-end;
  padding: 40px 40px 40px 40px;
`;
export const Text2 = styled.span`
  text-align: left;
  white-space: pre-line;
  color: ${({ theme }) => theme.colors.primary_200};

  ${TextSmNormal}
`;
export const Row = styled.div`
  display: flex;
  width: fit-content;
  align-items: center;
  gap: 8px;
`;
export const HelpUntitleduiCom = styled.span`
  text-align: left;
  white-space: pre-line;
  color: ${({ theme }) => theme.colors.primary_200};

  ${TextSmNormal}
`;
