import styled from "styled-components";

import { TextSmMedium } from "styles/mixins";

export const CheckBoxWrapper = styled.span<{ disabled?: boolean }>`
  ${(props) => (props.disabled ? "" : "cursor: pointer;")}
  margin: auto auto;
  height: 24px;
  display: inline-block;
`;

export const CheckboxText = styled.span<{ disabled?: boolean }>`
  padding-top: 7px;
  text-align: left;
  color: ${(props) =>
    props.disabled ? props.theme.colors.gray_300 : props.theme.colors.gray_700};
  ${TextSmMedium}
  position: relative;

  margin-left: 30px;
  top: -23px;
  user-select: none;
`;
