import React, { useState } from "react";
import {
  MainWrapper,
  HeaderSection,
  Container,
  Divider,
  Section,
} from "pages/TeamPage/TeamPage.styled";
import { useNavigate } from "react-router-dom";
import PageHeader from "components/layout/PageHeader";
import TableComponent from "components/core/TableComponent";
import Button from "components/core/Button";
import { Navigate } from "react-router-dom";
import { Report } from "types";
import SlidingPane from "components/layout/SlidingPane";
import ReportScheduleForm from "forms/ReportScheduleForm";
import Badge, { BadgeType } from "components/core/Badge";

type ReportsPageProps = {};

const ReportTemplatesPage: React.FC<ReportsPageProps> = ({}) => {
  const navigate = useNavigate();
  const [paneOpen, setPaneOpen] = useState(false);
  const [filter, setFilter] = useState<any>();
  const [tableRefreshTrigger, setTableRefreshTrigger] = useState(false);

  return (
    <MainWrapper>
      <Container>
        <PageHeader
          title={"Report Schedules"}
          subtitle={"Here you can manage the report schedules."}
          rightHandSideChildren={
            <Button
              onClick={() => {
                setPaneOpen(true);
              }}
              text={"New Schedule"}
              primary
            />
          }
        />
      </Container>
      <Section className="min-h-full mt-4">
        <TableComponent
          onRowClick={(row: Report) => navigate(`/reports/${row.id}/`)}
          title={"Report Schedules"}
          triggerReload={tableRefreshTrigger}
          endpoint={"reports/schedules/"}
          filter={filter}
          fields={{
            id: {
              name: "Id",
            },
            name: {
              name: "Name",
            },
            report_template: {
              name: "Report Template",
            },
            frequency: {
              name: "Frequency",
            },
            last_dispatch: {
              name: "Last Dispatch",
            },
            active: {
              name: "active",
              middleware: (row: any) =>
                row?.active ? (
                  <td>
                    <Badge type={BadgeType.success} text={"YES"} />
                  </td>
                ) : (
                  <td>
                    <Badge type={BadgeType.danger} text={"NO"} />
                  </td>
                ),
            },
          }}
        />

        <SlidingPane
          className="overlay-pane-content"
          overlayClassName="overlay-pane"
          isOpen={paneOpen}
          title="New Report Schedule"
          width="800px"
          subtitle="Complete the form to create a new schedule for a pre-existing report."
          onRequestClose={() => {
            // triggered on "<" on left top click or on outside click
            setPaneOpen(false);
          }}
          cancelPromptTitle={"Are you sure?"}
          cancelPromptMessage={"Are you sure you'd like to abandon this form?"}
        >
          <ReportScheduleForm
            cb={() => {
              setPaneOpen(false);
              setTableRefreshTrigger(!tableRefreshTrigger);
            }}
          />
        </SlidingPane>
      </Section>
    </MainWrapper>
  );
};

export default ReportTemplatesPage;
