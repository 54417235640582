import React, { useState, useEffect } from "react";
import {
  MainWrapper,
  Container,
  Section,
} from "pages/TeamPage/TeamPage.styled";
import TableComponent from "components/core/TableComponent";
import PageHeader from "components/layout/PageHeader";
import FilterBar from "components/core/FilterBar";
import Button from "components/core/Button";
import useIsAdmin from "hooks/useIsAdmin";
import { get } from "utilities/Network";
import { LoaderIcon } from "components/core/FilterBar/FilterBar.styled";
import BrokerDropDownField from "components/inputs/BrokerDropDownField";
import Avatar from "components/core/Avatar";
import { BoxMetricsContainer } from "pages/DashboardPage/DashboardPage.styled";
import BoxMetric from "components/metrics/BoxMetric";

type SettlementsPageProps = {};

const openBankStatementForm = () => {};
const months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

interface Settlement {
  term: string;
  interest_rate: string;
  balloon: string;
  brokerage: string;
  deposit: string;
  naf: string;
  dof: string;
  settlement_date: string;
  referral_partner_commission: string;
  title: string;
  time_to_settlement: string;
  assigned?: string;
  assigned_url?: string;
  total_settled_naf?: string;
}

const SettlementsPage: React.FC<SettlementsPageProps> = () => {
  const date = new Date();
  const [broker, setBroker] = useState<any>("All");

  const [currentYear, setCurrentYear] = useState(date.getFullYear());
  const [currentMonth, setCurrentMonth] = useState(
    date.getMonth() === 0 ? 11 : date.getMonth() - 1
  );
  const [loading, setLoading] = useState(true);
  const [filter, setFilter] = useState();
  const [settlementsData, setSettlementsData] = useState<any>(null);
  const { isAdmin } = useIsAdmin();

  useEffect(() => {
    const fetchSettlements = async () => {
      let bfilter = "";
      if (!!broker && isAdmin) {
        bfilter = `?broker=${broker}`;
      }
      const response = await get(
        `deals/broker/settlements/${currentMonth + 1}/${currentYear}/${bfilter}`
      );
      setLoading(false);
      setSettlementsData(response.data);
    };

    fetchSettlements();
  }, [currentMonth, currentYear, broker, isAdmin]);

  const prevMonth = () => {
    if (currentMonth === 0) {
      setCurrentYear((prevYear) => prevYear - 1);
      setCurrentMonth(11);
    } else {
      setCurrentMonth((prevCurrentMonth) => prevCurrentMonth - 1);
    }
  };

  const nextMonth = () => {
    if (currentMonth === date.getMonth() && currentYear === date.getFullYear())
      return;
    if (currentMonth === 11) {
      setCurrentYear((prevYear) => prevYear + 1);
      setCurrentMonth(0);
    } else {
      setCurrentMonth((prevCurrentMonth) => prevCurrentMonth + 1);
    }
  };

  if (loading) {
    return (
      <MainWrapper>
        <Container>
          <PageHeader title={"Settlement Summaries"} />
        </Container>
        <Section>
          <LoaderIcon />
        </Section>
      </MainWrapper>
    );
  }

  if (!isAdmin) {
    return (
      <MainWrapper>
        <Container>
          <PageHeader
            title={"Settlement Summaries"}
            subtitle={"Welcome to your month-by-month settlement summaries."}
          />
        </Container>
        <Section>
          {/* Header section */}
          <div className="w-full mb-4 mt-4 flex flex-row">
            <div className="w-1/2 flex justify-start">
              <h2 className="text-2xl">
                Your settlements for{" "}
                <strong>{`${months[currentMonth]} ${currentYear}`}</strong>
              </h2>
            </div>
            <div className="w-1/2 flex justify-end">
              <nav
                className="relative z-0 inline-flex rounded-md shadow-sm -space-x-px"
                aria-label="Pagination"
              >
                <a
                  href="#"
                  onClick={prevMonth}
                  className="relative inline-flex items-center px-2 py-2 rounded-l-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50"
                >
                  <span className="sr-only">Previous</span>
                  <svg
                    className="h-5 w-5"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z"
                      clip-rule="evenodd"
                    />
                  </svg>
                </a>

                <a
                  href="#"
                  aria-current="page"
                  className="z-10  border-amber-500 text-amber-600 relative inline-flex items-center px-4 py-2 border text-sm font-medium"
                >
                  <div className="min-w-[110px] text-center">{`${months[currentMonth]} ${currentYear}`}</div>
                </a>

                <a
                  href="#"
                  onClick={nextMonth}
                  className="relative inline-flex items-center px-2 py-2 rounded-r-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50"
                >
                  <span className="sr-only">Next</span>
                  <svg
                    className="h-5 w-5"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                      clip-rule="evenodd"
                    />
                  </svg>
                </a>
              </nav>
            </div>
          </div>
        </Section>

        {/* Settlements List */}
        <Section>
          <div className="w-full overflow-x-auto">
            {!!settlementsData && (
              <table className="min-w-full divide-y divide-gray-200">
                <thead className="bg-gray-50">
                  <tr>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase"
                    >
                      Deal Title
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase"
                    >
                      Time To Settlement (days)
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase"
                    >
                      Term
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase"
                    >
                      Interest Rate
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase"
                    >
                      Balloon
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase"
                    >
                      Brokerage
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase"
                    >
                      Deposit
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase"
                    >
                      NAF
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase"
                    >
                      DOF
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase"
                    >
                      Referral Partner Commission
                    </th>
                  </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-200">
                  {(settlementsData.deals || []).map(
                    (settlement: Settlement, index: number) => (
                      <tr key={index}>
                        <td className="px-6 py-4 whitespace-nowrap">
                          {settlement.title}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap">
                          {settlement.time_to_settlement}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap">
                          {settlement.term}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap">
                          {settlement.interest_rate}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap">
                          {settlement.balloon}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap">
                          {settlement.brokerage}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap">
                          {settlement.deposit}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap">
                          {settlement.naf}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap">
                          {settlement.dof}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap">
                          {settlement.referral_partner_commission}
                        </td>
                      </tr>
                    )
                  )}
                </tbody>
              </table>
            )}
          </div>
        </Section>
      </MainWrapper>
    );
  }

  return (
    <MainWrapper>
      <Container>
        <PageHeader
          title={"Settlement Summaries"}
          subtitle={"Welcome to your month-by-month settlement summaries."}
        />
      </Container>
      <Section>
        {/* Header section */}
        <div className="w-full mb-4 mt-4 flex flex-row">
          <div className="w-1/2 flex justify-start">
            <h2 className="text-2xl">
              Your settlements for{" "}
              <strong>{`${months[currentMonth]} ${currentYear}`}</strong>
            </h2>
          </div>
          <div className="w-1/2 flex justify-end">
            <div className="max-w-[250px] mr-2">
              <BrokerDropDownField
                placeholder={"Filter by broker"}
                allowDefault={false}
                endpoint="team/brokers/"
                defaultAll={true}
                onChange={(e) => {
                  if (!!e) {
                    setBroker(e);
                  }
                }}
              />
            </div>
            <nav
              className="relative z-0 inline-flex rounded-md shadow-sm -space-x-px"
              aria-label="Pagination"
            >
              <a
                href="#"
                onClick={prevMonth}
                className="relative inline-flex items-center px-2 py-2 rounded-l-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50"
              >
                <span className="sr-only">Previous</span>
                <svg
                  className="h-5 w-5"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                  aria-hidden="true"
                >
                  <path
                    fill-rule="evenodd"
                    d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z"
                    clip-rule="evenodd"
                  />
                </svg>
              </a>

              <a
                href="#"
                aria-current="page"
                className="z-10  border-amber-500 text-amber-600 relative inline-flex items-center px-4 py-2 border text-sm font-medium"
              >
                <div className="min-w-[110px] text-center">{`${months[currentMonth]} ${currentYear}`}</div>
              </a>

              <a
                href="#"
                onClick={nextMonth}
                className="relative inline-flex items-center px-2 py-2 rounded-r-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50"
              >
                <span className="sr-only">Next</span>
                <svg
                  className="h-5 w-5"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                  aria-hidden="true"
                >
                  <path
                    fill-rule="evenodd"
                    d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                    clip-rule="evenodd"
                  />
                </svg>
              </a>
            </nav>
          </div>
        </div>
      </Section>

      {/* Settlements List */}
      <Section>
        <div className="w-full overflow-x-auto">
          {!!settlementsData && (
            <>
              <BoxMetricsContainer className="flex flex-wrap items-center justify-center w-full gap-4">
                <BoxMetric
                  wide
                  title={"Settled Deals"}
                  value={settlementsData.total_deals}
                ></BoxMetric>
                <BoxMetric
                  wide
                  title={"Total Brokerage"}
                  value={settlementsData.total_brokerage}
                ></BoxMetric>
                <BoxMetric
                  wide
                  title={"Total NAF"}
                  value={settlementsData.total_settled_naf}
                ></BoxMetric>
              </BoxMetricsContainer>
              <table className="min-w-full divide-y divide-gray-200">
                <thead className="bg-gray-50">
                  <tr>
                    {broker === "All" && (
                      <th
                        scope="col"
                        className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase"
                      >
                        Broker
                      </th>
                    )}
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase"
                    >
                      Deal Title
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase"
                    >
                      Time To Settlement (days)
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase"
                    >
                      Settlement Date
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase"
                    >
                      Term
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase"
                    >
                      Interest Rate
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase"
                    >
                      Balloon
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase"
                    >
                      Brokerage
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase"
                    >
                      Deposit
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase"
                    >
                      NAF
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase"
                    >
                      DOF
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase"
                    >
                      Referral Partner Commission
                    </th>
                  </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-200">
                  {(settlementsData.deals || []).map(
                    (settlement: Settlement, index: number) => (
                      <tr key={index}>
                        {broker === "All" && (
                          <td className="px-6 py-4 whitespace-nowrap flex flex-row gap-4 justify-start items-center mr-4">
                            {!!settlement.assigned_url && (
                              <Avatar src={settlement.assigned_url}></Avatar>
                            )}
                            {settlement.assigned}
                          </td>
                        )}
                        <td className="px-6 py-4 whitespace-nowrap">
                          {settlement.title}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap">
                          {settlement.time_to_settlement}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap">
                          {settlement.settlement_date}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap">
                          {settlement.term}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap">
                          {settlement.interest_rate}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap">
                          {settlement.balloon}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap">
                          {settlement.brokerage}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap">
                          {settlement.deposit}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap">
                          {settlement.naf}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap">
                          {settlement.dof}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap">
                          {settlement.referral_partner_commission}
                        </td>
                      </tr>
                    )
                  )}
                </tbody>
              </table>
            </>
          )}
        </div>
      </Section>
    </MainWrapper>
  );
};

export default SettlementsPage;
