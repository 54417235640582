import useEditableText from "hooks/useEditableText";
import { toast } from "react-toastify";
import { post } from "utilities/Network";

const useApplicantManager = ({ applicant }: any) => {
  const editableFirstNameText = useEditableText({
    defaultText: applicant?.first_name,
    handleSave: (value, cb: any) => {
      post(`organisations/people/${applicant?.id}/update/`, {
        field: "first_name",
        value,
      })
        .then(() => {
          toast.success("Applicant first name updated.");
          cb();
        })
        .catch(() => cb(false));
    },
    validator: (value: string) => {
      let error = "";
      let valid = false;

      if (value.length < 3) {
        valid = false;
        error = "Applicant first name must be at least 3 characters";
      }

      return { valid, error };
    },
  });
  const applicantFirstNameText = editableFirstNameText;

  const editableLastNameText = useEditableText({
    defaultText: applicant?.last_name,
    handleSave: (value, cb: any) => {
      post(`organisations/people/${applicant?.id}/update/`, {
        field: "last_name",
        value,
      })
        .then(() => {
          toast.success("Applicant last name updated.");
          cb();
        })
        .catch(() => cb(false));
    },
    validator: (value: string) => {
      let error = "";
      let valid = false;

      if (value.length < 3) {
        valid = false;
        error = "Applicant last name must be at least 3 characters";
      }

      return { valid, error };
    },
  });
  const applicantLastNameText = editableLastNameText;

  const editableContactNumber = useEditableText({
    defaultText: applicant?.contact_number,
    handleSave: (value, cb: any) => {
      post(`organisations/people/${applicant?.id}/update/`, {
        field: "contact_number",
        value,
      })
        .then(() => {
          toast.success("Applicant contact number updated.");
          cb();
        })
        .catch(() => cb(false));
    },
    validator: (value: string) => {
      let error = "";
      let valid = false;

      if (value.length < 3) {
        valid = false;
        error = "Applicant contact number must be at least 3 characters";
      }

      return { valid, error };
    },
  });
  const applicantContactNumber = editableContactNumber;

  const editableEmailAddress = useEditableText({
    defaultText: applicant?.email_address,
    handleSave: (value, cb: any) => {
      post(`organisations/people/${applicant?.id}/update/`, {
        field: "email_address",
        value,
      })
        .then(() => {
          toast.success("Applicant email address updated.");
          cb();
        })
        .catch(() => cb(false));
    },
    validator: (value: string) => {
      let error = "";
      let valid = false;

      if (value.length < 3) {
        valid = false;
        error = "Applicant email address must be at least 3 characters";
      }

      return { valid, error };
    },
  });
  const applicantEmailAddress = editableEmailAddress;

  return {
    applicantFirstNameText,
    applicantLastNameText,
    applicantContactNumber,
    applicantEmailAddress,
  };
};

export default useApplicantManager;
