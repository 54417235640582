import React, { useState } from "react";
import Button from "components/core/Button";
import * as yup from "yup";
import InputField from "components/inputs/InputField";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { post } from "utilities/Network";

export type FormData = {
  business_name: string;
  business_website: string;
  partner_first_name: string;
  partner_last_name: string;
  partner_email_address: string;
  partner_contact_number: string;
  partner_job_title: string;
};

export const FormSchema = yup.object({
  business_name: yup.string().required().min(3).max(254),
  business_website: yup.string().nullable(),
  partner_first_name: yup.string().required().min(3).max(254),
  partner_last_name: yup.string().required().min(3).max(254),
  partner_email_address: yup.string().email().required(),
  partner_contact_number: yup.string().required(),
  partner_job_title: yup.string().required(),
});

export const RequiredFieldsMap = {
  business_name: true,
  business_website: false,
  partner_first_name: true,
  partner_last_name: true,
  partner_email_address: true,
  partner_contact_number: true,
  partner_job_title: true,
};

type ReportProps = {
  cb?: (id: any) => void;
};

const NewPartnerForm: React.FC<ReportProps> = ({ cb }) => {
  const [submitting, setSubmitting] = useState(false);
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<FormData>({
    resolver: yupResolver(FormSchema),
  });

  const onSubmit = (formData: FormData) => {
    setSubmitting(true);
    post(`partners/create/`, {
      business_name: formData.business_name,
      accounts: [
        {
          first_name: formData.partner_first_name,
          last_name: formData.partner_last_name,
          email_address: formData.partner_email_address,
          job_title: formData.partner_job_title,
          contact_number: formData.partner_contact_number,
        },
      ],
    }).then((response) => {
      setSubmitting(false);
      if (!!cb) cb(response.data.id);
    });
  };

  return (
    <form>
      {/* Business Name */}
      <InputField
        formControlProps={register("business_name")}
        label={"Business Name"}
        disabled={submitting}
        errorMessage={errors.business_name?.message}
        required={!!RequiredFieldsMap.business_name}
      />

      {/* Partner First Name */}
      <InputField
        formControlProps={register("partner_first_name")}
        label={"Partner First Name"}
        disabled={submitting}
        errorMessage={errors.partner_first_name?.message}
        required={!!RequiredFieldsMap.partner_first_name}
      />

      {/* Partner Last Name */}
      <InputField
        formControlProps={register("partner_last_name")}
        label={"Partner Last Name"}
        disabled={submitting}
        errorMessage={errors.partner_last_name?.message}
        required={!!RequiredFieldsMap.partner_last_name}
      />

      {/* Partner Email Address */}
      <InputField
        formControlProps={register("partner_email_address")}
        label={"Partner Email Address"}
        disabled={submitting}
        errorMessage={errors.partner_email_address?.message}
        required={!!RequiredFieldsMap.partner_email_address}
      />

      {/* Partner Contact Number */}
      <InputField
        formControlProps={register("partner_contact_number")}
        label={"Partner Contact Number"}
        disabled={submitting}
        errorMessage={errors.partner_contact_number?.message}
        required={!!RequiredFieldsMap.partner_contact_number}
      />

      {/* Partner Job Title */}
      <InputField
        formControlProps={register("partner_job_title")}
        label={"Partner Job Title"}
        disabled={submitting}
        errorMessage={errors.partner_job_title?.message}
        required={!!RequiredFieldsMap.partner_job_title}
      />

      <Button
        onClick={handleSubmit(onSubmit)}
        text={"Done"}
        primary
        right
        disabled={submitting}
        wide={false}
      />
    </form>
  );
};

export default NewPartnerForm;
