import React from "react";

type SubTitleProps = {
  text: string;
  onClick?: () => void;
};

const SubTitle: React.FC<SubTitleProps> = ({ text, onClick = () => {} }) => {
  return (
    <div className="min-h-[30px] cursor-pointer" onClick={onClick}>
      <h2 className="inline mb-2 font-bold">{text}</h2>
    </div>
  );
};

export default SubTitle;
