import React, { useEffect, useState } from "react";
import LoaderLogo from "components/core/LoaderLogo";
import { useSearchParams } from "react-router-dom";
import {
  Styled,
  Text,
  SupportingText,
  TextAndSupportingText,
  Container,
  Section,
  SignUpWrapper,
} from "./RedirectionPage.styled";
import ThreeLoadingDots from "components/core/ThreeLoadingDots";
import { get, post } from "utilities/Network";

export type RedirectionPageProps = {};
const RedirectionPage: React.FC<RedirectionPageProps> = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [errored, setErrored] = useState(false);
  const [redirectResponse, setRedirectResponse] = useState();

  useEffect(() => {
    const token = searchParams.get("t");
    get(`redirect/?t=${token}`)
      .then((response) => {
        setRedirectResponse(response.data);
        if (!!response.data.redirect_url) {
          setTimeout(() => {
            window.location.href = response.data.redirect_url;
          }, 1000);
        }
      })
      .catch((response) => {
        setErrored(true);
        setRedirectResponse(response);
      });
  }, [searchParams]);

  return (
    <>
      {" "}
      <SignUpWrapper>
        <Section>
          <Container>
            <div style={{ position: "relative", left: "-88px", top: "40px" }}>
              <LoaderLogo width={400} withText />
            </div>
            <TextAndSupportingText>
              <Text>
                {errored ? (
                  "Sorry Steve, Something Went Wrong."
                ) : (
                  <>
                    {`Please wait,`}
                    <br />
                    {`We are processing your request `}
                    <ThreeLoadingDots />
                  </>
                )}
              </Text>
              {!errored && (
                <SupportingText>This should only take a moment.</SupportingText>
              )}
            </TextAndSupportingText>
            {errored && (
              <SupportingText>
                {JSON.stringify(redirectResponse)}
              </SupportingText>
            )}
          </Container>
        </Section>
      </SignUpWrapper>
    </>
  );
};

export default RedirectionPage;
