import styled from "styled-components";
import { TextLgMedium } from "styles/mixins";

export const SignUpWrapper = styled.div`
  display: flex;
  background: rgba(255, 255, 255, 1);
  align-self: stretch;
  height: 100vh;
  align-items: center;
`;
export const Section = styled.div`
  display: flex;
  background: linear-gradient(
    45deg,
    rgba(16, 24, 40, 1) 0%,
    rgba(71, 84, 103, 1) 100%
  );
  width: 100%;
  height: 100%;
  flex-direction: column;
  padding: 290px 0px;
`;
export const Container = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 0px 96px 0px 96px;
  gap: 32px;
`;
export const Styled = styled.img`
  width: 133.962px;
  height: 72.541px;
  display: flex;
  flex-direction: column;
  gap: 14.79733657836914px;
  padding: 0px 0.00000476837158203125px 0px 0px;
  align-items: center;
`;
export const TextAndSupportingText = styled.div`
  display: flex;
  align-self: stretch;
  flex-direction: column;
  align-items: center;
  gap: 24px;
`;
export const Text = styled.span`
  font-size: 60px;
  font-family: "Inter";
  font-weight: 500;
  line-height: 72px;
  letter-spacing: -0.02em;
  text-align: left;
  white-space: pre-wrap;
  align-self: stretch;
  color: ${({ theme }) => theme.colors.white};
`;
export const SupportingText = styled.span`
  text-align: left;
  align-self: stretch;
  color: ${({ theme }) => theme.colors.gray_200};

  ${TextLgMedium}
`;
