import React, { useState, useEffect } from "react";
import TableComponent from "components/core/TableComponent";
import {
  MainWrapper,
  Container,
  Section,
} from "pages/TeamPage/TeamPage.styled";
import { ReactComponent as userIcon } from "assets/icons/user.svg";
import Badge, { BadgeType } from "components/core/Badge";
import PageHeader from "components/layout/PageHeader";
import { useParams, useNavigate } from "react-router-dom";
import { get } from "utilities/Network";
import InfoBox from "components/core/InfoBox";
import { ReactComponent as infoIcon } from "assets/icons/info.svg";
import IconBase from "components/core/IconBase";
import constants from "styles/constants";
import Tabs from "components/core/Tabs";
import useOrganisationManager from "hooks/useOrganisationManager";

const OrganisationDetailPage = ({}: any) => {
  const navigate = useNavigate();
  const { organisationId } = useParams();
  const [organisation, setOrganisation] = useState<any>();

  const fetchOrganisation = () => {
    get(`organisations/${organisationId}/`).then((response) =>
      setOrganisation(response.data)
    );
  };
  useEffect(fetchOrganisation, [organisationId]);

  const {
    organisationNameText,
    organisationStateText,
    organisationPostCodeText,
    organisationStreetAddressText,
    organisationCityText,
    organisationABNText,
  } = useOrganisationManager({ organisation });

  return (
    <MainWrapper>
      <Container>
        <PageHeader
          title={`Organisation ${organisation?.name}`}
          subtitle={<>{organisationId}</>}
          breadcrumbs={[
            {
              title: "Applicants",
              link: "/applicants",
            },
          ]}
        />
      </Container>
      <Section>
        <div className="flex flex-wrap-reverse w-full gap-8 pt-4">
          <Tabs
            tabs={[
              {
                name: "Details",
                component: (
                  <>
                    <div className="w-1/2">
                      <InfoBox
                        title={"Organisation Details"}
                        icon={
                          <IconBase
                            src={infoIcon}
                            color={constants.colors.primary_600}
                          />
                        }
                        info={{
                          name: organisationNameText,
                          abn: organisationABNText,
                          state: organisationStateText,
                          "Post Code": organisationPostCodeText,
                          "Street Address": organisationStreetAddressText,
                          City: organisationCityText,
                          "Pipedrive Id": organisation?.pipedrive_id,
                          id: organisation?.id,
                          "Primary Contact": `${organisation?.primary_contact?.first_name} ${organisation?.primary_contact?.last_name}`,
                        }}
                        w={"100%"}
                      />
                    </div>
                  </>
                ),
              },
              {
                name: "Deals",
                component: (
                  <>
                    <TableComponent
                      endpoint={`organisations/${organisationId}/deals/`}
                      onRowClick={(row: any, newTab = false) => {
                        if (newTab) {
                          window.open(
                            `https://crm.finstead.com.au/deals/${row.id}`,
                            "_blank",
                            "noreferrer"
                          );
                        } else {
                          navigate(`/deals/${row.id}`);
                        }
                      }}
                      fields={{
                        id: {
                          name: "Id",
                        },
                        pipedrive_id: {
                          name: "Pipedrive Id",
                        },
                        broker: {
                          name: "Broker",
                          middleware: (row: any) => (
                            <>
                              {!!row.broker_profile_picture_url ? (
                                <img
                                  src={row.broker_profile_picture_url}
                                  className="w-[25px] h-[25px] object-cover rounded-full inline-block mr-2"
                                />
                              ) : (
                                <span className="inline-block mr-2 border rounded-full border-slate-100">
                                  <IconBase src={userIcon} />
                                </span>
                              )}
                              <span>{row.broker}</span>
                            </>
                          ),
                        },
                        title: {
                          name: "Title",
                        },
                        add_time: {
                          name: "Lead In",
                        },
                        value: {
                          name: "Value",
                        },
                        status: {
                          name: "Status",
                          middleware: (row: any) => {
                            switch (row.status) {
                              case "":
                                return "";
                              case "open":
                                return (
                                  <Badge type={BadgeType.info} text="Open" />
                                );
                              case "lost":
                                return (
                                  <Badge type={BadgeType.danger} text="Lost" />
                                );
                              case "pending assignment":
                                return (
                                  <Badge
                                    type={BadgeType.info}
                                    text={"Lead In"}
                                  />
                                );
                              case "won":
                              case "settled":
                                return (
                                  <Badge
                                    type={BadgeType.success}
                                    text={"Settled"}
                                  />
                                );
                              default:
                                return (
                                  <Badge
                                    type={BadgeType.warning}
                                    text={"Unknown ?"}
                                  />
                                );
                            }
                          },
                        },
                      }}
                    />
                  </>
                ),
              },
              {
                name: "People",
                component: (
                  <TableComponent
                    onRowClick={(row: any, newTab = false) => {
                      if (newTab) {
                        window.open(
                          `https://crm.finstead.com.au/applicants/${row.id}`,
                          "_blank",
                          "noreferrer"
                        );
                      } else {
                        navigate(`/applicants/${row.id}`);
                      }
                    }}
                    title={"Applicants"}
                    endpoint={`organisations/${organisationId}/people/`}
                    fields={{
                      id: {
                        name: "Id",
                      },
                      pipedrive_id: {
                        name: "Pipedrive Id",
                      },
                      first_name: {
                        name: "First Name",
                      },
                      last_name: {
                        name: "Last Name",
                      },
                      email_address: {
                        name: "Email",
                      },
                      contact_number: {
                        name: "Contact Number",
                      },
                      organisation: {
                        name: "Organisation",
                      },
                    }}
                  />
                ),
              },
            ]}
          />
        </div>
      </Section>
    </MainWrapper>
  );
};

export default OrganisationDetailPage;
