import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import StepProcessWrapper from "components/layout/StepProcessWrapper";
import PageHeader from "components/layout/PageHeader";
import Button from "components/core/Button";
import InputField from "components/inputs/InputField";
import TextArea from "components/inputs/TextArea";
import {
  ReadyForSubmissionBanner,
  Heading,
  HeadingAndBadge,
  SupportingText,
  HeadingAndSupportingTextWrapper,
  InsuranceInfoBox,
} from "./InsuranceReferralPage.styled";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { FormData, FormSchema, RequiredFieldsMap } from "./form";
import { get, post } from "utilities/Network";
import LoadingBar from "react-top-loading-bar";
import constants from "styles/constants";
import RadioGroupField from "components/inputs/RadioGroupField";

export type InsuranceReferralPageProps = {};
enum InsuranceReferralFormSteps {
  ApplicantDetails,
  Submission,
  Submitted = 3,
}

enum FormStatuses {
  preloading,
  waiting,
  readyToSubmit,
  submitting,
  submissionError,
  submissionSuccess,
}
const editableStatuses = [FormStatuses.waiting, FormStatuses.submissionError];

const InsuranceReferralPage: React.FC<InsuranceReferralPageProps> = ({}) => {
  const [loadingProgress, setLoadingProgress] = useState(0);
  const [searchParams, setSearchParams] = useSearchParams();
  const [step, setStep] = useState<InsuranceReferralFormSteps>(
    InsuranceReferralFormSteps.ApplicantDetails
  );
  const [formStatus, setFormStatus] = useState(FormStatuses.preloading);
  const [fromEditable, setFormEditable] = useState(false);

  const [hoveredInsuranceInfo, setHoveredInsuranceInfo] = useState<any>();

  // Form setup
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<FormData>({
    resolver: yupResolver(FormSchema),
  });

  // preload form data
  useEffect(() => {
    // get the token from the url
    const token = searchParams.get("t");
    setLoadingProgress(50);
    get(`referrals/insurance/finstead-risk/outward/?t=${token}`)
      .then((response) => {
        reset(response.data);
        setFormStatus(FormStatuses.waiting);
        setLoadingProgress(100);
      })
      .catch((response) => {
        // todo - handle error
      });
  }, [reset, searchParams]);

  // handle form editable status based on form status
  useEffect(() => {
    setFormEditable(editableStatuses.indexOf(formStatus) !== -1);
  }, [formStatus]);

  // perform submission
  const performSubmission = (data: FormData) => {
    setLoadingProgress(75);
    setFormStatus(FormStatuses.submitting);
    const token = searchParams.get("t");

    setStep(InsuranceReferralFormSteps.Submitted);
    setFormStatus(FormStatuses.submissionSuccess);
    setLoadingProgress(100);
    post(`referrals/insurance/finstead-risk/outward/?t=${token}`, data)
      .then((response) => {
        setStep(InsuranceReferralFormSteps.Submitted);
        setFormStatus(FormStatuses.submissionSuccess);
        setLoadingProgress(100);
      })
      .catch((error) => {
        // todo - handle error
        setFormStatus(FormStatuses.readyToSubmit);
        setStep(InsuranceReferralFormSteps.Submission);
        setLoadingProgress(0);
        // todo - handle error messages
      });
  };

  // handle step back
  const stepBack = () => {
    // only step back to Applicant details if on the Submission stage
    if (step === InsuranceReferralFormSteps.Submission) {
      setStep(InsuranceReferralFormSteps.ApplicantDetails);
      setFormStatus(FormStatuses.waiting);
    }
  };

  // Handle submission, this function only triggers when FormData is valid
  const onSubmit = (data: FormData) => {
    if (step === InsuranceReferralFormSteps.ApplicantDetails) {
      setStep(InsuranceReferralFormSteps.Submission);
      setFormStatus(FormStatuses.readyToSubmit);
    }
    if (step === InsuranceReferralFormSteps.Submission) {
      performSubmission(data);
    }
  };

  const formContent = (
    <>
      <PageHeader
        title={"Finstead Insurance Referral"}
        subtitle={"Refer this deal to Finstead Risk Solutions"}
      />
      {/* Form Container */}
      <form onSubmit={handleSubmit(onSubmit)} style={{ maxWidth: "700px" }}>
        <InputField
          loading={formStatus === FormStatuses.preloading}
          formControlProps={register("firstName")}
          label={"First Name"}
          errorMessage={errors.firstName?.message}
          disabled={!fromEditable}
          required={!!RequiredFieldsMap.firstName}
        />

        <InputField
          loading={formStatus === FormStatuses.preloading}
          formControlProps={register("lastName")}
          label={"Last Name"}
          errorMessage={errors.lastName?.message}
          disabled={!fromEditable}
          required={!!RequiredFieldsMap.lastName}
        />

        <InputField
          loading={formStatus === FormStatuses.preloading}
          formControlProps={register("emailAddress")}
          type="email"
          label={"Email"}
          errorMessage={errors.emailAddress?.message}
          disabled={!fromEditable}
          required={!!RequiredFieldsMap.emailAddress}
        />

        <InputField
          loading={formStatus === FormStatuses.preloading}
          formControlProps={register("phoneNumber")}
          label={"Phone Number"}
          errorMessage={errors.phoneNumber?.message}
          disabled={!fromEditable}
          required={!!RequiredFieldsMap.phoneNumber}
        />

        <InputField
          loading={formStatus === FormStatuses.preloading}
          formControlProps={register("organisationName")}
          label={"Organisation Name"}
          errorMessage={errors.organisationName?.message}
          disabled={!fromEditable}
          required={!!RequiredFieldsMap.organisationName}
        />

        <InputField
          loading={formStatus === FormStatuses.preloading}
          formControlProps={register("organisationABN")}
          label={"Organisation ABN"}
          errorMessage={errors.organisationABN?.message}
          disabled={!fromEditable}
          required={!!RequiredFieldsMap.organisationABN}
        />

        <InputField
          loading={formStatus === FormStatuses.preloading}
          formControlProps={register("organisationAddress")}
          label={"Organisation Address"}
          errorMessage={errors.organisationAddress?.message}
          disabled={!fromEditable}
          required={!!RequiredFieldsMap.organisationAddress}
        />

        <InputField
          loading={formStatus === FormStatuses.preloading}
          formControlProps={register("occupation")}
          label={"Occupation"}
          subtitle={
            "The current industry or vocation of the finance applicant."
          }
          errorMessage={errors.occupation?.message}
          disabled={!fromEditable}
          required={true}
        />

        <InputField
          loading={formStatus === FormStatuses.preloading}
          formControlProps={register("interestedParty")}
          label={"Interested Party"}
          subtitle={
            "A third party (i.e. financier/lender) who may have some vested financial concern over the asset."
          }
          errorMessage={errors.interestedParty?.message}
          disabled={!fromEditable}
          required={true}
        />

        <TextArea
          loading={formStatus === FormStatuses.preloading}
          formControlProps={register("notes")}
          label={"Notes"}
          placeholder={
            "Give some context about the client's industry and business description. Also if possible provide the asset type, vehicle make/model/year."
          }
          errorMessage={errors.notes?.message}
          disabled={!fromEditable}
          required={!!RequiredFieldsMap.notes}
        />

        {/* Insurances */}
        <RadioGroupField
          register={register}
          label={"Insurance Type Options"}
          onItemHover={setHoveredInsuranceInfo}
          disabled={!fromEditable}
          options={[
            {
              field: "insurances.general",
              description: "General Insurance Required",
              extended_description:
                "Client would like to discuss insurances, limited knowledge on existing policies.",
            },
            {
              field: "insurances.professional_indemnity",
              description: "Professional Indemnity (PI)",
              extended_description:
                "Third party claims relating to erroneous advice or services",
            },
            {
              field: "insurances.management_liability",
              description: "Management Liability (ML)",
              extended_description:
                "Combined policy for private companies: Covering claims against management and the entity for alleged wrongful management acts, Employment Practices Liability, Crime &Statutory Liability",
            },
            {
              field: "insurances.crime",
              description: "Crime",
              extended_description:
                "Direct financial loss discovered during the policy period",
            },
            {
              field: "insurances.directors_and_officers_liability",
              description: "Directors & Officers Liability (D&O)",
              extended_description: "Allegations of wrongful management acts",
            },
            {
              field: "insurances.investment_managers",
              description: "Investment Managers Insurance (IMI)",
              extended_description:
                "Combination of PI, Crime and D&O for fund managers",
            },
            {
              field: "insurances.private_equity",
              description: "Private Equity Insurance (PE)",
              extended_description:
                'Similar to IMI but for PE firms.  D&O cover the "Outside Directorships" is a key part of the policy',
            },
            {
              field: "insurances.warranty_and_indemnity",
              description: "Warranty & Indemnity Insurance (W&I)",
              extended_description:
                "Exposures arising from the purchase or sale of a business. Can be arranged by the buyer or the seller",
            },
            {
              field: "insurances.information_technology_liability",
              description: "Information Technology Liability (IT)",
              extended_description:
                "For IT firms, combined PI and Public and Prodcuts Liablity",
            },
            {
              field: "insurances.public_general_product_liability",
              description:
                "Public Liability (General Liability) / Products Liability (PL/GL)",
              extended_description:
                "Claims for bodily injury or property damage arising from the insured's activities. Products Liability is injury, damage or illness caused by the insured's product",
            },
            {
              field: "insurances.business_pack",
              description: "Business Pack",
              extended_description:
                "Combination of Contents, plate glass and Public & Products Liability, usually for office-risks",
            },
            {
              field: "insurances.marine_transit",
              description: "Marine Transit",
              extended_description:
                "Can be within Australia (Inland Transit), or worldwide - similar but priced differently. Loss arising from the movement of goods from one place to another.",
            },
            {
              field: "insurances.comprehensive_motor",
              description: "Comprehensive Motor Insurance",
              extended_description:
                "sDamage, or theft of the insured vehicle, along with costs arising from damage to other's cars, property or bodily injury",
            },
            {
              field: "insurances.plant_and_equipment",
              description: "Plant & Equipment",
              extended_description:
                "Loss as a result of damage or theft of commercial plant or equipment",
            },
          ]}
        ></RadioGroupField>

        {fromEditable && (
          <InsuranceInfoBox>
            <strong style={{ marginBottom: "20px", display: "block" }}>
              {hoveredInsuranceInfo?.description}
            </strong>
            {hoveredInsuranceInfo?.extended_description ||
              "Hover your mouse over any of the insurance options above to view more detailed information."}
          </InsuranceInfoBox>
        )}

        {/* Prompt user to check the forms contents */}
        {formStatus === FormStatuses.readyToSubmit && (
          <ReadyForSubmissionBanner>
            Please review the form before submitting.
          </ReadyForSubmissionBanner>
        )}

        {/* Inform the user that the form is submitting */}
        {formStatus === FormStatuses.submitting && (
          <ReadyForSubmissionBanner>
            Submitting... Please don't close this page.
          </ReadyForSubmissionBanner>
        )}

        <Button
          onClick={stepBack}
          primary={true}
          disabled={
            formStatus === FormStatuses.submitting ||
            formStatus === FormStatuses.submissionSuccess ||
            step !== InsuranceReferralFormSteps.Submission
          }
          text={"back"}
        />

        <Button
          type={"submit"}
          onClick={() => {}}
          primary={true}
          right={true}
          disabled={
            formStatus === FormStatuses.submitting ||
            formStatus === FormStatuses.preloading
          }
          text={formStatus === FormStatuses.readyToSubmit ? "Submit" : "Next"}
        />
      </form>
    </>
  );

  const formSubmittedContent = (
    <>
      <PageHeader title={""} subtitle={""} />
      <br />
      <HeadingAndSupportingTextWrapper>
        <HeadingAndBadge>
          <Heading>Referral Complete</Heading>
        </HeadingAndBadge>
        <SupportingText>
          This deal has been successfully referred to Finstead Risk Solutions.
        </SupportingText>
        <SupportingText>You may now close this tab/window.</SupportingText>
        {/* <Button
          primary={true}
          onClick={() => {
            window.close();
          }}
          text="Back To Pipedrive"
        /> */}
      </HeadingAndSupportingTextWrapper>
    </>
  );

  return (
    <>
      <LoadingBar
        color={constants.colors.primary_600}
        progress={loadingProgress}
        onLoaderFinished={() => setLoadingProgress(0)}
        loaderSpeed={1000}
      />
      <StepProcessWrapper
        steps={[
          {
            title: "Applicant Details",
            subtitle: "Please provide the applicant's basic details.",
          },
          {
            title: "Submission",
            subtitle: "Submit to Finstead Risk Solutions.",
          },
          {
            title: "Confirmation",
            subtitle: "Submission confirmation.",
          },
        ]}
        currentStep={step}
        currentStepLoading={
          formStatus === FormStatuses.submitting ||
          formStatus === FormStatuses.preloading
        }
      >
        {formStatus === FormStatuses.submissionSuccess
          ? formSubmittedContent
          : formContent}
      </StepProcessWrapper>
    </>
  );
};

export default InsuranceReferralPage;
