import React from "react";
import upArrow from "assets/icons/up_arrow.svg";
import downArrow from "assets/icons/down_arrow.svg";
import IconBase from "components/core/IconBase";
import { ReactComponent as InfoIcon } from "assets/icons/info.svg";

import {
  Heading,
  HeadingContainer,
  Number,
  Styled,
  ChangeValue,
  Change,
  PeriodDescription,
  ChangeAndText,
  NumberAndBadge,
  ValueContainer,
  BoxMetricWrapper,
} from "./BoxMetric.styled";
import ReactTooltip from "react-tooltip";

export type BoxMetricProps = {
  title: string;
  value: any;
  delta_value?: any;
  delta_is_positive?: boolean;
  delta_type?: string;
  period_description?: string;
  inverse_delta?: boolean;
  wide?: boolean;
  disclaimer?: string;
  className?: string;
};

const BoxMetricGroup: React.FC<BoxMetricProps> = ({
  title,
  value,
  delta_value,
  delta_is_positive,
  delta_type,
  period_description,
  disclaimer,
  inverse_delta = false,
  wide = false,
  className = "",
}) => {
  return (
    <BoxMetricWrapper wide={wide} className={className}>
      <ReactTooltip />
      <HeadingContainer>
        <Heading>{title}</Heading>
      </HeadingContainer>
      <ValueContainer>
        <NumberAndBadge>
          <Number>{value}</Number>
          {delta_is_positive !== undefined && (
            <ChangeAndText>
              <Change>
                <Styled src={delta_is_positive ? upArrow : downArrow} />
                <ChangeValue
                  is_positive={
                    !inverse_delta ? !!delta_is_positive : !!!delta_is_positive
                  }
                >
                  {delta_value}
                  {delta_type}
                </ChangeValue>
              </Change>
              <PeriodDescription>{period_description}</PeriodDescription>
            </ChangeAndText>
          )}
        </NumberAndBadge>
      </ValueContainer>

      {!!disclaimer && (
        <div className="relative z-90">
          <span className="absolute bottom-0 right-0">
            <IconBase src={InfoIcon} data-tip={disclaimer} />
          </span>
        </div>
      )}
    </BoxMetricWrapper>
  );
};

export default BoxMetricGroup;
