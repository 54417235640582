import React, { useEffect, useState } from "react";
import {
  MainWrapper,
  Container,
  Section,
} from "pages/TeamPage/TeamPage.styled";

import PageHeader from "components/layout/PageHeader";
import TableComponent from "components/core/TableComponent";
import FilterBar from "components/core/FilterBar";
import { useNavigate } from "react-router-dom";

export type ApplicantPageProps = {};

const ApplicantPage: React.FC<ApplicantPageProps> = ({}) => {
  const navigate = useNavigate();
  const [filter, setFilter] = useState<string | undefined>();

  return (
    <MainWrapper>
      <Container>
        <PageHeader
          title={"Applicants"}
          subtitle={
            "All applicants associated with Finstead deals - if you want to add a new applicant, create a new deal."
          }
        />
      </Container>
      <Section className="min-h-full">
        <div className="flex w-full">
          <FilterBar setFilter={setFilter} />
        </div>

        <TableComponent
          onRowClick={(row: any, newTab = false) => {
            if (newTab) {
              window.open(
                `https://crm.finstead.com.au/applicants/${row.id}`,
                "_blank",
                "noreferrer"
              );
            } else {
              navigate(`/applicants/${row.id}`);
            }
          }}
          title={"Applicants"}
          endpoint={"organisations/people/"}
          filter={filter}
          fields={{
            id: {
              name: "Id",
            },
            pipedrive_id: {
              name: "Pipedrive Id",
            },
            first_name: {
              name: "First Name",
            },
            last_name: {
              name: "Last Name",
            },
            email_address: {
              name: "Email",
            },
            contact_number: {
              name: "Contact Number",
            },
            organisation: {
              name: "Organisation",
            },
          }}
        />
      </Section>
    </MainWrapper>
  );
};

export default ApplicantPage;
