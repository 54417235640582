import Badge, { BadgeType } from "components/core/Badge";
import LoaderLogo from "components/core/LoaderLogo";
import TableComponent from "components/core/TableComponent";
import BasicTableCell from "components/core/TableComponent/components/BasicTableCell";
import useUser from "hooks/useUser";
import React from "react";

type ComponentProps = { refreshTrigger?: boolean };
const PartnerAPIAccessLogsView: React.FC<ComponentProps> = ({
  refreshTrigger,
}) => {
  const { user } = useUser();

  return (
    <div className="w-full h-full">
      {!user ? (
        <LoaderLogo />
      ) : (
        <TableComponent
          triggerReload={refreshTrigger}
          endpoint={`partners/api/logs/`}
          onRowClick={(row: any, newTab = false) => {}}
          fields={{
            method: {
              name: "HTTP Method",
            },
            endpoint: {
              name: "Endpoint Path",
            },
            errors: {
              name: "Errors",
            },
            when: {
              name: "Called",
            },
            created_at: {
              name: "Call Date",
            },
            status_code: {
              name: "HTTP Status Code",
              middleware: (row: any) =>
                199 < row?.status_code && row?.status_code <= 299 ? (
                  <td>
                    <Badge type={BadgeType.success} text={row.status_code} />
                  </td>
                ) : (
                  <td>
                    <Badge type={BadgeType.danger} text={row.status_code} />
                  </td>
                ),
            },
            is_test: {
              name: "Is Test",
              middleware: (row: any) =>
                row?.is_test ? (
                  <td>
                    <Badge type={BadgeType.info} text={"YES"} />
                  </td>
                ) : (
                  <td />
                ),
            },
          }}
        />
      )}
    </div>
  );
};

export default PartnerAPIAccessLogsView;
