import styled from "styled-components";

import { TextSmMedium, TextSmNormal } from "styles/mixins";

export const FooterWrapper = styled.div`
  display: flex;
  align-self: stretch;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px 16px 32px 16px;
  gap: 24px;
`;
export const Navigation = styled.div`
  display: flex;
  align-self: stretch;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
`;
export const Divider = styled.div`
  display: flex;
  width: 247px;
  height: 1px;
`;
export const Styled1 = styled.svg`
  fill: ${({ theme }) => theme.colors.gray_600};
  width: 247px;
  height: 1px;
`;
export const Styled = styled.path``;
export const Account = styled.div`
  display: flex;
  align-self: stretch;
  justify-content: space-between;
  align-items: flex-start;
  padding: 0px 8px 0px 8px;
  gap: 47px;
`;
export const AvatarLabelGroup = styled.div`
  display: flex;
  width: fit-content;
  align-items: center;
  gap: 12px;
`;
export const TextAndSupportingText = styled.div`
  display: flex;
  width: fit-content;
  flex-direction: column;
  align-items: flex-start;
`;
export const Text = styled.span`
  text-align: left;
  white-space: nowrap;
  color: ${({ theme }) => theme.colors.white};

  ${TextSmMedium}
`;
export const SupportingText = styled.span`
  text-align: left;
  white-space: nowrap;
  color: ${({ theme }) => theme.colors.gray_100};

  ${TextSmNormal}
`;
export const LogoutButton = styled.div`
  position: relative;
  right: -11px;
  top: 0px;
  cursor: pointer;
  border-radius: 6px;

  padding: 5px;
  padding-top: 8px;

  &:hover {
    background-color: ${({ theme }) => theme.colors.gray_700};
  }
`;
