import styled from "styled-components";

import { TextLgMedium, TextSmNormal, TextXlNormal } from "styles/mixins";

export const SectionHeaderWrapper = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px 0px 1px 0px;
  gap: 20px;
  margin-bottom: 32px;
`;
export const TextAndSupportingText = styled.div`
  display: flex;
  align-self: stretch;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
`;
export const Text = styled.span`
  display: inline-block
  text-align: left;
  align-self: stretch;
  color: ${({ theme }) => theme.colors.gray_900};

  ${TextXlNormal}
`;
export const SupportingText = styled.span`
  text-align: left;
  align-self: stretch;
  color: ${({ theme }) => theme.colors.gray_500};

  ${TextSmNormal}
`;
export const Divider = styled.div`
  display: flex;
  background: rgba(228, 231, 236, 1);
  width: 100%;
  height: 1px;
`;
