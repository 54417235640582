import React from "react";
import Select from "react-select";

type MultiSelectDropDownFieldProps = {
  options: any;
  handleChange: any;
  selected: any;
  disabled?: boolean;
};

const MultiSelectDropDownField: React.FC<MultiSelectDropDownFieldProps> = ({
  options,
  handleChange,
  selected,
  disabled = false,
}) => {
  return (
    <Select
      className="w-full"
      options={options}
      isMulti={true}
      onChange={handleChange}
      value={selected}
      isDisabled={disabled}
    />
  );
};

export default MultiSelectDropDownField;
