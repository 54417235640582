import { useState } from "react";
import SlidingPane from "components/layout/SlidingPane";
import "react-sliding-pane/dist/react-sliding-pane.css";
import * as yup from "yup";
import InputField from "components/inputs/InputField";
import DropDownField from "components/inputs/DropDownField";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import Button from "components/core/Button";
import LoadingBar from "react-top-loading-bar";
import ReactTooltip from "react-tooltip";
import { post } from "utilities/Network";
import JSONPretty from "react-json-pretty";
import Lottie from "react-lottie-player";

import * as fireworksAnimationData from "components/core/Modal/fireworks.json";
// import "react-json-pretty/themes/monikai.css";

type Props = {
  isOpen?: boolean;
  toggle?: (state: boolean) => void;
  cb?: () => void;
};

const EVENT_TYPES = [
  "deal_created",
  "deal_assigned_updated",
  "deal_stage_updated",
  "deal_settled",
  "deal_lost",
];

export const FormSchema = yup.object({
  event_type: yup
    .string()
    .oneOf(EVENT_TYPES)
    .required("You must select an event type."),
  url: yup
    .string()
    .url()
    .required(
      "You must provide a valid target url for Finstead to send this event to."
    ),
  bearer_token: yup
    .string()
    .min(32)
    .required("You must provide a bearer token."),
});

const RequiredFieldsMap = {
  url: true,
  event_type: true,
  bearer_token: true,
};

type FormData = {
  event_type: string;
  url: string;
  bearer_token: string;
};

const PartnerNewWebhookRegistrationForm = ({ isOpen, toggle, cb }: Props) => {
  const [submitting, setSubmitting] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [testing, setTesting] = useState(false);
  const [notTested, setNotTested] = useState(true);
  const [testSuccess, setTestSuccess] = useState<any>();
  const [showAnimation, setShowAnimation] = useState(false);
  const [testData, setTestData] = useState<any>();
  const {
    register,
    handleSubmit,
    reset,
    getValues,
    formState: { errors },
  } = useForm<FormData>({
    resolver: yupResolver(FormSchema),
  });

  const sendTest = () => {
    setTesting(true);
    setNotTested(false);
    post("partners/api/webhooks/test/", {
      bearer_token: getValues("bearer_token"),
      url: getValues("url"),
      event_type: getValues("event_type"),
    }).then((response) => {
      setTestData(response.data.response);
      setTestSuccess(response.data.success);
      setShowAnimation(true);
      setTimeout(() => {
        setShowAnimation(false);
      }, 3500);
    });
  };

  const submit = (formData: FormData) => {
    setSubmitting(true);
    post("partners/api/webhooks/create/", formData).then((response) => {
      setSubmitted(true);
      setSubmitting(false);
      if (!!cb) {
        cb();
      }
      if (!!toggle) {
        toggle(false);
      }
    });
  };

  return (
    <SlidingPane
      className="overlay-pane-content"
      overlayClassName="overlay-pane"
      isOpen={isOpen}
      title="New Webhook Registration"
      width="800px"
      subtitle="Subscribe to a specific event type to receive notifications when that event occurs back to your API."
      onRequestClose={() => {
        // triggered on "<" on left top click or on outside click
        if (!!toggle) {
          toggle(false);
        }
      }}
      cancelPromptTitle={"Abandon new Webhook Registration?"}
      cancelPromptMessage={
        "Are you sure you'd like to abandon this new Webhook Registration?"
      }
    >
      <ReactTooltip />
      <LoadingBar
        progress={!notTested ? 100 : testing ? 50 : 0}
        color="#AE7221"
      />

      <LoadingBar
        progress={submitted ? 100 : submitting ? 50 : 0}
        color="#AE7221"
      />

      {/* URL */}
      <InputField
        formControlProps={register("url")}
        label={"Target URL"}
        disabled={submitting}
        errorMessage={errors.url?.message}
        required={!!RequiredFieldsMap.url}
      />

      {/* Bearer Token */}
      <InputField
        formControlProps={register("bearer_token")}
        label={"Bearer Token"}
        disabled={submitting}
        errorMessage={errors.bearer_token?.message}
        required={!!RequiredFieldsMap.bearer_token}
      />

      {/* Event Type */}
      <DropDownField
        formControlProps={register("event_type")}
        label={"Event Type"}
        loading={submitting}
        disabled={submitting}
        errorMessage={errors.event_type?.message}
        required={!!RequiredFieldsMap.event_type}
        placeholder={"Please select an event type."}
        options={EVENT_TYPES.map((type) => {
          return { text: type, value: type };
        })}
      />
      <br />
      <div className="w-full flex justify-end flex-row gap-6">
        <Button onClick={sendTest} text={"Send Test"} />
        {!notTested && testSuccess && (
          <Button
            onClick={handleSubmit(submit)}
            text={"Confirm"}
            disabled={notTested}
            data-tip={
              notTested
                ? "You must send a successful test before you can create this webhook registration."
                : ""
            }
          />
        )}
      </div>
      <br />
      <br />
      <br />
      <hr />
      <br />
      <br />
      {testSuccess && showAnimation && (
        <div className="absolute top-0 left-0 w-full h-full z-80">
          <Lottie
            animationData={fireworksAnimationData}
            style={{ width: "100%", height: "100%" }}
            loop={false}
            play={true}
          />
        </div>
      )}
      {!notTested && testSuccess !== null && (
        <div>
          <h3 className="text-2xl font-bold">Test Response</h3>
          {testSuccess === false && (
            <div className="bg-red-200 text-lg flex items-center justify-center w-full mb-4 mt-4 p-5 min-h-[150px] text-center">
              Something went wrong when contacting your API. You'll need to fix
              this before proceeding.
            </div>
          )}
          {testSuccess === true && (
            <div className="bg-green-200 text-lg flex items-center justify-center w-full mb-4 mt-4 p-5 min-h-[150px] text-center">
              Your test was successful! You can now confirm creation this
              webhook and go live.
            </div>
          )}
          <br />
          <h3 className="text-lg font-bold">Status Code:</h3>
          <p className="mb-4">{testData?.status_code}</p>

          <h3 className="text-lg font-bold">Data:</h3>
          <p className="mb-4 overflow-x-scroll">
            <JSONPretty id="json-pretty" data={testData?.data}></JSONPretty>
          </p>
          <h3 className="text-lg font-bold">Headers:</h3>
          <p className="mb-4 overflow-x-scroll">
            <JSONPretty id="json-pretty" data={testData?.headers}></JSONPretty>
          </p>
        </div>
      )}
    </SlidingPane>
  );
};
export default PartnerNewWebhookRegistrationForm;
