import { useMemo } from "react";
import useUser from "./useUser";

export enum DashboardModes {
  Broker,
  Admin,
}

const useIsAdmin = () => {
  const { scope } = useUser();

  const isAdmin = useMemo(() => {
    if (scope.indexOf("dashboard-administrator") !== -1) {
      return true;
    } else {
      return false;
    }
  }, [scope]);

  return {
    isAdmin,
  };
};

export default useIsAdmin;
