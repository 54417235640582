import React, { memo, FC } from "react";
import { IconWrapper, IconWrapperProps } from "./IconBase.styled";

const IconBase = memo((props: IconWrapperProps & { src: FC }) => {
  const { src, ...rest } = props;
  const Icon = src;
  return (
    <IconWrapper {...rest}>
      <Icon />
    </IconWrapper>
  );
});

export default IconBase;
