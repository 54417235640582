import React from "react";
import InputFieldWrapper from "components/inputs/InputFieldWrapper";
import { Input } from "./InputField.styled";

export type InputFieldProps = {
  label?: string;
  subtitle?: string;
  placeholder?: string;
  type?: string;
  value?: string;
  onChange?: (v: any) => void;
  disabled?: boolean;
  required?: boolean;
  errorMessage?: any | string | undefined;
  formControlProps?: any;
  loading?: boolean;
  autoFocus?: boolean;
  step?: any;
};

const InputField: React.FC<InputFieldProps> = ({
  label,
  subtitle,
  placeholder,
  type,
  value,
  onChange,
  errorMessage,
  loading = false,
  disabled = false,
  required = false,
  autoFocus = false,
  formControlProps = {},
  step,
}) => {
  return (
    <InputFieldWrapper
      label={label}
      required={required}
      errorMessage={errorMessage?.split("_").join(" ")}
    >
      <>
        {subtitle && <label>{subtitle}</label>}
        <Input
          loading={loading}
          placeholder={loading ? "" : placeholder}
          type={type}
          value={value}
          onChange={(e: any) => onChange && onChange(e.target.value)}
          disabled={disabled}
          hasError={!!errorMessage}
          autoFocus={autoFocus}
          step={step}
          {...formControlProps}
        ></Input>
      </>
    </InputFieldWrapper>
  );
};

export default InputField;
