import React, { useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { get } from "utilities/Network";
import BrokerageInvoiceTemplate from "./templates/BrokerageInvoiceTemplate";
import LoaderLogo from "components/core/LoaderLogo";
import ReferrerRCTIInvoiceTemplate from "./templates/ReferrerRCTIInvoiceTemplate";
import ApplicantRCTIInvoiceTemplate from "./templates/ApplicantRCTIInvoiceTemplate";
import TaxInvoiceTemplate from "./templates/TaxInvoiceTemplate";
import PrivateSaleInvoiceTemplate from "./templates/PrivateSaleInvoiceTemplate";

const PDFPage = () => {
  const { pdfAccessToken } = useParams();
  const [loading, setLoading] = useState(true);
  const [pdf, setPdf] = useState<any>(null);

  useEffect(() => {
    get(`files/context/${pdfAccessToken}/`).then((response) => {
      setLoading(false);
      setPdf(response.data.context);
    });
  }, [pdfAccessToken]);

  const reportComponent = useMemo(() => {
    if (!!pdf) {
      switch (pdf.data_type) {
        case "brokerage_invoice":
          return <BrokerageInvoiceTemplate pdf={pdf} />;
        case "referrer_rcti_invoice":
          return <ReferrerRCTIInvoiceTemplate pdf={pdf} />;
        case "applicant_rcti_invoice":
          return <ApplicantRCTIInvoiceTemplate pdf={pdf} />;
        case "tax_invoice_request":
          return <TaxInvoiceTemplate pdf={pdf} />;
        case "private_sale_invoice":
          return <PrivateSaleInvoiceTemplate pdf={pdf} />;
      }
    }
    return null;
  }, [pdf]);

  if (loading || !pdf) {
    return (
      <div className="flex items-center justify-center w-full h-full bg-white">
        <LoaderLogo withText={true} light={true} />
      </div>
    );
  }
  return reportComponent;
};

export default PDFPage;
