import styled from "styled-components";

export const Container = styled.div`
  overflow: hidden;
  display: flex;
  height: 100%;
  width: 100%;
  background: rgba(255, 255, 255, 1);
`;

export type PageContainerProps = {
  wide?: boolean;
  noMargin?: boolean;
  collapsed?: boolean;
};

export const PageContainer = styled.div`
  display: flex;
  background: rgba(255, 255, 255, 1);
  height: 100%;
  flex-direction: column;
  align-items: flex-start;
  padding: 32px 0px 48px 0px;
  ${(props: PageContainerProps) => (props.noMargin ? "padding: 0px;" : "")}
  gap: 32px;
  width: calc(
    100vw -
      ${(props: PageContainerProps) =>
        props.wide ? "0px" : props.collapsed ? "45px" : "279px"}
  );
  margin-left: ${(props: PageContainerProps) =>
    props.wide ? "0px" : props.collapsed ? "45px" : "279px"};

  overflow-y: scroll;
`;
